import { useEffect, useState } from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import useDocuments from '../../../hooks/useDocuments';
import { format } from 'date-fns';
import { log } from '../../../console-config';

const getStatusColor = (status) => {
    switch (status) {
        case 'PENDING':
        case 'Pending':
        case 'ONGOING':
        case 'Ongoing':
            return '#f3bb1b';
        case 'RESEND':
        case 'Resend':
        case 'Document Resend Email':
        case 'CREATE':
        case 'Create':
        case 'CREATED':
        case 'Created':
            return '#0178f2';
        case 'CANCELLED':
        case 'Cancelled':
        case 'UPDATE':
        case 'Update':
            return '#fd8e39';
        case 'DENIED':
        case 'Denied':
        case 'ERROR':
        case 'Error':
        case 'DECLINED':
        case 'Declined':
            return 'red';
        case 'COMPLETED':
        case 'Completed':
            return 'green';
        case 'DRAFT':
        case 'Draft':
            return 'gray';
        default:
            return 'gray';
    }
};

const CustomTimelineItem = styled(TimelineItem)(({ theme }) => ({
    '&:before': {
        content: 'none',
    },
}));

const SkeletonTimeline = () => (
    <Timeline position="right">
        {[1, 2, 3].map((_, index) => (
            <CustomTimelineItem key={index}>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                        <Skeleton variant="circular" width={24} height={24} />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Skeleton width="60%" />
                    <Skeleton width="40%" />
                    <Skeleton width="80%" />
                </TimelineContent>
            </CustomTimelineItem>
        ))}
    </Timeline>
);

export default function HistoryDialog(props) {
    const { openHistory, handleCloseHistoryDialog, document_id, document } = props;

    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');

    const { documentHistory, historyData, generatingHistory } = useDocuments();

    useEffect(() => {
        log("document_id here", document_id)
        if (document_id) {
            documentHistory(document_id);
        }
    }, [document_id])

    return (
        <Dialog onClose={handleCloseHistoryDialog} open={openHistory} fullWidth={fullWidth} maxWidth={maxWidth}>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
                    {document?.title} Document History
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton sx={{ color: '#0178f2' }} onClick={handleCloseHistoryDialog}><CloseIcon /></IconButton>
            </DialogTitle>
            <DialogContent sx={{ maxHeight: historyData.length >= 7 ? '500px' : 'none', overflowY: historyData.length >= 7 ? 'auto' : 'visible' }}>
                {generatingHistory ? (
                    <SkeletonTimeline />
                ) : historyData.length === 0 ? (
                    <Typography variant="body1" color="text.secondary">
                        No history found
                    </Typography>
                ) : (
                    <Timeline position="right">
                        {historyData.map((item, index) => (
                            <CustomTimelineItem key={index}>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot sx={{ bgcolor: getStatusColor(item.status) }} />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        {format(new Date(item && item.timestamp), 'PPpp')}
                                    </Typography>
                                    <Typography sx={{ fontSize: 18 }}>
                                        {item.status}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }}>{item.activity} by {item.email}</Typography>
                                </TimelineContent>
                            </CustomTimelineItem>
                        ))}
                    </Timeline>
                )}
            </DialogContent>

        </Dialog>
    );
}
