import React, {useEffect, useState} from "react";
import {Grid, Typography, Button} from "@mui/material";
import environment from "../../../environment/environment";
import QRCodeGenerator from "qrcode";
import QRCode from "qrcode.react";
import useSignature from "../../../hooks/useSignature";
import CreateSignatureByLinkSkeletonComponent from "../../skeleton/create-signature-byLink-skeleton-component";
import { log } from "../../../console-config";
import MsalUtil from "../../../services/utils/msals";

const ChangeSignatureByLink = ({props}) => {

    const {breakpoints, user} = props;

    const [qrLinkWeb, setQrLinkWeb] = useState(null);
    const {accessToken, isFetchingAccessToken, getEsignatureAccessToken} = useSignature();
    log("qrLinkWeb xxxx" + qrLinkWeb, accessToken);
    const currentUser = MsalUtil.getFirstAccount().username

    log('userrrrrrrrrrrrrrrrrrrrrrrrr', currentUser)
 
    const env = environment.msal_url.url + "/ovsign/signaturePad?accessToken=";
    // const env = environment.msal_url.url + "/sample?accessToken=";
    // const env = "http://localhost:3000/ovsign/signaturePad?accessToken=";
    // const env = "https://icy-plant-0b487f81e.2.azurestaticapps.net/ovsign/signaturePad?accessToken=";

    
    useEffect(() => {
        getEsignatureAccessToken();

        if (accessToken) {
            generateQR();
        }
    }, [accessToken])


    const generateQR = async () => {
        let qrLink;

        if (user || currentUser) {
            qrLink = await QRCodeGenerator.toDataURL(env + accessToken + `&email=${user ? user.email_address : currentUser}`);
            setQrLinkWeb(env + accessToken + `&email=${ user ? user.email_address : currentUser}`);
        }

        return qrLink;
    }

    if (isFetchingAccessToken) return <CreateSignatureByLinkSkeletonComponent breakpoints={breakpoints} />
    
    
    return (
        <Grid container sx={{padding: "8px 0px"}}> 
            <Grid item xs={12} sx={{textAlign: "center"}}>
                <QRCode value={qrLinkWeb} style={{width: 231, height: 231}} />
            </Grid>

            <Grid item xs={12} sx={{textAlign: "center", mt: "10px"}}>
                <Typography variant="caption" sx={{fontWeight: "bold"}}>
                    Scan QR Code
                </Typography>
            </Grid>

            <Grid item xs={12} sx={{textAlign: "center"}}>
                <Typography variant="caption" sx={{fontWeight: "bold"}}>
                    or
                </Typography>
            </Grid>

            <Grid item xs={12} sx={{textAlign: "center", mt: "-4px", mb: "-3px"}}>
                <Button href={(breakpoints == "xs" || breakpoints == "sm") ? qrLinkWeb : qrLinkWeb} sx={{fontWeight: "bold", color: "#0178f2", textTransform: "none", fontSize: 14}}>
                    <a href={qrLinkWeb} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "#0178f2"}}>
                        Click here to sign
                    </a>
                </Button>
            </Grid>

            <Grid item xs={12} sx={{textAlign: "center", mt: "-4px", mb: "-3px"}}>
                <Typography variant="caption" sx={{fontWeight: "bold", fontStyle: 'italic'}}>
                    iOS device not supported.
                </Typography>
            </Grid>
        </Grid>
    ) 
}

export default ChangeSignatureByLink