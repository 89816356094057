import React from "react";
import {Grid, Card, CardContent, Avatar, Typography, IconButton, Button, Tooltip, Box} from "@mui/material";
import {stringAvatar} from "../../utils/stringAvatarUtil";
import EditIcon from '../../icons/edit.png';
import { Link } from "react-router-dom";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ImageCropperDialog from "./dialog/image-cropper.dialog";
import { useState } from "react";
import profileUpload from '../../icons/file-upload.png'
import { log } from "../../console-config";

const ProfileComponent = (props) => {

    const {breakpoints, user} = props;
    const [userImg, setUserImg] = useState(null);
    const [userPhotoPreview, setUserPhotoPreview] = useState(null);
    const [photoFile, setPhotoFile] = useState(null);
    const [loading, setLoading] = useState(false)
    const [openImageEditor, setOpenImageEditor] = useState(false);

    const handleClearPhoto = () => {
        setPhotoFile(null);
        setUserPhotoPreview(null);
    }

    const validatePhoto = (e) => {
        setImageStateFromChild();

        var fileName = document.getElementById("upload-photo").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile == "jfif" || extFile == "svg" || extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        
            const imageFile = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                setUserImg(e.target.result);
            }

            reader.readAsDataURL(imageFile);

            setOpenImageEditor(true);
 
        } else {
            log("Only image files are supported as of now");
        }
    }

    const setImageStateFromChild = (child) => {
        setUserImg(child);
    }

    log('get user photo xxxxxxxxxxxxx', user)

    return (
        <>
            <Card sx={{borderRadius: 2, padding: 3, paddingTop: 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints == 'xs' || breakpoints == 's' ? 'auto' : "80vh"}}>
                <CardContent>
                    <Grid container sx={{textAlign:'center', margin: 'auto', justifyContent: 'center', alignSelf: 'center'}}>
                        <Grid item xs={12} sm={12} sx={{top: breakpoints == 'xs' ? 'none' : '40%', position: breakpoints == 'xs' ? 'none' : 'absolute'}}>
                            <Grid container sx={{justifyContent: 'center', alignSelf: 'center', width:'100%'}}>
                                { user &&
                                    <Avatar className="AvatarLogo"
                                        {...stringAvatar(user?.firstName ? user?.firstName : user?.email_address )}
                                        src={user?.profile_picture}
                                        sx={{width: 120, height: 120, fontSize: 50, fontWeight: "bold", backgroundSize: "cover", backgroundColor: "#168401", border:'1px solid #168401', "&.MuiAvatar-img": {borderRadius: "50% !important", borderColor:'black'}}} 
                                    />
                                }
                                { user?.firstName && user?.lastName ?
                                    <Grid item xs={12} sm={12} >
                                        <Typography sx={{fontWeight: 600, fontSize: 18, mb: 2, mt: 2}}>{user?.firstName}&nbsp;{user?.lastName} </Typography>
                                    </Grid>
                                    :
                                    null
                                }
                                    <Grid item xs={12} sm={12} sx={{width:'100%'}}>
                                        <Typography sx={{fontSize: 13, fontWeight: 450, color: '#21649e'}}>Email: {user?.email_address}</Typography>
                                    </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {openImageEditor &&
                <ImageCropperDialog userImg={userImg} setImageStateFromChild={setImageStateFromChild}
                setUserPhotoPreview={setUserPhotoPreview} setPhotoFile={setPhotoFile} 
                openImageEditor={openImageEditor} setOpenImageEditor={setOpenImageEditor}
                />
            }
        </>
    )
}

export default ProfileComponent