import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, IconButton, Stack, Switch, TableCell, TableRow, Tooltip, Typography } from "@mui/material"
import { useState, useEffect } from "react";
import useDocuments from "../../hooks/useDocuments";
import { format } from 'date-fns';
import DeleteFileDialog from "../dialog/delete-file-dialog.component";
import DownloadFileDialog from "../dialog/download-file-dialog.component";
import ResendEmailDialog from "../dialog/resend-email-dialog.component";
import RetryEmailDialog from "../dialog/retry-email-dialog.component";
import DocumentWorkflowService from "../../services/api/documents";
import { ThreeDots } from "react-loader-spinner";
import RetryErrorDialog from "../dialog/retry-error-dialog.component";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ActionsPopperComponent from "./actions-popper.component";
import { log } from "../../console-config";
import { useNavigate } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import ResendFileDialog from "../dialog/resend-file-dialog.component";
import DeclineReasonDialog from "./dialog/decline-reason-dialog.component";
import HistoryDialog from "./dialog/history-dialog.component";



function FilesListComponent(props) {

    const { document, openDeleteFile, handleCloseDeleteFile, setOpenDeleteFile, isDeleting, handleOpenDeleteFile, idToDelete, index, retrieveDocuments } = props;

    // console.log("document here aaa:", document)

    const { viewFile, isViewing, updateAutoReminder, isUpdating, getDeniedReason, decline_reason, generating } = useDocuments();
    const navigate = useNavigate()

    const [selectedIndex] = useState(index);
    const [noReminder, setNoReminder] = useState(false);
    const [reminder, setReminder] = useState(true);
    const [openDownloadFile, setOpenDownloadFile] = useState(false);
    const [openResendEmail, setOpenResendEmail] = useState(false);
    const [openResendFile, setOpenResendFile] = useState(false);
    const [openRetryEmail, setOpenRetryEmail] = useState(false);
    const [openRetryError, setOpenRetryError] = useState(false);
    const [switchValue, setSwitchValue] = useState({ [index]: document?.document?.is_auto_remind });
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);


    const handleSwitchChange = (e, selectedIndex, id) => {

        // log('get selected id', id)
        // log('eeeeeeeeeeee', e)

        let document_id = id;
        let is_auto_reminder = e.target.checked;

        // log('handle selected value', is_auto_reminder)

        setSwitchValue(prevState => ({
            ...prevState,
            [selectedIndex]: e.target.checked // Update state for the specific index
        }));

        if (e.target.checked) {
            updateAutoReminder(document_id, is_auto_reminder);
        } else {
            updateAutoReminder(document_id, 'false');
        }
    };

    const handleCloseDownloadFile = () => {
        setOpenDownloadFile(false);
    };

    const handleCloseResendEmail = () => {
        setOpenResendEmail(false);
    }

    const handleCloseRetryEmail = () => {
        setOpenRetryEmail(false);
    }

    const handleCloseRetryError = () => {
        setOpenRetryError(false);
    }

    const handleCloseResendFile = () => {
        setOpenResendFile(false);
    }

    // Function to check if retry is allowed based on the created date
    function isRetryAllowed(createdDate) {
        // log("createdDate:", createdDate);
        if (!createdDate) {
            return false;
        }

        const currentDate = new Date();
        const thirtyMinutesAgo = new Date(new Date(createdDate).getTime() + 30 * 60000); // Adding 30 minutes in milliseconds
        return currentDate >= thirtyMinutesAgo;
    }

    const handleClickView = (id) => {
        viewFile(id)
    }

    const viewDocuments = () => {
        const view = document?.document?.status === "COMPLETED" ? navigate("/view-template?id=" + document?.document?.id) : navigate("/view-template?id=" + document?.document?.id);

        return view;
    }


    const handleInfoClick = () => {
        console.log('Info icon clicked');
        if (document?.document?.id) {
            getDeniedReason(document?.document?.id, setOpenInfoDialog);
        }
    };

    const handleCloseDialog = () => {
        setOpenInfoDialog(false);
    };


    const handleCloseHistoryDialog = () => {
        setOpenHistory(false);
    };

    // Function to calculate signed status
    const calculateSignedStatus = (document) => {
        console.log("document here", document)
        const total_signed = document?.document?.documentSignatory.filter(item => item?.status === "DONE").length;
        const total_signatory = document?.document?.documentSignatory.length;
        return `${total_signed}/${total_signatory}`;
    };

    // log("document here", document)

    return (
        <>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell sx={{ color: "#0178f2", fontWeight: 530, cursor: "pointer" }} component="th" scope="row" onClick={viewDocuments}>
                    {isViewing ? <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} /> : <Box sx={{ display: 'flex', algnItems: 'center' }}><PictureAsPdfIcon sx={{ marginRight: 1, color: document?.document?.status == 'PENDING' || document?.document?.status == 'pending' ? '#f3bb1b' : document?.document?.status == 'COMPLETED' || document?.document?.status == 'completed' ? 'green' : document?.document?.status == 'ERROR' || document?.document?.status == 'error' ? 'red' : document?.document?.status == 'CANCELLED' || document?.document?.status == 'cancelled' ? '#fd8e39' : document?.document?.status === 'DENIED' ? 'red' : 'gray' }} />{document?.document?.title}</Box>}
                </TableCell>

                <TableCell align="left" >
                    {calculateSignedStatus(document)}
                </TableCell>

                <TableCell align="left" sx={{ color: document?.document?.status == 'PENDING' || document?.document?.status == 'pending' ? '#f3bb1b' : document?.document?.status == 'COMPLETED' || document?.document?.status == 'completed' ? 'green' : document?.document?.status == 'ERROR' || document?.document?.status == 'error' ? 'red' : document?.document?.status == 'CANCELLED' || document?.document?.status == 'cancelled' ? '#fd8e39' : 'inherit', fontWeight: 530 }}>
                    {/* {document?.document?.status} */}
                    {document?.document?.status === 'DENIED' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                            {document?.document?.status} &nbsp;
                            {generating ? <ThreeDots height="20" width="20" radius="9" color="red" ariaLabel="three-dots-loading" visible={true} /> :
                                <Tooltip title="Reason">
                                    <IconButton onClick={() => handleInfoClick(document)}>
                                        <InfoIcon sx={{ color: 'red' }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                    ) : (
                        document?.document?.status
                    )}
                </TableCell>

                <TableCell align="left" >
                    {format(new Date(document && document?.document?.updated_time), 'PPpp')}
                </TableCell>

                <TableCell align="center" >
                    <FormGroup key={index} className="card-update" sx={{ alignContent: 'center' }}>
                        {isUpdating ?
                            <Box sx={{ textAlign: "center" }}>
                                <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />
                            </Box>
                        :
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>On</Typography>
                                <Switch
                                    id={index}
                                    inputProps={{ "aria-label": "ant design" }}
                                    sx={{ transform: "scaleX(-1)" }}
                                    checked={switchValue[index] || false} // Use switchValue state or default to false
                                    onChange={(e) => handleSwitchChange(e, index, document?.document?.id)}
                                />
                                <Typography>Off</Typography>
                            </Stack>
                        }
                    </FormGroup>
                </TableCell>

                <TableCell align="left">
                    <ButtonGroup variant="text" aria-label="text button group" >
                        <ActionsPopperComponent documentDetails={document?.document} document={document?.document} isRetryAllowed={isRetryAllowed} handleClickView={handleClickView}
                            setOpenDeleteFile={setOpenDeleteFile} setOpenDownloadFile={setOpenDownloadFile} setOpenResendEmail={setOpenResendEmail}
                            openDeleteFile={openDeleteFile} idToDelete={idToDelete} retrieveDocuments={retrieveDocuments}
                            setOpenRetryEmail={setOpenRetryEmail} setOpenRetryError={setOpenRetryError} handleOpenDeleteFile={handleOpenDeleteFile}
                            isDeleting={isDeleting} handleCloseDeleteFile={handleCloseDeleteFile} setOpenResendFile={setOpenResendFile} setOpenHistory={setOpenHistory}
                        />
                    </ButtonGroup>
                </TableCell>
            </TableRow>


            {openDownloadFile && document &&
                <DownloadFileDialog openDownloadFile={openDownloadFile} handleClose={handleCloseDownloadFile}
                    handleCloseDownloadFile={handleCloseDownloadFile} userDocument={document?.document}
                />
            }

            {openResendEmail && document &&
                <ResendEmailDialog openResendEmail={openResendEmail} handleClose={handleCloseResendEmail} handleCloseResendEmail={handleCloseResendEmail}
                    userDocument={document?.document} />
            }

            {openRetryEmail && document &&
                <RetryEmailDialog openRetryEmail={openRetryEmail} handleClose={handleCloseRetryEmail}
                    handleCloseRetryEmail={handleCloseRetryEmail} userDocument={document?.document} />
            }

            {openRetryError && document &&
                <RetryErrorDialog openRetryError={openRetryError} handleClose={handleCloseRetryError}
                    handleCloseRetryError={handleCloseRetryError} userDocument={document?.document} />
            }

            {openResendFile && document &&
                <ResendFileDialog openResendFile={openResendFile} handleClose={handleCloseResendFile}
                    handleCloseResendFile={handleCloseResendFile} userDocument={document?.document} />
            }

            {openInfoDialog &&
                <DeclineReasonDialog openInfoDialog={openInfoDialog} handleCloseDialog={handleCloseDialog} decline_reason={decline_reason}
                    setOpenInfoDialog={setOpenInfoDialog} />
            }

            {openHistory &&
                <HistoryDialog openHistory={openHistory} handleCloseHistoryDialog={handleCloseHistoryDialog} setOpenHistory={setOpenHistory}
                    document_id={document?.document?.id} document={document?.document} />
            }


        </>
    )

}

export default FilesListComponent