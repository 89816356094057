import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export default function SuccessInquiryDialogComponent (props) {

    const {openSuccessInquiry, handleClose, resetInquiry} = props;

    return(
        <Dialog open={openSuccessInquiry}  maxWidth={"sm"} fullWidth={"sm"} onClose={handleClose} >
            {/* <DialogTitle sx={{display:'flex', alignItems:'center', bgcolor:'#00da00'}}>
                <Typography sx={{fontWeight:'bold', fontSize: 16, color:'white', display:'flex',alignItems:'center'}}> <TaskAltIcon sx={{mr: 1}}/> Send Inquiry</Typography>
                <Box sx={{flexGrow: 1}}/>
                <IconButton onClick={handleClose}>
                    <CloseIcon sx={{color:'white'}}/>
                </IconButton>
            </DialogTitle> */}
            <DialogContent sx={{m: 3, mt: 3, mb: 0, textAlign:'center'}}>
                <TaskAltIcon sx={{mr: 1, color:'green', height: 50 , width: 50}}/>
                <Typography sx={{textAlign:'center', fontWeight:'bold', color:'green'}}>
                    Inquiry Sent!
                </Typography>
                <Typography sx={{textAlign:'center', mt: 1, fontSize: 13}} >
                    Thank you for your inquiry! Your message has been successfully sent. Please check your email inbox for a response from our team. We will get back to you as soon as possible.
                </Typography>
            </DialogContent>
            <DialogActions sx={{justifyContent:'center', mb: 2}}>
                <Button variant="contained" onClick={resetInquiry} sx={{backgroundColor: '#168401',width: 200 ,borderRadius: 1, boxShadow: 'none', textTransform:'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                >
                   Okay
                </Button>
            </DialogActions>
        </Dialog>
    )
}