import React , { useContext } from 'react';
import { render } from "react-dom";

import {dialogStore, useDialogStore} from "./useDialogStore";
import Emitter from "./emitter";

let dialog_data = []
let dialog_data_unique = []

const DialogContext = React.createContext()

export const useDialog = () => {
    return useContext(DialogContext)
}

function removeDuplicates(item) {
    var i = dialog_data_unique.findIndex(x => (x.tag == item.tag));
    if(i <= -1){
        dialog_data_unique.push(item);
    }
}

export const DialogProvider = ({children}) => {

    const {dialogs, openDialog: open, closeDialog: close} = useDialogStore()
    
    /**
     * @param {string} tag - unique tag for dialog
     * @param {any} Component - any Dialog Component
     * @param {any} payload - any data to pass in dialog
     */

    const openDialog = (tag, component, payload) => {
    
        var data = {
            tag: tag,
            component: React.createElement(component)
        }
    
        dialog_data.push(data)
        dialog_data.filter(removeDuplicates)

        var promise = new Promise((resolve, reject) => {
            Emitter.on('CLOSE_COMMON_DIALOG', (res) => {
                Emitter.off('CLOSE_COMMON_DIALOG');
                resolve(res)
            });
            open(tag, payload)
        });
        return promise
    }
    
    const closeDialog = (tag, payload) => {
        Emitter.emit("CLOSE_COMMON_DIALOG", payload);
        close(tag)
    }

    const Dialog = {
        openDialog: openDialog,
        closeDialog: closeDialog,
    }

    return(
        <DialogContext.Provider value={Dialog}>
            {
                dialog_data_unique.length > 0 && 
                <>
                    {dialog_data_unique
                        .map((item) => {
                            const { tag, component } = item;
                            let dynamic_dialog = React.cloneElement(component, {open: dialogs[tag].open, tag: tag, params: dialogs[tag].params})
                         
                            return(
                                <>{dynamic_dialog}</>
                            );
                    })}
                </>
            }
            {children}
        </DialogContext.Provider>
    )
}