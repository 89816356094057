import { Box, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Grid, Typography } from "@mui/material"
import useWidth from "../../hooks/useWidth";
import { useNavigate } from "react-router-dom";
import useReports from "../../hooks/useReport";
import { useEffect, useState } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReportListSkeleton from "../skeleton/report-list-skeleton.component";
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import { log } from "../../console-config";
import { Download } from "@mui/icons-material";
import ExtractReportConfirmationModal from "./dialog/extract-report-confirmation-modal.component";

function TableHeader(){

    const tablecell = [ 
        {label : 'Date'},
        {label : 'Status'},
        {label : 'No. of Documents'},
    ]

    return(
        <TableHead >
            <TableRow>
                {tablecell.map((value)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, backgroundColor: '#3333cf', color: 'white'}}>{value.label}</TableCell>
                        
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

const ReportDetailsComponent = () => {

    let month = new URLSearchParams(window.location.search).get("month");
    let year = new URLSearchParams(window.location.search).get("year");
    let status = new URLSearchParams(window.location.search).get("status");
    

    const navigate = useNavigate();
    const pageNumber = 1;

    const {getReportMonthly, monthList, loading} = useReports();
    const [openExtractConfirmation, setOpenExtractConfirmation] = useState(false);

    const handleOpenExtractConfirmation = () => {
        setOpenExtractConfirmation(true)
    }

    const handleCloseModal = () => {
        setOpenExtractConfirmation(false)
    }

    const mappedData = monthList.map(item => {
        return {
            status: status,
            month: item[0],
            count: item[1]
          };
    });

    // Get total count
    const totalCount = mappedData.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.count;
    }, 0);

    const generatePDF = () => {
        const doc = new jsPDF();

        doc.text(`Document Report ${month} ${year}`, 10, 10);
      
        const columns = ["Status", "Month", "No. of Documents"];
        const data = mappedData.map(item => [item.status, item.month, item.count, item.year]);

        const overallTotal = mappedData.reduce((total, item) => total + item.count, 0);
      
        doc.autoTable({
          head: [columns],
          body: data,
          startY: 20
        });
        doc.setFontSize(12); // Set the font size to 12

        
        // Calculate the position to display the "Overall Total" text below the right side of the table
        const pageWidth = doc.internal.pageSize.getWidth();
        const textWidth = doc.getStringUnitWidth(`Overall Total: ${overallTotal}`) * 12; // 12 is the font size
        const textX = pageWidth - textWidth - -35; // Adjust the position as needed
        const textY = doc.autoTable.previous.finalY + 10; // Adjust the vertical position as needed

        doc.text(`Overall Total: ${overallTotal}`, textX, textY);

      
        doc.save('document-report.pdf');
      };

      const generatexlsx = () => {
        try {
            const wb = XLSX.utils.book_new();
    
            // Creating worksheet with merged cells for Month and Year in the first row
            const ws = XLSX.utils.aoa_to_sheet([[`Document Report ${month} ${year}`, "", "", ""]]);
            ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }];
    
            // Adding headers in the second row
            XLSX.utils.sheet_add_aoa(ws, [["Status", "Month", "No. of Documents"]], { origin: 'A2' });
    
            // Adding data from mappedDataX starting from the third row
            const dataRows = mappedData.map(item => [item.status, item.month, item.count, item.year]);
            XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: 'A3' });
    
            XLSX.utils.book_append_sheet(wb, ws, `Logs`);
            const excelFileName = `Document Report ${month} ${year}.xlsx`;
            XLSX.writeFile(wb, excelFileName);
            handleCloseModal()
        } catch (error) {
            console.error("Error generating Excel:", error);
            // Handle the error (e.g., show a message to the user)
        }

    };

    useEffect(()=>{
        getReportMonthly(status, year, month)
    },[])   
    
    log('mapped data list', mappedData)
    log('get total count', totalCount)

    return(
        <Box sx={{width:'98%', margin:'auto', pt: 1 }}>
            <Grid container >
                <Grid item sm={6} sx={{alignSelf:'center'}}>
                    <Button onClick={(e) => navigate("/reports")} startIcon={
                        <ArrowBackIosIcon style={{width: 20, height: 20, color: "168401"}} />} 
                            style={{color: "#fff", fontSize: 16, textTransform: "none", fontWeight: 600}}
                        >
                            <span style={{color: status == 'PENDING' || status == 'pending' ? 'orange' : status == 'COMPLETED' || status == 'completed' ? 'green' : status == 'ERROR' || status == 'error' ? 'red' : 'black'}}>{status}</span>
                    </Button>
                </Grid>
                <Grid item sm={6} sx={{alignSelf:'center', textAlign:'end'}}>
                    <Typography sx={{fontWeight:'bold'}}>
                        {month + " " + year}
                    </Typography>
                </Grid>
            </Grid>
            <Box sx={{textAlign:'end', mb: 2}}>
                {mappedData == null ?
                    <Button size="small" disabled sx={{textTransform:'none', fontSize: 12, fontWeight:'bold'}}>
                       <Download sx={{height: 20, width: 20}}/> Extract
                    </Button>
                :
                    <Button size="small" onClick={handleOpenExtractConfirmation} sx={{textTransform:'none', fontSize: 12, fontWeight:'bold', color: '#168401'}}>
                        <Download sx={{height: 20, width: 20, color: '#168401'}}/> Extract
                    </Button>
                }
            </Box>
            <TableContainer component={Paper} sx={{border: "1px solid #8080807a", borderRadius: 1,  width: "100%", mb: 2, mt: 2}}>
                <Table >
                    <TableHeader/>
                    {loading ? 
                        <ReportListSkeleton/>
                    :
                        <TableBody>
                            {mappedData?.map((list)=>(
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {list.month + ", " + year} 
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{color: status == 'PENDING' || status == 'pending' ? 'orange' : status == 'COMPLETED' || status == 'completed' ? 'green' : status == 'CANCELLED' || status == 'cancelled' ? 'red' : 'inherit',}}>
                                        {status} 
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {list.count}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            <Typography sx={{textAlign:'end', fontWeight:'bold'}}>
                Total Documents: {totalCount}
            </Typography>
            <ExtractReportConfirmationModal generatePDF={generatePDF} generatexlsx={generatexlsx} openExtractConfirmation={openExtractConfirmation} handleCloseModal={handleCloseModal} />
        </Box>
    )
}

export default ReportDetailsComponent