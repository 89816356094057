import { log } from "../../console-config";

let auth_token;

log("auth_token herexxx:", auth_token);

export const setAuthToken = (token) => {
    auth_token = token
}

export const getAuthToken = () => {
    return auth_token
}
