import useWidth from "../../hooks/useWidth";
import ovcodeLogox from "../../icons/new-ovcode-logo.png"
import ovcodeLogo from "../../icons/ovcode-reverse-white.png"
import { Box, Typography, Button, Grid } from "@mui/material";
import nextixLogo from "../../icons/nextixLogo.png"

export default function LandingConfirmationComponent () {

    const breakpoints = useWidth();
    return(
        <Box style={{height: '100vh', position: 'relative'}} class="content">
            <Box sx={{margin: breakpoints == 'xs' || breakpoints == 'sm' ? '0px' : '0px 50px 0px 60px'}}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}  sx={{alignSelf: 'start', textAlign: 'left',margin: 0, position: 'absolute',top: '50% ',transform: 'translate(0, -50%)'}} >
                    <img src={nextixLogo} style={{height: breakpoints == 'xs' || breakpoints == 'sm' ? 90 : 120, paddingLeft: 0, pb: 0}}/>
                    {breakpoints == 'xs' || breakpoints == 'sm' ? null  : <Box sx={{mb: 2}}/>   }
                    <Typography sx={{textAlign:'justify', maxWidth: breakpoints == 'xs' || breakpoints == 'sm' ? '100%' : 450, color: '#000',textJustify: 'inter-word', whiteSpace: 'pre-wrap',overflowWrap: 'break-word', ml: breakpoints == 'xs' || breakpoints == 'sm' ? '0px' : 1, margin: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : '0px'}}>
                    Thank you for using Nextix Inc. eSign. You just signed, secured, and authenticated your digital document with just a few easy steps.
                    </Typography>
                    <br/>
                    <Typography sx={{textAlign:'justify', maxWidth: breakpoints == 'xs' || breakpoints == 'sm' ? '100%' : 450, color: '#000',textJustify: 'inter-word', whiteSpace: 'pre-wrap',overflowWrap: 'break-word', ml: breakpoints == 'xs' || breakpoints == 'sm' ? '0px' : 1, margin: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : '0px'}}>
                    We look forward to your next document in our Nextix Inc. eSign platform!
                    </Typography>
                
                    {/* <DrawerHeader/> */}
                    <Button variant="contained" sx={{mt: 3, textTransform:'none', backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', mb: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 2}} onClick={()=> window.location.href="/"} >
                        Return Home
                    </Button>
                </Grid>
            </Box>

            <Box sx={{alignSelf: 'center', justifyContent: 'center', textAlign: breakpoints == 'xs' || breakpoints == 'sm' ? 'center' : 'right',padding: breakpoints == 'xs' || breakpoints == 'sm' ? '0.105em 0' : '1.125em 0', position: 'fixed', width: '100%', bottom: 0, ml: breakpoints == 'xs' || breakpoints == 'sm' ? '0px' :'-50px'}}>
                <Button sx={{textShadow: "3px 3px 3px rgb(0 0 0 / 43%)", textTransform: 'none', fontSize: 15, fontWeight: 500, color: breakpoints == 'xs' || breakpoints == 'sm' ? 'black' : 'white', fontWeight: 500}} endIcon={ <img src={breakpoints == 'xs' || breakpoints == 'sm' ? ovcodeLogox : ovcodeLogo} style={{height: 28}} />}>
                    Powered by
                </Button>
            </Box> 

        </Box>
    )
}