import { Button, Card, CardActionArea, Paper, Checkbox, Dialog, DialogActions, DialogContent, 
    DialogTitle, Divider, FormControlLabel, FormGroup, Grid, TextField, Typography, InputBase,
    InputAdornment, IconButton } from "@mui/material"
import { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { log } from "../../../console-config";

function ConfirmationDialog ({openConfirmationDialog, handleCloseDialog}) {

    const userList = [
        {email: "juandelacruz@gmail.com"},
        {email: "johnwhite@testmail.com"},
    ]

    const [counter, setCounter] = useState(0);
    const [inputValues, setInputValues] = useState({});

    const handleClick = () => {
        setCounter(counter + 1);
        log(counter);
    };
    
    const handleOnChange = (e) => {
        const abc = {};
        abc[e.target.className] = e.target.value;
        setInputValues({ ...inputValues, ...abc });
    };

    const handleDelete = () => {
        setCounter(counter - 1);
        log(counter);
    };

    return(
        <Dialog open={openConfirmationDialog} onClose={handleCloseDialog} maxWidth={"sm"} fullWidth={"sm"} 
            aria-labelledby="responsive-dialog-title"
            PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}}}
        >
            <DialogTitle>
                Confirmation
            </DialogTitle>
            <DialogContent sx={{marginLeft: 3, marginRight: 3}}>
                <Typography>
                    Signatory
                </Typography>
                <Card sx={{backgroundColor:'#E2EBF5', padding: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            {userList.map((email)=>{
                                return(
                                    <CardActionArea sx={{padding: 2, backgroundColor: 'white', marginBottom: 1, borderRadius: 2}}>
                                        <Typography>{email.email}</Typography>
                                    </CardActionArea>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Card>
                <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked />} label={<Typography sx={{fontSize: 12}}>Include Certificate</Typography>} />
                </FormGroup>
            </DialogContent>
            <DialogContent sx={{marginLeft: 3, marginRight: 3}}>
                <Typography>
                    Add Carbon Copy
                </Typography>
                <Card sx={{backgroundColor:'#E2EBF5', padding: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            
                            <CardActionArea onClick={handleClick} sx={{padding: 2, backgroundColor: 'white', marginBottom: 1, borderRadius: 2}}>
                                <Typography>Add Carbon Copy</Typography>
                            </CardActionArea>

                            <Divider sx={{margin: 2}}/>

                            {Array.from(Array(counter)).map((c, index) => {
                                return (
                                    <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', marginBottom: 1 }}
                                    >
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }} onChange={handleOnChange}
                                            placeholder="Email"
                                            inputProps={{ 'aria-label': 'email' }}
                                        />
                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                        <IconButton onClick={handleDelete} color="primary" sx={{ p: '10px' }} aria-label="directions">
                                            <DeleteIcon sx={{color:'red'}} />
                                        </IconButton>
                                    </Paper>
                                );
                            })}
                            
                        </Grid>
                    </Grid>
                </Card>
                
                <DialogActions>
                    <Button variant="contained" sx={{width: 120, backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'none'}} onClick={() => window.location.href="/test"}>
                        Okay
                    </Button>
                </DialogActions>
            </DialogContent>
            
        </Dialog>
    )
}

export default ConfirmationDialog