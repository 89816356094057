import {useState, useRef, useEffect} from 'react';
import {Box, Grid} from "@mui/material";
import ClickAwayListener from '@mui/base/ClickAwayListener';

import ElementToolbarComponent from "./ElementToolbarComponent";
import ElementComponent from "./ElementComponent";
import { useDragHook } from "../hooks/dnd/useDragHook";
import { dragSourceType, elementModes } from "../common/staticVariables";
import { getAdjFontSize } from "../../utils/FontUtil";
import { useDialog } from "../../utils/common-dialog";
import CreateSignatureDialogComponent from "../../../components/digital-siganture/e-signature/create-signature.dialog";
import { elementTypes } from "../common/staticVariables";
import { log } from '../../../console-config';
import { useDrag } from 'react-dnd';


const ElementContainerComponent = ({element, currentPage, setElementResizedData, setElementDeletedData, setElementUpdateValueData, onEditElement, onDeleteElement, signees, signeesOptionLabel, onAddSignee,
    onAddToSignees, onAssignNewSigneeToElement, onUpdateESignature, currentUser, onErrorAddSignee,getDocumentById, dndRef, setElementRef, setDuplicateElementData, 
    parentRef, setTextElementFontSize, setESignatureUpdateValueData}) => {

    log("element from container", element);

    const [isElementClicked, setIsElementClicked] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [isDraggingUsingToolbarDragItem, setIsDraggingUsingToolbarDragItem] = useState(false);

    const elRef = useRef();
    const elRef2 = useRef();
    const elementToolbarRef = useRef();

    const {dragRef} = useDragHook(dragSourceType, element)

    const [{ isDragging },dragRef2, preview] = useDrag(() => ({
        type: dragSourceType,
        item: {...element, elementToolbarRef: elementToolbarRef},
        canDrag: (item, monitor) => {
            console.log("isDraggingUsingToolbarDragItem bbbb", isDraggingUsingToolbarDragItem);
            return isDraggingUsingToolbarDragItem
        },
        end: (item, monitor) => {},
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
          handlerId: monitor.getHandlerId(),
        }),
    }), [JSON.stringify(element), elementToolbarRef, isDraggingUsingToolbarDragItem])


    const elDragRef = dragRef(elRef)
    const elDragRef2 = dragRef2(elRef2)

    const {openDialog} = useDialog()

    useEffect(() => {
        if (elDragRef2 != null) {
            setElementRef(elDragRef2)
        }
    }, [elDragRef2, JSON.stringify(element)])

    const handleResize = (width, height, origWidth, origHeight) => {
        console.log("setElementResizedData aaa", width, height);
        setElementResizedData({id: element.id, width, height, origWidth, origHeight});
    }

    const handleRemoveElement = () => {
        onDeleteElement(element)
        setElementDeletedData({id: element.id})
    }

    const handleEditElement = () => {
        if (element.type === elementTypes.userSignature) {
            openDialog("signature-dialog", CreateSignatureDialogComponent).then((signature) => {
                if (signature) {
                    handleOnChangeESignature(signature)
                    onEditElement({...element, value: signature})
                }
            })
            return;
        }
    }

    const handleOnChangeESignature = (value) => {
        console.log("value bbbbb", value);
        setESignatureUpdateValueData(value)
    }

    const handleOnChangeValue = (value) => {
        log("element on change", element);
        setElementUpdateValueData({id: element.id, value})
    }

    function handleOnIsEditing(bool){
        log("handleOnIsEditing bool", bool)
        setIsEditing(bool);
    }

    const handleOnFontSizeChange = (font_size, currentPage) => {
        var orig_font_size = font_size;
        var adjusted_font_size = getAdjFontSize(orig_font_size, currentPage.originalWidth, currentPage.width);
        // setCurrentElement({...element, font_size: orig_font_size, adjusted_font_size: adjusted_font_size});
    }

    const handleOnChangeSignee = (signee) => {
        log("value signee", signee);
        // setCurrentElement({...element, value: signee.email_address});
    }

    const handleOnMouseEnter = (isDraggable) => {
        console.log("handleOnMouseEnter bbbb", isDraggable);
        setIsDraggingUsingToolbarDragItem(true)
    }

    const handleOnResizeStart = () => {
        setIsDraggingUsingToolbarDragItem(false)
    }

    useEffect(() => {
        console.log("isDragging bbbb", isDragging);
    }, [isDragging])

    return(
        <ClickAwayListener onClickAway={()=>setIsElementClicked(isEditing ? true : false)}>
            <Box sx={{position: 'absolute', left: element?.x, top: element?.y, opacity: isDragging ? 0 : 1, zIndex: 1}} ref={elDragRef2} onClick={()=>setIsElementClicked(true)}>
                <Grid container justifyContent="left">
                    <ElementToolbarComponent 
                        element={element} 
                        isElementClicked={isElementClicked} 
                        onEditElement={handleEditElement} 
                        onRemoveElement={handleRemoveElement}
                        setIsEditing={handleOnIsEditing}
                        onChangeFontSize={(font_size)=>handleOnFontSizeChange(font_size, currentPage)}
                        onChangeSignee={handleOnChangeSignee}
                        signees={signees}
                        signeesOptionLabel={signeesOptionLabel}
                        onAddSignee={onAddSignee}
                        onAddToSignees={onAddToSignees}
                        onAssignNewSigneeToElement={onAssignNewSigneeToElement}
                        currentUser={currentUser}
                        onErrorAddSignee={onErrorAddSignee} getDocumentById={getDocumentById}
                        setDuplicateElementData={setDuplicateElementData} elementToolbarRef={elementToolbarRef}
                        elDragRef={elDragRef} setIsDraggingUsingToolbarDragItem={setIsDraggingUsingToolbarDragItem}
                        setTextElementFontSize={setTextElementFontSize} setIsElementClicked={setIsElementClicked}
                    /> 
                </Grid>

                <ElementComponent 
                    element={element} 
                    elDragRef={elDragRef}
                    elDragRef2={elDragRef2} 
                    handleResize={handleResize} 
                    onChangeValue={handleOnChangeValue}
                    getDocumentById={getDocumentById} elementToolbarRef={elementToolbarRef}
                    dndRef={dndRef} handleOnMouseEnter={handleOnMouseEnter} parentRef={parentRef}
                    handleOnResizeStart={handleOnResizeStart}
                />
            </Box>
        </ClickAwayListener>
    )
}

export default ElementContainerComponent