import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import useDocuments from "../../hooks/useDocuments";
import {ThreeDots} from "react-loader-spinner";
import { useEffect, useState } from "react";


const ResendFileDialog = (props) => {

    const {openResendFile , handleClose, handleCloseResendFile, userDocument } = props;

    const { resendFile, isResendingFile } = useDocuments();

    const handleResendFile = () => {

        resendFile(userDocument.id, handleCloseResendFile);

    }

 

    return(
        <Dialog open={openResendFile} onClose={handleClose} handleCloseResendEmail={handleCloseResendFile} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
              Resend Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography sx={{fontSize: 16}}>
                    Are you sure you want to resend <b>{userDocument?.title}</b>  ?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{width: 120, borderRadius: 10}} 
                    onClick={handleClose}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{width: isResendingFile ? 180 : 120, backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'none'}}
                    onClick={(e) => handleResendFile(e)}
                    startIcon={isResendingFile &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {isResendingFile ? 'Resending': 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ResendFileDialog