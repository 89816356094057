import React, {useEffect, useRef, useState} from "react";
import {Dialog, DialogActions, DialogContent, Box, Button, Grid, Typography} from "@mui/material";
import SignaturePad from 'react-signature-canvas';
import useWidth from "../hooks/useWidth";
import {RotatingLines} from "react-loader-spinner";
import AccessDenied from "../icons/expired.png";
import useSignature from "../hooks/useSignature";
import {ThreeDots} from "react-loader-spinner";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AccountService from "../services/api/accounts";

const SignaturePadScreen = () => {

    const signaturePadRef = useRef(null);
 
    const breakpoints = useWidth();
    const {openSuccessDialog, handleCloseSuccessDialog, isValidatingAccessToken, isValidEsignatureToken, 
        getEsignatureTokenValidation, convertUrlToFile, createSignatureByLink, isUploadingSignature
    } = useSignature();
 
    let accessToken = (new URLSearchParams(window.location.search)).get("accessToken");
    let userEmail = (new URLSearchParams(window.location.search)).get("email");

    const [orientation, setOrientation] = useState(window.orientation);
    const [openLandscapeNotif, setOpenLandscapeNotif] = useState(false);
    

    useEffect(() => {
        let accessToken = (new URLSearchParams(window.location.search)).get("accessToken");

        getEsignatureTokenValidation(accessToken);
    },[])

    useEffect(() => {
        function handleOrientationChange() {
            setOrientation(window.orientation);
        }

        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        }
    }, [])

    useEffect(() => {
        if (orientation == 0) {
            setOpenLandscapeNotif(true);

        } else {
            setOpenLandscapeNotif(false); 
        }
    }, [orientation])

    const handleClearSignature = () => {
        signaturePadRef.current.clear();
    }

    const handleUploadSignature = (e) => {
        let signaturePad = signaturePadRef.current; 

        if (signaturePad.isEmpty()) {
            // do nothing
      
        } else {
            const signatureDataUrl = signaturePad.toDataURL();

            convertUrlToFile(signatureDataUrl, 'file.png', 'image/png').then(function(file) { 
                let formData = new FormData();
            
                formData.append("signature", file);
                formData.append("email_address", userEmail);

                createSignatureByLink(formData, accessToken);
            })
        }
    }

    function PageLoader () {
        return (
            <div 
                style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                minWidth: "100% !important",
                maxWidth: "100% !important",
                borderRadius: "8px",
                cursor: "default",
                flexGrow: 0,
                height: "100vh",
                justifyContent: "center",
                margin: "auto",
                transition: "border-color .15s linear"
                }}
            >
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width={(breakpoints == "xs" || breakpoints == "sm") ? "50" : "65"}
                    visible={true}
                />
            </div>
        )
    }

    function SuccessConfirmationDialog () {
        return (
            <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog} maxWidth={'xs'} fullWidth={'xs'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 8,
                width: (breakpoints == "xs" || breakpoints == "sm") ? "90%" : "25%"}}}
            >
                <DialogContent sx={{textAlign: "center", paddingBottom: 0}}>
                    <Typography sx={{mb: "1px", fontWeight: "bold"}}>
                        Successfully saved signature
                    </Typography>
                </DialogContent>

                <DialogActions sx={{margin: "auto", paddingBottom: "24px", width: (breakpoints == "xs" || breakpoints == "sm") ? "38%" : "45%"}}>
                    <Button variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: "40px", textTransform: "none", fontSize: 16, '&:hover': {backgroundColor: "#0178f2", color: "white"}}}
                        fullWidth onClick={() => {handleCloseSuccessDialog()}}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    function LandscapeNotifDialog () {
        return (
            <Dialog open={openLandscapeNotif} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 8}}}>
                <DialogContent sx={{textAlign: "center"}}>
                    <Typography sx={{mb: "1px", fontWeight: "bold"}}>
                       To update signature, please set your phone's orientation to landscape
                    </Typography>
                </DialogContent>
            </Dialog>
        )
    }

    useEffect(() => {
        const getSignatureUpdate = async () => {
          try {
            let isRemoveCache = true;
            
            await AccountService.retrieveSignature(isRemoveCache)
          
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        if (isUploadingSignature) getSignatureUpdate()
    }, [isUploadingSignature])

  if (isValidatingAccessToken) return <PageLoader />


    return (
        <div style={{overflowY: "auto"}}>
            {(isValidEsignatureToken && (orientation == 90 || orientation == -90 || orientation == undefined)) ?
                <div style={{width: "90%", height: "100vh", margin: "auto auto 15px auto"}}>
                    <Grid sx={{mt: "20px", mb: "20px", display: "flex"}}>
                        <Box sx={{mr: "auto"}}>
                            <Button variant="outlined" sx={{borderRadius: "40px", textTransform: "none", fontSize: 16, color: '#3333cf', borderColor: "#3333cf4f !16840100"}}
                                startIcon={<DeleteForeverIcon fontSize="small" />} 
                                onClick={() => {handleClearSignature()}}
                            >
                                Clear
                            </Button> 
                        </Box>

                        <Button variant="outlined" sx={{borderRadius: "40px", textTransform: "none", fontSize: 16, color: '#3333cf', borderColor: "#3333cf4f !16840100"}}
                            onClick={()=> window.location.href = "/"}
                        >
                            Cancel
                        </Button> 

                        &nbsp;

                        <Button variant="contained" sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? {} : (isUploadingSignature) ? "10%" : "7%", backgroundColor: "#168401", borderRadius: "40px", textTransform: "none", fontSize: 16, '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                            onClick={(e) => (isUploadingSignature) ? null : handleUploadSignature(e)}
                            startIcon={isUploadingSignature && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />} 
                        >
                            {isUploadingSignature ? "Saving" : "Save"}
                        </Button> 
                    </Grid>
                
                    <div style={{width: "100%", height: (breakpoints == "xs" || breakpoints == "sm") ? "65%" : "78%", backgroundColor: "#fff", border: "1px solid #3636362b", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                        <SignaturePad ref={signaturePadRef} canvasProps={{className: "signaturePadCanvas"}} clearOnResize={false} />
                    </div>

                    <Typography sx={{color: "#b9b9b9", textAlign: "center", mt: "10px", letterSpacing: "0.6px"}}>
                        Draw Signature
                    </Typography>
                </div>
            :

                (!isValidEsignatureToken) ?
                    <Box sx={{textAlign: "center"}}>
                        <img alt="" src={AccessDenied} style={{width: 200, height: 200, marginLeft: "-60px", paddingTop: "170px"}} />
                
                        <Typography sx={{fontSize: 16}}>
                            Access Token expired
                        </Typography>
                    </Box> 
            : 
                null
            }
        
            {openSuccessDialog && <SuccessConfirmationDialog />}

            {openLandscapeNotif && <LandscapeNotifDialog />}

        </div>
    )
}

export default SignaturePadScreen