import React from "react";
import {Dialog, DialogContent, Grid, Typography, Button} from "@mui/material";
import environment from "../../../environment/environment";

const TokenExpiredDialog = (props) => {

    const {breakpoints} = props;

    const website_url = environment.msal_url.url;

    const gotoLink = () => {
        window.open(website_url, "_self")
    }

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}}}>
            <DialogContent sx={{padding: "30px 24px 35px 24px"}}>
                <Grid container justifyContent="center" direction="column">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto"}}>
                        <Typography variant="h6" sx={{ fontWeight: "bold", color: "red"}}>
                            Link Expired
                        </Typography>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto"}}>
                        <Typography variant="subtitle2" sx={{color: "#000"}}>
                            Please contact document creator to resend email.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto", mt: "22px"}}>
                        <Button onClick={gotoLink} variant="contained" sx={{width: 220, backgroundColor: 'red', borderRadius: 10, boxShadow: 'none'}}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default TokenExpiredDialog