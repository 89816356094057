import { Box, Button, ButtonGroup, TableCell, TableRow } from "@mui/material"
import { useState, useEffect } from "react";
import useDocuments from "../../hooks/useDocuments";
import {format} from 'date-fns';
import DeleteFileDialog from "../dialog/delete-file-dialog.component";
import DownloadFileDialog from "../dialog/download-file-dialog.component";
import ResendEmailDialog from "../dialog/resend-email-dialog.component";
import RetryEmailDialog from "../dialog/retry-email-dialog.component";
import DocumentWorkflowService from "../../services/api/documents";
import {ThreeDots} from "react-loader-spinner";
import RetryErrorDialog from "../dialog/retry-error-dialog.component";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { log } from "../../console-config";
import { useNavigate } from "react-router-dom";



function RecentFilesRowComponent(props) {

    const { document, sendDocumentToParent } = props;

    const [openDeleteFile, setOpenDeleteFile] = useState(false);
    const [openDownloadFile, setOpenDownloadFile] = useState(false);
    const [openResendEmail, setOpenResendEmail] = useState(false);
    const [openRetryEmail, setOpenRetryEmail] = useState(false);
    const [openRetryError, setOpenRetryError] = useState(false);


    const {retrieveDocuments, setLoading, viewFile, isViewing} = useDocuments();

    const navigate = useNavigate();

    const [page, setPage] = useState(1);

    useEffect(()=>{ 
        sendDocumentToParent(document);
        
        retrieveDocuments(page); },[page]
    )

    const handleOpenDeleteFile = () => {
        setOpenDeleteFile(true);
    };
  
    const handleCloseDeleteFile = () => {
        setOpenDeleteFile(false);
    };

    const handleOpenDownloadFile = () => {
        setOpenDownloadFile(true);
    };

    const handleCloseDownloadFile = () => {
        setOpenDownloadFile(false);
    };

  
    const handleOpenResendEmail = () => {
        setOpenResendEmail(true);
    }

    const handleCloseResendEmail = () => {
        setOpenResendEmail(false);
    }


    const handleOpenRetryEmail = () => {
        setOpenRetryEmail(true);
    }

    const handleCloseRetryEmail = () => {
        setOpenRetryEmail(false);
    }

    const handleOpenRetryError = () => {
        setOpenRetryError(true);
    }

    const handleCloseRetryError = () => {
        setOpenRetryError(false);
    }

    // Function to check if retry is allowed based on the created date
    function isRetryAllowed(createdDate) {
        log("createdDate:", createdDate);
        if (!createdDate) {
            return false;
        }
    
        const currentDate = new Date();
        const thirtyMinutesAgo = new Date(new Date(createdDate).getTime() + 30 * 60000); // Adding 30 minutes in milliseconds
        return currentDate >= thirtyMinutesAgo;
    }

    const handleClickView = (id) => {
        viewFile(id)
    }
    
    return(
        <>
       
        <TableRow  sx={{'&:last-child td, &:last-child th': {border: 0}}} >
            <TableCell sx={{color: "#0178f2", fontWeight: 530, cursor: "pointer"}} component="th" scope="row" onClick={(e) => navigate("/view-template?id=" + document.id)}> 
                {isViewing ? <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} /> : <Box sx={{display:'flex', algnItems:'center'}}><PictureAsPdfIcon sx={{marginRight: 1,color: document.status == 'PENDING' || document.status == 'pending' ? 'orange' : document.status == 'COMPLETED' || document.status == 'completed' ? 'green' : document.status == 'ERROR' || document.status == 'error' ? 'red' : 'gray'}}/>{document.title}</Box>}
            </TableCell>

            {/* <TableCell align="left" > 
                {document.signed}
            </TableCell>

            <TableCell align="left" sx={{color: document.status == 'PENDING' || document.status == 'pending' ? 'orange' : document.status == 'COMPLETED' || document.status == 'completed' ? 'green' : document.status == 'ERROR' || document.status == 'error' ? 'red' : 'inherit', fontWeight: 530}}>
                {document.status}
            </TableCell> */}

            <TableCell align="left" >
                {format(new Date(document?.created_time), 'PPpp')}
            </TableCell>

        </TableRow>
       
        {openDeleteFile && document &&
            <DeleteFileDialog openDeleteFile={openDeleteFile} handleClose={handleCloseDeleteFile}
                handleCloseDeleteFile={handleCloseDeleteFile} userDocument={document}
            /> 
        }
        {openDownloadFile && document &&
            <DownloadFileDialog openDownloadFile={openDownloadFile} handleClose={handleCloseDownloadFile}
                handleCloseDownloadFile={handleCloseDownloadFile} userDocument={document}
            /> 
        }

        {openResendEmail && document &&
            <ResendEmailDialog openResendEmail={openResendEmail} handleClose={handleCloseResendEmail} handleCloseResendEmail={handleCloseResendEmail} userDocument={document}   />
        }

        {openRetryEmail && document &&
            <RetryEmailDialog  openRetryEmail={openRetryEmail} handleClose={handleCloseRetryEmail} 
                handleCloseRetryEmail={handleCloseRetryEmail} userDocument={document}/>
        }

        {openRetryError && document &&
            <RetryErrorDialog  openRetryError={openRetryError} handleClose={handleCloseRetryError} 
            handleCloseRetryError={handleCloseRetryError} userDocument={document}/>
        }

        </>
    )
    
}

export default RecentFilesRowComponent