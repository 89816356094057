import React from "react";
import {Skeleton, Grid} from '@mui/material';

const CreateSignatureByLinkSkeletonComponent = ({breakpoints}) => {

    return (
        <Grid container sx={{padding: "8px 0px"}}> 
            <Grid item xs={12} sx={{textAlign: "center"}}>
                <Skeleton variant="rectangular" style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", width: 231, height: 231}} />
            </Grid>

            <Grid item xs={12} sx={{textAlign: "center", mt: "10px"}}>
                <Skeleton variant="rectangular" sx={{mt: "8px !important", ml: "auto", mr: "auto", width: (breakpoints == "xs" || breakpoints == "sm") ? "45%" : "60%", backgroundColor: "rgb(108 139 222 / 30%)"}} />
            </Grid>

            <Grid item xs={12} sx={{textAlign: "center"}}>
                <Skeleton variant="rectangular" sx={{mt: "8px !important", ml: "auto", mr: "auto", width: (breakpoints == "xs" || breakpoints == "sm") ? "45%" : "60%", backgroundColor: "rgb(108 139 222 / 30%)"}} />
            </Grid>

            <Grid item xs={12} sx={{textAlign: "center", pb: "8px"}}>
                <Skeleton variant="rectangular" sx={{mt: "8px !important", ml: "auto", mr: "auto", width: (breakpoints == "xs" || breakpoints == "sm") ? "45%" : "60%", backgroundColor: "rgb(108 139 222 / 30%)"}} />
            </Grid>
        </Grid>
    )
}

export default CreateSignatureByLinkSkeletonComponent