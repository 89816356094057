import * as React from 'react';
import { Box, Typography, Card, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";

function SignatoryCardComponent({order_sequence, email}){
    return(
      <Card sx={{mt: 1, mb: 1}}>
        <ListItem>
            <ListItemIcon>
              <Typography sx={{color: '#168401'}} component="div">
                <b>{order_sequence}</b>
              </Typography>
            </ListItemIcon>
            <ListItemText primary={email} />
        </ListItem>
      </Card>   
    )
}

function SignatoryList({emails}) {
    return (
      <List dense={true}>
        {
          emails.map((email, index)=>{
            return(
              <SignatoryCardComponent key={index} email={email} order_sequence={index + 1}/>
            )
          })
        }
      </List>
    );
}

function SignatorySectionComponent({emails}) {
    return (
      <>
        <Typography sx={{ mt: 4, mb: 2 }} component="div">
          Signatory
        </Typography>
        <Box sx={{
          minWidth: 275, p: 1, 
          color: 'draggable_element.text.primary',
          background: '#16840138', 
          borderColor: '#16840100', 
          borderStyle: 'solid',
        }}>
          {
            emails.length == 0 ?
            <Typography component="div">
              No signatory found.
            </Typography>
            :
            <SignatoryList emails={emails} />
          }
          <Typography sx={{fontStyle:'Italic', color:'#168401', fontSize: 11,}}>
            Note: Please make sure all Signee elements are assigned.
          </Typography>
        </Box>
      </>
    );
}

export default SignatorySectionComponent;