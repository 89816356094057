import { Box } from "@mui/material"
import React, { useState } from 'react';
import samplePdf from '../../images/samplePdf.pdf';
import { Document, Page, pdfjs } from "react-pdf";
 pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFBoxComponents () {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return(
        <Box sx={{height: "100vh", overflowY: "auto", bgcolor: '#efedec'}}>
            <br/>
            <Document file={samplePdf} onLoadSuccess={onDocumentLoadSuccess} >
                <Page pageNumber={pageNumber} className={'react-pdf__Page__canvas'}/>
            </Document>
        </Box>
    )
}
export default PDFBoxComponents