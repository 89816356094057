import {useState, useEffect} from 'react';
import { Box, Grid, ClickAwayListener } from "@mui/material"

// import ElementToolbarComponent from "./ElementToolbarComponent";
import ElementSigneeComponent from "./ElementSigneeComponent";
import {elementTypes} from "../common/staticVariables";

import {useDialog} from "../../utils/common-dialog";
import ElementSigneeToolbarComponent from './ElementSigneeToolbarComponent';
import CreateSignatureDialogComponent from "../../../components/digital-siganture/e-signature/create-signature.dialog";

const ElementSigneeContainerComponent = ({element, currentPage, setCurrentElement, onEditElement, currentUser, currentUserSignature, elRefs, index, 
    isViewing, signatory, onChangeSignature}) => {
    const [isElementClicked, setIsElementClicked] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    console.log("element bbbb", element);
    console.log("currentUser bbbb", currentUser);
    console.log("currentUserSignature bbbb", currentUserSignature);
    console.log("elRefs bbbb", elRefs);
    
    const {openDialog} = useDialog()

    // useEffect(() => {
    //     let signature = element.signature;
    //     if (isViewing && signature) handleOnChangeSignature(signature)
    // }, [isViewing, JSON.stringify(element)])

    const handleResize = (width, height, origWidth, origHeight) => {
        console.log("handleResize ccc ", {width, height});
        setCurrentElement({...element, width, height, origWidth, origHeight});
    }

    const handleEditElement = () => {
        openDialog("signature-dialog", CreateSignatureDialogComponent, {isPublic: true, email_address: element.value}).then((signature) => {
            console.log("signature aab", signature);
            if (signature) {
                onChangeSignature(signature)
                handleOnChangeSignature(signature)
            }
            
        })
    }

    const handleOnChangeSignature = (signature) => {
        setCurrentElement({...element, isSigned: true, signature});
    }

    // const handleOnValueChange = (value) => {
    //     console.log("element on change", element);
    //     setCurrentElement({...element, value: value});
    // }

    // function handleOnIsEditing(bool){
    //     console.log("handleOnIsEditing bool", bool)
    //     setIsEditing(bool);
    // }

    // const handleOnFontSizeChange = (font_size, currentPage) => {
    //     var orig_font_size = font_size;
    //     var adjusted_font_size = getAdjFontSize(orig_font_size, currentPage.originalWidth, currentPage.width);
    //     setCurrentElement({...element, font_size: orig_font_size, adjusted_font_size: adjusted_font_size});
    // }

    // const handleOnChangeSignee = (value) => {
    //     console.log("value signee", value);
    //     setCurrentElement({...element, value: value.signee, signeeId: value.id});
    // }

    const handleOnClickElement = () => {
        if (!isViewing) {
            let isDisabled = element.disabled || element.type != elementTypes.signatory ? true : false
            if (!isDisabled) {
                if (currentUser == element.value && currentUserSignature) {
                    if (element.isSigned) {
                        setIsElementClicked(true)
                    } else handleOnChangeSignature(currentUserSignature)
                } else {
                    handleEditElement()
                }
            }
        }
    }

    return(
        <ClickAwayListener onClickAway={()=>setIsElementClicked(isEditing ? true : false)}>
            <Box ref={(ref) => currentUser == element.value ? elRefs.current[element.id] = ref : null} sx={{position: 'absolute', left: element?.x, top: element?.y}} onClick={handleOnClickElement}>
                <Grid container justifyContent="center">
                    <ElementSigneeToolbarComponent 
                        element={element} 
                        isElementClicked={isElementClicked} 
                        onEditElement={handleEditElement} 
                        // setIsEditing={handleOnIsEditing}
                        // onChangeFontSize={(font_size)=>handleOnFontSizeChange(font_size, currentPage)}
                    /> 
                </Grid>
                <ElementSigneeComponent 
                    element={element} 
                    // onValueChange={handleOnValueChange}
                    onResize={handleResize}
                    currentUser={currentUser}
                    isViewing={isViewing}
                    signatory={signatory}
                />
            </Box>
        </ClickAwayListener>
    )
}

export default ElementSigneeContainerComponent