import LandingScreen from "../screens/landing-screen";
import PDFTemplateScreen from "../screens/pdf-template-screen";
import DigitalSignatureScreen from "../screens/DigitalSignatureScreen";
import ProfileScreen from "../screens/ProfileScreen";
import FilesScreen from "../screens/FilesScreen";
import SignaturePadScreen from "../screens/SignaturePadScreen";
// import SignPDFScreen from "../screens/SignPDFScreen_old";
import OvdocsScreen from "../screens/OvdocsScreen";
import UpdateProfileComponent from "../components/account/update-profile.component";
import SampleComponent from "../components/digital-siganture/sample.component";
import DashboardScreen from "../screens/DashboardScreen";
import UserLoggedScreen from "../screens/UserLoggedScreen";
import AnalyticsScreen from "../screens/AnalyticsScreen";
import AnalyticsDraftComponent from "../components/analytics/analytics-draft.component";
import ReportScreen from "../screens/ReportScreen";
import ReportDetailsComponent from "../components/reports/report-details.component";
import SignedLogsScreen from "../screens/SignedLogsScreen";
import { ActivityLogsScreen } from "../screens/ActivityLogsScreen";
import SignPDFScreen from "../screens/SignPDFScreen";
import SendInquiryScreen from "../screens/SendInquiryScreen";
import LandingConfirmationComponent from "../components/landing-component/landing-confirmation.component";

export const routes = [
    {
        path: "/",
        name: 'LandingScreen',
        element: <LandingScreen />,
        isPrivate: false
    },
   
    {
        path: "/pdf-template",
        name: 'OvdocsScreen',
        element: <OvdocsScreen />,
        isPrivate: true
    },

    {
        path: "/view-template",
        name: 'OvdocsScreen',
        element: <OvdocsScreen />,
        isPrivate: true
    },

    {
        path: "/documents",
        name: 'DigitalSignatureScreen',
        element: <DigitalSignatureScreen />,
        isPrivate: true
    },

    {
        path: "/logs",
        name: 'UserLoggedScreen',
        element: <UserLoggedScreen />,
        isPrivate: true
    },

    {
        path: "/profile",
        name: 'ProfileScreen',
        element: <ProfileScreen />,
        isPrivate: true
    },

    {
        path: "/files",
        name: 'FilesScreen',
        element: <FilesScreen />,
        isPrivate: true
    },

    {
        path: "/files/draft",
        name: 'FilesScreen',
        element: <FilesScreen />,
        isPrivate: true
    },

    {
        path: "/files/pending",
        name: 'FilesScreen',
        element: <FilesScreen />,
        isPrivate: true
    },

    {
        path: "/files/completed",
        name: 'FilesScreen',
        element: <FilesScreen />,
        isPrivate: true
    },

    {
        path: "/files/cancelled",
        name: 'FilesScreen',
        element: <FilesScreen />,
        isPrivate: true
    },

    {
        path: "/files/denied",
        name: 'FilesScreen',
        element: <FilesScreen />,
        isPrivate: true
    },

    {
        path: '/ovsign/signaturePad',
        name: 'SignaturePadScreen',
        element: <SignaturePadScreen />,
        isPrivate: false
    },

    {
        path: '/sign',
        name: 'SignPDFScreen',
        element: <SignPDFScreen />,
        // element: <OvdocsScreen />,
        isPrivate: false
    },

    {
        path: '/test',
        name: 'PDFTemplateScreen',
        element: <PDFTemplateScreen />,
        isPrivate: false
    },

    // {
    //     path: '/update-profile',
    //     name: 'UpdateProfileComponent',
    //     element: <UpdateProfileComponent />,
    //     isPrivate: true
    // },

    {
        path: "/dashboard",
        name: 'DashboardScreen',
        element: <DashboardScreen />,
        isPrivate: true
    },

    {
        path: "/analytics",
        name: 'AnalyticsScreen',
        element: <AnalyticsScreen />,
        isPrivate: true
    },

    {
        path: "/reports",
        name: 'ReportScreen',
        element: <ReportScreen />,
        isPrivate: true
    },

    {
        path: "/view-reports",
        name: 'ReportDetailsComponent',
        element: <ReportDetailsComponent />,
        isPrivate: true
    },
    {
        path: "/signed-logs",
        name: 'SignedLogsScreen',
        element: <SignedLogsScreen />,
        isPrivate: true
    },
    {
        path: "/activity-logs",
        name: 'ActivityLogsScreen',
        element: <ActivityLogsScreen />,
        isPrivate: true
    },
    {
        path: "/send-inquiry",
        name: 'SendInquiryScreen',
        element: <SendInquiryScreen />,
        isPrivate: true
    },

    {
        path: '/landing-confirmation',
        name: 'LandingConfirmationComponent',
        element: <LandingConfirmationComponent />,
        isPrivate: false
    },

]
