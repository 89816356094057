import { useEffect, useState } from 'react';
import {Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, Tabs, Tab, TextField} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {ThreeDots} from "react-loader-spinner";

import { useDialog } from "../../../../utils/common-dialog";
import AccountPublicService from '../../../../../services/api/account_public';
import { useSnackbar } from 'notistack';
import useDocuments from '../../../../../hooks/useDocuments';
import { log } from '../../../../../console-config';

const AddSigneeDialogComponent = ({tag, open, params}) => {

    const id = new URLSearchParams(window.location.search).get("id");

    const {viewDocumentByID, getDocumentById} = useDocuments()

    log("searchEmailxxxx", params)
    log('get document by id on Add signee component', getDocumentById)
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const {closeDialog} = useDialog()
    const [loading, setLoading] = useState(false)

    const [email, setEmail] = useState("")

    useEffect(()=>{viewDocumentByID(id)},[])

    useEffect(() => {
        if(params){
            setEmail(params); // Set the initial value of email using the params prop
        }
      }, [params]);

    const handleCloseDialog = () => {
        closeDialog(tag)
        setEmail(null)
    }

    const testData = {
        domain:["google.com", "getnada.com"],
        email:["dvnsnpty@gmail.com", "rexlim@gmail.com"]
    }

    log('get test data hereeeeee', testData)

    const handleSubmit = () => {
        let payload = {
            recently_added_signees : [email]
        }

        const domain = email?.split('@')[1];
        setLoading(true)
        if (getDocumentById?.domain.length === 0 && getDocumentById?.email.length === 0) {
            // Return true or handle the scenario where both arrays are empty
                log('Both domain and email arrays are empty.');
                AccountPublicService.saveSignee(payload).then((res)=>{
                    log('get save signee res here', res)
                    setEmail(null)
                    closeDialog(tag, email)
                    setLoading(false)
                    enqueueSnackbar("Success! Email address saved.", { 
                        variant: 'success',
                        autoHideDuration: 3000
                });
                }).catch((err)=>{
                    enqueueSnackbar(err.response.data.error, { 
                        variant: 'error',
                        autoHideDuration: 3000
                    });
                    setLoading(false)
                })
          } else if(!getDocumentById?.domain.includes(domain) === getDocumentById?.email.includes(email)){
            // alert( "Success! Email address saved.")
            AccountPublicService.saveSignee(payload).then((res)=>{
                log('get save signee res here', res)
                setEmail(null)
                closeDialog(tag, email)
                setLoading(false)
                enqueueSnackbar("Success! Email address saved.", { 
                    variant: 'success',
                    autoHideDuration: 3000
            });
            }).catch((err)=>{
                enqueueSnackbar(err.response.data.error, { 
                    variant: 'error',
                    autoHideDuration: 3000
                });
                setLoading(false)
            })
        } 
          else{
            // alert("This email/domain is unauthorized. Contact your enterprise admin.")
            enqueueSnackbar("This email/domain is unauthorized. Contact your enterprise admin.", { 
                variant: 'error',
                autoHideDuration: 3000
            });
            setLoading(false)
        }
    }

    const handleOnChangeInputEmail = (e) => {
        setEmail(e.target.value);
    }

    return(
        <Dialog 
            open={open}
            // onClose={handleCloseDialog} 
            maxWidth={"sm"} 
            fullWidth={"sm"} 
            aria-labelledby="responsive-dialog-title"
            PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}}}
        >
            <DialogTitle>
                Add Signee
            </DialogTitle>

            <DialogContent>
                <TextField 
                    fullWidth 
                    sx={{margin: 'auto', backgroundColor:'white'}} 
                    placeholder="Email" 
                    variant="outlined" 
                    InputProps={{
                        style: {fontSize: 14, padding: 0}
                    }}
                    value={email} 
                    onChange={handleOnChangeInputEmail}
                />
            </DialogContent>
            <DialogActions>
                    <Button variant="outlined" sx={{width: 120, borderRadius: 10, color:'#3333cf', borderColor: "#3333cf4f !16840100" }}  onClick={handleCloseDialog}>
                        Cancel
                    </Button>
                    <Button variant="contained" sx={{width: 120, backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}} onClick={handleSubmit}
                    startIcon={loading &&
                        <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />
                    }
                    >
                       {loading ? "Adding" : "Add"}
                    </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddSigneeDialogComponent