import {useState} from "react";
import AccountService from "../services/api/accounts";
import { useSnackbar } from "notistack";
import { log } from "../console-config";

const useUsers = () => {

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [updatedUser, setUserUpdated] = useState(false);
  const [updatingProfile, setUpdatingProfile] = useState(false);


  const retrieveUser = () => {
    setLoading(true);

    AccountService.retrieveUser().then(res => {
      log("retrieveUser data xxxx", res)
      setLoading(false);

      if (res.data.success) {
        setUser(res.data.data);
      }

    }).catch((err)=>{ 
      log("retrieveUser error xxxx", err)
      setLoading(false);
    })
  }

  const updateUserInformation = (firstname, lastname) => {
    setUpdatingProfile(true);
        
    AccountService.updateUserInformation(firstname, lastname).then((res) => {

        log("get update res here:", res);

        setUpdatingProfile(false);

        if(res.data.success){
            setUserUpdated(true);
            enqueueSnackbar('Successfully updated user information', { 
                variant: 'success',
                autoHideDuration: 3000
            });
        }

    }).catch((error) => {
        log("update user err", error);

        setUpdatingProfile(false);

    })
  }


  return {loading, user, retrieveUser , updatedUser, updateUserInformation, updatingProfile}

}

export default useUsers;