

import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import ReportService from "../services/api/reports";
import FileService from "../services/api/file";
import { log } from "../console-config";

function blobToPdfFileWithSize(blob, fileName) {
    // Create a new Blob with the same data and type
    const pdfBlob = new Blob([blob], { type: blob.type });
    const pdfFile = new File([pdfBlob], fileName, { type: 'application/pdf' });
  
    return pdfFile;
}

const useFile = () => {

    const fileUploader = (formData, title, setFile) => {
        FileService.fileUploader(formData).then((res)=>{
            log('get file uploader res', res)
            let byteArray = res.data.byteFile;
           
            const binaryData = atob(byteArray);
            // Create a Uint8Array from the binary data
            const uint8Array = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
                uint8Array[i] = binaryData.charCodeAt(i);
            }
            // Create a Blob from the Uint8Array
            const blob = new Blob([uint8Array], { type: 'application/pdf' });

            // Create a URL for the Blob
            const pdfFile = blobToPdfFileWithSize(blob, title);
            setFile(pdfFile);
            log('PDF FILEEEEEEEEEEEEEEEEE', pdfFile)
              
        })
    }

 
    return {fileUploader}

}

export default useFile;