import React from "react";
import {Menu, MenuItem, Typography} from "@mui/material";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import {useNavigate } from "react-router";
import AuthService from "../services/auth";
import { Link } from "react-router-dom";
import logsIcon from "../icons/log-in.png";
import SignedIcon from "../icons/historyLogs.png"
import RestorIcon from "../icons/restore.png"
import SendIcon from "../icons/send.png";

const AccountPopper = (props) => {

    const {anchorEl, handleCloseMenu} = props;
    const navigate = useNavigate();

    const handleClick = () => {
        AuthService.logout().then((res) => {
             // Get all cache storage keys
            caches.keys().then(function(cacheNames) {
                // Iterate through all cache storage keys and delete them
                cacheNames.forEach(function(cacheName) {
                    caches.delete(cacheName);
                });
            }).then(function() {
                console.log('All cache storage has been cleared.');
                localStorage.clear();
                window.location.href = "/";
                handleCloseMenu();
            }).catch(function(error) {
                console.error('Error clearing cache storage:', error);
            });
        });
    }

    return (
        <Menu sx={{top: "35px"}}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
        >
            <MenuItem component={Link} to="/profile" onClick={handleCloseMenu} sx={{paddingRight: "25px"}}>
                <PersonOutlineOutlinedIcon sx={{color: "#168401"}} />
            
                <Typography sx={{ml: "5px", fontSize: 14}}>
                    Profile
                </Typography>
            </MenuItem>

            <MenuItem component={Link} to="/activity-logs" onClick={handleCloseMenu} sx={{paddingRight: "25px"}}>
                <img alt="activity-icon" src={RestorIcon} style={{height: 20}} />
            
                <Typography sx={{ml: 1, fontSize: 14}}>
                    Activity Logs
                </Typography>
            </MenuItem>

            <MenuItem component={Link} to="/signed-logs" onClick={handleCloseMenu} sx={{paddingRight: "25px"}}>
                <img alt="sign-in-icon" src={SignedIcon} style={{height: 20}} />
            
                <Typography sx={{ml: 1, fontSize: 14}}>
                    History
                </Typography>
            </MenuItem>

            <MenuItem component={Link} to="/logs" onClick={handleCloseMenu} sx={{paddingRight: "25px"}}>
                <img alt="sign-in-icon" src={logsIcon} style={{height: 20}} />
            
                <Typography sx={{ml: 1, fontSize: 14}}>
                    Sign in Logs
                </Typography>
            </MenuItem>

            <MenuItem component={Link} to="/send-inquiry" onClick={handleCloseMenu} sx={{paddingRight: "25px"}}>
                <img alt="send-icon" src={SendIcon} style={{height: 20}} />
            
                <Typography sx={{ml: 1, fontSize: 14}}>
                    Send Inquiry
                </Typography>
            </MenuItem>

            <MenuItem onClick={(e) => handleClick(e, "logout")} sx={{paddingRight: "25px"}}>
                <ExitToAppOutlinedIcon sx={{color: "#168401"}} />

                <Typography sx={{ml: "5px", fontSize: 14}}> 
                    Logout
                </Typography>
            </MenuItem>
        </Menu>
    )
}

export default AccountPopper