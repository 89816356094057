import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {ThreeDots} from "react-loader-spinner";
import { useEffect } from "react";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const CreditLimitErrorDialog = (props) => {

    const {openCreditLimit, handleOnClickYes, setOpenCreditLimit, saving, } = props;

    const handleCloseDialog = () => {
        setOpenCreditLimit(false)
    }

    return(
        <Dialog open={openCreditLimit} handleCloseSaveAsDraft={handleOnClickYes} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2}}}>
            {/* <DialogTitle style={{fontWeight: "bold", fontSize: 16, display:'flex', alignItems:'center'}}>
              <WarningAmberIcon color="error" sx={{mr: 1}} />  Credit Limit Error
            </DialogTitle>

            <Divider /> */}

            <DialogContent sx={{padding: 6, textAlign:'center', pb: 3}}>
                
                <WarningAmberIcon color="error" sx={{height: 50, width: 50}}/>
                <Typography sx={{fontSize: 16, fontWeight:'bold', mb: 1}}>
                    Credit Limit Error
                </Typography>
                <Typography sx={{fontSize: 14, color:'gray', textAlign:'center', fontStyle:'italic'}}>
                    Your allocated credit limit currently stands at zero. We kindly request you to get in touch with your enterprise administrator regarding any subscription inquiries or adjustments.
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'none'}}
                    onClick={(e) => handleOnClickYes(e)}
                    startIcon={saving &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                  {saving ? 'Saving to draft' : 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreditLimitErrorDialog