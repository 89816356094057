import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

const elementTypes = {
    userSignature: 'esignature',
    companySeal: 'eseal',
    qrcode: 'qrcode',
    textField: 'textfield',
    currentDate: 'current_date',
    signatory: 'signatory',
}

const default_draggable_elements = [
    {text: 'E-signature', image: null, icon: <BorderColorOutlinedIcon/>, type: elementTypes.userSignature, width: 100, height: 100},
]

const drawerWidth = 340;

const dragSourceType = 'element_fields'
const errorCodes = {
    invalidSignatory: 100
}

const pdfPageSizes = {
    A4: {
        width: 595.5,
        height: 842.25,
        unit: "pt"
    }
}

const initialDragElements = {
    qrcode: {
        width: 100,
        height: 100
    },
    userSignature: {
        width: 200,
        height: 100
    },
    textField: {
        width: 200,
        height: 50
    },
    currentDate: {
        width: 120,
        height: 23
    },
    signatory: {
        width: 150,
        height: 60
    },
}

const minElementWidth = 40
const minElementHeight = 20

export {elementTypes, default_draggable_elements, drawerWidth, dragSourceType, errorCodes, pdfPageSizes, initialDragElements, minElementWidth, minElementHeight}