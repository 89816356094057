import React, { useContext, useState } from 'react'

import AlertDialogComponent from "./component/alert-dialog.component";
import Emitter from "./emitter";
import { log } from '../../../console-config';


const AlertDialogContext = React.createContext()

export const useAlertDialog = () => {
    return useContext(AlertDialogContext)
}

const alertInitialConfig = {
    variant: 'info',
    title: null,
    message: null,
    okayBtn: {
        text: 'Okay',
        backgroundColor: 'transparent',
        textColor: 'inherit',
        variant: 'text',
        startIcon: null,
        endIcon: null
    },
    confirmBtn: {
        text: 'Okay',
        backgroundColor: 'transparent',
        textColor: 'inherit',
        variant: 'text',
        startIcon: null,
        endIcon: null
    },
    cancelBtn: {
        text: 'Cancel',
        backgroundColor: 'transparent',
        textColor: 'inherit',
        variant: 'text',
        startIcon: null,
        endIcon: null
    }
}

export const AlertDialogProvider = ({children}) => {

    const [openAlert, setOpenAlert] = useState(false)
    const [alertConfig, setAlertConfig] = useState(alertInitialConfig)

    const showAlert = (opts) => {
        setOpenAlert(true)
        setAlertConfig({
            dialogType: 'alert',
            variant: opts.variant,
            title: opts.title,
            message: opts.message,
            okayBtn: {
                ...alertConfig.okayBtn,
                ...opts?.okayBtn
            }
        })
    }

    const showConfirmAlert = (opts) => {
        setOpenAlert(true)
        setAlertConfig({
            open: true,
            dialogType: 'confirm',
            variant: opts?.variant,
            title: opts?.title,
            message: opts?.message,
            confirmBtn: {
            ...alertConfig.confirmBtn,
            ...opts?.confirmBtn
            },
            cancelBtn: {
            ...alertConfig.cancelBtn,
            ...opts?.cancelBtn
            }
        })
    }

    const alert = (opts) => {
        var promise = new Promise((resolve, reject)=>{
            showAlert(opts)
            Emitter.on('CLOSE_ALERT_DIALOG', (res) => {
                Emitter.off('CLOSE_ALERT_DIALOG');
                resolve()
            });
        })
        return promise
    }

    const confirm = (opts) => {
        var promise = new Promise((resolve, reject)=>{
            showConfirmAlert(opts)
            Emitter.on('CONFIRM_ALERT_DIALOG', () => {
                Emitter.off('CONFIRM_ALERT_DIALOG');
                log("confirm btn");
                resolve()
            });
            Emitter.on('CLOSE_ALERT_DIALOG', () => {
                Emitter.off('CLOSE_ALERT_DIALOG');
                log("cancel btn");
                reject()
            });
        })
        return promise
    }

    const handleCloseAlertDialog = (type) => {
        setOpenAlert(false)
        let tag;
        if (type == 'confirm') {
            tag = 'CONFIRM_ALERT_DIALOG'
        } else {
            tag = 'CLOSE_ALERT_DIALOG'
        }
        Emitter.emit(tag, true);
    }

    const AlertDialog = {
        alert: alert,
        confirm: confirm,
    }

    return(
        <AlertDialogContext.Provider value={AlertDialog}>
            <AlertDialogComponent open={openAlert} handleCloseAlertDialog={handleCloseAlertDialog} alertConfig={alertConfig} />
            {children}
        </AlertDialogContext.Provider>
    )
}