import { useDrag } from 'react-dnd'

export const useDragHook = (dragSourceType, item) => {

    const [{ isDragging }, dragRef] = useDrag(() => ({
        type: dragSourceType,
        item: item,
        end: (item, monitor) => {},
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
          handlerId: monitor.getHandlerId(),
        }),
    }), [JSON.stringify(item)])

    return {dragRef}
}