import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import useWidth from "../hooks/useWidth";
// import {PDFSignee} from "../ovdocs1";
import {PDFSignee} from "../ovdocs";
import DocumentsPublicService from "../services/api/documents_public";
import AccountPublicService from "../services/api/account_public";
import FinishNotificationDialog from "../components/pdf-editor/dialog/finish-notification.dialog";
import TokenExpiredDialog from "../components/pdf-editor/dialog/token-expired-dialog.component";
import {RotatingLines} from "react-loader-spinner";
import ErrorSignedDialogComponent from "../components/pdf-editor/dialog/error-signed-dialog.component";
import { log } from "../console-config";
import deviceInfoUtil from "../utils/deviceDetailsUtils";
import environment from "../environment/environment";
import DocumentCancelledDialog from "../components/pdf-editor/dialog/document-cancelled-dialog.component";

const SignPDFScreen = () => {

    const [fileUrl, setFileUrl] = useState(null)
    const [signatories, setSignatories] = useState([])
    const [elements, setElements] = useState([])
    const [currentUser, setCurrentUser] = useState(null)
    const [currentUserSignature, setCurrentUserSignature] = useState(null)
    const [openFinishDialog, setOpenFinishDialog] = useState(false);
    const [openCancelledDialog, setOpenCancelledDialog] = useState(false);

    const [isPageLoading, setPageLoading] = useState(true);
    const [isTokenExpired, setOpenTokenExpiredDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [sender, setSender] = useState(null);
    const [senderOID, setSenderOID] = useState(null)
    const [signeeCCEmail, setSigneeCCEmail] = useState(null)
    const [document_id, setDocumentId] = useState(null);
    const [document_status, setDocumentStatus] = useState(null);

    const [cancel_reason, setReason] = useState("");
    

    const subscriptionKey = environment.ovsign_account_service.subscriptionKey;

    const breakpoints = useWidth;

    const {enqueueSnackbar} = useSnackbar();

    const {deviceInfo} = deviceInfoUtil();

    let accessToken = (new URLSearchParams(window.location.search)).get("token");

    useEffect(() => {
        setTimeout(() => {
            setPageLoading(false);
        }, 2500)
    }, [])


    useEffect(() => {
        if (currentUser) {
            DocumentsPublicService.getSignatureByEmail(currentUser, accessToken).then(({data}) => {
                log("getSignatureByEmail res", data);
                let signature = data.data === null ? "" : data.data.signature_url;
                log("signature res", signature);

                setCurrentUserSignature(signature)
            })
        }
    }, [currentUser])

    const subKey = "&subscription-key=" + subscriptionKey

    useEffect(() => {
        DocumentsPublicService.getDocumentWithProperties(accessToken).then(({data}) => {
            log("res data aaaaaaaa", data);

            if (data.success) {
                setSigneeCCEmail(data?.data);
                log("res elements aa", data.data.elements);
                setSenderOID(data.data.document.oid)
                let file_url = data.data.document_url + subKey;
                log("res file_url aa", file_url);
                let signatories = data.data.signatories
                log("res signatories aa", signatories);
                let current_user = data.data.user
                log("res current_user aa", current_user);
                let document_id = data.data.document.id
                log("res document_id aa", document_id);

                let elements = data.data.elements.map((el) => {

                    log("res el aa", el);

                    let signee = signatories.find((item) => item.email_address == el.value)

                    log("res signee aa", signee);

                    let signature = signee?.status == "DONE" ? signee?.signature_url : null

                    log("res signature aa", signature);

                    return {
                        "id": el.id,
                        "type": el.type,
                        "value": el.value,
                        "x": parseFloat(el.x_axis),
                        "y": parseFloat(el.y_axis),
                        "page_no": el.page_no,
                        "width": parseFloat(el.width),
                        "height": parseFloat(el.height),
                        "signature": signature,
                        "isSigned": !!signature,
                        "disabled": !!signature
                    }
                })

                log("res public file_url aa", file_url);
                log("res public signatories aa", signatories);
                log("res public elements aa", elements);
                log("res public current_user aa", current_user);
                
                setFileUrl(file_url)
                setSignatories(signatories)
                setElements(elements)
                setCurrentUser(current_user)
                setDocumentId(document_id)
            } else {
                log("data xaa", data);
                enqueueSnackbar(data.error, { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        }).catch((e) => {
    
            // let docStatusPending = "Document status is not PENDING.";
            // let docSigned = "Error occurred. You have already finished signing.";
            // let tokenExpired = "Token is expired.";
            
            // log("getDocumentWithProperties error xxxx", e?.response?.data);

            // if (e.response?.data.error == docStatusPending || e.response?.data.error == docSigned) {
            //     setOpenFinishDialog(true);

            // } else if (e.response?.data.error == tokenExpired) {
            //     setOpenTokenExpiredDialog(true);
            // }

            // if (e.response?.data.error == tokenExpired) {
            //     enqueueSnackbar(tokenExpired, { 
            //         variant: "error",
            //         autoHideDuration: 3000
            //     });
            // } 

            setDocumentStatus(e?.response?.data?.data?.status);

            let tokenExpired = "Token is expired.";
            
            
            log("getDocumentWithProperties error xxxx", e?.response?.data);
            log("getDocumentWithProperties xxxx", e?.response?.data?.data);

            if (e?.response?.data?.data?.status === "COMPLETED") {
                setOpenFinishDialog(true);

            } else if (e?.response?.data?.data?.status == tokenExpired) {
                setOpenTokenExpiredDialog(true);
            } else if (e?.response?.data?.data?.status === "CANCELLED" || e?.response?.data?.data?.status === "DENIED") {
                setOpenCancelledDialog(true);
                setReason(e?.response?.data?.data?.cancel_reason);
            }


            if (e.response?.data.error == tokenExpired) {
                enqueueSnackbar(tokenExpired, { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            } 
        })
    },[])

    const getUserByOID = (senderOID) => {
        AccountPublicService.getUserByOID(senderOID, accessToken).then((res)=>{
            log('get user by oid', res)

            setSender(res.data.data)
        }).catch((err)=>{
            log('get err logs user by oid', err)
        })
    }

    useEffect(()=>{
        if(senderOID){
            getUserByOID(senderOID);
        }
    },[JSON.stringify(senderOID)])

    useEffect(()=>{})

    function handleOnDocumentLoadSuccess(pdf){
        log("hit handleOnDocumentLoadSuccess in App.js: ", pdf)
    }

    function handleOnDocumentPageLoadSuccess(page){
        log("hit handleOnDocumentPageLoadSuccess in App.js: ", page)
    }

    const handleOnFinishSignee = (pdf_result, setIsLoading) => {
        log("signee finished!", pdf_result)
        log("currentUser finished!", currentUser)
        let signature_url = pdf_result.elements.find((el) => el.value == currentUser).signature
        log("signature_url finished!", signature_url)

        // if(signature_url !== null){
        var promise = new Promise((resolve, reject) => {
            AccountPublicService.updateSignatureStatusPublic(currentUser, accessToken).then(({data}) => {
                log("updateSignatureStatusPublic xx", data);
                if (data.success) {
                    if(signature_url !== null){
                        DocumentsPublicService.signDocument(signature_url, accessToken, deviceInfo).then(({data}) => {
                            log("signDocument data response xxxx", data);

                            if (data.success) {
                                setOpenFinishDialog(true);
                                resolve();

                            } else {
                                log("signDocument reject error response xxxx", data.error);

                                reject();
                                enqueueSnackbar(data.error, { 
                                    variant: "error",
                                    autoHideDuration: 3000
                                });
                            }
                        }).catch((e) => {
                            log("signDocument catch error response xxxx", data.error);
                        
                            // Dialog
                            // setOpenErrorDialog(true)

                            enqueueSnackbar(e.response.data.error, { 
                                variant: "error",
                                autoHideDuration: 3000
                            });
                            reject();
                        })
                    }else{
                        enqueueSnackbar("Kindly ensure that you have signed all the necessary documents before proceeding with the submission.", { 
                            variant: "error",
                            autoHideDuration: 3000
                        });
                        setIsLoading(false)
                    }
                }
            }).catch((e) => {
                enqueueSnackbar(e.response.data.error, { 
                    variant: "error",
                    autoHideDuration: 3000
                });
                
                reject()
            })
        })

        return promise
        // }else {
        //     enqueueSnackbar("Sign the document before you proceed.", { 
        //         variant: "error",
        //         autoHideDuration: 3000
        //     });
        // }
    }


    const handleCloseFinishDialog = () => {setOpenFinishDialog(false);}

    const handleCloseCancelledDialog = () => {setOpenCancelledDialog(false);}

    const handleCloseErrorSignedDialog = () => {setOpenErrorDialog(false);}

    function PageLoader () {
        return (
            <div 
                style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                minWidth: "100% !important",
                maxWidth: "100% !important",
                borderRadius: "8px",
                cursor: "default",
                flexGrow: 0,
                height: "100vh",
                justifyContent: "center",
                margin: "auto",
                transition: "border-color .15s linear"
                }}
            >
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width={(breakpoints == "xs" || breakpoints == "sm") ? "50" : "65"}
                    visible={true}
                />
            </div>
        )
    }

    if (isPageLoading) return <PageLoader />

    log('get document url XXXXXXXX', fileUrl)

    return(
        <>
            <PDFSignee 
                file_url={fileUrl} 
                elements_to_plot={elements} 
                signatory={signatories}
                onDocumentLoadSuccess={handleOnDocumentLoadSuccess} 
                onDocumentPageLoadSuccess={handleOnDocumentPageLoadSuccess} 
                onClickFinish={handleOnFinishSignee}
                currentUser={currentUser}
                currentUserSignature={currentUserSignature}
                setCurrentUserSignature={setCurrentUserSignature}
                sender={sender} signeeCCEmail={signeeCCEmail}
                document_id={document_id}
                setOpenCancelledDialog={setOpenCancelledDialog}
            />

            {openFinishDialog &&
                <FinishNotificationDialog breakpoints={breakpoints} 
                    openFinishDialog={openFinishDialog} handleCloseFinishDialog={handleCloseFinishDialog}
                />
            }

            {openCancelledDialog &&
                <DocumentCancelledDialog breakpoints={breakpoints} documentStatus={document_status} cancel_reason={cancel_reason}
                    openCancelledDialog={openCancelledDialog} handleCloseCancelledDialog={handleCloseCancelledDialog}
                />
            }

            {isTokenExpired &&
                <TokenExpiredDialog breakpoints={breakpoints} 
                    isTokenExpired={isTokenExpired}
                />
            }

            {openErrorDialog &&
                <ErrorSignedDialogComponent breakpoints={breakpoints} 
                    openErrorDialog={openErrorDialog} handleCloseErrorSignedDialog={handleCloseErrorSignedDialog}
                />
            }
        </>
    )
}

export default SignPDFScreen

