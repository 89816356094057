import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {useSnackbar} from "notistack";

import useDocuments from "../hooks/useDocuments";
import useSignature from "../hooks/useSignature";
import {PDFEditor, PDFSignee} from "../ovdocs";
import {useDialog} from "../ovdocs/utils/common-dialog";
import DocumentWorkflowService from "../services/api/documents";
import DocumentWorkflowConfirmationDialog from "../components/pdf-editor-v2/dialogs/document-workflow-confirmation-dialog.component";
import SaveAsDraftDialog from "../components/pdf-editor-v2/dialogs/save-as-draft-dialog.component";
import MsalUtil from "../services/utils/msals";

import { getObjectArrayDiff } from "../utils/arrayUtils";

import testPDF4 from "../pdf-with-diff-orientations.pdf";
import environment from "../environment/environment";
import PDFEditorGuide from "../components/walkthrough/pdf-editor-guide";
import { log } from "../console-config";

function OvdocsScreen(props) {

  const id = new URLSearchParams(window.location.search).get("id");
  const subscriptionKey = environment.ovsign_account_service.subscriptionKey;

  const [signatory_emails, setSignatoryEmails] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSaveAsDraft, setOpenSaveAsDraft] = useState(false);
  const [displayedPDFType, setDisplayedPDFType] = useState("");
  const [pdfResult, setPDFResult] = useState(null);
  const [signatories, setSignatories] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [documentProperties, setDocumentProperties] = useState([]);
  const [documentsId, setDocumentsId] = useState(null)
  const [verificationLink, setVerificationLink] = useState(null)
  const location = useLocation();
  const [documentURLFinal, setDocumentURLFinal] = useState(null)
  const [ loading, setLoading ] = useState(false);

  log('111111111111111111111111111111111', documentURLFinal)

  const {enqueueSnackbar} = useSnackbar();

  const {getDocumentById, viewDocumentByID, saveDocAsDraft, isSaving: isSavingDocument, isSaved, downloadView, viewFile, viewDownloadedPdf} = useDocuments();
  const currentUser = MsalUtil.getFirstAccount().username
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const [isWalkthrough, setIsWalkthrough] = useState(null);

  var module_id = "PDF";

  useEffect(() => {
    if (pdfResult) {
      console.log("pdfResult bbbb", pdfResult);
    }
  }, [JSON.stringify(pdfResult)])

  useEffect(() => {
    if (documentProperties) {
      console.log("documentProperties aaa", documentProperties);
    }
  }, [JSON.stringify(documentProperties)])

  const handleWalkthroughButtonClick = () => {
      setShowWalkthrough(true);
  };

  const reopenJoyride = () => {
    setShowWalkthrough(true);
  };

  const retrieveWalkthrough = () =>{
    DocumentWorkflowService.getWalkthrough(module_id).then((res)=>{
        log('is user walkthrough', res)
        setIsWalkthrough(res.data.data.is_walkthrough)
    })
  }

  useEffect(()=>{
      retrieveWalkthrough(module_id)
  },[module_id])


  useEffect(()=>{
    viewFile(id)
  },[])

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     return () => {
  //       viewFile(id);
  //     };
  //   }
  // }, []);

  useEffect(()=>{
    let type;
    if (location.pathname === '/view-template') {
      type = "pdf-viewer"
    } else if(location.pathname === '/pdf-template'){
      type = "pdf-editor"
    }
    setDisplayedPDFType(type)
  }, [location.pathname])

  useEffect(()=>{
    viewDocumentByID(id);
  },[])


  useEffect(()=>{
    if (getDocumentById) {
      console.log("getDocumentById bbb", getDocumentById);
      let document_id = getDocumentById.id
      let verificationLink = getDocumentById.verification_link

      if (verificationLink) {
        setVerificationLink(verificationLink)
      } else {
        generateVerifiableQRCode(document_id)
      }
    }
  },[JSON.stringify(getDocumentById)])

  

  const {signature, retrieveSignature} = useSignature();

  useEffect(() => {
    retrieveSignature();
  },[])

  useEffect(()=>{
    var document_id = new URLSearchParams(window.location.search).get("id");

    getProperties(document_id)
    getSignatories(document_id)
  },[])

  useEffect(() => {
    if (displayedPDFType == "pdf-viewer" && documentProperties.length > 0) {

      let updated_document_properties = documentProperties.map((el) => {

        let signee = signatories.find((item) => item.email_address == el.value)
        let signature = signee?.status == "DONE" ? signee?.signature_url : null
  
        return {
            ...el,
            "signature": signature,
            "isSigned": !!signature,
            "disabled": true
        }
      })
      setDocumentProperties(updated_document_properties)
    }
  }, [displayedPDFType, JSON.stringify(documentProperties), JSON.stringify(signatories)])

  const getProperties = (document_id) => {
    setLoading(true)
    DocumentWorkflowService.getDocumentProperties(document_id).then((res)=>{
      log('get properties document hereeeeee', res.data.data)
      if(res.data.success){
        setLoading(false)
        var elements_to_plot = res.data.data.documentProperties.map((el) => ({
          "id": el.id,
          "type": el.type,
          "value": el.value,
          "font_size": el.font_size,
          "x": parseFloat(el.x_axis),
          "y": parseFloat(el.y_axis),
          "page_no": el.page_no,
          "width": parseFloat(el.width),
          "height": parseFloat(el.height),
        }))

        log("elements ssa", elements_to_plot);

        setDocumentProperties(elements_to_plot)

        // setDocumentURLFinal(res?.data?.data?.document.document_url + `&subscription-key=${subscriptionKey}`)
        res.data.data.document.document_url = res.data.data.document.document_url + `&subscription-key=${subscriptionKey}`

        
        setDocumentsId(res.data.data.document)
      }
    })
  }

  const getSignatories = (document_id) => {
    DocumentWorkflowService.getSignatories(document_id).then(({data})=>{
      let signatories = data.data.map((item) => {
        return {
          id: item.id,
          email_address: item.email_address,
          status: item.status,
          signature_url: item.signature_url,
          sequence: item.sequence,
          updated_time: item.updated_time
        }
      })
      setSignatories(signatories)
    })
  }

  signatories.sort((a, b) => a.sequence - b.sequence);

  const generateVerifiableQRCode = (document_id) => {
    DocumentWorkflowService.generateVerifiableQRCode(document_id).then(({data}) => {
      log("generateVerifiableQRCode res xx " + data);
      if (data.success) {
        let verificationLink = data.data.verification_link
        setVerificationLink(verificationLink)
      }
    })
  }



log("signature_url xxxx", signature?.signature_url)
log("getDocumentById xxxx", getDocumentById)

  const {openDialog} = useDialog()

  //TODO add dynamic value for QRCode
  const draggable_elements = [
    {type: 'esignature', name: "Signature", value: signature?.signature_url !== null ? signature?.signature_url : "Please add signature" },
    {type: 'textfield', name: "Text Field", value: ''},
    {type: 'current_date', name: "Date"},
    {type: "qrcode", name: "QR Code", value: verificationLink}
  ]

  function handleClose(isCancel){
    log("hit handleOnClose!")
    setOpen(false);
    if (!isCancel) window.location.href="/files";
  }

  function handleOnSave(pdf_result){
    // result here . . .
    // alert("got the result in App js");
    log("document properties aa", documentProperties);
    log("App JS pdf_resultpdf_result: ", pdf_result)

    setPDFResult(pdf_result)
    setSignatoryEmails(pdf_result.signatory);
    setOpen(true);
  }

  const getDeleteProperties = (old_elements, new_elements) => {
    var new_els = new_elements.map((el) => ({
      "id": el.id,
      "type": el.type,
      "value": el.value,
      "x": el.x,
      "y": el.y,
      "page_no": el.page_no,
      "width": el.width,
      "height": el.height,
    }))

    let diff = getObjectArrayDiff(old_elements, new_els)
    return diff;
  }

  function handleOnCancel(pdf_result){
    setPDFResult(pdf_result)
    setOpenSaveAsDraft(true)
  }

  const handleCloseSaveAsDraft = () => {
    setOpenSaveAsDraft(false)
    // window.location.href="/files";
  }

  const handleOnSaveAsDraft = () => {
    // Create payload
    let document_id = JSON.parse(id); 

    log("save as draft aa", pdfResult);
    let elements = pdfResult.elements.map((el) => ({
      "type": el.type,
      "value": el.value,
      "x_axis": el.x.toString(),
      "y_axis": el.y.toString(),
      "page_no": el.page_no.toString(),
      "width": el.width.toString(),
      "height": el.height.toString(),
      "font_size": el.font_size?.toString()
    }))

    let signatory = pdfResult.signatory
    let total_page = pdfResult.total_page
    let filename = pdfResult.filename

    log("payload elements saa", elements);

    setIsSaving(true)
    DocumentWorkflowService.saveAsDraftDocumentWorkflow(document_id, elements, filename, signatory, [], total_page).then(({data}) => {
      log("res save as draft xxxx", data);
      setIsSaving(false)
      if (data.success) {
        enqueueSnackbar("Successfully save document as draft.", { 
          variant: "success",
          autoHideDuration: 3000
        });
        localStorage.removeItem("elements")
        localStorage.removeItem("deleted_elements")
        handleClose(false)
        window.location.href="/files";
      } else {
        enqueueSnackbar(data.error, { 
          variant: "error",
          autoHideDuration: 3000
        });
      }
    }).catch((e) => {
      setIsSaving(false)

      let error = e.response.data.error
      log("error pppp", error);
      enqueueSnackbar(error, { 
        variant: "error",
        autoHideDuration: 3000
      });
    })
    // var deleted_property_ids = getDeleteProperties(documentProperties, pdfResult.elements).map((el) => el.id)
    // log("deleted_property_ids", deleted_property_ids)

    // let new_properties = getNewProperties(pdfResult.elements)
    // log("new_properties", new_properties)

    // saveDocAsDraft(document_id, new_properties, deleted_property_ids);
  }

  const getNewProperties = (elements) => {
    var new_properties = elements.map((el) => ({
      "type": el.type,
      "value": el.value,
      "x_axis": el.x.toString(),
      "y_axis": el.y.toString(),
      "page_no": el.page_no,
      "width": el.width.toString(),
      "height": el.height.toString(),
    }))
    return new_properties
  } 

  function handleOnDocumentLoadSuccess(pdf){
    log("hit handleOnDocumentLoadSuccess in App.js: ", pdf)
  }

  function handleOnDocumentPageLoadSuccess(page){
    log("hit handleOnDocumentPageLoadSuccess in App.js: ", page)
  }

  function handleOnDeleteElement(element){
    log("hit handleOnDeleteElement in App.js: ", element)
  }

  function handleOnEditElement(element){
    log("hit handleOnEditElement in App.js: ", element)
  }

  const handleOnFinishSignee = (pdf_result) => {
    log("signee finished!", pdf_result)
  }

  const showPDF = (type) => {
    setDisplayedPDFType(type)
  }

  // draggable fillable signatory element
  const draggable_fillable_elements = [
    {type: "signatory", name: "Signatory"}
  ]


  // signatories to plot
  const signatories_to_plot = [
    {type: "signatory", value: "namjoon@hybe.com", width: 149.9336382405346, height: 60.02655645267232, x: 355.2278481012658, y: 357.162, page_no: 1}
  ]

  const handleGetPDFResult = (pdf_result) => {
    log("handleGetPDFResult aa", pdf_result);
    setPDFResult(pdf_result)
  }

  function handleOnDocumentLoadSuccess(pdf){
    log("hit handleOnDocumentLoadSuccess in App.js: ", pdf)
  }

  function handleOnDocumentPageLoadSuccess(page){
      log("hit handleOnDocumentPageLoadSuccess in App.js: ", page)
  }

  const handleOnErrorAddSignee = (error) => {
    log("signee error rrrr", error);
    if (error.code == 0) {
      enqueueSnackbar(error.error, { 
        variant: "error",
        autoHideDuration: 8000
      });
    }
  }

  // DOCUMENT URL WITH SUBSCRIPTION KEY START

  const file_static = "https://ovcodedevapim.ovcode.com/ovsign-public-dw/document/public/view?e=yARi6_07xQwnDGJwTG8jfTrksN0IbrBw2SBB8k_-ecyM7GESX7wnxVjbZyn-2Gmo11GE9wqn7ILcHQsrv6RE05jIblJ2LNVtArYFsYAnmC2mvvegr-IjxxNUJR1xXfp_oXuKFSWoknBAYb-ystHzOQ==&i=d166b6fc-d082-4493-ace4-66cd89ea5b75&subscription-key=5512ec541cac4152bb6b2e2966b98506"

  const subKey = `&subscription-key=${subscriptionKey}`;

  const document_url = documentsId?.document_url ;

  log('get document url with subscription key xxxxxx', document_url)

  log('get sub key here xxxx', subKey )

  useEffect(()=>{
    if(!loading && documentsId?.document_url){
      setDocumentURLFinal(documentsId?.document_url)
    }
  },[loading && documentsId?.document_url])

  log('get document url final XXXXXXXx', documentsId?.document_url)
  log('FILE URL', documentURLFinal)

  return (
    <>
      {displayedPDFType == "pdf-editor" ?
        <>
          {documentsId?.document_url &&
            <PDFEditor
              documentId={id}
              file_url={documentURLFinal}
              // documentId={911}
              // file_url={testPDF4}
              elements_to_plot={documentProperties}
              draggable_elements={draggable_elements}
              onGetPDFResult={handleGetPDFResult}
              onSave={handleOnSave} 
              onCancel={handleOnCancel} 
              onDocumentLoadSuccess={handleOnDocumentLoadSuccess}
              onDocumentPageLoadSuccess={handleOnDocumentPageLoadSuccess}
              onDeleteElement={handleOnDeleteElement}
              onEditElement={handleOnEditElement}
              showPDF={showPDF}
              displayedPDFType={displayedPDFType}
              draggable_fillable_elements={draggable_fillable_elements}
              signatories={signatories}
              signatoryOptionLabel={(option) => option.email_address}
              savePDFButtonLabel={"Send"}
              onErrorAddSignee={handleOnErrorAddSignee}
              currentUser={currentUser} getDocumentById={getDocumentById} 
              handleWalkthroughButtonClick={handleWalkthroughButtonClick}
              reopenJoyride={reopenJoyride} showWalkthrough={showWalkthrough}
            />
          }
          <DocumentWorkflowConfirmationDialog
            signatory_emails={signatory_emails}
            pdfResult={pdfResult}
            documentId={id}
            open={open}
            onClose={handleClose}
            handleOnClickYes={handleOnSaveAsDraft}
            saving={isSaving}
          />

          <SaveAsDraftDialog openSaveAsDraft={openSaveAsDraft} handleOnClickNo={handleCloseSaveAsDraft} 
            handleOnClickYes={handleOnSaveAsDraft} saving={isSaving} saved={isSaved} 
            getDocumentById={getDocumentById}
          />
        </>
      :
        displayedPDFType == "pdf-viewer" ? 
          <>
          {log('get url from download', downloadView)}
          {downloadView || viewDownloadedPdf ? 
          // COMPLETED
              <PDFSignee 
                  file_url={downloadView} 
                  // elements_to_plot={documentProperties} 
                  signatory={signatories}
                  onDocumentLoadSuccess={handleOnDocumentLoadSuccess} 
                  onDocumentPageLoadSuccess={handleOnDocumentPageLoadSuccess} 
                  isViewing={true} viewDownloadedPdf={viewDownloadedPdf} getDocumentById={getDocumentById}
                  
              /> 
            :
            // DRAFT / PENDING
              <PDFSignee 
                  file_url={documentURLFinal} 
                  elements_to_plot={documentProperties} 
                  signatory={signatories}
                  onDocumentLoadSuccess={handleOnDocumentLoadSuccess} 
                  onDocumentPageLoadSuccess={handleOnDocumentPageLoadSuccess} 
                  isViewing={true} getDocumentById={getDocumentById}
              /> 
          }
          </>
          
      : null
      }
    {(isWalkthrough === false) && (
      <PDFEditorGuide setShowWalkthrough={setShowWalkthrough} />
    )}
    {showWalkthrough &&
      <PDFEditorGuide showWalkthrough={showWalkthrough} setShowWalkthrough={setShowWalkthrough}/>
    }

    </>
  );

}

export default OvdocsScreen;