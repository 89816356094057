

import { Avatar, Box, Button, Grid, IconButton, List, ListItem, ListItemText, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import SignInLogsSkeletonComponent from "../skeleton/signin-logs-skeleton.component";

function TableHeader(){

    const tableHeader = [ 
        {label : 'Email'},
        {label : 'Device Details'},
        {label : 'Location'},
        {label : 'Coordinates'},
    ];

    return(
        <TableHead >
            <TableRow>
                {tableHeader.map((item, index)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, backgroundColor: '#3333cf', color:'white'}}>{item.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

function TableContent(props){

    const { user , index , data, breakpoints,} = props;
 
    return(
        <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}}>
            {/* <TableCell component="th" scope="row" sx={{padding: 2,}}> 
                {data?.userId}
            </TableCell> */}

            <TableCell align="left" sx={{padding: 2}}>
                {data?.userPrincipalName}
            </TableCell>

            <TableCell align="left" sx={{padding: 2,}}> 
                <List sx={{width: "100%", bgcolor: "transparent"}}>
                    <ListItem>
                        <ListItemText 
                            primary={
                                <React.Fragment>
                                    <Typography sx={{color: "rgba(0, 0, 0, 0.87)", fontSize: 14}}>
                                        {data?.deviceDetail.operatingSystem}
                                    </Typography>
                                </React.Fragment>
                            } 
                            secondary={
                                <React.Fragment>
                                    <Typography sx={{color: "rgba(0, 0, 0, 0.87)", fontSize: 14}}>
                                        {data?.deviceDetail.browser}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </List>
            </TableCell>

            <TableCell align="left" sx={{padding: 2,}}> 
                {data?.location.city}{(data?.location.city) ? "," : null} {data?.location.countryOrRegion}
            </TableCell>

            <TableCell align="left" sx={{padding: 2}}> 
                <List sx={{width: "100%", bgcolor: "transparent"}}>
                    <ListItem>
                        <ListItemText 
                            primary={
                                <React.Fragment>
                                    <Typography sx={{color: "rgba(0, 0, 0, 0.87)", fontSize: 14}}>
                                        Lat: {data?.location.geoCoordinates.latitude}
                                    </Typography>
                                </React.Fragment>
                            } 
                            secondary={
                                <React.Fragment>
                                    <Typography sx={{color: "rgba(0, 0, 0, 0.87)", fontSize: 14}}>
                                        Long: {data?.location.geoCoordinates.longitude}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </List>
            </TableCell>
        </TableRow>
    )
}



function UserLoggedListComponent(props) {

    const {breakpoints, sessionLogs, isFetchingLogs, pagination, setFetchingLogs, handleUserLogs} = props;
    const [page, setPage] = useState(1);
    
    const handleChangePagination = (event, page) => {
        setPage(page);
        setFetchingLogs(true);
        handleUserLogs(page)
    }

    return(
        <Grid item xs={12} sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 3}}>
            <TableContainer className="LogsTable" component={Paper} sx={{border: "1px solid #8080807a", borderRadius: 2,  width: "100%"}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader/>

                    {(isFetchingLogs) ?
                        <TableBody>
                            <SignInLogsSkeletonComponent />
                        </TableBody>
                    :
                        <TableBody >
                            
                        {sessionLogs?.map((logs, index) => {
                            return (
                                <TableContent breakpoints={breakpoints}
                                data={logs} index={index} />
                            )
                        })}
                        </TableBody>
                    }
                </Table>
                {(!isFetchingLogs && sessionLogs.length > 0) &&
                    <Pagination
                        count={pagination} 
                        page={page} 
                        onChange={handleChangePagination} 
                        style={{margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30}}
                    />
                }
            </TableContainer>
        </Grid> 

    )
    
}

export default UserLoggedListComponent