import { log } from "../../../console-config";
import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpPost, HttpGet, HttpDelete, HttpPostMultipart, HttpPut, HttpPut1, HttpDownloadFile} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.ovsign_account_service.subscriptionKey;


const fileUploader = (formData) => {

    let payload = formData;

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.FILE_UPLOADER, payload, subscriptionKey);
    log('get file uploader route', routes.FILE_UPLOADER)
    return result;
}

const FileService = {
    fileUploader: fileUploader,
}

export default FileService
