import {Button, ButtonGroup, Card, CardContent, Grid, List, ListItem, ListItemText, Skeleton, TextField, Typography} from '@mui/material';
import FileUploaderComponent from './file-uploader.component';
import {useSnackbar} from "notistack";
import {useState, useEffect} from 'react';
import CreateSignatureDialogComponent from './e-signature/create-signature.dialog';
import useDocuments from '../../hooks/useDocuments';
import useUsers from '../../hooks/useUsers';
import { resizeImageWithAspectRatio } from '../../utils/ImageRationUtil';
import { useRef } from 'react';
import { log } from '../../console-config';


function SignatureComponent(props){

    const {breakpoints, signature} = props;
    const {user, retrieveUser} = useUsers();

    const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
    const handleOpenSignatureDialog = () => {setOpenSignatureDialog(true);}
    const handleCloseSignatureDialog = () => {setOpenSignatureDialog(false);}

    useEffect(() => {
        retrieveUser();
    }, [])


    const [imgDimens, setImgDimens] = useState(null);
    const [adjustedImgDimens, setAdjustedImgDimens] = useState(null);

    const signatureRef = useRef()

    useEffect(() => {
        if (imgDimens) {
            let {width, height} = resizeImageWithAspectRatio(imgDimens?.width, imgDimens?.height, signatureRef.current?.clientWidth, 
                signatureRef.current?.clientHeight)
            log("imgDimens nnn", imgDimens);
            log("newWidth", width);
            log("newHeight", height);
            setAdjustedImgDimens({width, height})
        }
    }, [JSON.stringify(imgDimens)])

    const onLoadImg = (e) => {
        setImgDimens({width: e.target.naturalWidth, height: e.target.naturalHeight})
    }



    return(
        <Grid container spacing={2} className="CreateSignature">
            <Grid item xs={12} sm={4}>
                <Card ref={signatureRef} onClick={(e) => !signature && handleOpenSignatureDialog()} sx={{border: '1px solid #0178f252', width: "100%", height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center',alignContent: 'center',flexWrap: 'wrap', boxShadow: 'none'}}>
                    <CardContent sx={{cursor: "default", justifyContent: 'center', alignItems: 'center',alignContent: 'center', padding: signature ? 1 : 2, paddingBottom: signature ? '0px !important' : 3}}>
                        {signature ?
                            <img src={signature?.signature_url} style={{width: adjustedImgDimens?.width, height: adjustedImgDimens?.height}}  onLoad={(e) => onLoadImg(e)} />
                        :
                            <Typography sx={{fontSize: 14, color: '#0178f252', fontStyle: 'italic', textAlign: 'center'}} >Create your signature</Typography>
                        }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} sx={{alignSelf: 'center'}}>
                {signature ?
                    <Button sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 12, textAlign:'left' }} onClick={(e) => handleOpenSignatureDialog(e)}>
                        <label style={{cursor: "pointer"}} htmlFor="upload-signature">
                            Change Signature
                        </label>
                    </Button>

                :
                    <Button sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 12, textAlign: "left"}}
                        onClick={(e) => handleOpenSignatureDialog()}
                    >
                        <label style={{cursor: "pointer" }} htmlFor="upload-signature">
                            Create Signature
                        </label>
                    </Button>
                }
            </Grid>
            

            {openSignatureDialog && 
                <CreateSignatureDialogComponent openSignatureDialog={openSignatureDialog} user={user}
                    breakpoints={breakpoints} handleCloseSignatureDialog={handleCloseSignatureDialog}
                />
            }
        </Grid>
    )
}

function FilesComponent(){

    const {loading, countAllRecords, all, drafts, pending, completed} = useDocuments();

    useEffect(() => {
        countAllRecords();
    },[])

    const fileFilter = [ 
        {label : 'All', path: "files", count : loading ? <Skeleton style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} /> : all},
        {label : 'Draft', path: "files/draft", count : loading ? <Skeleton style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} /> : drafts},
        {label : 'Pending', path: "files/pending",count : loading ? <Skeleton style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} /> : pending},
        {label : 'Completed', path: "files/completed",count : loading ? <Skeleton style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} /> : completed}
    ]

    return(
        <ButtonGroup variant="text" aria-label="text button group">
            {fileFilter.map((value) => {
                return (
                    <Button sx={{ textTransform: 'none', borderColor: '#0000004a !important','&:hover': { backgroundColor: '#f2f2f2'} }}
                    onClick={() => (window.location.href = value.path)}
                    >
                        <List sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper', pt: '0px !important', pb: '0px !important'}} >
                            <ListItem sx={{ pt: '0px !important', pb: '0px !important' }}>
                                <ListItemText
                                    primary={
                                    <Typography sx={{ textAlign: 'center', color: 'black', fontSize: 16 }} >
                                        {value.count}
                                    </Typography>
                                    }
                                    secondary={
                                    <Typography sx={{textAlign: 'center',color: '#0178f2',fontSize: 14 }}>
                                        {value.label}
                                    </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Button>
                );
            })}
        </ButtonGroup>
    )
}

function DigitalSignatureComponent(props) {

    const {breakpoints, signature} = props;

    const [title, setTitle] = useState("");

    const handleTemplateNameChange = (e) => {
        const { value } = e.target;
        setTitle(value);
    }

    return(

        <Grid container spacing={2} direction="row" sx={{padding: 3, pt: 2}} >
            <Grid item xs={12}  sm={12} sx={{pt: 0}}>
                <Card sx={{borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 'sm' ? 0 : 5, height:'100%',}}>
                    <CardContent>
                        <Grid container spacing={2} sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 16, pt: 0, pb: 3}}>
                            <Grid item xs={12} sm={8}>
                                <SignatureComponent breakpoints={breakpoints} signature={signature}/>
                            </Grid>
                            {/* <Grid item xs={12} sm={6.5} sx={{alignSelf: 'center', textAlign: breakpoints == 'xs' || breakpoints == 'sm' ? "center" : "end"}}>
                               <FilesComponent />
                            </Grid> */}
                            <Grid item xs={12} sm={4} sx={{alignSelf: 'center', textAlign:'end'}}>
                                <TextField  
                                    fullWidth  
                                    variant="filled"  
                                    autoComplete="off"  
                                    placeholder="Document Name"  
                                    className="DocumentName"
                                    value={title} 
                                    onChange={(e) => handleTemplateNameChange(e)} 
                                    InputProps={{
                                        disableUnderline: true, // <== added this
                                        padding: 0
                                      }}
                                      inputProps={{
                                        style: {
                                          padding: 10,
                                          color: "#21649e",
                                          fontSize: 14
                                        }
                                     }}
                                />
                            </Grid>
                        </Grid>

                        <FileUploaderComponent breakpoints={breakpoints} title={title} setTitle={setTitle} />
                        
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
    
}

export default DigitalSignatureComponent