import msal from "../utils/msals";

/**
 * login
 * @returns {Promise} returns Promise returned by msal loginPopup
 * in case you want to do something after calling this function, use "then();" callback in your desired view
 */
const login = () => {
    return msal.loginPopup();
}


/**
 * login
 * @returns {Promise} returns Promise returned by msal loginPopup
 * in case you want to do something after calling this function, use "then();" callback in your desired view
 */
const loginRedirect = () => {
    return msal.loginRedirect();
}


/**
 * logout
 * @returns {Promise} returns Promise returned by msal logoutPopup
 * in case you want to do something after calling this function, use "then();" callback in your desired view
 */
 const logout = () => {
    return msal.logoutPopup();
}

/**
 * logout
 * @returns {Promise} returns Promise returned by msal acquireTokenSilent
 * in case you want to do something after calling this function, use "then();" callback in your desired view
 */
 const getAuthToken = () => {
    return msal.acquireTokenSilentFirstAccount();
}

/**
 * logout
 * @returns {Boolean} returns boolean
 */
 const isUserLoggedIn = () => {
    return msal.isUserLoggedIn();
}


const getFirstAccount = () => {
    return msal.getFirstAccount();
}

const loginssoSilent = () => {
    return msal.loginssoSilent();
}

const AuthService = {
    login,
    logout,
    getAuthToken,
    isUserLoggedIn,
    loginRedirect,
    getFirstAccount,
    loginssoSilent
}

export default AuthService;