import {useState, useRef, useEffect, useCallback} from 'react';
import { Grid, Typography, Box, Stack, TextareaAutosize, TextField, Input  } from "@mui/material"

import { ResizableBox } from 'react-resizable';
// import { useResizeDetector } from 'react-resize-detector';
import ResizeObserver from 'rc-resize-observer';
import moment from 'moment'

import { elementTypes, initialDragElements, minElementWidth, minElementHeight } from "../common/staticVariables";
import { useDebounce } from "../hooks/useDebounce";
import { getAdjustedHeight, getAdjustedWidth } from "../../utils/AspectRatioUtil";
import { log } from '../../../console-config';

const SignatoryElement = ({elDragRef, element}) => {
    return(
        <Box sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', width: "100%", height: "100%"}}>
            <Typography align='center' noWrap sx={{textAlign: 'center',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', margin:'auto'}}>{!element.value ? "Signatory" : element.value}</Typography>
        </Box>
    )
}


const ElementComponent = ({element, elDragRef, elDragRef2, handleResize, onChangeValue, dndRef, handleOnMouseEnter, handleOnResizeStart, parentRef}) => {

    const [imgDimens, setImgDimens] = useState(null);
    const [elMinConstraints, setElMinConstraints] = useState({width: 0, height: 0});
    const [elMaxConstraints, setElMaxConstraints] = useState({width: 0, height: 0, x: 0, y: 0});
    const {_debounce, data} = useDebounce(200)
    const [textValue, setTextValue] = useState('')

    const isElementWithText = element.type == elementTypes.currentDate || element.type == elementTypes.textField ? true : false

    useEffect(() => {
        if (imgDimens) {
            // set min constraints for image elements (QRCode, UserSignature)
            let {width, height} = getElMinConstraints(imgDimens?.width, imgDimens?.height, true)
            setElMinConstraints({width, height})

            // reset el max constraints when updating image
            setElMaxConstraints({width: 0, height: 0})
        }
    }, [JSON.stringify(imgDimens)])

    // set minimum constraints for non image elements (Date, TextField, Signatory)
    useEffect(() => {
        let elWidth, elHeight;

        if (element.type == elementTypes.currentDate) {
            elWidth = initialDragElements.currentDate.width
            elHeight = initialDragElements.currentDate.height
        } else if (element.type == elementTypes.textField) {
            elWidth = initialDragElements.textField.width
            elHeight = initialDragElements.textField.height
        } else if (element.type == elementTypes.signatory) {
            elWidth = initialDragElements.signatory.width
            elHeight = initialDragElements.signatory.height
        }
        
        let {width, height} = getElMinConstraints(elWidth, elHeight, false)
        setElMinConstraints({width, height})
    }, [])

    // set maximum constraints for all elements
    useEffect(() => {
        if (parentRef.current != null && elDragRef2.current != null) {
            const parentRect = parentRef.current.getBoundingClientRect();
            const childRect = elDragRef2.current.getBoundingClientRect();
    
            if (element.type == elementTypes.textField) {
                let maxWidth = (parentRect.right - childRect.right) + childRect.width
                let maxHeight = (parentRect.bottom - childRect.bottom) + childRect.height
                setElMaxConstraints({width: maxWidth, height: maxHeight})
                return;
            }
    
            let maxWidth, maxHeight;
            let rightDiff = parentRect.right - childRect.right;
            let bottomDiff = parentRect.bottom - childRect.bottom;
    
            maxWidth = rightDiff + childRect.width
            maxHeight = bottomDiff + childRect.height

            let hasCoordsChange = elMaxConstraints.x != childRect.x && elMaxConstraints.y != childRect.y ? true : false;
            if (hasCoordsChange) setElMaxConstraints({width: Math.floor(maxWidth), height: Math.floor(maxHeight), x: childRect.x, y: childRect.y})
        }
    }, [JSON.stringify(element), parentRef, elDragRef2])

    useEffect(() => {
        if (data) {
            if(data.type == 'input_change') onChangeValue(data.value)
        }
    }, [JSON.stringify(data)])

    const getElMinConstraints = (origWidth, origHeight, hasAspectRatio) => {
        let minWidth, minHeight
        if (hasAspectRatio) {
            minWidth = getAdjustedWidth(minElementHeight, origWidth, origHeight)
            minHeight = getAdjustedHeight(minWidth, origWidth, origHeight)
        } else {
            minWidth = minElementWidth
            minHeight = minElementHeight
        }

        return {width: minWidth, height: minHeight}
    }

    const onResizeStop = (event, {el, size, handle}) => {
        const childRect = elDragRef2.current.getBoundingClientRect();
        setElMaxConstraints({width: elMaxConstraints.width, height: elMaxConstraints.height, x: childRect.x, y: childRect.y})
        handleResize(size.width, size.height)
    };

    const onLoadImg = (e) => {
        console.log("on load img aaa", e.target.width);
        setImgDimens({width: e.target.width, height: e.target.height})
    }

    const onResize = (event, {el, size, handle}) => {
        // for max constraints with maintained aspect ratio
        if (element.type == elementTypes.qrcode || element.type == elementTypes.userSignature || element.type == elementTypes.signature) {
            let sizeWidth = Math.floor(size.width)
            let sizeHeight = Math.floor(size.height)

            console.log("parentRef aaa1", parentRef);
            console.log("elDragRef2 aaa1", elDragRef2);
            
            if (parentRef.current != null) {
                const parentRect = parentRef.current.getBoundingClientRect();
                
                let rightDiff = parentRect.width - (element.x + element.width);
                let bottomDiff = parentRect.height - (element.y + element.height);
                
                let maxWidth = Math.floor(rightDiff + element.width)
                let maxHeight = Math.floor(bottomDiff + element.height)
                console.log("sizeWidth aaa", sizeWidth);
                console.log("maxWidth aaa", maxWidth);
                console.log("maxHeight aaa", maxHeight);
    
                if (sizeWidth >= maxWidth) {
                    let adjustedHeight = getAdjustedHeight(maxWidth, sizeWidth, sizeHeight)
                    setElMaxConstraints({width: maxWidth, height: Math.floor(adjustedHeight)})
                    return
                }
                if (sizeHeight >= maxHeight) {
                    let adjustedWidth = getAdjustedWidth(maxHeight, sizeWidth, sizeHeight)
                    setElMaxConstraints({width: Math.floor(adjustedWidth), height: maxHeight})
                    return
                }
            }
        }
    }

    const handleTextfieldChange = (e) => {
        let value = e.target.value
        setTextValue(value)
        _debounce({value, type: 'input_change'})
    }

    const onResizeStart = () => {
        handleOnResizeStart()
    }

    return(
        <>
            {
                <ResizableBox
                    onMouseEnter={() => handleOnMouseEnter(true)}
                    className={'editor-box'}
                    // width={getResizableBoxOnLoadDimens().width}
                    // height={getResizableBoxOnLoadDimens().height}
                    width={element?.width}
                    height={element?.height}
                    lockAspectRatio={isElementWithText ? false : true}
                    onResizeStop={onResizeStop}
                    onResize={onResize} 
                    onResizeStart={onResizeStart}
                    axis={'both'}
                    minConstraints={elMinConstraints.width > 0 && elMinConstraints.height > 0 ? [elMinConstraints.width, elMinConstraints.height] : null}
                    maxConstraints={elMaxConstraints.width > 0 && elMaxConstraints.height > 0 ? [elMaxConstraints.width, elMaxConstraints.height] : null}
                >
                    {   
                    (element?.type == elementTypes.userSignature || element?.type == elementTypes.qrcode) ? 
                        <>
                            <img alt="" src={element?.value} style={{width: '100%', height: '100%', objectFit: "contain", padding: 0, margin: 0}} onLoad={(e) => onLoadImg(e)} /> 
                            {element?.value == undefined && <div style={{textAlign:'center', position:'absolute', transform:'translate(50%, -50%)', top: '50%', padding: 15,}}>Signature</div>}
                        </>
                    : 
                    element?.type == elementTypes.textField ? 
                        <textarea
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                                resize: 'none',
                                outline: 'none',
                                backgroundColor: "#1684013b",
                                borderRadius: "5px",
                                padding: 0,
                                color: "#1a1a1a",
                                fontSize: element.font_size,
                                fontFamily: 'Helvetica',
                                lineHeight: 0.95,
                            }}
                            autoFocus
                            defaultValue={textValue || element?.value}
                            onChange={(e) => handleTextfieldChange(e)}
                            placeholder='Type here'
                        />
                    :
                    element?.type == elementTypes.currentDate ?
                        <Typography sx={{textAlign: "start", fontSize: element?.font_size, whiteSpace: 'nowrap',overflow: 'hidden', 
                            textOverflow: 'ellipsis', width: '100%', lineHeight: 0.95, letterSpacing: '0px'}}>{element?.value}</Typography>
                    :
                        <SignatoryElement element={element} />
                    }
                </ResizableBox>
            }
        </>
    )
}

export default ElementComponent