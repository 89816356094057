import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material"
import PDFIcon from "../../../icons/pdf.png";
import XLSIcon from "../../../icons/xls.png";
import CloseIcon from '@mui/icons-material/Close';

import 'jspdf-autotable';

const ExtractConfirmationModal = (props) => {

    const {openExtractConfirmation, handleCloseModal, handleDownloadPDF} = props;

    return(
        <Dialog open={openExtractConfirmation} onClose={handleCloseModal} >
            <DialogTitle sx={{display:'flex', alignItems:'center'}}>
                <Typography sx={{fontWeight:'bold', fontSize: 16}}>Export</Typography>
                <Box sx={{flexGrow: 1}}/>
                <IconButton onClick={handleCloseModal}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{m: 3, mt: 0}}>
                <Typography sx={{fontSize: 14}}>
                    "By default, the system allows downloading a maximum of 1000 records."
                </Typography>
                <Box sx={{display:'flex', alignItems:'center', mt: 2, width:'100%'}}>
                    <Button onClick={() => handleDownloadPDF("EXCEL")} variant="outlined" color="success" sx={{textTransform:'none'}}
                    >
                        <img src={XLSIcon} style={{height: 20, width: 20, marginRight: 5}}/> Export as Excel
                    </Button>
                    <Box sx={{flexGrow: 1}}/>
                    <Button onClick={() => handleDownloadPDF("PDF")} variant="outlined" color="error" sx={{textTransform:'none'}}
                    >
                        <img src={PDFIcon} style={{height: 20, width: 20, marginRight: 5}}/> Export as PDF
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ExtractConfirmationModal;