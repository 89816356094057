import * as React from 'react';
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Button, DialogActions, DialogContent, Divider, Checkbox, FormGroup, FormControlLabel, Grid, Stack, Switch, Typography } from "@mui/material";
import {ThreeDots} from "react-loader-spinner";
import {useSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";


import SignatorySectionComponent from '../components/SignatorySectionComponent';
import AddCarbonCopySectionComponent from '../components/AddCarbonCopySectionComponent';
import DocumentWorkflowService from "../../../services/api/documents";
import CreditLimitErrorDialog from './credit-limit-error-dialog.component';
import { log } from '../../../console-config';

function DocumentWorkflowConfirmationDialog(props) {

  const { signatory_emails, open, onClose, pdfResult, documentId, handleOnClickYes, saving } = props;
  const [checked, setChecked] = useState(true);
  const [carbon_copy_list, setCarbonCopyList] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [openCreditLimit, setOpenCreditLimit] = useState(false)
  const [switchValue, setSwitchValue] = useState(true);

  const handleChange = (event) => {
    setSwitchValue(event.target.checked ? true : false);
  };

  const {enqueueSnackbar} = useSnackbar();

  const handleClose = (isCancel) => {
    onClose(isCancel);
  };

  function handleOnCheck(value){
    setChecked(checked ? false : true);
  }

  log('get check value here', switchValue)

  function handleOnClickOkay(){
    log("============== CLICKED OKAY BUTTON =================: ");
    log("INCLUDE LAST PAGE: ", checked);
    log("SIGNATORY EMAILS: ", signatory_emails);
    log("CARBON COPY LIST: ", carbon_copy_list);
    log("pdfResult bbbb: ", pdfResult);
    log("documentId bbbb: ", documentId);

    let document_id = JSON.parse(documentId); 
    
    let elements = pdfResult.elements.map((el) => ({
      "type": el.type,
      "value": el.value,
      "x_axis": el.x.toString(),
      "y_axis": el.y.toString(),
      "page_no": el.page_no.toString(),
      "width": el.width.toString(),
      "height": el.height.toString(),
      "font_size": el.font_size?.toString(),
    }))

    let total_page = pdfResult.total_page
    let filename = pdfResult.filename
    let is_auto_remind = switchValue

    setIsSaving(true)
    DocumentWorkflowService.saveDocumentWorkflow(document_id, elements, filename, signatory_emails, carbon_copy_list, total_page, checked, is_auto_remind).then(({data}) => {
      log("res save doc workflow aaa", data);
      setIsSaving(false)
      if (data.success === true) {
        enqueueSnackbar("Successfully created document workflow.", { 
          variant: "success",
          autoHideDuration: 3000
        });
         // remove document cached elements
         let cachedElements = JSON.parse(localStorage.getItem("elements"))
         let hasDocumentCached = !cachedElements || !cachedElements[document_id] ? false : true;
         if (hasDocumentCached) {
           delete cachedElements[document_id];
           cachedElements = {
             ...cachedElements
           }
           localStorage.setItem("elements", JSON.stringify(cachedElements))
         }
         handleClose(false)
      } else {
        enqueueSnackbar(data.error, { 
          variant: "error",
          autoHideDuration: 3000
        });
      }
      if(data.is_credit_limit === false){
        handleClose(true)
        setOpenCreditLimit(true)
      }
    }).catch((e) => {
      setIsSaving(false)
      let error = e.response.data.error
      log("error pppp", error);
      enqueueSnackbar(error, { 
        variant: "error",
        autoHideDuration: 3000
      });
    })
  }

  function handleOnCarbonCopyListChange(carbonCopyList){
    log("handleOnCarbonCopyListChange carbonCopyList: ", carbonCopyList);
    setCarbonCopyList(carbonCopyList);
  }

  return (
    <>
    <Dialog 
      open={open} 
      onClose={() => handleClose(true)} 
      PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2}}}>
        
        <DialogTitle style={{fontWeight: "bold", fontSize: 20, display: "flex"}}>
          Confirmations

          <Grid sx={{ml: "auto"}}>
            <CloseIcon fontSize="small" onClick={() => handleClose(true)} sx={{cursor: "pointer"}} />
          </Grid>
        </DialogTitle>

        <Divider />

        <DialogContent sx={{padding: 6, paddingTop: 2}}>
          
          <SignatorySectionComponent emails={signatory_emails} />

          <FormGroup>
            <FormControlLabel 
              control={<Checkbox defaultChecked color="success" onChange={(event)=>handleOnCheck(event.target.value)} />} 
              label="Include last page certificate" 
            />
          </FormGroup>

          <Typography sx={{ mt: 2, mb: 0 }} component="div">
            Auto Reminder
          </Typography>

          <FormGroup className="card-update">
            <Stack direction="row" spacing={1} alignItems="center">
              <Switch
                checked={switchValue === true}
                onChange={handleChange} color="success"
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography>
              {switchValue === true ? 'On' : 'Off'}
              </Typography>
            </Stack>
          </FormGroup>

          <AddCarbonCopySectionComponent onCarbonCopyListChange={handleOnCarbonCopyListChange}/>
        
        </DialogContent>

        <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
          <Button variant="contained" 
            startIcon={isSaving &&
              <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
            }
            sx={{width: 120, backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
            onClick={(e) => handleOnClickOkay()}>
            Confirm
          </Button>
        </DialogActions>

    </Dialog>
    {openCreditLimit &&
      <CreditLimitErrorDialog openCreditLimit={openCreditLimit} saving={saving}
      handleOnClickYes={handleOnClickYes} setOpenCreditLimit={setOpenCreditLimit}/>
    }
    </>

  );
}

DocumentWorkflowConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DocumentWorkflowConfirmationDialog;