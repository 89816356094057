import DonutChart from "react-donut-chart";
import { log } from "../../console-config";
import useWidth from "../../hooks/useWidth";

function AnalyticsComponent (props) {

    const {all, drafts, pending, completed} = props;

    const breakpoints = useWidth();

    log("breakpointsxxx", breakpoints)

    const reactDonutChartdata = [
        {
          label: "Pending",
          value: pending,
          color: "#FF8300"
        },
        {
          label: "Draft",
          value: drafts,
          color: "#808080"
        },
        {
          label: "Completed",
          value: completed,
          color: "#008000"
        },
        
      ];
      const reactDonutChartBackgroundColor = [
        "#FF8300",
        "#808080",
        "#008000",
      ];
      const reactDonutChartInnerRadius = 0.5;
      const reactDonutChartSelectedOffset = 0.04;
      const reactDonutChartHandleClick = (item, toggled) => {
        if (toggled) {
          log(item);
        }
      };
      let reactDonutChartStrokeColor = "#FFFFFF";
      const reactDonutChartOnMouseEnter = (item) => {
        let color = reactDonutChartdata.find((q) => q.label === item.label).color;
        reactDonutChartStrokeColor = color;
      };

    return(
        <DonutChart
            width={breakpoints === "xs" ? 300 : 500}
            height={breakpoints === "xs" ? 200 : 400}
            onMouseEnter={(item) => reactDonutChartOnMouseEnter(item)}
            strokeColor={reactDonutChartStrokeColor}
            data={reactDonutChartdata}
            colors={reactDonutChartBackgroundColor}
            innerRadius={reactDonutChartInnerRadius}
            selectedOffset={reactDonutChartSelectedOffset}
            onClick={(item, toggled) => reactDonutChartHandleClick(item, toggled)}
        />
    )
}

export default AnalyticsComponent