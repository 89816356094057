import React , { useCallback } from 'react';

import { createStore, selector } from "./zustand";

const module_name = "dialogs"

export const dialogStore = createStore((set) => ({
    // state
    dialogs: {},

    // actions
    openDialog: (tag, payload) => set((prev) => {
        return {...prev, dialogs: {...prev.dialogs, [tag]: {open: true, params: payload}}}
    }, false, module_name + "/open"),

    closeDialog: (tag) => set(prev => {
        const updatedDialogs = Object.keys(prev.dialogs).reduce((accumulator, key) => {
            return {...accumulator, [key]: key == tag ? {open: false, params: undefined} : prev.dialogs[key]};
        }, {});

        return {...prev, dialogs: updatedDialogs}
    }, false, module_name + "/close")

}), module_name)

export const useDialogStore = () => dialogStore(selector)
