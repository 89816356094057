import { Card, CardActionArea, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import useWidth from "../../hooks/useWidth";



function StatusCardsComponent ({all, drafts, pending, completed}) {

    const breakpoints = useWidth();

    return(
        <>
            <Card className="All" sx={{backgroundColor:'#deeaf3'}}>
                <CardActionArea sx={{padding: 5}} component={Link} to={"/files"} >
                <Typography sx={{fontSize: 14, fontWeight:"bold", color: "#0178f2", textAlign: breakpoints === "xs" ? 'center' : 'start'}}>
                    All
                </Typography>
                <Typography sx={{fontSize: 30, fontWeight:"bold", color: "#0178f2", textAlign: breakpoints === "xs" ? 'center' : 'start'}}> 
                {/* {loading ? <>...</> : <>{status.counter}</>} */}
                    {all}
                </Typography>
                </CardActionArea>
            </Card>
            <Card className="Drafts" sx={{backgroundColor:'#deeaf3'}}>
                <CardActionArea sx={{padding: 5}} component={Link} to={"/files/draft"} >
                <Typography sx={{fontSize: 14, fontWeight:"bold", color: "#0178f2", textAlign: breakpoints === "xs" ? 'center' : 'start'}}>
                    Drafts
                </Typography>
                <Typography sx={{fontSize: 30, fontWeight:"bold", color: "#0178f2", textAlign: breakpoints === "xs" ? 'center' : 'start'}}> 
                {/* {loading ? <>...</> : <>{status.counter}</>} */}
                    {drafts}
                </Typography>
                </CardActionArea>
            </Card>
            <Card className="Pending" sx={{backgroundColor:'#deeaf3'}}>
                <CardActionArea sx={{padding: 5}} component={Link} to={"/files/pending"} >
                <Typography sx={{fontSize: 14, fontWeight:"bold", color: "#0178f2", textAlign: breakpoints === "xs" ? 'center' : 'start'}}>
                    Pending
                </Typography>
                <Typography sx={{fontSize: 30, fontWeight:"bold", color: "#0178f2", textAlign: breakpoints === "xs" ? 'center' : 'start'}}> 
                {/* {loading ? <>...</> : <>{status.counter}</>} */}
                    {pending}
                </Typography>
                </CardActionArea>
            </Card>
            <Card className="Completed" sx={{backgroundColor:'#deeaf3'}}>
                <CardActionArea sx={{padding: 5}} component={Link} to={"/files/completed"} >
                <Typography sx={{fontSize: 14, fontWeight:"bold", color: "#0178f2", textAlign: breakpoints === "xs" ? 'center' : 'start'}}>
                    Completed
                </Typography>
                <Typography sx={{fontSize: 30, fontWeight:"bold", color: "#0178f2", textAlign: breakpoints === "xs" ? 'center' : 'start'}}> 
                {/* {loading ? <>...</> : <>{status.counter}</>} */}
                    {completed}
                </Typography>
                </CardActionArea>
            </Card>
        </>
    )
}

export default StatusCardsComponent