import { Box, Button, Card, CardActionArea, CardActions, CardContent, Grid, TextField, Typography } from "@mui/material";
import SendIcon from "../icons/send-mail.png"
import useWidth from "../hooks/useWidth";
import useUsers from "../hooks/useUsers";
import { useEffect } from "react";
import { useState } from "react";
import { log } from "../console-config";
import DocumentWorkflowService from "../services/api/documents";
import InquiryComponent from "../components/inquiry/inquiry-component";
import SuccessInquiryDialogComponent from "../components/inquiry/dialog/success-inquiry-dialog.component";
import { useSnackbar } from "notistack";


export default function SendInquiryScreen () {

    const breakpoints = useWidth();
    const {user, retrieveUser} = useUsers();
    const [inputInquiry, setInputInquiry] = useState('');
    const [openSuccessInquiry, setOpenSuccessInquiry] = useState(false);
    const [loading, setLoading] = useState(false)
    const [inputRequired, setInputRequired] = useState(false)
    const {enqueueSnackbar} = useSnackbar()

    log('input inquiry', inputInquiry)

    const handleInputInquiry = (e) => {
        setInputInquiry(e.target.value);
        setInputRequired(false)
    }

    const handleClose = () => {
        setOpenSuccessInquiry(false)
    }

    const handleSubmit = () => {
        if(!inputInquiry){
            setInputRequired(true)
        } else {
            setLoading(true)
            DocumentWorkflowService.sendInquiry(inputInquiry).then((res)=>{
                log('get inquiry res here', res)
                setOpenSuccessInquiry(true)
                setLoading(false)
            }).catch((err)=>{
                log('get inquiry err here', err)
                setLoading(false)
                enqueueSnackbar(err.response.data.error, { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            })
        }
    }

    const resetInquiry = () => {
        setInputInquiry('');
        handleClose()
    }

    useEffect(()=>{retrieveUser()},[])

    return(
        <Box sx={{width: breakpoints == 'xs' || breakpoints == 'sm' ? '100%' : '50%', margin:'auto', mt: 2, mb: 2}}>

            {/* Inquiry component */}
            <Card sx={{borderRadius: 1, padding: 3, paddingTop: 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
                <InquiryComponent handleSubmit={handleSubmit} handleInputInquiry={handleInputInquiry} 
                user={user} loading={loading} inputInquiry={inputInquiry} inputRequired={inputRequired}/>
            </Card>

            {/* Success Inquiry Modal */}
            <SuccessInquiryDialogComponent openSuccessInquiry={openSuccessInquiry} handleClose={handleClose}  resetInquiry={resetInquiry} />
        </Box>
    )
}