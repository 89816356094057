import React from "react";
import {Dialog, DialogContent, Grid, Typography, Button, IconButton, Box} from "@mui/material";
import environment from "../../../environment/environment";
import CloseIcon from '@mui/icons-material/Close';
import { log } from "../../../console-config";

const FinishNotificationDialog = (props) => {

    const {openFinishDialog, handleCloseFinishDialog} = props;

    const website_url = environment.msal_url.url;

    const gotoLink = () => {
        window.open(website_url + "/landing-confirmation", "_self")
    }

    log('get website url', website_url)

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}}}>
            <Box sx={{textAlign:'end', padding: "5px 10px 10px 0px"}}>
                <IconButton onClick={gotoLink}>
                    <CloseIcon sx={{color:'#0178f2'}}/>
                </IconButton>
            </Box>
            <DialogContent sx={{padding: "0px 24px 35px 24px"}}>
                <Grid container justifyContent="center" direction="column">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto"}}>
                        <Typography variant="h5" sx={{ fontWeight: "bold", color: "#168401"}}>
                            You're Done
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto"}}>
                        <Typography variant="subtitle2" sx={{color: "#000"}}>
                            You will receive an email once everyone has signed
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto", mt: "20px"}}>
                        <Typography variant="h6" sx={{fontWeight: "bold", color: "#168401"}}>
                            Thank you for using eSign
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto"}}>
                        <Typography variant="subtitle2" sx={{color: "#000"}}>
                            Create, customize and send document for signatures
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto", mt: "22px"}}>
                        <Button onClick={gotoLink} variant="contained" sx={{width: 220, backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none'}}>
                            Done
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default FinishNotificationDialog