import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import useDocuments from "../../../../hooks/useDocuments";
import DocumentsPublicService from "../../../../services/api/documents_public";
import { log } from "../../../../console-config";
import { ThreeDots } from "react-loader-spinner";
import { useSnackbar } from "notistack";

const DeclineResonDialog = (props) => {
    const { openDeclined, handleClose, handleDecline, currentUser, document_id, setOpenCancelledDialog } = props;

    let accessToken = (new URLSearchParams(window.location.search)).get("token");

    const { enqueueSnackbar } = useSnackbar();

    const [reason, setReason] = useState('');
    const [maxWidth, setMaxWidth] = useState('sm');
    const [fullWidth, setFullWidth] = useState(true);
    const [error, setError] = useState(false);
    const [declining, setDeclining] = useState(false);

    const handleReasonChange = (event) => {
        setReason(event.target.value);
        if (event.target.value) {
            setError(false);
        }
    };

    const handleSubmit = () => {
        if (reason) {
            setDeclining(true);
            DocumentsPublicService.declineDocument(document_id, currentUser, reason, accessToken).then(({ data }) => {
                log("declineDocument data response xxxx", data);
                setDeclining(false)
                if (data?.success) {
                    handleDecline();
                    setOpenCancelledDialog(true);
                    resolve();

                } else {
                    log("declineDocument reject error response xxxx", data?.error);
                    enqueueSnackbar("Something went wrong. Please try again.", {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                    setDeclining(false);
                    handleClose();
                }
            }).catch((e) => {
                log("declineDocument error response xxxx", e);
                enqueueSnackbar(e.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
                setDeclining(false);
                handleClose();
            })
        } else {
            setError(true);
        }
    };

    return (
        <Dialog open={openDeclined} onClose={handleClose} maxWidth={maxWidth} fullWidth={fullWidth}>
            <DialogTitle id="alert-dialog-title">{"Provide Reason for Declining"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please provide a reason for declining the document:
                </DialogContentText>
                <br />
                <TextField
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    sx={{ "& .MuiInputBase-root": { borderRadius: 4, backgroundColor: 'transparent' } }}
                    inputProps={{ maxLength: 120, style: { fontSize: 14 } }}
                    multiline
                    rows={3}
                    placeholder="Enter reason here..."
                    value={reason}
                    onChange={handleReasonChange}
                    error={error}
                    helperText={error ? "Reason is required" : `${reason.length}/120`}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained"
                    sx={{ backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
                    startIcon={declining &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {declining ? 'Declining' : 'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeclineResonDialog;
