import React, {useEffect, useState} from "react";
import {BottomNavigation, BottomNavigationAction, Toolbar} from "@mui/material";
import {Box} from "@mui/system";
import {useNavigate} from "react-router-dom";
import DigitalSignatureIcon from "../icons/signature-pen.png";
import DashboardIcon from "../icons/dashboard.png";
import FilesIcon from "../icons/files.png";
import DocumentsIcon from "../icons/file.png";
import AnalyticsIcon from "../icons/analytics.png";
import ReportsIcon from "../icons/document.png";

const TopNavigationComponent = ({breakpoints}) => {

    const url = window.location.pathname;
    const [value, setValue] = useState(0);
  
    const navigate = useNavigate();

    useEffect(() => {
        if (url == "/dashboard") {
            setValue(0);
        } else if (url == "/documents") {
            setValue(1);
        } else if (url == "/files") {
            setValue(3);
        } else if (url == "/analytics") {
            setValue(4);
        } else if (url == "/reports") {
            setValue(5);
        }
    },[])
   
    const handleTabChange = (event, newValue) => {
        setValue(newValue);

        let route;
      
        switch (newValue) {
            case 0:
                route = '/dashboard';
                break;
            case 1:
                route = '/documents';
                break;
            case 2:
                route = '/files';
                break;
            case 3:
                route = '/analytics';
                break;
            case 4:
                route = '/reports';
                break;
        }

        navigate(route);
    }


    return (
        <Box sx={{zIndex: 2,width: "100%", position: "fixed", top: 12, boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)"}}>
            <Toolbar />
            
            <BottomNavigation showLabels value={value} onChange={handleTabChange}
                sx={{mt: "-5px", boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)"}}
            >
                <BottomNavigationAction sx={{backgroundColor: (value == 0) ? "#0178f212" : {}}}
                    icon={<img alt="dashboard-icon" src={DashboardIcon} style={{height: 25}} title="Dashboard" />}  
                />

                <BottomNavigationAction sx={{backgroundColor: (value == 1) ? "#0178f212" : {}}}
                    icon={<img alt="documents-icon" src={DocumentsIcon} style={{height: 25}} title="Documents" />}  
                />

                <BottomNavigationAction sx={{backgroundColor: (value == 2) ? "#0178f212" : {}}}
                    icon={<img alt="files-icon" src={FilesIcon} style={{height: 25}} title="Files" />}  
                />

                <BottomNavigationAction sx={{backgroundColor: (value == 3) ? "#0178f212" : {}}}
                    icon={<img alt="files-icon" src={AnalyticsIcon} style={{height: 25}} title="Analytics" />}  
                />

                <BottomNavigationAction sx={{backgroundColor: (value == 4) ? "#0178f212" : {}}}
                    icon={<img alt="files-icon" src={ReportsIcon} style={{height: 25}} title="Reports" />}  
                />
                
            </BottomNavigation>
            
        </Box>
    )  
}
  
export default TopNavigationComponent