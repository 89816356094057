import { Box, Grid, IconButton } from "@mui/material";
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Document, Page, pdfjs } from "react-pdf";
import PDFBoxComponents from "../components/pdf-editor/pdf-box.component";
import PDFElementsComponents from "../components/pdf-editor/pdf-elements.component";
 pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

 

function PDFTemplateScreen () {

    return(
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto"}}>
                <Box sx={{height: "100vh", overflowY: "auto"}}>
                    {/* <Box sx={{width:'100%', border: '1px solid rgb(0 0 0 / 6%)'}}>
                        <IconButton onClick={()=> window.location.href='/files'}><CloseIcon sx={{color:'#0178f2'}}/></IconButton>
                    </Box> */}
                    <Grid container>
                        <Grid item xs={9} sm={9} sx={{margin:'auto', textAlign:'center'}}>
                            <PDFBoxComponents/>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <PDFElementsComponents/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}
export default PDFTemplateScreen