import { Box, Grid, MenuItem, Select, Typography, IconButton } from "@mui/material";
import ReportService from "../services/api/reports";
import { useState } from "react";
import { useEffect } from "react";
import ReportComponent from "../components/reports/report-component";
import ReactSelect from "react-select";
import useReports from "../hooks/useReport";
import DocumentWorkflowService from "../services/api/documents";
import ReportGuide from "../components/walkthrough/report-guide";
import HelpIcon from '@mui/icons-material/Help';
import { log } from "../console-config";

function ReportScreen () {

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Note: getMonth() returns 0-11, not 1-12

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const currentMonthName = monthNames[currentMonth - 1];

    const [year, setYear] = useState(currentYear);
    const [month, setMonth] = useState(currentMonthName); // Pad the month with leading zero if needed.
    const [monthValue, setMonthValue] = useState(currentMonthName); // Keep the raw month value without padding.

    const {loading, getReport, pagination, reportList, } = useReports();
    const [isWalkthrough, setIsWalkthrough] = useState(null)
    const [showWalkthrough, setShowWalkthrough] = useState(false);

    var module_id = "RPT"

    const handleWalkthroughButtonClick = () => {
        setShowWalkthrough(true);
    };

    const reopenJoyride = () => {
        setShowWalkthrough(true);
    };

    const retrieveWalkthrough = () =>{
        DocumentWorkflowService.getWalkthrough(module_id).then((res)=>{
            log('is user walkthrough', res)
            setIsWalkthrough(res.data.data.is_walkthrough)
        })
    }

    useEffect(()=>{
        retrieveWalkthrough(module_id)
    },[module_id])

    const mappedData = reportList.map(item => {
        return {
            status: item[0],
            month: item[1],
            count: item[2],
            year: year
          };
    });

    const mappedDataX = reportList.map(item => {
        return {
            status: item[0],
            month: monthValue,
            count: item[2],
            year: year
          };
    });

    
    const [activeState, setActiveState] = useState(mappedData);
    const [page, setPage] = useState(1);

    var limit = 10;

    log('get active state', activeState)

    function getMonthNames() {
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
    
        return monthNames.map((monthName, index) => {
            const monthValue = (index + 1).toString().padStart(2, '0');
            return {
                name: monthName,
                value: monthValue,
            };
        });
    }

    function generateArrayOfYears() {
        var current_year = new Date().getFullYear();
        var years = [];

        for (var i = 2022; i <= current_year; i++) {
            let year = { value: i };
            years.push(year);
        }

        return years;
    }

    var years = generateArrayOfYears();

    years.sort(function (a, b) {
        return b.value - a.value;
    });

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const handleMonthChange = (event) => {
        const selectedMonth = parseInt(event.target.value); // Convert the selected value to an integer
        const paddedMonth = (selectedMonth + 1).toString().padStart(2, '0'); // Adding 1 to get the correct month
        setMonth(event.target.value);
        setMonthValue(event.target.value);
    };

    useEffect(() => {
        if(reportList){
            setActiveState(mappedData); // Set initial state to reportList
        }
    }, [reportList]);

    useEffect(()=>{
        if (monthValue !== undefined && year !== undefined){
            getReport(year, monthValue, page, limit, setActiveState)
        }
    },[year, monthValue])

    return(
        <Box sx={{width:'98%', margin:'auto', pt: 1 }}>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={6} sx={{mb: 2}}>
                    <Typography variant='h6' sx={{fontWeight: "bold", color: "#21649e"}}>Reports</Typography>
                </Grid>

            <Grid item xs={12} sm={6} sx={{ textAlign: "end", justifyContent: 'flex-end', ml: 'auto', display:'flex', mb: 2  }}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={month}
                    sx={{ width: 150, mr: 2, bgcolor:'white' }}
                    onChange={handleMonthChange}
                    className="FilterMonth"
                    inputProps={{ sx: { mr: "28px", padding: 1, fontSize: 12, textAlign: "center" } }}
                    InputProps={{
                        startAdornment: <Typography>Month:</Typography>,
                    }}
                >
                    {getMonthNames().map((monthInfo, index) => (
                        <MenuItem key={monthInfo.value} value={monthInfo.name}>
                            {monthInfo.name}
                        </MenuItem>
                    ))}
                </Select>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="FilterYear"
                    value={year}
                    sx={{ width: 150, bgcolor:'white' }}
                    onChange={handleYearChange}
                    inputProps={{ sx: { mr: "28px", padding: 1, fontSize: 12, textAlign: "center" } }}
                    InputProps={{
                        startAdornment: <Typography>Year:</Typography>,
                    }}
                >
                    {years?.map((year) => {
                        return (
                            <MenuItem key={year.value} value={year.value}>
                                {year.value}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
            </Grid>
            <div style={{position:'fixed', bottom: "5px", right:"3%", cursor:'pointer'}} >
                {showWalkthrough ? (
                    <IconButton variant="contain" onClick={handleWalkthroughButtonClick}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                ) : (
                    <IconButton variant="contain" onClick={reopenJoyride}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                )}
            </div>
            <ReportComponent mappedDataX={mappedDataX} mappedData={activeState} monthValue={monthValue} year={year} loading={loading}/>

            {(isWalkthrough === false) && (
                <ReportGuide setShowWalkthrough={setShowWalkthrough} />
            )}

            {showWalkthrough && <ReportGuide showWalkthrough={showWalkthrough} setShowWalkthrough={setShowWalkthrough}/>}

        </Box>
    )
}

export default ReportScreen