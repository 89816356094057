import { cache_strategy } from "../../../common/staticVariables";
import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpPost, HttpGet, HttpDelete, HttpPostMultipart, HttpPut, HttpPut1, HttpDownloadFile, HttpPost1, HttpGetWithCache} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.ovsign_account_service.subscriptionKey;
const CACHE_NAME = 'walkthrough_cache';

const getDocumentByID = (id) => {

    let parameters = {
        id: id
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_DOCUMENTS_BY_ID, parameters, subscriptionKey);
    
    return result;
}

const retrieveDocuments = (page, limit) => {

    let query_params = {
        page: page,
        limit: limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.RETRIEVE_DOCUMENTS, query_params, subscriptionKey);

    return result;

}

const createDocumentWorkflow = (formData) => {
    let payload = formData;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.CREATE_DOCUMENT_WORKFLOW, payload, subscriptionKey);

    return result;
}

const deleteDocumentWorkflow = (id) => {
    let parameters = {
        id: id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpDelete(routes.DELETE_DOCUMENT_WORKFLOW, parameters, subscriptionKey);

    return result;
}


const findByOidandStatus = (status, page, limit) => {

    let query_params = {
        status: status,
        page: page,
        limit: limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.FILTER_DOCUMENTS_BY_STATUS, query_params, subscriptionKey);

    return result;

}

const getDocumentProperties = (document_id) => {

    let parameters = {
        document_id: document_id,
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_DOCUMENTS_PROPERTIES, parameters, subscriptionKey);

    return result;

}


/**
 * Create/Update Template Properties
 * @param {Array<Object>} new_properties List of properties to add to the template. For example: {"page_no": 1, "type": "userSignature", "x_coordinate": "645.5", "y_coordinate": "236.5", "width": 192, "height": 96, "value": ""}
 * @param {Integer} document_id
 */ 
const createUpdateTemplateProperties = (new_properties, deleted_property_ids, document_id) => {
    var payload = {
        new_properties: new_properties,
        deleted_property_ids,
        document_id: document_id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPut(routes.CREATE_UPDATE_TEMPLATE_PROPERTIES, payload, subscriptionKey);
    return result;
}
const saveDocAsDraft = (id, newProperties, deleted_property_ids) => {
    let payload = {
        document_id: id,
        new_properties: newProperties,
        deleted_property_ids
    }

    let subscriptionKey = SUBSCRIPTION_KEY;


    let result = HttpPost(routes.SAVE_DOC_AS_DRAFT, payload, subscriptionKey);

    return result;
}


const countAllRecords = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.COUNT_ALL_RECORDS, query_params, subscriptionKey);

    return result;


}

const getSignatories = (document_id) => {

    let parameters = {
        document_id,
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.SIGNATORY_LIST, parameters, subscriptionKey);

    return result;

}

const addSignatory = (document_id, element_id, email_address) => {
    var payload = {
        data: {
            document_id,
            element_id,
            email_address
        }
    }
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.ADD_SIGNATORY, payload, subscriptionKey);
    return result;
}

const updateSignatory = (id, email_address) => {
    let parameters = {
        id,
        email_address
    }

    let result = HttpPut1(routes.UPDATE_SIGNATORY, parameters);
    return result;
}

const deleteSignatory = (document_id, element_id) => {
    let parameters = {
        document_id,
        element_id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpDelete(routes.DELETE_SIGNATORY, parameters, subscriptionKey);

    return result;
}

const updateSignatorySequence = (document_id, signatories) => {
    var payload = {
        data: {
            document_id,
            signatories,
        }
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPut(routes.UPDATE_SIGNATORY_SEQUENCE, payload, subscriptionKey);
    return result;
}

const saveDocumentWorkflow = (document_id, elements = [], filename, signatory = [], cc = [], total_page, is_certificate, is_auto_remind) => {
    var payload = {
        data: {
            document_id,
            elements,
            filename,
            signatory,
            cc,
            total_page,
            is_certificate,
            is_auto_remind
        }
    }
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.SAVE_DOCUMENT_WORKFLOW, payload, subscriptionKey);
    return result;
}

const saveAsDraftDocumentWorkflow = (document_id, elements = [], filename, signatory = [], cc = [], total_page) => {
    var payload = {
        data: {
            document_id,
            elements,
            filename,
            signatory,
            cc,
            total_page,
        }
    }
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.SAVE_AS_DRAFT_DOCUMENT_WORKFLOW, payload, subscriptionKey);
    return result;
}

const downloadFile = (id) => {
    let parameter = {
        id 
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpDownloadFile(routes.DOWNLOAD_FILE, parameter, subscriptionKey);
    return result;

}

const resendEmail = (document_id) => {
    var payload = {
        document_id,
    }
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.RESEND_FILE, payload, subscriptionKey);
    return result;
}

const resendFile = (document_id) => {
    var payload = {
        document_id,
    }
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.RESEND_FILE, payload, subscriptionKey);
    return result;
}

const generateVerifiableQRCode = (document_id) => {
    var payload = {
        document_id,
    }
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.GENERATE_VERIFIABLE_QRCODE, payload, subscriptionKey);
    return result;
}

const retryEmail = (document_id) => {
    var payload = {
        document_id,
    }
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.RETRY_EMAIL, payload, subscriptionKey);
    return result;
}

const retryError = (document_id) => {
    var payload = {
        document_id,
    }
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.RETRY_ERROR, payload, subscriptionKey);
    return result;
}

const userLogs = (page) => {
    let query_params = {
        page: page
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.USER_LOGS, query_params, subscriptionKey);
    
    return result;
}

const documentHistory = (document_id) => {
    let query_params = {
        document_id: document_id
    };
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_HISTORY, query_params, subscriptionKey);
    return result;
}

const retrieveAnalytics = (year) => {

    let parameters = {
        year: year,
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.RETRIEVE_ANALYTICS, parameters, subscriptionKey);

    return result;

}


const getWalkthrough = (module_id, isRemoveCache) => {
    let query_params = {
        module_id: module_id
    };
    let subscriptionKey = SUBSCRIPTION_KEY;

    let opts = {
        isRemoveCache
    };
    // let result = HttpGet(routes.GET_WALKTHROUGH, query_params, subscriptionKey);
    let result = HttpGetWithCache(routes.GET_WALKTHROUGH, query_params, subscriptionKey, null, cache_strategy.CacheFirst, CACHE_NAME, opts);
    
    return result;
}

const walkthroughUpdate = (module_id, is_walkthrough) => {
    let query_params = {
        module_id: module_id,
        is_walkthrough: is_walkthrough
    };
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPut1(routes.WALKTHROUGH_UPDATE, query_params, subscriptionKey);
    return result;
}

const signedLogs = (email, page, limit) => {

    let query_params = {
        email: email,
        page: page,
        limit: limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.SIGNED_LOGS, query_params, subscriptionKey);

    return result;

}

    const activityLogsList = (page, limit) => {

        let query_params = {
            page: page,
            limit: limit
        }

        let subscriptionKey = SUBSCRIPTION_KEY;

        let result = HttpGet(routes.ACTIVITY_LOGS_LIST, query_params, subscriptionKey);

        return result;

    }

    const activityListFilter = (activity, module, actions, month, year, page, limit) => {

        let query_params = {
            activity: activity,
            module: module,
            actions: actions,
            month: month,
            year: year,
            page: page,
            limit: limit
        }

        let subscriptionKey = SUBSCRIPTION_KEY;

        let result = HttpGet(routes.ACTIVITY_LOGS_LIST, query_params, subscriptionKey);

        return result;

    }

    const searchDocument = (status, title, month, year, page, limit) => {

        let query_params = {
            status: status,
            title: title,
            month: month,
            year: year,
            page, page,
            limit: limit
        };
    
        let subscriptionKey = SUBSCRIPTION_KEY;
    
        let result = HttpGet(routes.SEARCH_DOCUMENT, query_params, subscriptionKey);
    
        return result;
    }

    const updateEmail = (document_id, email, updated_email) => {
        var payload = {
            document_id: document_id,
            email: email,
            updated_email: updated_email
        }
    
        let subscriptionKey = SUBSCRIPTION_KEY;
    
        let result = HttpPut(routes.UPDATE_EMAIL, payload, subscriptionKey);
        return result;
    }

    const cancelDocument = (document_id, cancel_reason) => {
        let query_params = {
            document_id: document_id,
            cancel_reason: cancel_reason
        }

        let subscriptionKey = SUBSCRIPTION_KEY;
    
        let result = HttpPost1(routes.CANCEL_DOCUMENT, query_params, subscriptionKey);
        return result;
    }

    const sendInquiry = (inquiry) => {
        var payload = {
            inquiry: inquiry,
        }

        let subscriptionKey = SUBSCRIPTION_KEY;
    
        let result = HttpPost(routes.SEND_INQUIRY, payload, subscriptionKey);
        return result;
    }

    const updateAutoReminder = (document_id, is_auto_reminder) => {
        let parameters = {
            document_id: document_id,
            is_auto_reminder: is_auto_reminder
        }

        let subscriptionKey = SUBSCRIPTION_KEY;
    
        let result = HttpPut1(routes.UPDATE_AUTO_REMINDER, parameters, subscriptionKey);
        return result;
    }

    const countAnalytics = (year) => {

        let query_params = {
            year: year
        };
    
        let subscriptionKey = SUBSCRIPTION_KEY;
    
        let result = HttpGet(routes.COUNT_ANALYTICS, query_params, subscriptionKey);
    
        return result;
    
    
    }

    const getDeniedReason = (document_id) => {

        let parameters = {
            document_id: document_id,
        }
    
        let subscriptionKey = SUBSCRIPTION_KEY;
    
        let result = HttpGet(routes.GET_DENIED_REASON, parameters, subscriptionKey);
    
        return result;
    
    }

   

const DocumentWorkflowService = {
    getDocumentByID: getDocumentByID,
    retrieveDocuments: retrieveDocuments,
    findByOidandStatus : findByOidandStatus,
    createDocumentWorkflow: createDocumentWorkflow,
    deleteDocumentWorkflow: deleteDocumentWorkflow,
    getDocumentProperties: getDocumentProperties,
    createUpdateTemplateProperties: createUpdateTemplateProperties,
    saveDocAsDraft: saveDocAsDraft,
    countAllRecords: countAllRecords,
    getSignatories,
    addSignatory,
    updateSignatory,
    deleteSignatory,
    updateSignatorySequence,
    saveDocumentWorkflow,
    saveAsDraftDocumentWorkflow,
    downloadFile,
    resendEmail,
    generateVerifiableQRCode,
    retryEmail,
    retryError,
    userLogs: userLogs,
    retrieveAnalytics: retrieveAnalytics,
    getWalkthrough: getWalkthrough,
    walkthroughUpdate: walkthroughUpdate,
    signedLogs: signedLogs,
    activityLogsList : activityLogsList,
    activityListFilter: activityListFilter,
    searchDocument: searchDocument,
    updateEmail: updateEmail,
    cancelDocument: cancelDocument,
    sendInquiry: sendInquiry,
    updateAutoReminder: updateAutoReminder,
    countAnalytics: countAnalytics,
    resendFile,
    getDeniedReason,
    documentHistory
    
}

export default DocumentWorkflowService
