import { Dialog, DialogContent, DialogTitle, Typography, Button, Divider, DialogActions } from "@mui/material";


export default function DeclineReasonDialog({ openInfoDialog, setOpenInfoDialog, decline_reason }) {

    const handleClose = () => {
        setOpenInfoDialog(false)
    }


    return (
        <Dialog open={openInfoDialog} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2 } }}>
            <DialogTitle style={{ fontWeight: "bold", fontSize: 20 }}>Reason for Denial</DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: 6, textAlign: 'center' }}>
                <Typography sx={{ fontSize: 16 }}>{decline_reason}</Typography>
            </DialogContent>
            <DialogActions sx={{ padding: 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button variant="outlined" sx={{ width: 120, borderRadius: 10 }}
                    onClick={handleClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}