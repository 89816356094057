import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Grid} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import {useSnackbar} from "notistack";
import useSignature from "../../../hooks/useSignature";
import {ThreeDots} from "react-loader-spinner";
import {convertFileSizeToBytes, formatBytes} from "../../../utils/fileSizeToBytesUtils";
import { convertUrlToFile } from "../../../ovdocs/utils/convertUrlToFileUtil";
import { log } from "../../../console-config";
import AccountService from "../../../services/api/accounts";

const CreateSignatureByUploadComponent = ({props}) => {

    const {handleCloseSignatureDialog, breakpoints, isPublic, email_address} = props;
    const [modifiedImage, setModifiedImage] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const {uploadSignature, uploadSignaturePublic, isUploadingSignature} = useSignature();
    const imageRef = useRef(null);
    const [signatureFile, setSignatureFile] = useState(null);
    const [signaturePreview, setSignaturePreview] = useState(null);

    let accessToken = (new URLSearchParams(window.location.search)).get("token");

    log("props 33", props);

    const handleChange = (file) => {
        var fileName = file.name;
        var fileExt = fileName.lastIndexOf(".") + 1;
        var mimetype = fileName.substr(fileExt, fileName.length).toLowerCase();
        
        if (mimetype == "jpg" || mimetype == "jpeg" || mimetype == "png" || mimetype == "jfif") {
            
            let sizeFile = file?.size;
            let fileSizes = formatBytes(sizeFile);
            let fileBytes = convertFileSizeToBytes(fileSizes);

            if (fileBytes < 120800 || fileBytes == 120800) {
                setSignatureFile(file);
                setSignaturePreview(URL.createObjectURL(file));

            } else {
                enqueueSnackbar("File size exceeds 100KB, please choose another file", { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            }

        } else {
            enqueueSnackbar("Image file only, please choose an image file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const handleResetFile = () => {
        setSignatureFile(null);
        setSignaturePreview(null);
    }

    const removeWhiteBackground = (imageFile) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onload = (event) => {
            const image = new Image();
            image.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = image.width;
              canvas.height = image.height;
      
              // Draw the image on the canvas
              ctx.drawImage(image, 0, 0);
      
              // Get the image data
              const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
              const data = imageData.data;
      
              // Remove the background (assuming light background and dark signature lines)
              const tolerance = 30; // Adjust this value to set the tolerance for background removal
              for (let i = 0; i < data.length; i += 4) {
                const red = data[i];
                const green = data[i + 1];
                const blue = data[i + 2];
                const alpha = data[i + 3];
            
                // Check if the pixel color is close to red, green, yellow, blue, or white
                if (
                    // Red
                    (red >= 200 && green <= 100 && blue <= 100) ||
                    // Green
                    (red <= 100 && green >= 150 && blue <= 100) || // Adjusted the green range
                    // Blue
                    (red <= 100 && green <= 100 && blue >= 200) ||
                    // Yellow
                    (red >= 200 && green >= 200 && blue <= 100) ||
                    // White
                    (red >= 200 && green >= 200 && blue >= 200)
                ) {
                    data[i + 3] = 0; // Set alpha to 0 (transparent)
                }
            }


      
              // Put the modified image data back to the canvas
              ctx.putImageData(imageData, 0, 0);
      
              // Convert the canvas content back to base64 string
              const modifiedImage = canvas.toDataURL();
      
              resolve(modifiedImage);
            };
      
            image.src = event.target.result;
          };
      
          reader.readAsDataURL(imageFile);
        });
    };

    //   const removeWhiteBackground = (imageFile) => {
    //     return new Promise((resolve, reject) => {
    //       const reader = new FileReader();
      
    //       reader.onload = (event) => {
    //         const image = new Image();
    //         image.onload = () => {
    //           const canvas = document.createElement('canvas');
    //           const ctx = canvas.getContext('2d');
    //           canvas.width = image.width;
    //           canvas.height = image.height;
      
    //           // Draw the image on the canvas
    //           ctx.drawImage(image, 0, 0);
      
    //           // Get the image data
    //           const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    //           const data = imageData.data;
      
    //           // Remove the white background
    //           for (let i = 0; i < data.length; i += 4) {
    //             const red = data[i];
    //             const green = data[i + 1];
    //             const blue = data[i + 2];
    //             const alpha = data[i + 3];
      
    //             if (red >= 240 && green >= 240 && blue >= 240 && alpha > 0) {
    //               data[i + 3] = 0; // Set alpha to 0 (transparent)
    //             }
    //           }
      
    //           // Put the modified image data back to the canvas
    //           ctx.putImageData(imageData, 0, 0);
      
    //           // Convert the canvas content back to base64 string
    //           const modifiedImage = canvas.toDataURL();
      
    //           resolve(modifiedImage);
    //         };
      
    //         image.src = event.target.result;
    //       };
      
    //       reader.readAsDataURL(imageFile);
    //     });
    //   };

     // KB Convertion
    const bytesToKilobytes = (bytes) => {
    const kilobytes = bytes / 1024;
    return kilobytes;
    };
    
    const sizeInBytes = signatureFile?.size;
    const sizeInKilobytes = bytesToKilobytes(sizeInBytes);

    const handleUploadSignature = async (e) => {

        removeWhiteBackground(signatureFile)
        .then((modifiedImage) => {
            // Use the modified image as needed (e.g., display or upload)
            log(modifiedImage);
            setModifiedImage(modifiedImage); // Store the modified image in state
            convertUrlToFile(modifiedImage, "edited_signature_file.png", "image/png").then(function (editedSignatureFile) {

                log('get converted signature file', editedSignatureFile)
                    let formData = new FormData();

                    if(sizeInKilobytes < 100){
                        if (isPublic) {
                            formData.append("email_address", email_address);
                            formData.append("signature", editedSignatureFile);
                            uploadSignaturePublic(formData, accessToken, handleCloseSignatureDialog)
                        } else {
                            formData.append("signature_url", editedSignatureFile);
                            uploadSignature(formData, handleCloseSignatureDialog);
                        }
                    }else{
                        log('File size is invalid, must below or equal to 100KB')
                    }
            });

        })
        .catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        const getSignatureUpdate = async () => {
          try {
            let isRemoveCache = true;
            
            await AccountService.retrieveSignature(isRemoveCache)
          
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        if (isUploadingSignature) getSignatureUpdate()
    }, [isUploadingSignature])

    log('get modifiedImage hereeeeeeeeeeee', modifiedImage)
    log('get original signature file',sizeInKilobytes)

    return (
        <Box sx={{padding: (signaturePreview && (breakpoints == 'xs' || breakpoints == 'sm')) ? "0px 0px 16px 0px" 
            : (signaturePreview && (breakpoints == 'md' || breakpoints == 'lg' ||  breakpoints == 'xl')) ? "0px 40px 16px 40px" 
            : (!signaturePreview && (breakpoints == 'xs' || breakpoints == 'sm')) ? "23px 0px 16px 0px" : "23px 40px 16px 40px", 
            mt: (signaturePreview) ? "-13px" : {}}}
        >
            {signaturePreview &&
                <Grid sx={{textAlign: "end"}}>
                    <Button onClick={(e) => handleResetFile(e)} sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14}}>
                        Clear
                    </Button>
                </Grid>
            }

            <Grid item xs={12}>
                {(signatureFile) ? 
                    <div 
                        style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            minWidth: "100% !important",
                            maxWidth: "100% !important",
                            border: "dashed 2px #0178f2",
                            borderRadius: "8px",
                            cursor: "pointer",
                            flexGrow: 0,
                            height: "210px",
                            justifyContent: "center",
                            margin: "auto",
                            transition: "border-color .15s linear",
                            objectFit: "contain"
                        }}
                    >
                        <img alt="signature-preview" src={signaturePreview} style={{height: (breakpoints == 'xs' || breakpoints == 'sm') ? 55 : 150, objectFit:'contain', width:'100%'}} />
                    </div>

                :
                    <FileUploader
                        hoverTitle=""
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        label="Drag and Drop file here or click here to upload"
                        children={
                            <div
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                    minWidth: "100% !important",
                                    maxWidth: "100% !important",
                                    border: "dashed 2px #0178f2",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    flexGrow: 0,
                                    height: "210px",
                                    justifyContent: "center",
                                    margin: "auto",
                                    transition: "border-color .15s linear",
                                    
                                }}
                            >
                                <p style={{color: "#666", fontWeight: 600, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : {}}}>
                                    Drag and Drop file here or click here to upload
                                </p>
                            </div>
                        }
                        
                    
                    />
                }
                    <div>
                        <p style={{color: "#db2828", fontWeight: 20, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : {}}}>
                            Maximum of 100KB file size
                        </p>
                    </div>
            </Grid>

            <Box sx={{paddingTop: 6, textAlign: "end"}}>
                <Button onClick={handleCloseSignatureDialog} variant="outlined" sx={{width: 120, borderRadius: 10, color: '#3333cf', borderColor: "#3333cf4f !16840100" }}>
                    Close
                </Button>
                &nbsp;
                <Button variant="contained" sx={{width: isUploadingSignature ? 180 : 120, backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                    onClick={(e) => (isUploadingSignature) ? null : handleUploadSignature(e)}
                    startIcon={isUploadingSignature && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />} 
                >
                    {isUploadingSignature ? "Saving" : "Save"} 
                </Button>
            </Box>
        </Box>
    )
}

export default CreateSignatureByUploadComponent