import React, {useState, useEffect, useRef} from 'react';
import { Box, IconButton, Stack, Tooltip, Fade } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { log } from '../../../console-config';

const ElementSigneeToolbarComponent = ({element, isElementClicked, onEditElement, setIsEditing, onChangeFontSize}) => {
  
      const actions = [
          {title: "Edit", icon_component: <EditIcon sx={{color: '#e5e5e5'}}/>, onClick: onEditElement},
      ]
  
      function handleOnChangeFontSize(font_size){
        // todo
        log("TODO: handleOnChangeFontSize - pass size to parent ", font_size)
        // onChangeFontSize(font_size);
      }
  
      return(
          <Fade direction="up" in={isElementClicked} mountOnEnter unmountOnExit>
              <Box sx={{bgcolor: "#474747",  position: 'absolute', mt: -6, width: 'auto', borderRadius: '4px', height: '40px'}}>
                  <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={0.5}
                  >
                    {actions.map((action)=>{
                        return(
                            <Tooltip key={action.title} title={action.title} arrow>
                                <IconButton sx={{ "&:hover": { backgroundColor: "gray" } }} onClick={action.onClick}>
                                    {action.icon_component}
                                </IconButton>
                            </Tooltip>
                        )
                    })}
                  </Stack>
              </Box>
          </Fade>
      )
  }
  
  export default ElementSigneeToolbarComponent