
const deviceInfoUtil = () => {

    const getDeviceInfo = () => {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;
        const appName = window.navigator.appName;
    
    
    
        return {
        userAgent,
        platform,
        appName,
        };
    };

    const deviceInfo = getDeviceInfo();

    return{deviceInfo}

}

export default deviceInfoUtil;

