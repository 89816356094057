import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PublicClientApplication} from "@azure/msal-browser";
import {msalconfig} from './config';
import {SnackbarProvider} from "notistack";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { OVCPDFEditorProvider } from "./ovdocs";
// import { OVCPDFEditorProvider } from "./ovdocs1";

const msalInstance = new PublicClientApplication(msalconfig);
const root = ReactDOM.createRoot(document.getElementById('root'));

if(window.location.hash !== ""){
  console.log('hash found' + window.location.hash);
}else{
  root.render(
    <React.StrictMode>
        <SnackbarProvider>
          <OVCPDFEditorProvider>
              <App publicClientApp={msalInstance}/>
          </OVCPDFEditorProvider>
        </SnackbarProvider>
    </React.StrictMode>
  );
}

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
