import {Divider, Button, Box, Grid, IconButton, Typography, List, ListItem, ListItemText} from '@mui/material';
import Drawer from '../styled-components/Drawer';
import { drawerWidth } from '../common/staticVariables';
import PDFSignatoryComponent from '../../../components/pdf-editor/pdf-signatory.component';
import {ThreeDots} from "react-loader-spinner";
import useWidth from "../../../hooks/useWidth";
import DrawerHeader from '../../pdf-editor/styled-components/DrawerHeader';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { log } from '../../../console-config';

export default function ToolsPanelDrawerComponent(props){

    const {signatory, currentUserSignature, gotoSigningPages, signatoriesCounter, isLoading, elements_to_plot,documentProp, handleClickOpen,
        handleOnClickFinish, handleDrawerToggleClose, handleDrawerToggle, openDrawer, isViewing, getDocumentById, sender, signeeCCEmail} = props;

    const breakpoints = useWidth;
    const width = useWidth();

    log('get elementsssssssssss', getDocumentById)

    return(
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    bgcolor: 'tools_panel.background.default',
                    color: 'tools_panel.text.primary',
                    boxSizing: 'border-box',
                    whiteSpace: 'nowrap',
                    boxSizing: 'border-box',
                    padding: "0px 15px 15px 15px",
                    height:'100vh'
                },
            }}
            variant="permanent"
            anchor="right"
            open={openDrawer}
        >
        <DrawerHeader/>
            {isViewing &&
                <>
                {/* <DrawerHeader/> */}
                <Box sx={{textAlign:'end'}}>
                    {openDrawer ? 
                        <IconButton onClick={handleDrawerToggleClose}>
                            <CloseIcon sx={{color:'#0178f2'}}/>
                        </IconButton>
                        : 
                        <IconButton color="inherit" aria-label="open drawer"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon sx={{color: "black"}} />
                        </IconButton> 
                    }
                </Box>
                </>
            }
            <br/>
            {/* {openDrawer && 
                <Box sx={{border: '1px solid #0178f2', borderRadius: 1, p: 2}}>
                    <Typography sx={{textAlign: 'center'}}>
                     <strong>Sent by:</strong>   {senderEmail}
                    </Typography>
                </Box>
            }
            
            <br /> */}
            {openDrawer &&
                <Box>

                <Typography sx={{pl: 1, fontWeight: "bold", mb: 1, fontSize: 18, color: '#0178f2'}}>{getDocumentById?.title ? getDocumentById?.title : null}</Typography>
            
                <PDFSignatoryComponent breakpoints={breakpoints} signatory={signatory} currentUserSignature={currentUserSignature} sender={sender} isViewing={isViewing}/>
           

                {isViewing && 
                    <Box sx={{mr: 3, ml: 3, display: "flex", flexDirection: "column", minHeight:'50vh'}}>
                        <Typography sx={{fontWeight: "bold"}}>
                            CC Emails
                        </Typography>
                        {getDocumentById?.cc_emails.length > 0 ?
                            <>
                                {getDocumentById?.cc_emails?.map((ccEmail) => {
                                    return (
                                        <Grid container direction="column" sx={{padding: "20px", mt: "20px", backgroundColor: "#16840138", borderRadius: "5px"}}>
                                            <List sx={{backgroundColor: "#fff", borderRadius: 2, mb: 1}}>
                                                <ListItem >
                    
                                                    <ListItemText  primary={
                                                    <Typography sx={{fontSize: 14, width: 150,textAlign: 'center',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis'}}>{ccEmail}</Typography>
                                                    }/>
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    )
                            })}
                            </>
                            :
                            <Grid container direction="column" sx={{padding: "20px", mt: "20px", backgroundColor: "#16840138", borderRadius: "5px"}}>
                            <Typography sx={{textAlign:'center'}}>No CC added.</Typography>
                            </Grid>
                        }

                    </Box>
                }

                {!isViewing && 
                <Box sx={{mr: 3, ml: 3, display: "flex", flexDirection: "column", minHeight:'50vh'}}>
                     <Typography sx={{fontWeight: "bold"}}>
                        CC Emails
                    </Typography>
                    {signeeCCEmail?.cc_emails.length > 0 ?
                        <>
                            {signeeCCEmail?.cc_emails?.map((ccEmail) => {
                                return (
                                    <Grid container direction="column" sx={{padding: "20px", mt: "20px", backgroundColor: "#16840138", borderRadius: "5px"}}>
                                        {log('sssssssssssssssssssssssssvvvvvvvvvvvv', ccEmail)}
                                        <List sx={{backgroundColor: "#fff", borderRadius: 2, mb: 1}}>
                                            <ListItem >
                
                                                <ListItemText  primary={
                                                <Typography sx={{fontSize: 14, width: 150,textAlign: 'center',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis'}}>{ccEmail?.emails}</Typography>
                                                }/>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                )
                        })}
                        </>
                        :
                        <Grid container direction="column" sx={{padding: "20px", mt: "20px", backgroundColor: "#16840138", borderRadius: "5px"}}>
                            <Typography sx={{textAlign:'center'}}>No CC added.</Typography>
                        </Grid>
                    }
                </Box>
                }
            </Box>
            }
            { width == 'xs' && 
                <>
                    <Box sx={{flexGrow: 1}}/>
                    
                    {!isViewing && openDrawer &&
                        <Grid container>

                            <Button
                                fullWidth
                                onClick={handleClickOpen}
                                variant="outlined"  sx={{ borderRadius: 10, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', color: "red", border: "1px solid red", "&:hover": { border: "1px solid red" } }} >
                                Decline
                            </Button>
                            
                            <Grid xs={12}>
                                <Button fullWidth onClick={gotoSigningPages} variant="outlined" sx={{borderRadius: 10, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', color: "#000", border: "1px solid rgb(0 0 0 / 33%)", "&:hover": {border: "1px solid rgb(0 0 0 / 33%)"}}}>
                                    {signatoriesCounter?.remainingSig == signatoriesCounter?.totalSig ? "Go to page signed" : "Go to page signing"}
                                </Button>
                            </Grid>
                            {/* &nbsp; &nbsp; */}
                            
                            <Grid xs={12} sx={{marginTop: 2}}>
                                <Button fullWidth
                                    startIcon={isLoading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                                    variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}} onClick={handleOnClickFinish}>
                                    Finish & Submit
                                </Button>
                            </Grid>
                            <Grid xs={12} sx={{marginTop: 2}}>
                            {/* <Button fullWidth
                                variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}} onClick={()=> window.location.href="/files"}>
                                Back
                            </Button> */}
                            </Grid>
                        </Grid>
                    }
                </>
            } 
            {openDrawer && isViewing &&
                <Button fullWidth
                variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}} onClick={()=> window.location.href="/files"}>
                Back
            </Button>
            }
        </Drawer>
    )
}