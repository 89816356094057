import { IconButton, InputAdornment, Select, Stack, TextField, MenuItem, Typography, FormControl, InputLabel, Box, Grid, } from "@mui/material";
import { useState } from "react";
import ReactSelect from "react-select";
import { log } from "../../../console-config";
import SearchIcon from '@mui/icons-material/Search';
import useWidth from "../../../hooks/useWidth";


export default function FilterByAllComponent (props)  {

    const {filter, filterStyles, filterLabel, setSelected, selectedFilter, years, handleYearChange, chosenTab,
        handleMonthChange, getMonthNames, month, year, monthName, searchDocuments, page, retrieveDocuments, title, searchTitle, keyPress, handleSearchTitle, handleSearchButton} = props;

    const breakpoints = useWidth();
    const handleSelectFilter = (selectedOption) => {
        setSelected(selectedOption.value);
        log('get selected filter', selectedOption);

        const status = selectedOption.value;
        searchDocuments(status, title, monthName, year, page)
        // if(filterLabel === null){
        //     // searchDocuments(status, title, monthName, year, page)
        // } else if(filterLabel == "Status"){
        //     searchDocuments(status, title, monthName, year, page)
        // }
        //     else if(filterLabel == "Title") {
            
        // }
        //     else if(filterLabel == "Date") {
            
        // } else {
        //     searchDocuments(status, title, monthName, year, page)
        // }
    }

    

    return(
        <>
            
                {selectedFilter === false ? (
                    <> 
                        {chosenTab === "DRAFT" || chosenTab === "PENDING" || chosenTab === "COMPLETED" || chosenTab === "CANCELLED" ?
                            null 
                        : 
                            <Grid item xs={12} sm={3}></Grid>
                        }
                    </>
                ):(
                    <Grid item xs={12} sm={3} sx={{textAlign: breakpoints == 'xs' || breakpoints == 's' ? '-webkit-center' : "start"}}>
                        {filterLabel === "Status" ?
                            <ReactSelect
                                className="basic-single"
                                placeholder={"Select"}
                                isSearchable={true}
                                isRtl={false}
                                options={filter}
                                styles={filterStyles}
                                onChange={selectedOption => handleSelectFilter(selectedOption)}
                            />
                        :
                            <>
                                {filterLabel === "Title" && (
                                    <TextField 
                                        variant="outlined" 
                                        autoComplete="off" 
                                        placeholder='Search Title'
                                        defaultValue={title}
                                        onChange={handleSearchTitle}
                                        onKeyDown={keyPress}
                                        sx={{"& .MuiInputBase-root": {height: 38, fontSize: 14}, textAlign:'end', backgroundColor: 'white'}}
                                        inputProps={{style: {padding: "10px"}}}
                                        InputProps={{ endAdornment: <InputAdornment position="end"> <IconButton aria-label="toggle password visibility" edge="end" onClick={handleSearchButton}><SearchIcon /></IconButton></InputAdornment>, }}
                                    />)
                                }
                            </>
                        }
                      </Grid>
                    
                )}
            
            {(chosenTab === "DRAFT" || chosenTab === "PENDING" || chosenTab === "COMPLETED" || chosenTab === "CANCELLED") && (
                <>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        variant="outlined" 
                        autoComplete="off" 
                        placeholder='Search Title'
                        defaultValue={title}
                        onChange={handleSearchTitle}
                        onKeyDown={keyPress}
                        sx={{"& .MuiInputBase-root": {height: 38, fontSize: 14}, textAlign:'end', backgroundColor: 'white'}}
                        inputProps={{style: {padding: "10px"}}}
                        InputProps={{ endAdornment: <InputAdornment position="end"> <IconButton aria-label="toggle password visibility" edge="end" onClick={handleSearchButton}><SearchIcon /></IconButton></InputAdornment>, }}
                    />
                </Grid>
                </>
            )}
        {/* <Box sx={{flexGrow: breakpoints == 'xs' || breakpoints == 's' ? 'none' : "1"}}/> */}
            <Grid item xs={12} sm={6} sx={{textAlign: breakpoints == 'xs' || breakpoints == 's' ? '-webkit-center' : "end"}}>
                <FormControl sx={{mr: 2}}>
                    <InputLabel id="demo-simple-select-label" sx={{mt: -.8, fontSize: 12, fontWeight:'bold'}}>Month</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Month"
                        value={monthName}
                        sx={{ width: 150, height: 38, borderRadius: 2, backgroundColor: 'white' }}
                        onChange={handleMonthChange}
                        inputProps={{ sx: { fontSize: 12, textAlign: "center" } }}
                        
                    >
                        {getMonthNames().map((monthInfo, index) => (
                            <MenuItem key={monthInfo.value} value={monthInfo.value}>
                                {monthInfo.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                    
                <FormControl>
                    <InputLabel id="demo-simple-select-label" sx={{mt: -.8, fontSize: 12, fontWeight:'bold'}}>Year</InputLabel>
                    <Select
                        // className="card-year"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Year"
                        value={year}
                        sx={{ width: 150, height: 38, borderRadius: 2, backgroundColor: 'white'}}
                        onChange={handleYearChange}
                        inputProps={{ sx: { fontSize: 12, textAlign: "center" } }}
                    >
                        {years?.map((year) => {
                            return (
                                <MenuItem key={year.value} value={year.value}>
                                    {year.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </>
    )
}