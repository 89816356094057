import { Box, Card, CardActionArea, Grid, Typography, Button, CardContent, IconButton } from "@mui/material";

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { Link, useNavigate } from "react-router-dom";
import useDocuments from "../hooks/useDocuments";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';

import RecentFilesComponent from "../components/dashboard/recent-files.component";
import AnalyticsComponent from "../components/dashboard/analytics.component";
import DashboardGuide from "../components/walkthrough/dashboard-guide";
import StatusCardsComponent from "../components/dashboard/status-cards.component";
import AccountService from "../services/api/accounts";
import HelpIcon from '@mui/icons-material/Help';
import DocumentWorkflowService from "../services/api/documents";
import { log } from "../console-config";
import deviceInfoUtil from "../utils/deviceDetailsUtils";
import useWidth from "../hooks/useWidth";
 
function DashboardHeader(){

    const navigate = useNavigate()

    return(
        <Box sx={{padding: 1, width:'100%', borderRadius: 0}}>
            <Grid container  sx={{width:'98%', margin:'auto'}}>
                <Grid item xs={6} >
                    <Typography variant='h6' sx={{fontWeight: "bold", color: "#21649e"}}>Dashboard</Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'end', alignSelf:'center'}} >
                    <Button className="Create" onClick={()=> navigate("/documents")} variant="contained" sx={{backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', textTransform:'none', pt: 0.5, pb: 0.5}}>
                       <AddIcon sx={{marginRight: 1}}/> Create
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}


function DashboardScreen () {

    const { loading, countAllRecords, all, drafts, pending, completed } = useDocuments();

    const breakpoints = useWidth();

    const [countAll, setCountAll] = useState(0);
    const [countDraft, setCountDraft] = useState(0);
    const [countPending, setCountPending] = useState(0);
    const [countCompleted, setCountCompleted] = useState(0);
    const [isWalkthrough, setIsWalkthrough] = useState(null)
    const [showWalkthrough, setShowWalkthrough] = useState(false);

    var module_id = "DSH"

    const handleWalkthroughButtonClick = () => {
        setShowWalkthrough(true);
    };

    const reopenJoyride = () => {
        setShowWalkthrough(true);
    };

    useEffect(() => {
        const timer = setInterval(() => {
          if (countAll < all) {
            setCountAll((prevCount) => prevCount + 1);
          } 
    
          if (countDraft < drafts) {
            setCountDraft((prevCount) => prevCount + 1);
          } 
    
          if (countPending < pending) {
            setCountPending((prevCount) => prevCount + 1);
          } 
    
          if (countCompleted < completed) {
            setCountCompleted((prevCount) => prevCount + 1);
          } 
        }, 100);
    
        return () => {
          clearInterval(timer);
        };
    }, [countAll, countDraft, countPending, countCompleted, all, drafts, pending, completed]);
    
    useEffect(()=>{
        countAllRecords()
    },[])


    const retrieveWalkthrough = () =>{
        DocumentWorkflowService.getWalkthrough(module_id).then((res)=>{
            log('is user walkthrough', res)
            setIsWalkthrough(res.data.data.is_walkthrough)
        })
    }

    useEffect(()=>{
        retrieveWalkthrough(module_id)
    },[module_id])

    const {deviceInfo} = deviceInfoUtil();

    console.log('get device infor', deviceInfo)

    return (
        <Box sx={{overflow: 'hidden'}}>

            <DashboardHeader />
            
            <Box sx={{padding: 2}}>
                <Masonry columns={{ xs: 1, sm: 4 }} spacing={2} sx={{margin:'auto', width: '100%', padding: 0}}>
                   <StatusCardsComponent all={all} pending={pending} drafts={drafts} completed={completed} />
                </Masonry>

                {/* ANALYTICS */}
                <Grid container spacing={2} sx={{padding: 1}}>
                    <Grid item xs={12} sm={6}>
                        <Card sx={{margin:'auto', textAlign:'center', height: breakpoints === "xs" ? 350 : 500}} className="Analytics">
                            <CardContent sx={{margin:'auto'}}>
                                {/* <Typography gutterBottom variant="h6" component="div" sx={{textAlign:'start', color: "#21649e"}}>
                                    Analytics
                                </Typography> */}
                                <Typography gutterBottom variant="h6" component="div" sx={{textAlign:'start', fontWeight:'bold' , color: "#21649e"}}>
                                   {all} Documents
                                </Typography>
                                <br/>
                                {breakpoints === "xs" && <br/>}
                                <AnalyticsComponent all={all} pending={pending} drafts={drafts} completed={completed}/>
                            </CardContent>
                        </Card>
                    </Grid>

                {/* RECENT */}
                    <Grid item xs={12} sm={6}>
                        <Card sx={{height: 500}} className="Recent">
                            <CardContent sx={{height: '100%'}}>
                                <Typography gutterBottom variant="h6" component="div" sx={{color: "#21649e"}}>
                                    Recent
                                </Typography>
                                <br/>
                                <RecentFilesComponent chosenTab={""} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <div style={{position:'fixed', bottom: "5px", right:"3%", cursor:'pointer'}} >
                <IconButton variant="contain" onClick={showWalkthrough ? handleWalkthroughButtonClick : reopenJoyride }><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>
            </div>

            {(isWalkthrough === false) && (
                <DashboardGuide setShowWalkthrough={setShowWalkthrough} />
            )}

            {showWalkthrough && <DashboardGuide showWalkthrough={showWalkthrough} setShowWalkthrough={setShowWalkthrough}/>}
        </Box>
    )
}

export default DashboardScreen