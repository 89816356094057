import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material"
import DrawerHeader from "../layout/drawer-header"
import BannerContentComponent from "../components/landing-component/banner-content.component";
import nextixLogo from "../icons/nextix-logo.png"
import ovcodeLogox from "../icons/new-ovcode-logo.png"
import ovcodeLogo from "../icons/ovcode-reverse-white.png"
import useWidth from "../hooks/useWidth";


function LandingScreen ({isUserLoggedIn}) {

    const breakpoints = useWidth();

    return(

        <Box style={{height: '100vh', position: 'relative'}} class="content">
            <Box sx={{margin: breakpoints == 'xs' || breakpoints == 'sm' ? '0px' : '0px 50px 0px 60px'}}>
                <BannerContentComponent breakpoints={breakpoints} isUserLoggedIn={isUserLoggedIn} />
            </Box>

            
            <Box sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                padding: '1.125em',
                ml: '30px'
            }}>
                <Button onClick={() => window.open('https://ovcode.com/terms-of-service', "_blank")} sx={{cursor:'pointer', textTransform:'none', fontSize: 10, textDecoration:'underline'}} >Terms of Use</Button>
                <Button onClick={() => window.open('https://ovcode.com/privacy-policy', "_blank")} sx={{cursor:'pointer', textTransform:'none' , fontSize: 10, textDecoration:'underline'}}>Privacy Policy</Button>
                <Button disabled sx={{fontSize: 10, textTransform:'none', color:'gray'}}>©2023 All Rights Reserved</Button>
            </Box> 
            <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          padding: '1.125em',
        }}
      >                <Button sx={{textShadow: "3px 3px 3px rgb(0 0 0 / 43%)", textTransform: 'none', fontSize: 15, fontWeight: 500, color: breakpoints == 'xs' || breakpoints == 'sm' ? 'black' : 'white', fontWeight: 500}} endIcon={ <img src={breakpoints == 'xs' || breakpoints == 'sm' ? ovcodeLogox : ovcodeLogo} style={{height: 28}} />}>
                    Powered by
                </Button>
            </Box> 

        </Box>
    
    )
}

export default LandingScreen