import {useState, useEffect} from 'react';
import {Grid, Typography} from '@mui/material';
import DraggableElementComponent from './DraggableElementComponent';
import {generateQRCodeBase64} from "../../utils/QRCodeUtil";

export default function DraggableElementsPanelContainer({draggable_elements}){

    const [draggableElements, setDraggableElements] = useState([]);
    
    useEffect(() => {
        setDraggableElements(draggable_elements);

        let qrcodeEl = draggable_elements.find((el) => el.type == 'qrcode');

        const generateElementsQRCodeBase64 = async () => {
            let qrcodeBase64 = await generateQRCodeBase64(qrcodeEl.value);

            setDraggableElements((prev) => prev.map(el => el.type == "qrcode" ? ({...qrcodeEl, value: qrcodeBase64}) : el))
        }

        if (qrcodeEl) generateElementsQRCodeBase64();

    }, [JSON.stringify(draggable_elements)])



    return(
        <>
            <Typography sx={{pl: 1, fontWeight: "bold", mb: 1}}>Elements</Typography>
            <Grid sx={{p: 1}} container spacing={1}>
                <br />
                {
                    draggableElements.map((draggable_element, i)=>{
                        return(
                            <Grid key={i} item xs={6}>
                                <DraggableElementComponent draggable_element={draggable_element}/>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}