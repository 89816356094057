import React, { useState } from 'react';
import ReactJoyride from 'react-joyride';
import ovsignLogo from "../../icons/nextixLogo.png"
import AccountService from '../../services/api/accounts';
import DocumentWorkflowService from '../../services/api/documents';
import { log } from '../../console-config';

const ProfileGuide = ({showWalkthrough, setShowWalkthrough}) => {
  const [joyride, setJoyride] = useState({
    run: true,
    steps: [
      {
        content: 
        <div>
            <img src={ovsignLogo} style={{height: 30}}/>
            <p style={{fontSize: 19, color:'#0178f2', fontWeight:'bold'}}>
                Let's get started.
            </p>
        </div>,
        target: "body",
        placement: "center",
        // content: <div><p>You will be presented with an all-encompassing view, showcasing not only the total number of impeccably crafted documents but also offering a comprehensive display of analytics and real-time updates on the most recent transactions.</p></div>,
        disableBeacon: true,
      },
      {
        content: "To easily update your account profile, just click on the update icon.",
        target: ".EditProfile",
      },
      {
        target: ".AvatarLogo",
        content: "Display your uploaded profile photo.",
      },
      {
        target: ".UploadLogo",
        content: "To easily update your profile photo, just click on the upload icon.",
        placement: "top",
      },
      {
        target: ".ProfileSignature",
        content: "Display uploaded electronic signature.",
        placement: "top",
      },
      {
        target: ".UploadSignature",
        content: "Simply click to begin creating or upload your electronic signature",
        placement: "top",
      },
    ]
  });

  const handleStepCallback = (data) => {
    const { status, type, action } = data;
    let module_id = "PRF";
    let is_walkthrough = 1
    if (action === 'close'|| action === 'skip' ) {
      DocumentWorkflowService.walkthroughUpdate(module_id, is_walkthrough).then((res)=>{
        log('update walkthrough res', res)
        DocumentWorkflowService.getWalkthrough(module_id, true).then(()=>{ // update cache
          // window.location.reload();
        })
      })
      setShowWalkthrough(false)
    } else if (status === 'finished' &&  type === 'tour:end'){
      DocumentWorkflowService.walkthroughUpdate(module_id, is_walkthrough).then((res)=>{
        log('update walkthrough res', res)
        DocumentWorkflowService.getWalkthrough(module_id, true).then(()=>{ // update cache
          // window.location.reload();
        })
      })
      setShowWalkthrough(false)
    }
    log('ACTIONSSSSSS', action)
  };


  return (<React.Fragment>
    <ReactJoyride
      steps={joyride.steps}
      run={showWalkthrough ? showWalkthrough : joyride.run}
      continuous
      showProgress
      showSkipButton
      disableScrolling={true}
      styles={{
        options: {
          arrowColor: '#fff',
          primaryColor: '#0178f2',
          textColor: '#000',
          width: 380,
          zIndex: 1250,
        },
        buttonNext: {
          backgroundColor: '#168401',
          borderRadius: 4,
          color: '#fff',
          borderColor: '#168401',
          outline: 'none',
        },
        buttonBack: {
          color: '#0178f2',
          marginLeft: 'auto',
          marginRight: 5,
        },
      }}
      locale={{
        skip: "Skip",
        last:"Done",
        
      }}
      callback={handleStepCallback} // Set the callback function
    />
  </React.Fragment>);
};

export default ProfileGuide


