import { useEffect, useState } from "react";
import { useDrop, useDrag } from 'react-dnd'


/**
 * useDndHook params
 * @param {Ref} ref // Required. Element where to drop drag sources
 * @param {String} accept // Required. A string, a symbol, or an array of either. This drop target will only react to the items produced by the drag sources of the specified type or types.
*/

export const useDropHook = (ref, accept) => {

    const [dropResult, setDropResult] = useState(null)
    const [hasDropped, setHasDropped] = useState(false)

    const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
        accept: accept,
        drop: (item, monitor) => {
            let coords = getOnDropCoords(monitor.getInitialSourceClientOffset(), monitor.getSourceClientOffset())
            setDropResult({...item, ...coords})
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))

    const dndRef = dropRef(ref)

    useEffect(() => {
        setHasDropped(dropResult ? true : false)
    }, [JSON.stringify(dropResult)])

    const getOnDropCoords = (initialPosition, finalPosition) => {
        const dropTargetPosition = dndRef.current.getBoundingClientRect();
    
        const { y: finalY, x: finalX } = finalPosition;
        const { y: initialY, x: initialX } = initialPosition;
        
        const newYposition =
        finalY > initialY
            ? initialY + (finalY - initialY) - dropTargetPosition.top
            : initialY - (initialY - finalY) - dropTargetPosition.top;
    
        const newXposition =
        finalX > initialX
            ? initialX + (finalX - initialX) - dropTargetPosition.left
            : initialX - (initialX - finalX) - dropTargetPosition.left;
    
        return {x: newXposition, y: newYposition};
    };

    return {dndRef, hasDropped, dropResult}
}