import {Tab, Tabs} from '@mui/material';
import {styled} from '@mui/material/styles';

const CustomTabs = styled(Tabs)({
  '& .MuiTab-root': {
    padding: '5px 15px 5px 15px',
    minHeight: 5,
    textTransform: 'none',
    // border: '1px solid #0178f238',
    backgroundColor: '#80808021',

    '&.Mui-selected': {
      color: "#0178f2",
      // borderBottom: '2px solid #0178f2',
      backgroundColor: '#0178f21c'
    },

    ':hover': {
      color: "#9a9a9a",
    },
  },       
});

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({theme}) => ({
  minWidth: "auto", 
  bgolor: "red",
  borderRadius: 2,
  marginRight: 10,
  fontWeight: "bold",
  color: "#000",
}));

export { CustomTabs, CustomTab };