import React, { useState } from 'react';
import ReactJoyride from 'react-joyride';
import ovsignLogo from "../../icons/nextixLogo.png"
import AccountService from '../../services/api/accounts';
import DocumentWorkflowService from '../../services/api/documents';
import { log } from '../../console-config';

const PDFEditorGuide = ({showWalkthrough, setShowWalkthrough}) => {

const [joyride, setJoyride] = useState({  
// run: true,
steps: [
    {
    content: 
    <div>
        <img src={ovsignLogo} style={{height: 30}}/>
        <p style={{fontSize: 19, color:'#0178f2', fontWeight:'bold'}}>
            PDF Editor
        </p>
        <p style={{fontSize: 14}}>Getting Started.</p>
    </div>,
    target: "body",
    placement: "center",
    // content: <div><p>You will be presented with an all-encompassing view, showcasing not only the total number of impeccably crafted documents but also offering a comprehensive display of analytics and real-time updates on the most recent transactions.</p></div>,
    disableBeacon: false,
    },
    // {
    // target: "#button-id",
    // content: "This will toggle your drawer.",
    // placement: "bottom",
    // },
    {
    content: "To add your signature, simply drag and drop the signature element to the desired location on the uploaded document.",
    target: ".Signature",
    },
    {
    content: "To add the text field, simply drag and drop the text field element to the desired location on the uploaded document.",
    target: ".Textfield",
    },
    {
    content: "To add the date, simply drag and drop the date element to the desired location on the uploaded document.",
    target: ".Date",
    },
    {
    content: "To add the QR code, simply drag and drop the QR code element to the desired location on the uploaded document.",
    target: ".QRcode",
    },
    {
    content: "For signatory, simply drag and drop the signatory element to the desired location on the uploaded document.",
    target: ".Signatory",
    },
    {
    content: "Once you have completed all the necessary steps, simply click on the Send button to proceed to the signing process.",
    target: ".Send",
    },
    {
    content: "If you wish to save your changes to draft , simply click on the Cancel button, and the document will be saved as a draft.",
    target: ".Cancel",
    },
]
});


const handleStepCallback = (data) => {
  const { status, type, action } = data;
  let module_id = "PDF";
  let is_walkthrough = 1
  if (action === 'close'|| action === 'skip' ) {
    DocumentWorkflowService.walkthroughUpdate(module_id, is_walkthrough).then((res)=>{
      log('update walkthrough res', res)
      DocumentWorkflowService.getWalkthrough(module_id, true).then(()=>{ // update cache
        // window.location.reload();
      })
    })
    setShowWalkthrough(false)
  } else if (status === 'finished' &&  type === 'tour:end'){
    DocumentWorkflowService.walkthroughUpdate(module_id, is_walkthrough).then((res)=>{
      log('update walkthrough res', res)
      DocumentWorkflowService.getWalkthrough(module_id, true).then(()=>{ // update cache
        // window.location.reload();
      })
    })
    setShowWalkthrough(false)
  }
  log('ACTIONSSSSSS', action)
};




  return (<React.Fragment>
    <ReactJoyride
      steps={joyride.steps}
      run={showWalkthrough}
      continuous
      showProgress
      showSkipButton
      disableScrolling={true}
      styles={{
        options: {
          arrowColor: '#fff',
          primaryColor: '#0178f2',
          textColor: '#000',
          width: 380,
          zIndex: 1250,
        },
        buttonNext: {
          backgroundColor: '#168401',
          borderRadius: 4,
          color: '#fff',
          borderColor: '#168401',
          outline: 'none',
        },
        buttonBack: {
          color: '#0178f2',
          marginLeft: 'auto',
          marginRight: 5,
        },
      }}
      locale={{
        skip: "Skip",
        last:"Finish",
        
      }}
      callback={handleStepCallback} // Set the callback function
    />
  </React.Fragment>);
};

export default PDFEditorGuide


