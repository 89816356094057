import { Box, Typography } from '@mui/material';
import { LineChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart } from 'recharts';

function AnalyticsPendingComponent ({dataForChart}) {

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        
        return (
          <g transform={`translate(${x},${y})`}>
            <text x={0} y={0}dy={16} textAnchor="end"fill="#666"transform="rotate(-35)"style={{fontSize: 11}}>
              {payload.value}
            </text>
          </g>
        );
    };
 
    function random_color() {
        const letters = '0123456789ABCDEF'.split('');
        let color = '#';
        
        for (let i = 0; i < 6; i++) {
            color += letters[Math.round(Math.random() * 15)];
        }
        
        return color;
    }
      
    return(
        <Box sx={{textAlign:'center'}}>
            {dataForChart.length == 0 ?
            <Typography sx={{color:'gray', padding: 4}} >No records.</Typography>
            :
                <ResponsiveContainer width="100%" height={400} className="GraphPending">
                    <BarChart
                    
                    data={dataForChart}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" height={50} tick={<CustomizedAxisTick />}/>
                    <YAxis />
                    <Tooltip />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <Legend layout="horizontal" verticalAlign="top" align="center"/>
                    <Bar dataKey="PENDING" stroke="#8884d8" fill="#68e8c8" />
                    {/* <Bar dataKey="pending" stroke="#82ca9d" />
                    <Bar dataKey="completed" stroke="#82ca9d" /> */}
                </BarChart>
                </ResponsiveContainer>
            }
        </Box>
    )
}
export default AnalyticsPendingComponent