import { useState, useRef, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, FormControl, FormControlLabel, Switch} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {ThreeDots} from "react-loader-spinner";

import AddIcon from '@mui/icons-material/Add';

import SigneeCardComponent from "./SigneeCardComponent";

import { useDialog } from "../../../../utils/common-dialog";
import { log } from '../../../../../console-config';

const ManageSigneesDialogComponent = ({tag, open, params}) => {

    let signatories = params || []

    const [signees, setSignees] = useState(signatories)
    const [hasSigningOrder, setHasSigningOrder] = useState(true);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const {closeDialog} = useDialog()

    log("signatories bb", signatories);

    useEffect(() => {
        setSignees(signatories)
    }, [JSON.stringify(signatories)])

    const handleCloseDialog = () => {
        closeDialog(tag)
    }

    const handleSubmit = () => {
        closeDialog(tag, signees)
    }

    const handleOnMoveCard = (dragIndex, hoverIndex) => {
        const result = Array.from(signees);
        const [removed] = result.splice(dragIndex, 1);
        result.splice(hoverIndex, 0, removed);
        setSignees(result)
    }

    const handleChangeHasSigningOrder = (e) => {
        setHasSigningOrder(e.target.checked)
    }

    const handleOnDeleteSignee = (signee) => {
        log("signee from manage aa", signee);
        setSignees((prev) => prev.filter((item) => item.id !== signee.id))
        // setSignees((prev) => prev.map((item, i) => item.id == signee.id ? ({...item, isRemove: true}) : item))
    }

    const handleOnEditSignee = (value, signee) => {
        // setSignees((prev) => prev.map((item, i) => item.id == signee.id ? ({...item, email_address: value, isEdited: true, old_email: item.email_address}) : item))
    }

    return(
        <Dialog
                fullScreen={fullScreen}
                fullWidth
                open={open}
                // onClose={handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
            >
            <DialogTitle id="responsive-dialog-title">Manage Signees</DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    <FormControlLabel
                        value="start"
                        control={
                            <Switch color="success" 
                                checked={hasSigningOrder}
                                onChange={handleChangeHasSigningOrder}
                                inputProps={{ 'aria-label': 'controlled' }} />
                        }
                        label="Set signing order"
                        labelPlacement="start"
                    />
                </FormControl>
                <br /><br />
                {signees?.filter((signee) => !signee.isRemove).length == 0 && <Typography align="center">No signees found.</Typography>}
                {
                    signees?.filter((signee) => !signee.isRemove).map((signee, i) => (
                        <SigneeCardComponent signee={signee} index={i} onMoveCard={handleOnMoveCard} hasSigningOrder={hasSigningOrder}
                            onDeleteSignee={handleOnDeleteSignee} onEditSignee={handleOnEditSignee} />
                    ))
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCloseDialog} variant="outlined" sx={{width: 120, borderRadius: 10, color:'#3333cf', borderColor: "#3333cf4f !important"}}>
                    Cancel
                </Button>
                <Button variant="contained" sx={{width: 120, backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}} onClick={handleSubmit} autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ManageSigneesDialogComponent