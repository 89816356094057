import { useState } from "react";
import { Paper, Button, BottomNavigation } from '@mui/material';
import DrawerHeader from "../styled-components/DrawerHeader";

export default function PDFBottomNavigationComponent({onSave, onCancel, savePDFButtonLabel}) {

    const [selected_index, setSelectedIndex] = useState(0);

    function handleOnClickSave(){
        onSave()
    }

    function handleOnClickCancel(){
        onCancel()
    }
  
    return (
        <>
            <DrawerHeader/>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation showLabels value={selected_index} onChange={(event, index) => {setSelectedIndex(index); }}>
                <Button className="Send" variant="contained" sx={{width: '100%', backgroundColor: '#0178f2', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}} onClick={handleOnClickSave}>
                        {savePDFButtonLabel ? savePDFButtonLabel : "Save"}
                    </Button>
                    
                    <Button className="Cancel" variant="outlined" sx={{width: '100%', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }} 
                        onClick={handleOnClickCancel}
                    >
                        Cancel
                    </Button>
                </BottomNavigation>
            </Paper>
        </>
    );

}