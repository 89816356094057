import { Masonry } from "@mui/lab";
import { Box, Grid, Button, Typography, Card, IconButton, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import useDocuments from "../hooks/useDocuments";
import AnalyticsCardComponent from "../components/analytics/analytics-cards.component";
import { useEffect } from "react";
import AnalyticsAllComponent from "../components/analytics/analytics-all.component";
import AnalyticsDraftComponent from "../components/analytics/analytics-draft.component";
import AnalyticsPendingComponent from "../components/analytics/analytics-pending.component";
import AnalyticsCompletedComponent from "../components/analytics/analytics-completed.component";
import { useState } from "react";
import DocumentWorkflowService from "../services/api/documents";
import HelpIcon from '@mui/icons-material/Help';
import FilesGuide from "../components/walkthrough/files-guide";
import AnalyticsGuide from "../components/walkthrough/analytics-guide";
import { log } from "../console-config";

function AnalyticsHeader(){

    return(
        <Box sx={{padding: 1, width:'100%', borderRadius: 0}}>
            <Grid container  sx={{width:'98%', margin:'auto'}}>
                <Grid item xs={6} >
                    <Typography variant='h6' sx={{fontWeight: "bold", color: "#21649e"}}>Analytics</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

function AnalyticsScreen () {

    const currentYear = new Date().getFullYear();

    const { loading, countAllRecords, all, drafts, pending, completed, countAnalyticsRecord, allAnalytics, draftsAnalytics, pendingAnalytics, completedAnalytics, cancelledAnalytics, } = useDocuments();
    const [test, setTest] = useState()
    const [analytics, setAnalytics] = useState([])
    const [isWalkthrough, setIsWalkthrough] = useState(null)
    const [showWalkthrough, setShowWalkthrough] = useState(false);
    const [year, setYear] = useState(currentYear);

    log('aaaaaaaaaaaaaaaaaaaaaa',  allAnalytics)

    var module_id = "ANL"

    const handleWalkthroughButtonClick = () => {
        setShowWalkthrough(true);
    };

    const reopenJoyride = () => {
        setShowWalkthrough(true);
    };

    const retrieveWalkthrough = () =>{
        DocumentWorkflowService.getWalkthrough(module_id).then((res)=>{
            log('is user walkthrough', res)
            setIsWalkthrough(res.data.data.is_walkthrough)
        })
    }

    useEffect(()=>{
        retrieveWalkthrough(module_id)
    },[module_id])

    const handleScrollToAll = () => {
        const completedSection = document.getElementById("analytics-all");
        if (completedSection) {
          completedSection.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
    };
    
    const handleScrollToCompleted = () => {
        const completedSection = document.getElementById("analytics-completed");
        if (completedSection) {
          completedSection.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
    };

    const handleScrollToDraft = () => {
        const completedSection = document.getElementById("analytics-draft");
        if (completedSection) {
          completedSection.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      };

    const handleScrollToPending = () => {
        const completedSection = document.getElementById("analytics-pending");
        if (completedSection) {
            completedSection.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest"});
        }
    };

    const getAnalytics = () => {
        DocumentWorkflowService.retrieveAnalytics(year).then((res)=>{
            log('retrieve analytics res here', res)
            setAnalytics(res.data.data)
        })
    }

    const totals = {};

    analytics.forEach(entry => {
        const { month, count, status, months } = entry;
        if (!totals[month]) {
            totals[month] = {};
            totals[month]["DRAFT"] = 0;
            totals[month]["PENDING"] = 0;
            totals[month]["COMPLETED"] = 0;
            totals[month]["TOTAL"] = 0;
        }
        totals[month][status] += count;
        totals[month]["TOTAL"] += count;
    });

    const dataForChart = Object.keys(totals).map(month => ({
        month,
        DRAFT: totals[month].DRAFT,
        PENDING: totals[month].PENDING,
        COMPLETED: totals[month].COMPLETED,
        TOTAL: totals[month].TOTAL,
    }));

    log('bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb', dataForChart)

    function generateArrayOfYears() {
        var current_year = new Date().getFullYear();
        var years = [];

        for (var i = 2022; i <= current_year; i++) {
            let year = { value: i };
            years.push(year);
        }

        return years;
    }

    var years = generateArrayOfYears();

    years.sort(function (a, b) {
        return b.value - a.value;
    });

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    useEffect(()=>{
        getAnalytics(year)
    },[year])

    useEffect(()=>{
        countAllRecords()
    },[])

    useEffect(()=>{
        countAnalyticsRecord(year)
    },[year])

    return(
        <Box>
             <AnalyticsHeader />
            
            <Masonry columns={{ xs: 1, sm: 4 }} defaultColumns={4} spacing={2} sx={{margin:'auto', width: '100%', pl: 1, pr: 1}}>
                <AnalyticsCardComponent all={allAnalytics} drafts={draftsAnalytics} pending={pendingAnalytics} 
                completed={completedAnalytics} handleScrollToCompleted={handleScrollToCompleted} handleScrollToAll={handleScrollToAll}
                handleScrollToPending={handleScrollToPending} handleScrollToDraft={handleScrollToDraft} />
            </Masonry>
            <Box sx={{pt: 0, pr: 2, pl: 2, pb: 2}}>
                <Select
                    labelId="demo-multiple-select-label"
                    id="demo-multiple-select"
                    className="FilterYear"
                    value={year}
                    sx={{ width: 200, mb: 2 , bgcolor:'white' }}
                    onChange={handleYearChange}
                    inputProps={{ sx: { mr: "28px", padding: 1, fontSize: 12, textAlign: "start" } }}
                    InputProps={{
                        startAdornment: <Typography>Year:</Typography>,
                    }}
                >
                    {years?.map((year) => {
                        return (
                            <MenuItem key={year.value} value={year.value}>
                                {year.value}
                            </MenuItem>
                        );
                    })}
                </Select>
                <Card id="analytics-all" sx={{borderRadius: 2, p: "24px 16px 16px 16px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                    <AnalyticsAllComponent dataForChart={dataForChart} />
                </Card>
                <br/>
                <Card id="analytics-draft"  sx={{borderRadius: 2, p: "24px 16px 16px 16px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                    <AnalyticsDraftComponent dataForChart={dataForChart} />
                </Card>
                <br/>
                <Card id="analytics-pending"  sx={{borderRadius: 2, p: "24px 16px 16px 16px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                    <AnalyticsPendingComponent dataForChart={dataForChart} />
                </Card>
                <br/>
                <Card id="analytics-completed" sx={{borderRadius: 2, p: "24px 16px 16px 16px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                    <AnalyticsCompletedComponent dataForChart={dataForChart}/>
                </Card>
            </Box>
            <div style={{position:'fixed', bottom: "5px", right:"3%", cursor:'pointer'}} >
                {showWalkthrough ? (
                    <IconButton variant="contain" onClick={handleWalkthroughButtonClick}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                ) : (
                    <IconButton variant="contain" onClick={reopenJoyride}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                )}
            </div>
            {(isWalkthrough === false) && (
                <AnalyticsGuide setShowWalkthrough={setShowWalkthrough} />
            )}

            {showWalkthrough && <AnalyticsGuide showWalkthrough={showWalkthrough} setShowWalkthrough={setShowWalkthrough}/>}
        </Box>
    )
}
export default AnalyticsScreen