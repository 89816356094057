import { TableCell, TableRow, ListItemAvatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const ReportRowListComponent = (props) => {


    const {list, breakpoints, monthValue, year, } = props;

    var status = list.status;

    const navigate = useNavigate()


    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor:'pointer' }}
            onClick={() => navigate('/view-reports?month=' + monthValue + '&year=' + year  + '&status=' + status )}
        >
            <TableCell component="th" scope="row">
                <PictureAsPdfIcon sx={{marginRight: 1, color: list.status == 'PENDING' || list.status == 'pending' ? 'orange' : list.status == 'COMPLETED' || list.status == 'completed' ? 'green' : list.status == 'CANCELLED' || list.status == 'cancelled' ? 'red' : 'inherit',}}/>
            </TableCell>
            <TableCell component="th" scope="row" sx={{color: list.status == 'PENDING' || list.status == 'pending' ? 'orange' : list.status == 'COMPLETED' || list.status == 'completed' ? 'green' : list.status == 'CANCELLED' || list.status == 'cancelled' ? 'red' : 'inherit',}}>
                {list.status}
            </TableCell>
            <TableCell component="th" scope="row">
                {list.count}
            </TableCell>
        </TableRow>
    )
}

export default ReportRowListComponent;