import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPublicGet, HttpPublicPost} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.ovsign_account_service.subscriptionKey;

/**
 * updateStatus - POST Method
 * @param {String} element_id element_id
 * @param {String} document_id document_id
 * @param {String} email_address email_address
*/ 
const updateStatus = (element_id, document_id, email_address) => {
    let payload = null;
    let subscriptionKey = SUBSCRIPTION_KEY;
    var route = 
        routes.UPDATE_STATUS_DW_PUBLIC + 
        '?element_id=' + element_id +
        '?document_id=' + document_id +
        '?email_address=' + email_address
    ;
    let result = HttpPost(route, payload, subscriptionKey);
    return result;
}

/**
 * validateToken - GET Method
 * @param {String} email_address email_address
*/ 
const validateToken = (email_address) => {
    let query_params = {
        email_address: email_address
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.VALIDATE_TOKEN_DW_PUBLIC, query_params, subscriptionKey);
    return result;
}

const getDocumentWithProperties = (accessToken) => {
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPublicGet(routes.GET_DOCUMENT_WITH_PROPERTIES_PUBLIC, null, accessToken, subscriptionKey);
    return result;
}

const getSignatureByEmail = (email_address, accessToken) => {
    let query_params = {email_address};
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPublicGet(routes.GET_SIGNATURE_BY_EMAIL_UA_PUBLIC, query_params, accessToken, subscriptionKey);
    return result;
}

const signDocument = (signature_url, accessToken, user_agent) => {
    let payload = {
        signature_url, user_agent
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPublicPost(routes.SIGN_DOCUMENT, null, payload, accessToken, subscriptionKey);
    return result;
}

const declineDocument = (document_id, email, denied_reason, accessToken) => {

    var payload = { document_id ,email,denied_reason };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPublicPost(routes.DECLINE_DOCUMENT, null, payload, accessToken, subscriptionKey);
    return result;
}

const DocumentsPublicService = {
    updateStatus: updateStatus,
    validateToken: validateToken,
    getDocumentWithProperties,
    getSignatureByEmail,
    signDocument,
    declineDocument
}

export default DocumentsPublicService;