import { useState } from "react"
import DocumentWorkflowService from "../services/api/documents"
import { log } from "../console-config"


const useActivityLogs = () => {

    const [logsList, setLogsList] = useState([])
    const [pagination, setPagination] = useState(0)
    const [loading, setLoading] = useState(false)

    var limit = 10

    const activityLogsList = (page) => {
        setLoading(true)
        DocumentWorkflowService.activityLogsList(page, limit).then((res)=>{
            log('get logs list here', res)
            
            if (res.data.success) {
                setLoading(false);
                setLogsList(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    setPagination(last_page)
                  }
              }
        }).catch((err)=>{
            log('get logs list err', err)
            setPagination(0)
            setLoading(false)
        })
    }
    
    const activityListFilter = (activity, module, actions, month, year, page) => {
        setLoading(true)
        DocumentWorkflowService.activityListFilter(activity, module, actions, month, year, page, limit).then((res)=>{
            log('get logs list filter here', res)
            
            if (res.data.success) {
                setLoading(false);
                setLogsList(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    setPagination(last_page)
                  }
              }
        }).catch((err)=>{
            log('get logs list err', err)
            setPagination(0)
            setLoading(false)
        })
    }

    return{
        activityLogsList, logsList, activityListFilter, loading, pagination, 
    }
}
export default useActivityLogs