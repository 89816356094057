import {useEffect, useState} from "react"
import {Typography, Box, Stack, TextareaAutosize} from "@mui/material"
import {elementTypes} from "../common/staticVariables";
import {resizeImageWithAspectRatio} from "../../utils/AspectRatioUtil";
import SignatoryElementComponent from "./SignatoryElementComponent"
import { log } from "../../../console-config";

function CurrentDateElementComponent({element, elDragRef, onValueChange}){

    return(
        <Typography sx={{textAlign: "start", fontSize: element?.font_size, whiteSpace: 'nowrap',overflow: 'hidden', 
            textOverflow: 'ellipsis', width: element?.width, lineHeight: 0.95, letterSpacing: '0px'}}>{element?.value}</Typography>
    )
}

const TextFieldElement = ({element, elDragRef, handleResize, onValueChange}) => {

    log("TextFieldElement element: ", element)

    const [textValue, setTextValue] = useState(element.value)

    useEffect(() => {
        if (element) setTextValue(element.value)
    }, [JSON.stringify(element)])

    return(
        <TextareaAutosize 
            id="textF"
            aria-label="empty textarea"
            placeholder="Enter value"
            value={textValue}
            defaultValue={textValue}
            disabled
            style={{
                fontSize: element.font_size,
                padding: '0px',
                color: "#000",
                "&. Muitextarea:focus": {border: "1px solid #E2EBF5"},
                width: element?.width,
                height: element?.height,
                resize: "none",
                border: 0,
                fontFamily: 'Helvetica',
                lineHeight: 0.95,
                backgroundColor: 'transparent'
            }}
            InputProps={{style: {"&. Muitextarea:focus": {border: "1px solid #E2EBF5"}}}}
            inputProps={{style: {"&. Muitextarea:focus": {border: "1px solid #E2EBF5"}}}}
        />
    )
}

const ElementSigneeComponent = ({element, currentUser, onResize, isViewing, signatory}) => {

    const [imgDimens, setImgDimens] = useState(null);
    const [elSignatoryStatus, setElSignatoryStatus] = useState(null);
    const [elDimens, setElDimens] = useState({width: element.width, height: element.height});
    log("currentUser aaa11", currentUser);
    log("elDimens aaa11", elDimens);

    useEffect(() => {
        if (imgDimens) {
            const {width, height} = getResizableBoxOnLoadDimens()
            log("imgDimensxxxxx", {width, height});
            onResize(width, height, imgDimens.width, imgDimens.height)
        }
    }, [JSON.stringify(imgDimens)])

    useEffect(() => {
        if (signatory.length > 0) {
            let status = signatory.find((item) => item.email_address == element.value)?.status.toLowerCase()
            setElSignatoryStatus(status)
        }
    }, [JSON.stringify(signatory)])

    const getResizableBoxOnLoadDimens = () => {
        let width, height

        // Image ratio for user signature
        if(element.isSigned){
            let {width: newWidth, height: newHeight} = resizeImageWithAspectRatio(imgDimens?.width, imgDimens?.height, elDimens?.width, elDimens?.height)
            width = newWidth
            height = newHeight
        } 
        else {
            width = element?.width
            height = element?.height
        }

        return {width, height}
    }

    const onLoadImg = (e) => {
        setImgDimens({width: e.target.naturalWidth, height: e.target.naturalHeight})
    }

    return(
        <>
            { 
                element?.type == elementTypes.signatory ?
                <Box className={currentUser == element?.value || elSignatoryStatus == "ongoing" || elSignatoryStatus == "draft" ? "signee-signatory-box-element" : ""} sx={{width: elDimens.width, height: elDimens.height}}>
                    <SignatoryElementComponent element={element} isViewing={isViewing} onLoadImg={onLoadImg} />
                </Box>
                :
                element?.type == elementTypes.textField ?
                <TextFieldElement element={element} />
                : 
                element?.type == elementTypes.currentDate ? 
                <CurrentDateElementComponent element={element} />
                :
                (element?.type == elementTypes.userSignature || element?.type == elementTypes.qrcode) ? 
                <Box sx={{width: elDimens.width, height: elDimens.height}}>
                    <img alt="" src={element?.value} style={{width: element.width, height: element.height, objectFit: "contain",}} onLoad={(e) => onLoadImg(e)} /> 
                </Box>
                :
                null
            }
        </>
    )
}

export default ElementSigneeComponent