const elementTypes = {
    userSignature: 'esignature',
    companySeal: 'eseal',
    qrcode: 'qrcode',
    textField: 'textfield',
    currentDate: 'current_date',
    signatory: 'signatory',
}

const drawerWidth = 340;

const pdfPageSizes = {
    A4: {
        width: 595.5,
        height: 842.25,
        unit: "pt"
    }
}

export {elementTypes, drawerWidth, pdfPageSizes}