import { Box, Button, Card, CardActionArea, CardActions, CardContent, Grid, TextField, Typography } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";


export default function InquiryComponent (props) {

    const {handleSubmit, handleInputInquiry, user, loading, inputInquiry, inputRequired} = props;

    return(
        <Box>
            <CardContent>
                <Typography sx={{textAlign:'center', fontWeight:'bold', fontSize: 16}}>Send us your inquiry</Typography>

                <Grid container spacing={1} sx={{mt: 2}}>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{fontWeight:'bold', fontSize: 14}}>Email</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{mb: "5px"}}>
                        <TextField
                            fullWidth 
                            variant="filled" 
                            autoComplete="off"
                            // placeholder={'Email'}
                            value={user?.email_address}
                            sx={{ "& .MuiInputBase-root": { borderRadius: 1, fontSize: 12, color: '#21649e'} }}
                            InputProps={{
                                disableUnderline: true, 
                                padding: 0
                            }}
                            inputProps={{
                                style: {
                                    padding: 10,
                                    color: "#21649e",
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{mt: 2}}>
                        <Typography sx={{fontWeight:'bold', fontSize: 14}}>
                            Kindly provide all the details for your inquiry.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <TextField
                            fullWidth 
                            variant="filled" 
                            multiline
                            rows={6}
                            placeholder={'Message'}
                            value={inputInquiry ? inputInquiry : ''}
                            onChange={(e) => handleInputInquiry(e)}
                            sx={{ "& .MuiInputBase-root": { borderRadius: 1, fontSize: 12, paddingTop: 2, color: '#21649e'} }}
                            InputProps={{
                                disableUnderline: true, // <== added this
                            }}
                            inputProps={{
                                maxLength: 500,
                            }}
                            helperText={
                                <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                                    {(inputRequired) && "Details required"}
                                </p>
                            }
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{p: 2}}>
                <Button variant="contained" fullWidth sx={{backgroundColor: '#168401', borderRadius: 1, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                    onClick={handleSubmit}
                    startIcon={loading &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {loading ? 'Submitting' : 'Submit'}
                </Button>
            </CardActions>
        </Box>
    )
}