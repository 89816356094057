import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Card, CardContent, CardMedia, Grid, Typography} from '@mui/material';
import CreateSignatureDialogComponent from "../digital-siganture/e-signature/create-signature.dialog";
import { resizeImageWithAspectRatio } from "../../utils/ImageRationUtil";
import ImageCropperDialog from "./dialog/image-cropper.dialog";
import AccountService from "../../services/api/accounts";
import {ThreeDots} from "react-loader-spinner";
import { useSnackbar } from "notistack";
import { log } from "../../console-config";

const ProfileSignatureComponent = (props) => {

    const {enqueueSnackbar} = useSnackbar();
    const {breakpoints, signature, user} = props;

    const [photoPreview] = useState(null);
    const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
    const [userPhoto, setUserPhoto] = useState(null);
    const [userImg, setUserImg] = useState(null);
    const handleOpenSignatureDialog = () => {setOpenSignatureDialog(true);}
    const handleCloseSignatureDialog = () => {setOpenSignatureDialog(false);}
    const [openImageEditor, setOpenImageEditor] = useState(false);
    const [photoFile, setPhotoFile] = useState(null);
    const [userPhotoPreview, setUserPhotoPreview] = useState(null);
    const [tempUserPhoto, setTempUserPhoto] = useState(null);

    const [imgDimens, setImgDimens] = useState(null);
    const [adjustedImgDimens, setAdjustedImgDimens] = useState(null);
    const [loading, setLoading] = useState(false)

    const signatureRef = useRef()

    useEffect(() => {
        if (imgDimens) {
            let {width, height} = resizeImageWithAspectRatio(imgDimens?.width, imgDimens?.height, signatureRef.current?.clientWidth, 
                signatureRef.current?.clientHeight)
            log("imgDimens nnn", imgDimens)
            log("newWidth", width)
            log("newHeight", height)
            setAdjustedImgDimens({width, height})
        }
    }, [JSON.stringify(imgDimens)])

    const onLoadImg = (e) => {
        setImgDimens({width: e.target.naturalWidth, height: e.target.naturalHeight})
    }

    const validatePhoto = (e) => {
        setImageStateFromChild();

        var fileName = document.getElementById("upload-photo").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile == "jfif" || extFile == "svg" || extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        
            const imageFile = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                setUserImg(e.target.result);
            }

            reader.readAsDataURL(imageFile);

            setOpenImageEditor(true);
 
        } else {
            log("Only image files are supported as of now");
        }
    }

    const setImageStateFromChild = (child) => {
        setUserImg(child);
    }

    const handleClearPhoto = () => {
        setPhotoFile(null);
        setUserPhotoPreview(null);
    }

    

    useEffect(() => { 
        if (user) {
            setUserPhoto(user?.profile_url);
        }
        
    }, [JSON.stringify(user)])

    

    useEffect(() => {
        if (userPhoto) {
            const fetchData = async () => {
                try {
                    const photoResponse = await fetch(userPhoto);
                    const photoBlob = await photoResponse.blob();
                    const tempPhotoFile = new File([photoBlob], 'temp_photo.png', {type: 'image/png'});
                    
                    setTempUserPhoto(tempPhotoFile);

                } catch (error) {
                    log('error fetching user photo xxxx', error);
                }
            };
        
            fetchData();

        } else {
            setTempUserPhoto(new Blob([new ArrayBuffer(0)], {type: 'application/octet-stream'}));
        }
    }, [userPhoto])

    const handleUpdateInfo = () => {
        let formData = new FormData();
        
        formData.append("file", photoFile);
        setLoading(true)
        AccountService.uploadProfile(formData).then((res)=>{
            log('upload profile response', res);
            if(res.data.success){
                setLoading(false)
                window.location.reload()
                enqueueSnackbar("Uploaded", { 
                    variant: "success",
                    autoHideDuration: 3000
                });
            }
            
        }).catch((error)=>{
            setLoading(false)
            enqueueSnackbar(error.response.data.error, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    log('get temp user photo', photoFile)
 
    return (
        <Card sx={{borderRadius: 2, padding: 3, paddingTop: 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints == 'xs' || breakpoints == 's' ? 'auto' : "80vh"}}>
            <CardContent sx={{mt: 1}}>
                <Grid container spacing={2}>

                    {/* SIGNATURE */}
                    <Grid item xs={12} sm={12}>
                        {/* <Card ref={signatureRef} sx={{border: '1px solid #0178f252', width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center',alignContent: 'center',flexWrap: 'wrap', boxShadow: 'none'}}> */}
                        <Card className="ProfileSignature" ref={signatureRef} sx={{border: 1, borderColor: "#0178f238", mb: 0, marginTop: 0, boxShadow: "none"}}>
                            <CardContent sx={{cursor: "default", justifyContent: 'center', alignItems: 'center',alignContent: 'center'}}>
                                {signature ?
                                    // <img src={signature?.signature_url} style={{width: adjustedImgDimens?.width, height: adjustedImgDimens?.height}}  onLoad={(e) => onLoadImg(e)} />
                                    <CardMedia
                                        component="img"
                                        src={signature?.signature_url}
                                        style={{width: "100%", height: 125, objectFit: "contain"}}
                                    />
                                    :
                                    <Typography sx={{fontSize: 14, color: '#0178f252', fontStyle: 'italic', textAlign: 'center'}}>Create your signature</Typography>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{textAlign:'center'}} className="UploadSignature" >
                        {signature ?
                            <Button sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 12, textAlign:'left' }} onClick={(e) => handleOpenSignatureDialog(e)}>
                                <label style={{cursor: "pointer"}} htmlFor="upload-signature">
                                    Change Signature
                                </label>
                            </Button>

                        :
                            <Button sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 12, textAlign: "left"}}
                                onClick={(e) => handleOpenSignatureDialog()}
                            >
                                <label style={{cursor: "pointer" }} htmlFor="upload-signature">
                                    Upload Signature
                                </label>
                            </Button>
                        }
                    </Grid>

                    {openSignatureDialog && 
                        <CreateSignatureDialogComponent openSignatureDialog={openSignatureDialog} user={user}
                            breakpoints={breakpoints} handleCloseSignatureDialog={handleCloseSignatureDialog}   
                        />
                    }
                </Grid>
            </CardContent>
            {openImageEditor &&
                <ImageCropperDialog userImg={userImg} setImageStateFromChild={setImageStateFromChild}
                setUserPhotoPreview={setUserPhotoPreview} setPhotoFile={setPhotoFile} setLoading={setLoading}
                openImageEditor={openImageEditor} setOpenImageEditor={setOpenImageEditor}
                />
            }
        </Card>  
    )
}

export default ProfileSignatureComponent