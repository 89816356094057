import { useEffect, useState, useRef } from "react"
import { Box, Button, IconButton, Stack } from "@mui/material"
import { v4 as uuidv4 } from 'uuid';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import "../styles/editor.css";
import testPDF3 from "../sample.pdf";
import TenMBPdf from "../10mb.pdf";
import NineMBPdf from "../9mb.pdf";
import PdfWithPw from "../pdf-with-pw-123.pdf";
import PdfWithDifferentOrientations from "../pdf-with-diff-orientations.pdf";
import SimplePDFRotated from "../simple-pdf-rotated.pdf";
import testPDF4 from "../pdf-with-diff-orientations.pdf";
import HelpIcon from '@mui/icons-material/Help';
import PDFPageComponent from "./PDFPageComponent";
import { getWidthByPercentage } from "../../utils/ProportionDimenUtil";

import useWidth from '../hooks/useWidth';
import PDFEditorGuide from "../../../components/walkthrough/pdf-editor-guide";
import { log } from "../../../console-config";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * elements array description
*/
const elements = [{
    id: Number, // id of element
    type: String, // type of element
    page_no: Number, // page number where element plotted
    value: String, // value of element
    width: String, // width of element
    height: String, // height of element
    x: String, // x coordinate of element
    y: String, // y coordinate of element
}]



const PDFBoxComponent = ({documentId, file_url, pdfResult, setPDFResult, setPDFTitle, elements_to_plot, onDocumentLoadSuccess, onDocumentPageLoadSuccess, onEditElement, 
    onDeleteElement, signees, setSignees, signeesOptionLabel, onUpdateSignees, onAddToSignees, onUpdateESignature, currentUser, onErrorAddSignee, getDocumentById, handleWalkthroughButtonClick, reopenJoyride, showWalkthrough}) => {

    let cachedElements = JSON.parse(localStorage.getItem("elements"));
    let deletedCachedElements = JSON.parse(localStorage.getItem("deleted_elements"));

    let has_document_cached = !cachedElements || !cachedElements[documentId] ? false : true;

    const [numPages, setNumPages] = useState(null);
    const [elements, setElements] = useState(has_document_cached ? cachedElements[documentId].elements : elements_to_plot)
    const [deletedElements, setDeletedElements] = useState(!deletedCachedElements ? [] : deletedCachedElements)
    const [isValidating, setIsValidating] = useState(true)
    const [error, setError] = useState(null)
    const [pdfPages, setPdfPages] = useState([])
    const [hasUpdatedElements, setHasUpdatedElements] = useState(false)
    const breakpoints = useWidth()

    log("pdfPages aaaab", pdfPages);
    log("signees aaaab", signees);
    log("elements_to_plot vvvv", elements_to_plot);

    const pdfEditorRef = useRef()
    const width = useWidth();

    const elementProps = {
        elements,
        elements_to_plot,
        setElements,
        deletedElements,
        setDeletedElements,
        elements_to_plot,
        pdfResult,
        setPDFResult,
        signees, 
        setSignees,
        signeesOptionLabel,
        onUpdateSignees,
        onAddToSignees,
    }

    // useEffect(()=>{
    //     if (elements_to_plot.length > 0) setElements(elements_to_plot);
    // }, [JSON.stringify(elements_to_plot)])
    
    useEffect(() => {
        let inital_result = {
            ...pdfResult,
            filename: 'Test_filename.pdf',
            total_page: numPages
        }
        setPDFResult(inital_result)
    }, [numPages])

    async function handleOnDocumentLoadSuccess(pdf){

        log("pdfpdfpdfpdfpdfpdf: ", pdf)
        // const page1 = await pdf.getPage(1);
        // log("page 1 width: ", page1)
        // log("page 1 height: ", page1.height)
        // const page2 = await pdf.getPage(2);
        // log("page 2 width: ", page2.width)
        // log("page 2 height: ", page2.height)

        handleSetPDFPages(pdf)

        var pdf_metadata = await pdf.getMetadata();
        validatePDF(pdf_metadata);
        // pass props to parent:
        // 1. set title
        var pdf_info = pdf_metadata.info;
        log("pdf_infopdf_info: ", pdf_info)
        setPDFTitle(pdf_info.Title); // UNDER DEVELOPMENT. not always have value
        // 2. onload of document success
        onDocumentLoadSuccess(pdf);
        setNumPages(pdf.numPages);
    }

    const handleSetPDFPages = (pdf) => {
        //TODO make comments

        [...Array(pdf.numPages)].map((item, i) => {
            let pageNum = i + 1
            pdf.getPage(pageNum).then((page) => {
                let pdfEditorWrapperWidth = pdfEditorRef?.current?.clientWidth

                let percentage = 80
                let percentageWidth = getWidthByPercentage(percentage, pdfEditorWrapperWidth)

                let rotate = page.rotate
                let isLandscape = rotate == 90 || rotate == 270 ? true : false
                // let widthDiff = isLandscape ? pdfPageOrigHeight - percentageWidth : 0
                // let isNegative = widthDiff < 0 ? true : false
                // let newNum = isLandscape ? pdfPageOrigHeight + widthDiff : 0
                // let origNum = isLandscape ? pdfPageOrigHeight : pdfPageOrigWidth
                // let percentageDiff = Math.abs(getPercentageIncreaseDecrease(newNum, origNum))
                // percentageDiff = isNegative ? Math.abs(percentageDiff) : -Math.abs(percentageDiff)

                // let sortedData = page.sort((a, b) => a.page_number - b.page_number);
                log('get pdf page on editor', page)

                // page["get width"]().then((res) => {
                //     log('get pdf page on width', res)
                // })
                log('get pdf page on rotate', rotate)

                let pageNo = page?.pageNumber

                let obj = {
                    numPages: pdf.numPages,
                    adjustedPDFWidth: percentageWidth,
                    pageNo,
                    rotate,
                    // ...(isLandscape && {adjustedWidth: percentageWidth, percentageDiff, widthDiff})
                }

                setPdfPages((prev) => {
                    // let _page = prev.find((item) => item.isLandscape)

                    // // For portrait
                    // if (_page && !isLandscape) {
                    //     let portrait_percentage_width = getWidthByPercentage(_page.percentageDiff, pdfPageOrigWidth)
                    //     obj.adjustedWidth = portrait_percentage_width + pdfPageOrigWidth
                    // }
                    return [...prev, obj]
                })
            })
        })

    }

    pdfPages.sort((a, b) => a.page_number - b.page_number);

    function getPercentageIncreaseDecrease(newNum, origNum) {
        return ((newNum - origNum) / origNum) * 100;
    }

    function getPercentageIncrease(newNum, origNum) {
        return ((newNum - origNum) / origNum) * 100;
    }

    function getPercentageDecrease(newNum, origNum) {
        return ((origNum - newNum) / origNum) * 100;
    }

    function handleOnDocumentPageLoadSuccess(page){
        log("pagepagepagepage: ", page);
        onDocumentPageLoadSuccess(page);
    }

    function validatePDF(pdf_metadata){
        // 1. check if pdf file is less than 10mb
        var pdf_bytes_length = pdf_metadata.contentLength;
        var ten_mb_to_bytes = 10485760; // 10mb
        var isPdfLessThan10MB = ten_mb_to_bytes > pdf_bytes_length;
        if(!isPdfLessThan10MB){
            setError('PDF File size exceeds 10MB.');
            setIsValidating(false);
            return false;
        }
        // 2. add here in case . . .
        setIsValidating(false);
        return true;
    }

    function handleOnLoadError(error){
        setError(error.message); // react-pdf validates by default
    }

    const handleOnDeleteElement = (element) => {
        onDeleteElement(element)
    }

    const handleOnEditElement = (element) => {
        onEditElement(element)
    }

    function onPassword(callback, reason) {
        alert("PDF with password is not yet supported.");
    }

    const handleOnLoadProgress = ({ loaded, total }) => {
        log('Loading a document: ' + (loaded / total) * 100 + '%');
    }


    
    

    return (
        <Box ref={pdfEditorRef} sx={{bgcolor: 'lightgray', height: '100%'}} display="flex" justifyContent="center" alignItems="center">
            { error ? 
                error
            :
                <Document 
                    file={file_url}
                    onPassword={onPassword}
                    loading={'Loading template...'}
                    onLoadSuccess={handleOnDocumentLoadSuccess}
                    onLoadError={handleOnLoadError}
                    onLoadProgress={handleOnLoadProgress}
                >
                    { isValidating ? 
                            "Validating . . ." 
                        : 
                            <Stack direction="column" justifyContent="space-evenly" alignItems="center" >
                                {pdfPages.map((page, i) => {
                                    return(
                                        <PDFPageComponent 
                                            key={i} 
                                            documentId={documentId}
                                            pdfPages={pdfPages}
                                            setPdfPages={setPdfPages}
                                            numPages={page.numPages} 
                                            page_number={page.pageNo} 
                                            adjustedPDFWidth={page.adjustedPDFWidth} 
                                            elementProps={elementProps}
                                            onDocumentPageLoadSuccess={handleOnDocumentPageLoadSuccess}
                                            onEditElement={handleOnEditElement}
                                            onDeleteElement={handleOnDeleteElement}
                                            onUpdateESignature={onUpdateESignature}
                                            currentUser={currentUser}
                                            onErrorAddSignee={onErrorAddSignee}
                                            getDocumentById={getDocumentById}
                                        />
                                    )
                                })}
                                {breakpoints !== 'xs' &&
                                    <div style={{position:'fixed', bottom: "20px", right:"25%", cursor:'pointer'}} >
                                        {showWalkthrough ? (
                                            <IconButton variant="contain" onClick={handleWalkthroughButtonClick}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                                        ) : (
                                            <IconButton variant="contain" onClick={reopenJoyride}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                                        )}
                                    </div>
                                }
                            </Stack>
                    }

                </Document>
            }
            
        </Box>
    )
}

export default PDFBoxComponent