import { useState, useRef, useEffect } from 'react';
import {Typography, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, ListItemIcon, TextField, Stack} from '@mui/material';

import { useDrop, useDrag } from 'react-dnd'
import CircularProgress from '@mui/material/CircularProgress';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

import { useAlertDialog } from "../../../../utils/common-alert-dialog";

const SigneeCardComponent = ({signee, index, onMoveCard, hasSigningOrder, onEditSignee, onDeleteSignee}) => {
  
    const [showEdit, setShowEdit] = useState(false)    
    const [email, setEmail] = useState(signee.email_address)    

    const alertDialog = useAlertDialog()

    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: "signees",
        collect(monitor) {
            return {handlerId: monitor.getHandlerId()}
        },
        hover(item, monitor) {
            if (!ref.current) return

            const dragIndex = item.index
            const hoverIndex = index

            if (dragIndex === hoverIndex) return

            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
    
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

            onMoveCard(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: "signees",
        item: () => {
        return { signee, index }
        },
        canDrag: hasSigningOrder,
        collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        }),
    }, [hasSigningOrder])

    drag(drop(ref))

    const handleOnDeleteSignee = () => {
        let options = {
            variant: 'warning',
            title: 'Delete Signee',
            message: "Removing Signee will remove also all its assigned fields. Are you sure you want delete?",
            confirmBtn: {
                text: 'Yes',
            },
            cancelBtn: {
                text: 'Cancel',
            }
        }
    
        alertDialog?.confirm(options)
        .then(()=>{
            onDeleteSignee(signee)
        })
        .catch(()=>{
        })
    }

    const handleShowEditSignee = () => {
        setShowEdit(true)
    }

    const handleOnChangeInputEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleOnEditSignee = () => {
        onEditSignee(email, signee)
        setShowEdit(false)
    }

    const handleCloseEdit = () => {
        setShowEdit(false)
    }

    return(
        <>
            {
                showEdit ? 
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    p={2}
                    sx={{border: '1px solid grey'}}x
                    m={1}
                >
                    <TextField label="Email" variant="standard" fullWidth placeholder='Enter email' value={email} onChange={handleOnChangeInputEmail} />
                    <IconButton onClick={handleOnEditSignee}><CheckIcon /></IconButton>
                    <IconButton onClick={handleCloseEdit}><CloseIcon /></IconButton>
                </Stack>
                :
                <List ref={ref} sx={{border: '1px solid grey', cursor: hasSigningOrder ? 'all-scroll' : 'context-menu' , m: 1}}>
                    <ListItem>
                        <ListItemIcon><DragIndicatorOutlinedIcon sx={{color:'#168401'}}/></ListItemIcon>
                        <ListItemText primary={<Typography variant="body">{signee.email_address}</Typography>} />
                        <ListItemSecondaryAction>
                            {/* <IconButton onClick={handleShowEditSignee}><EditIcon /></IconButton> */}
                            <IconButton onClick={handleOnDeleteSignee}><DeleteIcon sx={{color:'#168401'}}/></IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            }
        </>
    )
}

export default SigneeCardComponent