import { Box, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { log } from '../../console-config';

function AnalyticsAllComponent ({dataForChart}) {

    log('get date chart data here', dataForChart)

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        
        return (
          <g transform={`translate(${x},${y})`}>
            <text x={0} y={0}dy={16} textAnchor="end"fill="#666"transform="rotate(-35)"style={{fontSize: 11}}>
              {payload.value}
            </text>
          </g>
        );
    };

    return(
        <Box sx={{textAlign:'center'}} >
            {dataForChart.length == 0 ?
            <Typography sx={{color:'gray', padding: 4}} >No records.</Typography>
            :
            <ResponsiveContainer width="100%" height={400} className="GraphAll">
                <LineChart
                    width={500}
                    height={300}
                    data={dataForChart}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" height={50} tick={<CustomizedAxisTick />}/>
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="TOTAL" stroke="#7869e1" activeDot={{ r: 8 }} />
                    {/* <Line type="monotone" dataKey="pending" stroke="#82ca9d" />
                    <Line type="monotone" dataKey="completed" stroke="#82ca9d" /> */}
                </LineChart>
            </ResponsiveContainer>
            }
        </Box>
        
    )
}
export default AnalyticsAllComponent