import React from 'react';

import { Dialog, DialogContent, DialogTitle, DialogActions, Divider, 
    List, ListItem, ListItemAvatar, ListItemText, Typography, Button } from '@mui/material';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



const AlertDialogComponent = (props) => {

    const { open, handleCloseAlertDialog, alertConfig } = props
    const { variant, title, message, dialogType, okayBtn, cancelBtn, confirmBtn } = alertConfig

    return (
        <div>
          <Dialog open={open} fullWidth maxWidth="xs"
              PaperProps={{
                  style: {
                    borderRadius: 10,
                  },
                }}
              >
              <DialogTitle id="checkout-dialog" 
                  style={{
                      background:
                          variant == 'error' ? 
                          '#ef5350'
                          :
                          variant == 'warning' ?
                          '#e3a212'
                          :
                          variant == 'info' ?
                          '#126fe3'
                          :
                          variant == 'success' ?
                          '#126fe3'
                          :
                          null
                  }} 
                  onClose={() => handleCloseAlertDialog('cancel')}>
                  
                  <List disablePadding>
                      <ListItem style={{padding: 0}}>
                          <ListItemAvatar>
                          {
                              variant == 'error' ? 
                                  <HighlightOffIcon style={{fontSize: 35, color: 'white'}} />
                              :
                              variant == 'warning' ?
                              <WarningIcon style={{fontSize: 35, color: 'white'}} />
                              :
                              variant == 'info' ?
                              <InfoIcon style={{fontSize: 35, color: 'white'}} />
                              :
                              variant == 'success' ?
                              <CheckCircleIcon style={{fontSize: 35, color: 'white'}} />
                              :
                              null
                          }
                          </ListItemAvatar>
                          <ListItemText 
                              primary={
                                  <Typography style={{color: 'white'}} component="h6" variant="h6">
                                      {
                                          variant == 'error' ? 
                                              'Error'
                                          :
                                          variant == 'warning' ?
                                              'Warning'
                                          :
                                          variant == 'info' ?
                                              'Info'
                                          :
                                          variant == 'success' ?
                                              'Success'
                                          :
                                          null
                                      }
                                  </Typography>
                              }
                          />
                      </ListItem>
                  </List>
              </DialogTitle>
              <DialogContent>
                  <Typography style={{textAlign:'center', fontWeight: 800, padding: 10}} component="h5" variant="h5">{title}</Typography>
                  <Typography style={{textAlign:'center', fontSize: 16, padding: 10}}>
                      {message}
                  </Typography>
              </DialogContent>
              <br />
              <DialogActions divider style={{padding: 10}}>
                  {
                      dialogType == 'alert' ? 
                          <>
                            <Button 
                                onClick={() => handleCloseAlertDialog('cancel')} 
                                style={{
                                    backgroundColor: okayBtn?.backgroundColor, 
                                    color: okayBtn?.textColor
                                }} 
                                variant={
                                    okayBtn?.variant
                                } 
                                startIcon={okayBtn?.startIcon} 
                                endIcon={okayBtn?.endIcon}>
                                {okayBtn?.text}
                            </Button>
                          </>
                      :
                      <>
                          <Divider />
                          <Button 
                              onClick={() => handleCloseAlertDialog('cancel')} 
                              style={{
                                  backgroundColor: cancelBtn?.backgroundColor, 
                                  color: cancelBtn?.textColor
                              }} 
                              variant={cancelBtn?.variant} 
                              startIcon={cancelBtn?.startIcon} 
                              endIcon={cancelBtn?.endIcon}>
                              {cancelBtn?.text}
                          </Button>
                          <Button 
                              onClick={() => handleCloseAlertDialog('confirm')} 
                              style={{
                                  backgroundColor: confirmBtn?.backgroundColor, 
                                  color: confirmBtn?.textColor
                              }} 
                              variant={confirmBtn?.variant} 
                              startIcon={confirmBtn?.startIcon} 
                              endIcon={confirmBtn?.endIcon}>
                              {confirmBtn?.text}
                          </Button>
                      </>
                  }
              </DialogActions>
          </Dialog>
        </div>
      );
}

export default AlertDialogComponent