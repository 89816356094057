import { v4 as uuidv4 } from 'uuid';

import { getReverseProportionDimens, getReverseProportionCoords, getProportionalCoords, getProportionalDimens, getProportionalFontSize, getReverseProportionFontSize } from "../../utils/ProportionDimenUtil";
import { pdfPageSizes } from "../common/staticVariables";

import { log } from '../../../console-config';

export const useElementHook = (dndRef, currentPage) => {

    let dropRefCurrentWidth = dndRef?.current?.clientWidth
    let dropRefCurrentHeight = dndRef?.current?.clientHeight
    let rotation = currentPage?.rotate
    let isLandscape = rotation == 90 || rotation == 270 ? true : false

    //TODO create rotation condition
    let pageOrigWidth = isLandscape ? currentPage?.originalHeight : currentPage?.originalWidth
    let pageOrigHeight = isLandscape ? currentPage?.originalWidth : currentPage?.originalHeight

    const createElement = (page_no, type, width, height, x, y, value, signeeId) => {
        let {w, h} = getReverseProportionDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, width, height)
        log("width aaa", width);
        log("height aaa", height);
        log("w aaa", w);
        log("h aaa", h);
        return {id: uuidv4(), page_no, type, width: w, height: h, origWidth: width, origHeight: height, x, y, value, ...(signeeId && {signeeId})}
    }

    const getProportionalElementCoords = (x, y) => {
        return getProportionalCoords(dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, x, y)
    }

    const getProportionalElementDimens = (width, height) => {
        log("element width aa", width);
        log("element height aa", height);
        log("dropRefCurrentWidth aa", dropRefCurrentWidth);
        log("dropRefCurrentHeight aa", dropRefCurrentHeight);
        log("pageOrigWidth aa", pageOrigWidth);
        log("pageOrigHeight aa", pageOrigHeight);

        return getProportionalDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, width, height)
    }

    const getReverseProportionElementCoords = (propX, propY, dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOriginalWidth, pageOriginalHeight) => {
        log("dropRefCurrentWidth 11", dropRefCurrentWidth);
        log("dropRefCurrentHeight 11", dropRefCurrentHeight);
        log("pageOriginalWidth 11", pageOriginalWidth);
        log("pageOriginalHeight 11", pageOriginalHeight);
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
        console.log("isLandscape 11xxx", isLandscape, rotation, propX);

        let pageWidth = isLandscape ? pageOriginalHeight : pageOriginalWidth
        let pageHeight = isLandscape ? pageOriginalWidth : pageOriginalHeight
        
        log("pageWidth 11", pageWidth);
        log("pageHeight 11", pageHeight);
        log("aaax 11", propX);
        log("aaay 11", propY);

        log("aaax 22", propX);
        log("aaay 22", propY);

        let {x,y} = getReverseProportionCoords(dropRefCurrentWidth, dropRefCurrentHeight, pageWidth, pageHeight, propX, propY)
        log("aaax 333", x);
        log("aaay 333", y);
        
        return {x,y}
    }

    const getReverseProportionElementDimens = (rotation, dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, width, height) => {
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
        console.log("isLandscape 11xxx", isLandscape, rotation);

        let pageWidth = isLandscape ? pageOrigHeight : pageOrigWidth
        let pageHeight = isLandscape ? pageOrigWidth : pageOrigHeight

        let {w, h} = getReverseProportionDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageWidth, pageHeight, width, height)
        return {w,h}
    }

    const getProportionalElementFontSize = (rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, font_size) => {
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
        let pageWidth = isLandscape ? pageOrigHeight : pageOrigWidth

        return getProportionalFontSize(dropRefCurrentWidth, pageWidth, font_size)
    }

    const getReverseProportionalElementFontSize = (rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, font_size) => {
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
        let pageWidth = isLandscape ? pageOrigHeight : pageOrigWidth

        return getReverseProportionFontSize(dropRefCurrentWidth, pageWidth, font_size)
    }

    return {createElement, getProportionalElementCoords, getProportionalElementDimens, getReverseProportionElementCoords, getReverseProportionElementDimens, 
        getProportionalElementFontSize, getReverseProportionalElementFontSize}
}