import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";


// background-color: #a4508b;
// background-image: linear-gradient(326deg, #a4508b 0%, #5f0a87 74%);
// background-color: #f6d327;
// background-image: linear-gradient(315deg, #f6d327 0%, #de4daa 74%);
// background-color: #f6fba2;
// background-image: linear-gradient(315deg, #f6fba2 0%, #20ded3 74%);
// background-color: #36096d;
// background-image: linear-gradient(315deg, #36096d 0%, #37d5d6 74%);

function AnalyticsCardComponent ({all, drafts, pending, completed, handleScrollToCompleted, handleScrollToPending, handleScrollToDraft, handleScrollToAll}) {

    return(
        <>
            <Card className="AllDocument" onClick={handleScrollToAll} sx={{backgroundColor:'#a4508b', backgroundImage: "linear-gradient(326deg, #a4508b 0%, #6d6ff7 75%)", p: 2}}>
                <CardActionArea >
                    <Typography sx={{fontSize: 120, fontWeight:"bold", color: "#fff", textAlign:'center', opacity: 0.05}}>
                        {all}
                    </Typography>
                    <CardContent  sx={{pl: 4, mt: "-50%"}}>
                        <Typography sx={{fontSize: 14, fontWeight:"bold", color: "#fff"}}>
                            All Documents
                        </Typography>
                        <Typography sx={{fontSize: 70, fontWeight:"bold", color: "#fff"}}> 
                            {all}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Card className="TotalDrafts" onClick={handleScrollToDraft} sx={{backgroundColor:'#f6d327', backgroundImage: "linear-gradient(315deg, #f6d327 0%, #de4daa 75%)", p: 2}}>
                <CardActionArea>
                    <Typography sx={{fontSize: 120, fontWeight:"bold", color: "#fff", textAlign:'center', opacity: 0.05}}>
                        {drafts}
                    </Typography>
                    <CardContent  sx={{pl: 4, mt: "-50%"}}>
                        <Typography sx={{fontSize: 14, fontWeight:"bold", color: "#fff"}}>
                            Draft Documents
                        </Typography>
                        <Typography sx={{fontSize: 70, fontWeight:"bold", color: "#fff"}}> 
                            {drafts}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Card className="TotalPending" onClick={handleScrollToPending} sx={{backgroundColor:'#f6fba2', backgroundImage: "linear-gradient(315deg, #f6fba2 0%, #20ded3 75%)", p: 2}}>
                <CardActionArea >
                    <Typography sx={{fontSize: 120, fontWeight:"bold", color: "#fff", textAlign:'center', opacity: 0.05}}>
                        {pending}
                    </Typography>
                    <CardContent  sx={{pl: 4, mt: "-50%"}}>
                        <Typography sx={{fontSize: 14, fontWeight:"bold", color: "#fff"}}>
                            Pending Documents
                        </Typography>
                        <Typography sx={{fontSize: 70, fontWeight:"bold", color: "#fff"}}> 
                            {pending}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Card className="TotalCompleted" onClick={handleScrollToCompleted} sx={{backgroundColor:'#36096d', backgroundImage: "linear-gradient(315deg, #36096d 0%, #37d5d6 75%)", p: 2}}>
                <CardActionArea >
                    <Typography sx={{fontSize: 120, fontWeight:"bold", color: "#fff", textAlign:'center', opacity: 0.05}}>
                        {completed}
                    </Typography>
                    <CardContent  sx={{pl: 4, mt: "-50%"}}>
                        <Typography sx={{fontSize: 14, fontWeight:"bold", color: "#fff"}}>
                            Completed Documents
                        </Typography>
                        <Typography sx={{fontSize: 70, fontWeight:"bold", color: "#fff"}}> 
                            {completed}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    )
}

export default AnalyticsCardComponent