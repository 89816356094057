import React, {useState, useEffect} from "react";
import {Button, Box, Card, Grid, TextField, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import SignatureTextToImage from "./signature-text-to-image.component";
import useSignature from "../../../hooks/useSignature";
import {ThreeDots} from "react-loader-spinner";
import AccountService from "../../../services/api/accounts";

const ChangeSignatureByTypeComponent = ({props}) => {

    const {handleCloseSignatureDialog, breakpoints, isPublic, email_address} = props;

    const {enqueueSnackbar} = useSnackbar();

    const {convertUrlToFile, uploadSignature, uploadSignaturePublic, isUploadingSignature} = useSignature();

    let accessToken = (new URLSearchParams(window.location.search)).get("token");

    const [selectedKey, setSelectedKey] = useState(null);
    const [selectedType, setSelectedType] = useState(false);
    const [signatureText, setSignatureText] = useState(null);
    const [signatureTextBase64, setSignatureTextBase64] = useState(null);
    const [parentFontSize, setFontSize] = useState("");
    const [parentFontStyle, setFontStyle] = useState("");
    const [parentFontFamily, setFontFamily] = useState("");
    const [errorLimit, setErrorLimit] = useState('');

    const handleTextFieldChange = (e) => {
        const inputValue = e.target.value;
        setErrorLimit('')
        if (inputValue.length <= 15) {
            setSignatureText(inputValue);
            if (inputValue === "") {
                setFontStyle("");
                setSelectedKey(null);
                setSelectedType(false);
            }
        } else {
            setErrorLimit(<p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>{"Supports only 15 character."}</p>)
        }
    }

    const handleSelectSignatureType = (e, key, fstyle, fsize, ffamily) => {
        setSelectedType(true);
        setSelectedKey(key);
        setFontSize(fsize);
        setFontStyle(fstyle);
        setFontFamily(ffamily);
    }

    const sendDataToParent = (child) => {
        setSignatureTextBase64(child);
    }

    const handleUploadSignature = (e) => {
        if (selectedType) {
            convertUrlToFile(signatureTextBase64, 'signatureFile.png', 'image/png').then(function(file) { 
                let formData = new FormData();

                if (isPublic) {
                    formData.append("email_address", email_address);
                    formData.append("signature", file);
                    uploadSignaturePublic(formData, accessToken, handleCloseSignatureDialog)
                } else {
                    formData.append("signature_url", file);
                    uploadSignature(formData, handleCloseSignatureDialog);
                }
            })

        } else {
            enqueueSnackbar("Please select signature type", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }    
    }

    useEffect(() => {
        const getSignatureUpdate = async () => {
          try {
            let isRemoveCache = true;
            
            await AccountService.retrieveSignature(isRemoveCache)
          
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        if (isUploadingSignature) getSignatureUpdate()
    }, [isUploadingSignature])


    return (
        <Box sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? "100%" : "85.5%" , margin: "auto"}}>
            <Grid container direction="column" sx={{mt: 1}}>
                <Grid item xs={12}>
                    <TextField 
                        fullWidth
                        variant="outlined" 
                        autoComplete="off"  
                        placeholder="Type your signature here"
                        inputProps={{maxLength: 50}}
                        value={signatureText}
                        helperText={(errorLimit) && errorLimit}
                        onChange={(e) => handleTextFieldChange(e)}
                    />
                </Grid>

                <Grid item xs={12} sx={{mt: 4}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Card key={1} sx={{cursor: (signatureText) ? "pointer" : "default", border: (selectedType && selectedKey == 1) ? "2px solid #0178f2" : "1px solid #8080807a", borderRadius: 2, padding: (breakpoints == 'xs' || breakpoints == 'sm') ? "8px" : "12px 24px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", ":hover": {backgroundColor: (signatureText) ? "#0178f221" : null}}}
                                onClick={(e) => (signatureText) ? handleSelectSignatureType(e, 1, "italic bold", 250, "Antically") : null}
                            >
                                <Typography variant="h6" sx={{fontFamily: "Antically !important", fontStyle: "italic", fontSize: 22, fontWeight: "bold"}}>
                                    {(signatureText) ? signatureText : "Signature"}
                                </Typography>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card sx={{cursor: (signatureText) ? "pointer" : "default", border: (selectedType && selectedKey == 2) ? "2px solid #0178f2" : "1px solid #8080807a", borderRadius: 2, padding: (breakpoints == 'xs' || breakpoints == 'sm') ? "8px" : "12px 24px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", ":hover": {backgroundColor: (signatureText) ? "#0178f221" : null}}}
                                onClick={(e) => (signatureText) ? handleSelectSignatureType(e, 2, "", 250, "Adinda Melia") : null}
                            >
                                <Typography variant="h6" sx={{fontFamily: "Adinda Melia !important", fontWeight: 500, fontSize: 22}}>
                                    {(signatureText) ? signatureText : "Signature"}
                                </Typography>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card sx={{cursor: (signatureText) ? "pointer" : "default", border: (selectedType && selectedKey == 3) ? "2px solid #0178f2" : "1px solid #8080807a", borderRadius: 2, padding: (breakpoints == 'xs' || breakpoints == 'sm') ? "8px" : "12px 24px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", ":hover": {backgroundColor: (signatureText) ? "#0178f221" : null}}}
                                oonClick={(e) => (signatureText) ? handleSelectSignatureType(e, 3, "italic", 250, "JustSignature") : null}
                            >
                                <Typography variant="h6" sx={{fontWeight: 500, fontSize: 20, fontStyle: "italic", fontFamily: "JustSignature !important"}}>
                                    {(signatureText) ? signatureText : "Signature"}
                                </Typography>
                            </Card> 
                        </Grid>

                        <Grid item xs={6}>
                            <Card sx={{cursor: (signatureText) ? "pointer" : "default", border: (selectedType && selectedKey == 4) ? "1.5px solid #0178f2" : "1px solid #8080807a", borderRadius: 2, padding: (breakpoints == 'xs' || breakpoints == 'sm') ? "8px" : "12px 24px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", ":hover": {backgroundColor: (signatureText) ? "#0178f221" : null}}}
                                onClick={(e) => (signatureText) ? handleSelectSignatureType(e, 4, "500", 250, "Autograf") : null}
                            >
                                <Typography variant="h6" sx={{fontFamily: "Autograf !important", fontSize: 22, fontWeight: "500"}}>
                                    {(signatureText) ? signatureText : "Signature"}
                                </Typography>
                            </Card>  
                        </Grid>
                    </Grid>
                </Grid>
               
                {(selectedType && signatureText) &&
                    <Box sx={{mt: 3}}>
                        <SignatureTextToImage signatureText={signatureText} 
                            sendDataToParent={sendDataToParent} parentFontSize={parentFontSize}
                            parentFontStyle={parentFontStyle} parentFontFamily={parentFontFamily}
                        />
                    </Box>
                }
                
                <Box sx={{paddingTop: 6, paddingBottom: 2, textAlign: "end"}}>
                    <Button onClick={handleCloseSignatureDialog} variant="outlined" sx={{width: 120, borderRadius: 10, color: '#3333cf', borderColor: "#3333cf4f !16840100" }} >
                        Close
                    </Button>
                    &nbsp;
                    <Button variant="contained" sx={{width: isUploadingSignature ? 180 : 120, backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                        onClick={(e) => (isUploadingSignature) ? null : handleUploadSignature(e)}
                        startIcon={isUploadingSignature && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />} 
                    >
                       {isUploadingSignature ? "Saving" : "Save"} 
                    </Button>
                </Box>
            </Grid>
        </Box>
    )
    
}

export default ChangeSignatureByTypeComponent