import {Skeleton, Card, CardContent, Grid} from "@mui/material";


function ProfileDetailsSkeletonComponent(props) {

    const {breakpoints} = props;

    return(
        <Card sx={{borderRadius: 2, padding: 3, paddingTop: 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints == 'xs' || breakpoints == 's' ? 'auto' : 458}}>
            <CardContent>
                <Grid container spacing={3} sx={{textAlign:'center', margin: 'auto', justifyContent: 'center', alignSelf: 'center'}}>
                    <Grid item xs={8} sm={10} ></Grid>
                    <Grid item xs={4} sm={2} sx={{textAlign: 'end', margin:'auto'}}>
                        <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)',}} />
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <Grid container spacing={2} sx={{justifyContent: 'center', alignSelf: 'center'}}>
                                <Skeleton variant="circular" width={120} height={120}  style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                            <Grid item xs={12}>
                                <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>  
        
    )
    
}

export default ProfileDetailsSkeletonComponent