import { Box, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid, Button } from "@mui/material"
import ReportRowListComponent from "./report-row-list.component"
import ReportListSkeleton from "../skeleton/report-list-skeleton.component"
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import { log } from "../../console-config";
import { Download } from "@mui/icons-material";
import ExtractReportConfirmationModal from "./dialog/extract-report-confirmation-modal.component";
import { useState } from "react";

function TableHeader(){

    const tablecell = [ 
        {label : 'Document'},
        {label : 'Status'},
        {label : 'No. of Documents'},
    ]

    return(
        <TableHead >
            <TableRow>
                {tablecell.map((value)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, backgroundColor: '#3333cf', color: 'white'}}>{value.label}</TableCell>
                        
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

const ReportComponent = (props) => {

    const {mappedData, mappedDataX, loading, monthValue, year} = props;
    const [openExtractConfirmation, setOpenExtractConfirmation] = useState(false);

    const handleOpenExtractConfirmation = () => {
        setOpenExtractConfirmation(true)
    }

    const handleCloseModal = () => {
        setOpenExtractConfirmation(false)
    }

// Create an object to store the counts for each unique "count" value
    const countCounts = {};

// Iterate through the array and count occurrences of each "count" value
    mappedData?.forEach(item => {
        const countValue = item.count;
        if (countCounts[countValue] === undefined) {
            countCounts[countValue] = 1;
        } else {
            countCounts[countValue]++;
        }
    });

// Get total count
    const totalCount = mappedData?.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.count;
    }, 0);

    const generatePDF = () => {
        const doc = new jsPDF();

        doc.text(`Document Report ${monthValue} ${year}`, 10, 10);
      
        const columns = ["Status", "Month", "No. of Documents", "Year"];
        const data = mappedDataX.map(item => [item.status, item.month, item.count, item.year]);

        const overallTotal = mappedDataX.reduce((total, item) => total + item.count, 0);
      
        doc.autoTable({
          head: [columns],
          body: data,
          startY: 20
        });
        doc.setFontSize(12); // Set the font size to 12

        
        // Calculate the position to display the "Overall Total" text below the right side of the table
        const pageWidth = doc.internal.pageSize.getWidth();
        const textWidth = doc.getStringUnitWidth(`Overall Total: ${overallTotal}`) * 12; // 12 is the font size
        const textX = pageWidth - textWidth - -35; // Adjust the position as needed
        const textY = doc.autoTable.previous.finalY + 10; // Adjust the vertical position as needed

        doc.text(`Overall Total: ${overallTotal}`, textX, textY);

      
        doc.save('document-report.pdf');
      };

      const generatexlsx = () => {
        try {
            const wb = XLSX.utils.book_new();
    
            // Creating worksheet with merged cells for Month and Year in the first row
            const ws = XLSX.utils.aoa_to_sheet([[`Document Report ${monthValue} ${year}`, "", "", ""]]);
            ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }];
    
            // Adding headers in the second row
            XLSX.utils.sheet_add_aoa(ws, [["Status", "Month", "No. of Documents", "Year"]], { origin: 'A2' });
    
            // Adding data from mappedDataX starting from the third row
            const dataRows = mappedData.map(item => [item.status, item.month, item.count, item.year]);
            XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: 'A3' });
    
            XLSX.utils.book_append_sheet(wb, ws, `Logs`);
            const excelFileName = `Document Report ${monthValue} ${year}.xlsx`;
            XLSX.writeFile(wb, excelFileName);
            handleCloseModal()
        } catch (error) {
            console.error("Error generating Excel:", error);
            // Handle the error (e.g., show a message to the user)
        }

    };

    log('get total counts', totalCount)
    log('get mapped data for extraction', mappedDataX)

    return(
        <Box>
            <Box sx={{textAlign:'end', mb: 2}}>
                {mappedData == null ?
                    <Button size="small" disabled sx={{textTransform:'none', fontSize: 12, fontWeight:'bold'}}>
                       <Download sx={{height: 20, width: 20}}/> Extract
                    </Button>
                :
                    <Button size="small" onClick={handleOpenExtractConfirmation} sx={{textTransform:'none', fontSize: 12, fontWeight:'bold', color: '#168401'}}>
                        <Download sx={{height: 20, width: 20, color: '#168401'}}/> Extract
                    </Button>
                }
            </Box>
           
           <TableContainer component={Paper} sx={{border: "1px solid #8080807a", borderRadius: 1,  width: "100%", mb: 2}} className="DocumentOverview">
                <Table >
                    <TableHeader/>
                    {loading ? 
                    <ReportListSkeleton/>
                    :
                        <TableBody>
                            {mappedData?.map((list)=>(
                                <ReportRowListComponent list={list} monthValue={monthValue} year={year} />
                            ))}
                            
                        </TableBody>
                    }
                </Table>
                {!loading && mappedData === null && 
                    <Box sx={{margin:'auto', textAlign:'center', p: 5}}>
                        No record found.
                    </Box>
                }
            </TableContainer>
            <Typography className="TotalDocuments" sx={{textAlign:'end', fontWeight:'bold'}}>
                Total Documents: {totalCount === undefined ? 0 : totalCount}
            </Typography>

            <ExtractReportConfirmationModal generatePDF={generatePDF} generatexlsx={generatexlsx} openExtractConfirmation={openExtractConfirmation} handleCloseModal={handleCloseModal} />
        </Box>
    )
}

export default ReportComponent;