import { Box, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import useDocuments from "../../hooks/useDocuments";
import RecentFilesRowComponent from "./recent-files-row.component";
import RecentListSkeleton from "../skeleton/recent-list-skeleton.component";


function FilesTableHeader(){

    const filesTableHeader = [ 
        {label : 'Title'},
        {label : 'Date Created'},
    ]



    return(

        <TableHead >
            <TableRow >
                {filesTableHeader.map((value)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, backgroundColor: '#3333cf', color:'white'}}>{value.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>

    )
}


function RecentFilesComponent(props) {

    const {chosenTab, path} = props;

    const {loading, retrieveDocuments, setLoading, documentList, pagination, filterByStatus} = useDocuments();

    const [documentListArr, setDocumentListArr] = useState([])
    const [page, setPage] = useState(1);
    const [userDocument, setUserDocument] = useState(null);

    // page limit
    var limit = 6;

    const sendDocumentToParent = (child) => { setUserDocument(child); }

    useEffect(() => {
        if (documentList?.length > 0) {
            // console.log("documentList vvv", documentList);
            let document_list = documentList?.map((item) => {
                // console.log("item vvv", item);

                let document = item.document
                let total_signed = document.documentSignatory.filter((item) => item.status == "DONE").length
                let total_signatory = document.documentSignatory.length
                document.signed = total_signed + "/" + total_signatory
                document.total_signed = total_signed 
                document.total_signatory = total_signatory
                return document
            })
            setDocumentListArr(document_list)
        }
    }, [JSON.stringify(documentList)])



    useEffect(() => { getDocumentList(); },[])


    const getDocumentList = () => {
        if (chosenTab == "DRAFT") {
            getDocumentsByStatus("DRAFT", page);
        } else if (chosenTab == "PENDING") {
            getDocumentsByStatus("PENDING", page);
        } else if (chosenTab == "COMPLETED") {
            getDocumentsByStatus("COMPLETED", page);
        } else {
            retrieveDocuments(page, limit);
        }
    }


    const getDocumentsByStatus = (oid, status) => {  filterByStatus(oid,status); }

    return(

        <Box>
            <TableContainer component={Paper} sx={{border: 'none', borderRadius: 2,  width: '100%', height: 400}}>
                <Table stickyHeader aria-label="sticky table">
                    <FilesTableHeader />
                    {loading ?
                        <TableBody><RecentListSkeleton /></TableBody>
                    :
                        <TableBody>
                            {documentListArr.map((document, index) => {
                                return (
                                    <RecentFilesRowComponent document={document} sendDocumentToParent={sendDocumentToParent} />
                                )
                            })}
                        </TableBody>
                    }
                </Table>

                <Grid container spacing={2} sx={{justifyContent: 'center', alignSelf: 'center'}}>
                    {(!loading && documentListArr.length === 0) ?
                        <Typography style={{color: "rgba(0, 0, 0, 0.54)", fontWeight: 600, fontSize: "14px", marginTop: 50, marginBottom: 30, textAlign:'center'}} >
                            {(chosenTab == "") ? 
                                "No documents available to display yet." 
                            : (chosenTab === "DRAFT")  ?
                                "No document(s) on Draft to display yet."
                            : (chosenTab === "PENDING")  ?
                                "No document(s) on Pending to display yet."
                            : (chosenTab === "COMPLETED")  ?
                                "No document(s) on Completed to display yet."
                            :
                                null
                            }
                        </Typography> 
                    :
                        null
                    }
                </Grid>


            </TableContainer>
        </Box>
    )
    
}

export default RecentFilesComponent