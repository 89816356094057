import { useEffect, useState } from "react";
import {Box, Button, CssBaseline, IconButton, Toolbar} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';

import getDesignTokens from './theme/design-token';
import PDFBoxComponent from './components/PDFBoxComponent';
import PDFBottomNavigationComponent from './components/PDFBottomNavigationComponent';
import ToolsPanelDrawerComponent from './components/ToolsPanelDrawerComponent';
import PDFEditorToolbarComponent from './components/PDFEditorToolbarComponent';
import ToolsPanelToolbarComponent from './components/ToolsPanelToolbarComponent';
import Main from './styled-components/Main';
import useWidth from './hooks/useWidth';
import AppBar from "./styled-components/AppBar";
import MenuIcon from '@mui/icons-material/Menu';
import { modifyAndDownloadPDF } from '../helpers/OVDocsPDFHelper';

import testPDF4 from "./pdf-with-diff-orientations.pdf";
import DrawerHeader from "../../layout/drawer-header";
import PDFEditorGuide from "../../components/walkthrough/pdf-editor-guide";
import { log } from "../../console-config";


const initialPdfResult = {
    filename: "",
    total_page: 0,
    elements: [],
    signatory: []
}

export default function PDFEditor({file_url, elements_to_plot, draggable_elements, draggable_fillable_elements, onSave, onCancel, onDocumentLoadSuccess, 
    onDocumentPageLoadSuccess, onEditElement, onDeleteElement, showPDF, displayedPDFType, onErrorAddSignee, onGetPDFResult, signatories, signatoryOptionLabel,
    savePDFButtonLabel, currentUser, getDocumentById, documentId, handleWalkthroughButtonClick, reopenJoyride, showWalkthrough, onAddSignee}) {

    log("PDFEditorScreen file_url: ", file_url)
    log("PDFEditorScreenelements_to_plot: ", elements_to_plot)
    log("PDFEditorScreen draggable_elements: ", draggable_elements)
    log("PDFEditorScreen signatories 11: ", signatories)
    log("getDocumentById", getDocumentById)
    log("documentId 111 ", documentId)

    let cachedElements = JSON.parse(localStorage.getItem("elements"));
    // let stored_deleted_elements = JSON.parse(localStorage.getItem("deleted_elements"));

    // const [cachedElementsToPlot, setCachedElementsToPlot] = useState(!cachedElements || !cachedElements[documentId] ? [] : cachedElements[documentId])

    // let hasDocumentCached = !cachedElements || !cachedElements[documentId] ? false : true;
    // log("hasDocumentCached aaa", hasDocumentCached)


    // let docElements = !cachedElements || !cachedElements[documentId] ? {elements: [], signatory: []} : cachedElements[documentId]; 
    // let pdfElements = hasDocumentCached ? [...docElements.elements] : elements_to_plot;

    // initialPdfResult.elements = pdfElements
    // initialPdfResult.signatory = pdfSignatories

    const breakpoints = useWidth();
    const [open, setOpen] = useState(true);
    const [pdfResult, setPDFResult] = useState(initialPdfResult);
    const [signees, setSignees] = useState(!cachedElements || !cachedElements[documentId] ? [] : cachedElements[documentId].signatory);
    const [pdf_title, setPDFTitle] = useState(null);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [draggableElements, setDraggableElements] = useState(draggable_elements);
    const theme = createTheme(getDesignTokens(isDarkMode ? 'dark' : 'light'));
    
    
    log("PDFEditorScreen pdfResult: ", pdfResult)
    log("signees xx: ", signees)

    useEffect(() => {
        if (pdfResult) {
            console.log("pdfResult aaa", pdfResult);
        }
    }, [JSON.stringify(pdfResult)])

    // Load draggable elements
    useEffect(() => {
        setDraggableElements(draggable_elements)
    }, [JSON.stringify(draggable_elements)])

    // Load elements to plot
    // useEffect(() => {
    //     let hasDocumentCached = !cachedElements || !cachedElements[documentId] ? false : true;
    //     let pdfElements = hasDocumentCached ? cachedElements[documentId].elements : elements_to_plot;
    //     setElementsToPlot(pdfElements)
    // }, [])

    // Load signees
    // useEffect(() => {
    //     let hasDocumentCached = !cachedElements || !cachedElements[documentId] ? false : true;
    //     let cachedSignatories = hasDocumentCached ? cachedElements[documentId].signatory : [];
    //     let _signees = [];

    //     if (cachedSignatories.length > 0) {
    //         cachedSignatories.forEach((item, i) => {
    //             let signee = {
    //                 id: uuidv4(),
    //                 email_address: item,
    //                 status: "DRAFT",
    //                 signature_url: "",
    //                 sequence: i
    //             }
    //             _signees.push(signee)
    //         })
    //     } else _signees = signatories

    //     setSignees(_signees)
    // }, [])

    const handleOnUpdateESignature = (signature) => {
        setDraggableElements((prev) => prev.map((item) => item.type == 'esignature' ? {...item, value: signature} : item))
    }

    async function handleOnClickDownloadPDF(url){
        log("url aa " + url);
        var elements_to_draw = pdfResult.elements;
        await modifyAndDownloadPDF(url, elements_to_draw);
    }

    function handleDrawerToggle(){
        setOpen(true);
    };

    function handleDrawerToggleClose(){
        setOpen(false);
    };

    function toggleDarkMode(){
        setIsDarkMode(isDarkMode ? false : true);
    };

    function handleOnSave(){
        // alert("Button heard you PDFEditorScreen!");
        onSave(pdfResult)
    }

    function handleOnCancel(){
        onCancel(pdfResult);
    }

    function handleOnDocumentLoadSuccess(pdf){
        log("hit handleOnDocumentLoadSuccess PDFEditorScreen")
        onDocumentLoadSuccess(pdf);
    }

    function handleOnDocumentPageLoadSuccess(page){
        log("hit handleOnDocumentPageLoadSuccess PDFEditorScreen")
        onDocumentPageLoadSuccess(page);
    }

    const handleOnDeleteElement = (element) => {
        onDeleteElement(element)
    }

    const handleOnEditElement = (element) => {
        onEditElement(element)
    }

    const handleOnAddToSignees = (signee) => {
        log("new signee aa", signee);

        // let _signees = [...signees, signee]
        // setSignees([...new Set(_signees)])

        const isExist = isSigneeAlreadyExist(signee.email_address);
      
        if (!isExist) {
            setSignees((prev) => [...prev, signee]);
        }
    };
      
    const handleOnErrorAddSignee = (error) => {
        log("new signee aa", error);
        onErrorAddSignee(error);
    };
    
    const isSigneeAlreadyExist = (email) => {
        const isExist = signees.some((item) => item.email_address === email);
        return isExist;
    };
      
    // const handleOnAddToSignees = (signee) => {
    //     log("new signee aa", signee);

    //     let isExist = isSigneeAlreadyExist(signee)

    //     if(isExist){
    //         handleOnErrorAddSignee("Email already exist");
    //     }else{
    //         setSignees((prev) => [...prev, signee])
    //     }
    // }

    // const handleOnErrorAddSignee = (error) => {
    //     log("new signee aa", error);
    //     onErrorAddSignee(error)
    // }

    // const isSigneeAlreadyExist = (email) => {

    //     let isExist = signees.some((item) => item.email_address == email )

    //     return isExist;

    // }

    const handleUpdateSignees = (signees) => {
        log("signees jjj", signees);
        // update signees
        setSignees(signees)

        // Set PDF Result after updating signing order
        // let filter_signees = signees.filter((item) => item.signee == pdfResult.signatory?.find((pdfSignee) => pdfSignee == item.email_address))
        // let pdf_signees = filter_signees.map((item) => item.email_address)

        // setPDFResult((prev) => ({...prev, signatory: pdf_signees}))
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', height:'90vh', width:"100%" , overflowX:'hidden', position: breakpoints == "xs" ? 'fixed' : ''}}>
            <CssBaseline />
                
                <Main sx={{bgcolor: 'lightgrey', overflow:'auto', display: 'grid', pt: 0}} open={open}>
                { breakpoints == 'xs' && 
                    <AppBar position="sticky">
                        <ToolsPanelToolbarComponent draggable_elements={draggableElements} getDocumentById={getDocumentById} draggable_fillable_elements={draggable_fillable_elements} 
                        signees={signees} onAddSignee={onAddSignee} onUpdateSignees={handleUpdateSignees}/>
                    </AppBar> }
                    <PDFBoxComponent 
                        documentId={documentId}
                        file_url={file_url} 
                        // cached_elements_to_plot={cachedElementsToPlot} 
                        elements_to_plot={elements_to_plot} 
                        pdfResult={pdfResult}
                        setPDFResult={setPDFResult}
                        onDocumentLoadSuccess={handleOnDocumentLoadSuccess}
                        onDocumentPageLoadSuccess={handleOnDocumentPageLoadSuccess}
                        onDeleteElement={handleOnDeleteElement}
                        onEditElement={handleOnEditElement}
                        setPDFTitle={setPDFTitle}
                        signees={signees}
                        setSignees={setSignees}
                        signeesOptionLabel={signatoryOptionLabel}
                        onUpdateSignees={handleUpdateSignees}
                        onAddToSignees={handleOnAddToSignees}
                        onUpdateESignature={handleOnUpdateESignature}
                        currentUser={currentUser}
                        onErrorAddSignee={handleOnErrorAddSignee}
                        getDocumentById={getDocumentById} handleWalkthroughButtonClick={handleWalkthroughButtonClick}
                        reopenJoyride={reopenJoyride} showWalkthrough={showWalkthrough}
                    />
                    { breakpoints == 'xs' && <PDFBottomNavigationComponent onSave={handleOnSave} onCancel={handleOnCancel} savePDFButtonLabel={savePDFButtonLabel} />  }
                </Main>
                {breakpoints != "xs" &&
                    <ToolsPanelDrawerComponent 
                        draggable_elements={draggableElements} 
                        draggable_fillable_elements={draggable_fillable_elements} 
                        open={open} 
                        onDrawerToggle={handleDrawerToggle} 
                        onSave={handleOnSave}
                        onCancel={handleOnCancel}
                        onUpdateSignees={handleUpdateSignees}
                        signees={signees}
                        savePDFButtonLabel={savePDFButtonLabel}
                        handleDrawerToggleClose={handleDrawerToggleClose}
                        getDocumentById={getDocumentById} handleWalkthroughButtonClick={handleWalkthroughButtonClick}
                    /> 
                }
            </Box>
        </ThemeProvider>
    );

}