import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpPost, HttpGet, HttpDelete, HttpPostMultipart, HttpPut, HttpPut1, HttpDownloadFile} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.ovsign_account_service.subscriptionKey;

const getReport = (year, month, page, limit) => {

    let parameters = {
        year: year,
        month: month,
        page: page,
        limit: limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_REPORT, parameters, subscriptionKey);

    return result;

}

const getReportMonthly = (status, year, month) => {

    let parameters = {
        status: status,
        year: year,
        month: month,
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_REPORT_MONTHLY, parameters, subscriptionKey);

    return result;

}

const ReportService = {
    getReport: getReport,
    getReportMonthly: getReportMonthly
}

export default ReportService
