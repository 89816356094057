

import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import ReportService from "../services/api/reports";
import { log } from "../console-config";

const useReports = () => {

  const {enqueueSnackbar} = useSnackbar();

    const [reportList, setReportList] = useState([])
    const [monthList, setMonthList] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(0);

    const getReport = (year, month, page, limit, setActiveState) => {
        setLoading(true);

        ReportService.getReport(year, month, page, limit).then(res => {

            setLoading(false);
            log('get report res here', res)
            if (res.data.success) {
                setReportList(res.data.data)
                if(res.data.data.authenticated_data?.length === 0){
                    setPagination(0)
                }else{
                    var last_page = Math.ceil(res?.data?.total / limit); //set it depending on ui return - 18
                    setPagination(last_page)
                }
            }
            if(res.data.error === "No Records Found"){
                setActiveState(null)
            }

        }).catch((error)=>{

            log("error herexxxx:", error)
            setActiveState(null)
            setLoading(false);

        })
    }

    const getReportMonthly = (status, year, month) => {
        setLoading(true)
        ReportService.getReportMonthly(status, year, month).then((res)=>{
            log('get report monthly res', res)
            setLoading(false)
            setMonthList(res.data.data)
        }).catch(()=>{
            setLoading(false)
        })
    }
 
    return {loading, getReport, pagination, reportList, getReportMonthly, monthList}

}

export default useReports;