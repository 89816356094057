import {Divider, Button, Box, IconButton, TextField, Typography} from '@mui/material';
import DraggableElementsPanelContainer from './DraggableElementsPanelContainer';
import Drawer from '../styled-components/Drawer';
import { drawerWidth } from '../common/staticVariables';
import DrawerHeader from '../styled-components/DrawerHeader';
import DraggableFillableElementsPanelContainer from './DraggableFillableElementsPanelContainer';
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import PDFEditorGuide from '../../../components/walkthrough/pdf-editor-guide';
import { log } from '../../../console-config';

export default function ToolsPanelDrawerComponent({handleDrawerToggleClose, draggable_elements, draggable_fillable_elements, open, onDrawerToggle, onSave, onCancel, signees, 
    onAddSignee, onUpdateSignees, savePDFButtonLabel, getDocumentById, handleWalkthroughButtonClick}){

    const navigate = useNavigate();
    
    function handleDrawerToggle(){
        onDrawerToggle();
    }

    function handleOnClickSave(){
        onSave()
    }

    function handleOnClickCancel(){
        onCancel()
    }

    log("draggable_elementsxxxxxxxxxxxx!!!!", draggable_elements)

    return(
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    bgcolor: 'tools_panel.background.default',
                    color: 'tools_panel.text.primary',
                    boxSizing: 'border-box',
                    whiteSpace: 'nowrap',
                    boxSizing: 'border-box',
                    padding: "0px 15px 15px 15px",
                    height:'100vh'
                },
            }}
            variant="permanent"
            anchor="right"
            open={open}
        >
            <DrawerHeader/><br/>
            
            <Box sx={{textAlign:'end'}}>
                {open ? 
                    <IconButton onClick={handleDrawerToggleClose} id="button-id">
                        <CloseIcon sx={{color:'#168401'}}  />
                    </IconButton>
                    : 
                    <IconButton color="inherit" aria-label="open drawer"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon sx={{color: "black"}} />
                    </IconButton> 
                }
            </Box>

                {/* <IconButton variant="contain" onClick={handleWalkthroughButtonClick}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton> */}
            
            {open && 
                <>
                <br/>
                <Typography sx={{pl: 1, fontWeight: "bold", mb: 1, fontSize: 18, color: '#0178f2', overflow:'hidden', textOverflow:'ellipsis'}}>
                    {getDocumentById?.title}
                </Typography>
                    {/* <TextField  
                        variant="filled"  
                        autoComplete="off"  
                        placeholder="Document Name"  
                        disabled
                        value={getDocumentById?.title} 
                        sx={{ml: 1, mr: 1}}
                        InputProps={{
                            disableUnderline: true, // <== added this
                            padding: 0
                            }}
                            inputProps={{
                            style: {
                                padding: 10,
                                color: "#21649e",
                                fontSize: 14
                            }
                            }}
                    /> */}
                    <br /> 
                    <DraggableElementsPanelContainer draggable_elements={draggable_elements} getDocumentById={getDocumentById} />
                    
                    <br /> <br /> <br />

                    <DraggableFillableElementsPanelContainer draggable_fillable_elements={draggable_fillable_elements} 
                        signees={signees} onAddSignee={onAddSignee} onUpdateSignees={onUpdateSignees} getDocumentById={getDocumentById}
                    />
                </>
            }

            <Box sx={{flexGrow: 1}} />
            
            {open &&
                <Box sx={{pb: 1}}>
                    <Button className="Send" variant="contained" sx={{width: '100%', backgroundColor: '#168401', borderRadius: 10, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', mb: 1.2, '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}} onClick={handleOnClickSave}>
                        {savePDFButtonLabel ? savePDFButtonLabel : "Save"}
                    </Button>
                    
                    <br/>

                    <Button className="Cancel" variant="outlined" sx={{width: '100%', borderRadius: 10, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderColor: "#3333cf4f !important" }} 
                        onClick={handleOnClickCancel}
                    >
                        Cancel
                    </Button>
                </Box>
            }
        </Drawer>
    )
}