

import { Avatar, Box, Button, Grid, IconButton, List, ListItem, ListItemText, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import SignedInLogsSkeletonComponent from "../skeleton/signed-logs-skeleton.component";
import AccountService from "../../services/api/accounts";
import { log } from "../../console-config";

function TableHeader(){

    const tableHeader = [ 
        {label : 'Document ID'},
        {label : 'Title'},
        {label : 'Status'},
        {label : 'Reference'},
        {label: 'Requested By'},
        {label : 'Date Requested'},
        {label : 'Date Signed'},
    ];

    return(
        <TableHead >
            <TableRow>
                {tableHeader.map((item, index)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, color: 'white', backgroundColor: '#3333cf', color: 'white'}}>{item.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

// , backgroundColor: '#3333cf', color: 'white'

function TableContent(props){

    const { data,} = props;
    const [userOidName, setUserOidName] = useState(null)

    const userOid = data?.documents?.oid

    const getSignedLogsOID = () => {
        let oid = data?.documents.oid
        let payload = {
            data: {
                oids: [userOid],
            }
        }
        AccountService.getUserSignedLogs(payload).then((res)=>{
            
            log('SIGNED LOGS OIS RES HEREEEEEEEEEEEE', res?.data);
            setUserOidName(res?.data?.userSignedInLogs[0]);
        }).catch((err)=>{
            log('get signed logs error here', err)
        })
    }

    useEffect(()=>{
        if(userOid){
            getSignedLogsOID();
        }
    },[userOid])
 
    return(
        <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}}>
            {/* <TableCell component="th" scope="row" sx={{padding: 2,}}> 
                {data?.userId}
            </TableCell> */}

            <TableCell align="left" sx={{color:'#0178f2', width: 130}}>
                {data?.documents.id}
            </TableCell>
            <TableCell align="left">
                {data?.documents.title}
            </TableCell>
            <TableCell align="left" sx={{color: data?.documents.status == 'PENDING' || data?.documents.status == 'pending' ? 'orange' : data?.documents.status == 'COMPLETED' || data?.documents.status == 'completed' ? 'green' : data?.documents.status == 'ERROR' || data?.documents.status == 'error' ? 'red' : 'inherit'}}>
                {data?.documents.status}
            </TableCell>
            <TableCell align="left">
                {data?.documents.reference}
            </TableCell>
            <TableCell align="left" > 
                { userOidName === null ? "" : userOidName.firstName + " " + userOidName.lastname}
            </TableCell>

            <TableCell align="left" > 
                {format(new Date(data?.created_time), 'PPpp')}
            </TableCell>
            <TableCell align="left" > 
                {format(new Date(data?.updated_time), 'PPpp')}
            </TableCell>
        </TableRow>
    )
}



function SignedLogsComponent(props) {

    const {breakpoints, signedList, loading, pagination, retrieveSignedLogs, email} = props;
    const [page, setPage] = useState(1)


    const handleChangePagination = (event, page) => {
        setPage(page);
        retrieveSignedLogs(email, page)
    }

    return(
        <Grid item xs={12} sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 3}}>
            <TableContainer className="HistoryTable" component={Paper} sx={{border: "1px solid #8080807a", borderRadius: 2,  width: "100%"}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader/>
                        {(loading) ? 
                            <TableBody>
                                <SignedInLogsSkeletonComponent/>
                            </TableBody>
                        :
                            <TableBody >
                                {signedList?.map((logs, index) => {
                                    return (
                                        <TableContent breakpoints={breakpoints}
                                        data={logs} index={index} />
                                    )
                                })}
                            </TableBody>
                        }
                        
                </Table>
                {!loading && signedList === null && 
                    <Box sx={{margin:'auto', textAlign:'center', p: 5}}>
                        No record found.
                    </Box>
                }
                {(!loading && signedList.length > 0) &&
                    <Pagination
                        count={pagination} 
                        page={page} 
                        onChange={handleChangePagination} 
                        style={{margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30}}
                    />
                }
            </TableContainer>
        </Grid> 

    )
    
}

export default SignedLogsComponent