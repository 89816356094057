import { log } from "../../../console-config";
import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGetWithCache, HttpGet, HttpPostMultipart, HttpPut, HttpPublicGet, CustomHttpPostMultipart, HttpPost, HttpGet1} from "../common/HttpRequestBuilder";
import { cache_strategy } from '../../../common/staticVariables';

const SUBSCRIPTION_KEY = environment.ovsign_account_service.subscriptionKey;
const CACHE_NAME = 'accounts_cache';

const retrieveUser = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    // let result = HttpGet(routes.RETRIEVE_USER, query_params, subscriptionKey);
    let result = HttpGetWithCache(routes.RETRIEVE_USER, query_params, subscriptionKey, null, cache_strategy.CacheFirst, CACHE_NAME, null);
    
    return result;
}

const updateUserInformation = (payload) => {

    log("update user information payload :", payload);

    let result = HttpPut(routes.UPDATE_USER_INFORMATION, payload);

    return result;
}

const retrieveSignature = (isRemoveCache = false) => {

    log("retrieve signature payload xxxx",  );

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let opts = {
        isRemoveCache
    };

    // let result = HttpGet(routes.RETRIEVE_SIGNATURE, query_params, subscriptionKey);
    let result = HttpGetWithCache(routes.RETRIEVE_SIGNATURE, query_params, subscriptionKey, null, cache_strategy.CacheFirst, CACHE_NAME, opts);

    return result;
    
}

const uploadSignature = (formData) => {
    let payload = formData;
    let subscriptionKey = SUBSCRIPTION_KEY;

    log("uploadSignature payload xxxx", payload);

    let result = HttpPostMultipart(routes.UPLOAD_SIGNATURE, payload, subscriptionKey);

    return result;
}

const getEsignatureAccessToken = () => {

    let query_params = null;
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ESIG_ACCESS_TOKEN, query_params, subscriptionKey);
    
    return result;
}

const getEsignatureTokenValidation = (accessToken) => {
    let subscriptionKey = SUBSCRIPTION_KEY;
    let parameters = "";

    let result = HttpPublicGet(routes.GET_ESIG_TOKEN_VALIDATION, parameters, accessToken, subscriptionKey);
    
    return result;
}

const createSignatureByLink = (formData, accessToken) => {

    let payload = formData;

    log("createSignatureByLink payload xxxx", payload, accessToken);

    let result = CustomHttpPostMultipart(routes.CREATE_SIGNATURE_BY_LINK, payload, accessToken);
    
    return result;
}

const uploadProfile = (formData) => {
    let payload = formData;
    let subscriptionKey = SUBSCRIPTION_KEY;

    log("uploadProfile payload xxxx", payload);

    let result = HttpPost(routes.UPLOAD_PROFILE, payload, subscriptionKey);

    return result;
}

const retrieveWalkthroughByOID = () => {
    let query_params = null;
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.RETRIEVE_WALKTHROUGH_BY_OID, query_params, subscriptionKey);
    
    return result;
}

const postUserWalkthrough = (is_walkthrough) => {
    let query_params = {
        is_walkthrough: is_walkthrough
    };
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPostMultipart(routes.POST_USER_WALKTHROUGH, query_params, subscriptionKey);
    return result;
}

const getUserSignedLogs = (payload) => {

    // let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.GET_USER_SIGNED_LOGS, payload);

    return result;
}


const AccountService = {
    retrieveSignature: retrieveSignature,
    uploadSignature: uploadSignature,
    retrieveUser: retrieveUser,
    updateUserInformation: updateUserInformation,
    getEsignatureAccessToken: getEsignatureAccessToken,
    getEsignatureTokenValidation: getEsignatureTokenValidation,
    createSignatureByLink: createSignatureByLink,
    uploadProfile: uploadProfile,
    retrieveWalkthroughByOID: retrieveWalkthroughByOID,
    postUserWalkthrough: postUserWalkthrough,
    getUserSignedLogs: getUserSignedLogs
}

export default AccountService