const getResponsiveFontSize = (defaultFontSize, defaultParentWidth, parentCurrentWidth) => {
    let adjFontSize = (defaultFontSize * parentCurrentWidth) / defaultParentWidth
    return adjFontSize.toString() + 'px'
}

const getAdjFontSize = (defaultFontSize, defaultParentWidth, parentCurrentWidth) => {
    let adjFontSize = (defaultFontSize * parentCurrentWidth) / defaultParentWidth
    return adjFontSize;
}

export {getResponsiveFontSize, getAdjFontSize}