import React, { useState } from 'react';
import ReactJoyride from 'react-joyride';
import ovsignLogo from "../../icons/nextixLogo.png"
import AccountService from '../../services/api/accounts';
import DocumentWorkflowService from '../../services/api/documents';
import { log } from '../../console-config';

const ReportGuide = ({showWalkthrough, setShowWalkthrough}) => {
  const [joyride, setJoyride] = useState({
    run: true,
    steps: [
      {
        content: 
        <div>
            <img src={ovsignLogo} style={{height: 30}}/>
            <p style={{fontSize: 19, color:'#168401', fontWeight:'bold'}}>
                Let's get started.
            </p>
        </div>,
        target: "body",
        placement: "center",
        // content: <div><p>You will be presented with an all-encompassing view, showcasing not only the total number of impeccably crafted documents but also offering a comprehensive display of analytics and real-time updates on the most recent transactions.</p></div>,
        disableBeacon: true,
      },
      {
        content: "Filter and display the document count based on their status for the month.",
        target: ".FilterMonth",
      },
      {
        target: ".FilterYear",
        content: "Filter and display the document count based on their status for the year.",
      },
      {
        target: ".DocumentOverview",
        content: "Seamlessly Monitor the Documents in Your Account in this Section.",
      },
      {
        target: ".TotalDocuments",
        content: "Display the total documents made this month.",
      },
    ]
  });

  const handleStepCallback = (data) => {
    const { status, type, action } = data;
    let module_id = "RPT";
    let is_walkthrough = 1
    if (action === 'close'|| action === 'skip' ) {
      DocumentWorkflowService.walkthroughUpdate(module_id, is_walkthrough).then((res)=>{
        log('update walkthrough res', res)
      })
      setShowWalkthrough(false)
    } else if (status === 'finished' &&  type === 'tour:end'){
      DocumentWorkflowService.walkthroughUpdate(module_id, is_walkthrough).then((res)=>{
        log('update walkthrough res', res)
      })
      setShowWalkthrough(false)
    }
    log('ACTIONSSSSSS', action)
  };


  return (<React.Fragment>
    <ReactJoyride
      steps={joyride.steps}
      run={showWalkthrough ? showWalkthrough : joyride.run}
      continuous
      showProgress
      showSkipButton
      disableScrolling={true}
      styles={{
        options: {
          arrowColor: '#fff',
          primaryColor: '#0178f2',
          textColor: '#000',
          width: 380,
          zIndex: 1250,
        },
        buttonNext: {
          backgroundColor: '#168401',
          borderRadius: 4,
          color: '#fff',
          borderColor: '#168401',
          outline: 'none',
        },
        buttonBack: {
          color: '#0178f2',
          marginLeft: 'auto',
          marginRight: 5,
        },
      }}
      locale={{
        skip: "Skip",
        last:"Done",
        
      }}
      callback={handleStepCallback} // Set the callback function
    />
  </React.Fragment>);
};

export default ReportGuide


