import { Box, Grid, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import DeleteFileDialog from "../dialog/delete-file-dialog.component"
import ResendEmailDialog from "../dialog/resend-email-dialog.component"
import FilesListComponent from "./files-list.component"
import useDocuments from "../../hooks/useDocuments";
import DocumentListSkeleton from "../skeleton/document-list-skeleton.component"
import ReactSelect from "react-select"
import FilterByAllComponent from "./filter-documents/filter-by-all.component"
import { log } from "../../console-config"
import useWidth from "../../hooks/useWidth"
import DocumentWorkflowService from "../../services/api/documents"
import { useSnackbar } from "notistack"


const filterStyles = { control: (styles) => ({ ...styles, backgroundColor: "white", minHeight: "30px", width: 300, fontSize: 12, color: 'black' }) };

const filterStatus = [
    { value: "DRAFT", label: "DRAFT", placeholder: "Status" },
    { value: "PENDING", label: "PENDING", placeholder: "Status" },
    { value: "COMPLETED", label: "COMPLETED", placeholder: "Status" },
    { value: "CANCELLED", label: "CANCELLED", placeholder: "Status" },
    { value: "DENIED", label: "DENIED", placeholder: "Status" },
];




function FilesTableHeader() {

    const filesTableHeader = [
        { label: 'Title' },
        { label: 'Signed' },
        { label: 'Status' },
        { label: 'Last Date Modified' },
        { label: 'Auto Reminder' },
        { label: 'Actions' },
    ]

    return (

        <TableHead >
            <TableRow sx={{ position: "sticky" }}>
                {filesTableHeader.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, backgroundColor: '#b2d6f9', color: 'black', textAlign: value.label == "Auto Reminder" && "center" }}>{value.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>

    )
}

const FilesComponent = (props) => {

    const { chosenTab } = props;
    const breakpoints = useWidth();
    const { enqueueSnackbar } = useSnackbar();
    const {
        retrieveDocuments, filterByStatus, searchDocuments, documentListFilter, documentListSearch, loading, pagination,
        searchPagination, filterPagination, setLoading, listOfDocuments, searchStatusDocuments, statusRes, statusSearchPagination
    } = useDocuments();

    const [page, setPage] = useState(1);
    const [title, setTitle] = useState('');
    const [selectedFilter, setSelectedFilter] = useState(false);
    const [selected, setSelected] = useState(null)
    const [filterLabel, setFiilterLabel] = useState(null);
    const [filter, setFilter] = useState([]);
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [monthValue, setMonthValue] = useState("");
    const [openDeleteFile, setOpenDeleteFile] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [documentName, setDocumentName] = useState(null);
    const [isFiltering, setIsFiltering] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [status, getStatus] = useState(null)


    useEffect(() => { retrieveDocuments(page) },[page])

    const getDocumentsByStatus = (status, page) => {
        setIsFiltering(true);
        filterByStatus(status, page);
        getStatus(status)
    }

    console.log('GET STATUS HEREEEEEEEEEEEE', status)

    const getDocumentList = () => {
        if (chosenTab == "DRAFT") {
            getDocumentsByStatus("DRAFT", page);
        } else if (chosenTab == "PENDING") {
            getDocumentsByStatus("PENDING", page);
        } else if (chosenTab == "COMPLETED") {
            getDocumentsByStatus("COMPLETED", page);
        } else if (chosenTab == "CANCELLED") {
            getDocumentsByStatus("CANCELLED", page);
        } else if (chosenTab == "DENIED") {
            getDocumentsByStatus("DENIED", page);
        } 
    };

    useEffect(() => { getDocumentList(); }, [])

   

    const handleChangePagination = (event, page) => {
        setPage(page);
        setLoading(true);

        if (chosenTab == "DRAFT") {
            getDocumentsByStatus("DRAFT", page);
        } else if (chosenTab == "PENDING") {
            getDocumentsByStatus("PENDING", page);
        } else if (chosenTab == "COMPLETED") {
            getDocumentsByStatus("COMPLETED", page);
        } else if (chosenTab == "CANCELLED") {
            getDocumentsByStatus("CANCELLED", page);
        } else if (chosenTab == "DENIED") {
            getDocumentsByStatus("DENIED", page);
        } else {
            if (filter || title || month || year) {
                searchDocuments(filter, title, monthName, year, page);
            } else {
                retrieveDocuments(page);
            }
        }
    }

    const filterDocuments = [
        chosenTab == "" && { label: 'Status', value: filterStatus },
        { label: 'Title', value: title },
    ].filter(Boolean);

    function generateArrayOfYears() {
        var current_year = new Date().getFullYear();
        var years = [];

        for (var i = 2022; i <= current_year; i++) {
            let year = { value: i };
            years.push(year);
        }

        return years;
    }

    var years = generateArrayOfYears();

    years.sort(function (a, b) {
        return b.value - a.value;
    });


    function getMonthNames() {
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        return monthNames.map((monthName, index) => {
            const monthValue = (index + 1).toString().padStart(2, '0');
            return {
                name: monthName,
                value: monthValue,
            };
        });
    }

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const monthName = monthNames[monthValue - 1];

    const handleMonthChange = (event) => {
        setIsSearching(true);
        setIsFiltering(true)
        const selectedMonth = event.target.value;
        const mName = monthNames[event.target.value - 1];
        const paddedMonth = selectedMonth.toString().padStart(2, '0');
        setMonth(paddedMonth);
        setMonthValue(selectedMonth);
        if (chosenTab !== "") {
            // setIsFiltering(true);
            // setIsSearching(true);
            searchDocuments(status, title, mName, year, page);
        } else {
            // setIsSearching(true);
            // setIsFiltering(false);
            searchDocuments(status, title, mName, year, page);
        }
        // searchDocuments(filter, title, mName, year, page);
    };

    const handleYearChange = (event) => {
        setIsSearching(true);
        setIsFiltering(true)
        setYear(event.target.value);
        const yearChange = event.target.value;
        if (chosenTab !== "") {
            // setIsFiltering(true);
            // setIsSearching(true);
            searchDocuments(status, title, monthName, yearChange, page);
        } else {
            // setIsSearching(true);
            // setIsFiltering(false);
            searchDocuments(status, title, monthName, yearChange, page);
        }
        // searchDocuments(filter, title, monthName, yearChange, page);
    };

    const handleSearchTitle = (e) => {
        setTitle(e.target.value)
    }

    const handleSelectFilter = (selectedOption) => {
        console.log('get selected filter value here', selectedOption)
        setIsSearching(true)
        setSelectedFilter(true)
        setLoading(true)
        setIsFiltering(true);
        setFilter(selectedOption.value);
        setFiilterLabel(selectedOption.label)
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            if (chosenTab == "") {
                setIsSearching(true);
                setIsFiltering(true);
                searchDocuments(filter, title, monthName, year, page);
            } else {
                setIsSearching(true);
                setIsFiltering(true);
                searchDocuments(status, title, monthName, year, page);
            }
        }
    }


    const handleSearchButton = (e) => {
        if (chosenTab !== "") {
            setIsFiltering(true);
            setIsSearching(true);
            searchDocuments(filter, title, monthName, year, page);
        } else {
            setIsSearching(true);
            setIsFiltering(false);
            searchDocuments(filter, title, monthName, year, page);
        }
    }

    console.log("documentlist here:", listOfDocuments)
    console.log("document filter here:", documentListFilter)
    console.log("document search here:", documentListSearch)

    const renderNoDocumentsMessage = () => {
        switch (chosenTab) {
            case "":
                return "No documents available to display yet.";
            case "DRAFT":
                return "No document(s) on Draft to display yet.";
            case "PENDING":
                return "No document(s) on Pending to display yet.";
            case "COMPLETED":
                return "No document(s) on Completed to display yet.";
            case "CANCELLED":
                return "No document(s) on Cancelled to display yet.";
            case "DENIED":
                return "No document(s) on Denied to display yet.";
            default:
                return null;
        }
    };

    // useEffect(() => {
    //     if(isFiltering){
    //         searchDocuments(filter, title, monthName, year, page);
    //     }else{
    //         retrieveDocuments()
    //     }
    //  }, [filter])

    return (
        <Box>
            <Grid container>
                <Stack direction="row" spacing={3} sx={{ width: '100%', margin: 'auto', mb: 2 }}>

                    {(chosenTab == "") ?
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3} sx={{ textAlign: breakpoints == 'xs' || breakpoints == 's' ? '-webkit-center' : "start" }}>
                                <ReactSelect
                                    className="basic-single"
                                    placeholder={"Select Filter"}
                                    isSearchable={true}
                                    isRtl={false}
                                    options={filterDocuments}
                                    onChange={selectedOption => handleSelectFilter(selectedOption)}
                                    styles={filterStyles}
                                />
                            </Grid>
                            <FilterByAllComponent
                                chosenTab={chosenTab} selectedFilter={selectedFilter} filter={filter} filterStyles={filterStyles} title={title}
                                handleSearchTitle={handleSearchTitle} setSelected={setSelected} filterLabel={filterLabel} setIsSearching={setIsSearching}
                                years={years} handleYearChange={handleYearChange} searchDocuments={searchDocuments} keyPress={keyPress} page={page}
                                handleMonthChange={handleMonthChange} getMonthNames={getMonthNames} month={month} year={year} monthName={monthName}
                            />
                        </Grid>
                        : (chosenTab === "DRAFT" || chosenTab === "PENDING" || chosenTab === "COMPLETED" || chosenTab === "CANCELLED" || chosenTab === "DENIED") ? (
                            <FilterByAllComponent
                                chosenTab={chosenTab} selectedFilter={selectedFilter} filter={filter} filterStyles={filterStyles} title={title}
                                handleSearchTitle={handleSearchTitle} setSelected={setSelected} filterLabel={filterLabel} years={years} handleYearChange={handleYearChange}
                                searchDocuments={searchDocuments} keyPress={keyPress} handleSearchButton={handleSearchButton} handleMonthChange={handleMonthChange}
                                getMonthNames={getMonthNames} month={month} year={year} monthName={monthName} page={page} setIsSearching={setIsSearching}
                            />
                        ) :
                            null
                    }
                </Stack>
            </Grid>
            <TableContainer component={Paper} sx={{ border: 'none', borderRadius: 2, width: '100%' }} className="FilesTable">
                <Table stickyHeader aria-label="sticky table">
                    <FilesTableHeader />
                    {loading ?
                        <TableBody><DocumentListSkeleton /></TableBody>
                        :
                        <TableBody>
                           {isFiltering ?
                                <>
                                    {(selectedFilter || isSearching )?(
                                        <>
                                            {documentListSearch.map((document, index) => (
                                                <FilesListComponent
                                                    key={index}
                                                    index={index}
                                                    document={document}
                                                    retrieveDocuments={retrieveDocuments}
                                                    idToDelete={idToDelete}
                                                    openDeleteFile={openDeleteFile}
                                                    setOpenDeleteFile={setOpenDeleteFile}
                                                    page={page}
                                                />
                                            ))}
                                        </>
                                    ):(
                                        <>
                                            {documentListFilter.map((document, index) => (
                                                <FilesListComponent
                                                    key={index}
                                                    index={index}
                                                    document={document}
                                                    retrieveDocuments={retrieveDocuments}
                                                    idToDelete={idToDelete}
                                                    openDeleteFile={openDeleteFile}
                                                    setOpenDeleteFile={setOpenDeleteFile}
                                                    page={page}
                                                />
                                            ))}
                                        </>
                                        )
                                    }
                                </>
                            :
                                <>
                                    {listOfDocuments && listOfDocuments.map((document, index) => (
                                        <FilesListComponent
                                            key={index}
                                            index={index}
                                            document={document}
                                            retrieveDocuments={retrieveDocuments}
                                            idToDelete={idToDelete}
                                            openDeleteFile={openDeleteFile}
                                            setOpenDeleteFile={setOpenDeleteFile}
                                            page={page}
                                        />
                                    ))}
                                </>
                            }

                        </TableBody>
                    }

                </Table>


                <Grid container spacing={2} sx={{ justifyContent: 'center', alignSelf: 'center' }}>
                    {!loading && (
                        <>
                            {(isSearching && documentListSearch?.length === 0) ||
                                (isFiltering && !isSearching && documentListFilter?.length === 0) ||
                                (!isSearching && !isFiltering && listOfDocuments?.length === 0) ? (
                                <Typography style={{ color: "rgba(0, 0, 0, 0.54)", fontWeight: 600, fontSize: "14px", marginTop: 50, marginBottom: 30, textAlign: 'center' }}>
                                    {renderNoDocumentsMessage()}
                                </Typography>
                            ) : null}

                            {/* {(listOfDocuments?.length === 0 || documentListFilter?.length === 0)  ? (
                                <Typography
                                    style={{
                                    color: "rgba(0, 0, 0, 0.54)",
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    marginTop: 50,
                                    marginBottom: 30,
                                    textAlign: 'center'
                                    }}
                                >
                                    {renderNoDocumentsMessage()}
                                </Typography>
                            ):(null)} */}
                        </>
                    )}


                </Grid>


                {!loading &&
                    <>
                        {isSearching ?
                            <>
                                {(!loading && documentListSearch?.length === 0 && chosenTab == "") || (!loading && documentListSearch?.length === 0 && chosenTab === "DRAFT") || (!loading && documentListSearch?.length === 0 && chosenTab === "PENDING") || (!loading && documentListSearch?.length === 0 && chosenTab === "COMPLETED") ?
                                    null
                                    :
                                    <Pagination
                                        count={searchPagination}
                                        page={page}
                                        onChange={handleChangePagination}
                                        style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                    />
                                }
                            </>
                            : (isFiltering) ?
                                <>
                                    {(!loading && documentListFilter?.length === 0 && chosenTab == "") || (!loading && documentListFilter?.length === 0 && chosenTab === "DRAFT") || (!loading && documentListFilter?.length === 0 && chosenTab === "PENDING") || (!loading && documentListFilter?.length === 0 && chosenTab === "COMPLETED") ?
                                        null
                                        :
                                        <Pagination
                                            count={filterPagination}
                                            page={page}
                                            onChange={handleChangePagination}
                                            style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                        />
                                    }
                                </>
                                : (isFiltering && isSearching) ?
                                    <>
                                        {(!loading && statusRes?.length === 0 && chosenTab == "") || (!loading && statusRes?.length === 0 && chosenTab === "DRAFT") || (!loading && statusRes?.length === 0 && chosenTab === "PENDING") || (!loading && statusRes?.length === 0 && chosenTab === "COMPLETED") ?
                                            null
                                            :
                                            <Pagination
                                                count={statusSearchPagination}
                                                page={page}
                                                onChange={handleChangePagination}
                                                style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                            />
                                        }
                                    </>
                                    :
                                    (!isFiltering && !isSearching) ?
                                    <>
                                        {(!loading && listOfDocuments?.length === 0 && chosenTab == "") || (!loading && listOfDocuments?.length === 0 && chosenTab === "DRAFT") || (!loading && listOfDocuments?.length === 0 && chosenTab === "PENDING") || (!loading && listOfDocuments?.length === 0 && chosenTab === "COMPLETED") ?
                                            null
                                            :
                                            <Pagination
                                                count={pagination}
                                                page={page}
                                                onChange={handleChangePagination}
                                                style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                            />
                                        }
                                    </>
                                    :
                                    null
                        }
                    </>
                }
            </TableContainer>


        </Box>
    )
}

export default FilesComponent