import {useState} from "react";
import DocumentWorkflowService from "../services/api/documents";
import DocumentService from "../services/api/documents";
import {useSnackbar} from "notistack";
import { convertUrlToFile } from "../ovdocs/utils/convertUrlToFileUtil";
import { log } from "../console-config";
import DocumentsPublicService from "../services/api/documents_public";

const useDocuments = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [documentListFilter, setDocumentListFilter] = useState([]);
    const [documentListSearch, setDocumentListSearch] = useState([]);
    const [listOfDocuments, setListOfDocuments] = useState([]);
    const [pagination, setPagination] = useState(0);
    const [filterPagination, setFilterPagination] = useState(0);
    const [searchPagination, setSearchPagination] = useState(0);
    const [created, setCreated] = useState(false)
    const [id, setId] = useState('')
    const [getDocumentById, setGetDocumentById] = useState(null);
    const [all, setAll] = useState(0);
    const [drafts, setDrafts] = useState(0);
    const [pending, setPending] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [allAnalytics, setAnalyticsCountAll] = useState(0);
    const [draftsAnalytics, setAnalyticsDrafts] = useState(0);
    const [pendingAnalytics, setAnalyticsPending] = useState(0);
    const [completedAnalytics, setAnalyticsCompleted] = useState(0);
    const [cancelledAnalytics, setAnalyticsCancelled] = useState(0);
    const [deniedAnalytics, setAnalyticsDenied] = useState(0);
    const [isCreatingDocw, setCreatingDocw] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [isDeleted, setDeleted] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isDownloading, setDownloading] = useState(false);
    const [isViewing, setViewing] = useState(false);
    const [viewDownloadedPdf, setViewDownloadedPDF] = useState(false);
    const [isResendingEmail, setResendingEmail] = useState(false);
    const [isRetryingEmail, setRetryingEmail] = useState(false);
    const [downloadView, setDownloadView] = useState(null)
    const [cancelled, setCancelled] = useState(0)
    const [denied, setDenied] = useState(0)
    const [isUpdating, setIsUpdating] = useState(false)
    const [fileAttached, setFileAttached] = useState(null);
    const [attachingFile, setAttachingFile] = useState(false);
    const [isResendingFile, setResendingFile] = useState(false);
    const [sendFile, setFileSucess] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [decline_reason, setDeclineReason] = useState(null);
    const [generated,setGenerated] = useState(false);
    const [generatingHistory,setHistoryGenerating] = useState(false);
    const [historyData,setDocumentHistory] = useState([]);
    const [statusRes, setStatusRes] = useState([]);
    const [statusSearchPagination, setSearchStatusPagination] = useState(0);


    var limit = 10;


    const countAllRecords = () => {

      setLoading(true);

      DocumentService.countAllRecords().then((res) => {
        log("count all records res here:", res)

        if(res.data.success){
          setAll(res.data.data.drafts + res.data.data.completed + res.data.data.pending + res.data.data.denied + res.data.data.cancelled)
          setDrafts(res.data.data.drafts);
          setCompleted(res.data.data.completed);
          setPending(res.data.data.pending);
          setCancelled(res.data.data.cancelled);
          setDenied(res.data.data.denied);
        }

        setLoading(false);

      }).catch((error) => {
        log("count all records error here:", error)

        setLoading(false);
      })

    }

    const countAnalyticsRecord = (year) => {

      setLoading(true);

      DocumentService.countAnalytics(year).then((res) => {
        log("analytics count res xxxxxxx", res.data.data)

        setAnalyticsCountAll(res.data.data.drafts + res.data.data.completed + res.data.data.pending)
        setAnalyticsDrafts(res.data.data.drafts);
        setAnalyticsCompleted(res.data.data.completed);
        setAnalyticsPending(res.data.data.pending);
        setAnalyticsCancelled(res.data.data.cancelled);
        setAnalyticsDenied(res.data.data.denied);

        res.data.data.forEach(entry => {
          const count = entry.count;
          const status = entry.status;
        
          switch (status) {
              case "PENDING":
                  result.pending += count;
                  break;
              case "CANCELLED":
                  result.cancelled += count;
                  break;
              case "DENIED":
              result.denied += count;
              break;
              case "DRAFT":
                  result.drafts += count;
                  break;
              case "COMPLETED":
                  result.completed += count;
                  break;
          }
        });

        setLoading(false);

      }).catch((error) => {
        log("count all records error here:", error)

        setLoading(false);
      })

    }


    const retrieveDocuments = (page) => {
        setLoading(true);

        DocumentService.retrieveDocuments(page,limit).then((res) => {
            log("retrieve documents res here:", res);

            setLoading(false);

            if (res.data.success) {
                // setDocumentList(res.data.data);
                setListOfDocuments(res?.data?.data)

                if(res?.data?.data?.length === 0){
                  console.log("last_pagexx length", res?.data?.data?.length)
                  setPagination(0)
                }else{
                  var last_page = Math.ceil(res?.data?.total / 10); //set it depending on ui return - 18
                  console.log("last_pagexx", last_page)
                  setPagination(last_page)
                }
              }

        }).catch((error) => {
            log("retrieve documents error here:", error);
            setLoading(false);
        })


    }


    const documentHistory = (document_id) => {

      setHistoryGenerating(true);

      DocumentService.documentHistory(document_id).then((res) => {
          log("documentHistory res here:", res);

          setHistoryGenerating(false);

          if (res.data.success) {
            setDocumentHistory(res.data.data);
          }

      }).catch((error) => {
          log("documentHistory error here:", error);
          setHistoryGenerating(false);

      })


  }


    const filterByStatus = (status, page) => {

      setLoading(true);

      DocumentService.findByOidandStatus(status, page, 10).then((res) => {

        log("filter by status res here:", res);

        setLoading(false);

        if (res.data.success) {
          // setDocumentList(res.data.data);
          setDocumentListFilter(res.data.data)

          if(res.data.data.length === 0){
            // setPagination(0)
            setFilterPagination(0)
          }else{
            var last_page = Math.ceil(res?.data?.total / 10); //set it depending on ui return - 18
            setFilterPagination(last_page)
          }
        }

      }).catch((error) => {

        log("filter by status error here:", error);

        setLoading(false);

      })

    }


    const createDocumentWorkflow = (formData, setOpenCreditLimit) => {
      setCreatingDocw(true);

      DocumentWorkflowService.createDocumentWorkflow(formData).then((res) => {
        // log("createDocumentWorkflow data xxxx", res);

        if (res.data.success === true) {
          // let doc_id = res.data.data.id;
          // window.location.href = "/pdf-template?id=" + doc_id;
          setCreated(true);
          enqueueSnackbar("Successfully created document", { 
            variant: 'success',
            autoHideDuration: 3000
          });
          setId(res.data.data.id)
          setCreatingDocw(false);
        }

        if(res.data.has_credit === false){
          setOpenCreditLimit(true)
        }

      }).catch((error) => {
        setCreatingDocw(false);
        enqueueSnackbar(error.response.data.error, { 
          variant: 'error',
          autoHideDuration: 3000
        });
        log("createDocumentWorkflow error xxxx", error);
      })
    }

    const deleteDocumentWorkflow = (id, handleCloseDeleteFile) => {

      setDeleting(true);

      DocumentWorkflowService.deleteDocumentWorkflow(id).then((res) => {
        // log("deleteDocumentWorkflow data xxxx", res);

        setDeleting(false);

        if (res.data.success) {
          setDeleted(true);
          enqueueSnackbar("Successfully deleted document", { 
            variant: "success",
            autoHideDuration: 3000
          });
          handleCloseDeleteFile();
          // window.location.reload();
        }

      }).catch((error) => {
        log("deleteDocumentWorkflow error xxxx", error);
        enqueueSnackbar(error.response.data.error, { 
          variant: "error",
          autoHideDuration: 3000
        });
        setDeleting(false);
      })
    }

    const viewDocumentByID = (id) =>{
      
        DocumentWorkflowService.getDocumentByID(id).then((res)=>{
            log('get document by id res here', res.data.data)
            setGetDocumentById(res.data.data)
        })
    }

    const saveDocAsDraft = (id, newProperties, deleted_property_ids) => {

      setIsSaving(true);
      
      DocumentWorkflowService.saveDocAsDraft(id, newProperties, deleted_property_ids).then((res) => {
        // log("saveDocAsDraft data xxxx", res);

        setIsSaving(false);

        if (res.data.success) {
          enqueueSnackbar("Document successfully saved as draft.", { 
            variant: "success",
            autoHideDuration: 3000
          });
          setIsSaved(true);
        }

      }).catch((error) => {
        log("saveDocAsDraft error xxxx", error);
        enqueueSnackbar("Document unsuccessfully saved as draft.", { 
          variant: "error",
          autoHideDuration: 3000
        });
        setIsSaved(false);
        setIsSaving(false);
      })
    }

    const viewFile = (id) => {

      setViewDownloadedPDF(true);

      DocumentWorkflowService.downloadFile(id).then((res) => {
        
        if(res.data){
          setViewDownloadedPDF(false);
          const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
          // window.open(url, "_blank");
          log('DOWNLOADED FILE XXXXXXXXXXXX', res)
          setDownloadView(url)
        }

      }).catch((error) => {

        log("viewFile error:", error);
        setViewDownloadedPDF(false);
      });
    };
    

    const downloadFile = (id, handleCloseDownloadFile, filename) => {

      setDownloading(true);

      DocumentWorkflowService.downloadFile(id).then((res) => {
        // log("downloadFile data xxxx", res);

        setDownloading(false);

          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename + '.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();

          handleCloseDownloadFile();

      }).catch((error) => {
        log("downloadFile error xxxx", error);
        setDownloading(false);
        enqueueSnackbar("Please try again.", { 
          variant: "error",
          autoHideDuration: 3000
        });
      })
    }

    const attachFile = (id) => {
      setAttachingFile(true);
    
      DocumentWorkflowService.downloadFile(id)
        .then((res) => {
          if (res.data) {
            // Create a Blob from the response data
            const blob = new Blob([res.data], { type: "application/pdf" });
    
            setAttachingFile(false);
            setFileAttached(blob);
    
            // Logging for debugging purposes
            console.log('attachFile DOWNLOADED FILE:', res);
          } else {
            throw new Error('Empty response data');
          }
        })
        .catch((error) => {
          console.error("attachFile error:", error);
          setAttachingFile(false);
          // Handle error as needed (e.g., show error message to the user)
        });
    };
    


    const resendEmail = (id, handleCloseResendEmail) => {

      setResendingEmail(true);
      
      DocumentWorkflowService.resendEmail(id).then((res) => {
        log("resendEmail data xxxx", res);

        setResendingEmail(false);
        handleCloseResendEmail();

        if (res.data.success) {
          enqueueSnackbar("Email successfully send.", { 
            variant: "success",
            autoHideDuration: 3000
          });
          window.location.reload();
        }

      }).catch((error) => {
        log("resendEmail error xxxx", error);
        setResendingEmail(false);
        enqueueSnackbar("Email unsuccessfully send.", { 
          variant: "error",
          autoHideDuration: 3000
        });
       
      })
    }

    const resendFile = (document_id, handleCloseResendFile) => {
      setResendingFile(true);
      DocumentWorkflowService.resendFile(document_id).then((res) => {
        log("resendFile data xxxx", res);

        setResendingFile(false);
        handleCloseResendFile();
        setFileSucess(true);

        if (res.data.success) {
          enqueueSnackbar("Successfully send.", { 
            variant: "success",
            autoHideDuration: 3000
          });
          window.location.reload();
        }

      }).catch((error) => {
        log("resendFileerror xxxx", error);
        setResendingFile(false);
        enqueueSnackbar("Unsuccessfully send.", { 
          variant: "error",
          autoHideDuration: 3000
        });
       
      })
    }


    const retryEmail = (id, handleCloseResendEmail) => {

      setRetryingEmail(true);
      
      DocumentWorkflowService.retryEmail(id).then((res) => {
        log("retry email data xxxx", res);

        setRetryingEmail(false);

        if (res.data.success) {
          enqueueSnackbar("Email successfully send.", { 
            variant: "success",
            autoHideDuration: 3000
          });
          handleCloseResendEmail();
          window.location.reload();
        }

      }).catch((error) => {
        log("retry email error xxxx", error);
        setRetryingEmail(false);
        handleCloseResendEmail();
        enqueueSnackbar("Email unsuccessfully send.", { 
          variant: "error",
          autoHideDuration: 3000
        });
       
      })
    }



    const retryError = (id, handleCloseResendError) => {

      setRetryingEmail(true);
      
      DocumentWorkflowService.retryError(id).then((res) => {
        log("retry email data xxxx", res);

        setRetryingEmail(false);

        if (res.data.success) {
          enqueueSnackbar("Retry successfully send.", { 
            variant: "success",
            autoHideDuration: 3000
          });
          handleCloseResendError();
          window.location.reload();
        }

      }).catch((error) => {
        log("retry email error xxxx", error);
        setRetryingEmail(false);
        handleCloseResendError();
        enqueueSnackbar(error?.response?.data?.error, { 
          variant: "error",
          autoHideDuration: 3000
        });
       
      })
    }

    const searchStatusDocuments = (status, title, month, year, page) => {
      setLoading(true);

      DocumentService.searchDocument(status, title, month, year, page, 10).then((res) => {
          log("searchStatusDocuments res here:", res);

          setLoading(false);

          if (res.data.success) {
              // setDocumentList(res.data.data);
              setStatusRes(res.data.data);

              if(res.data.data.length === 0){
                setSearchStatusPagination(0)
              }else{
                var last_page = Math.ceil(res.data.total / 10); //set it depending on ui return - 18
                console.log("last_page here", last_page)
                setSearchStatusPagination(last_page)
              }
            }

      }).catch((error) => {
          log("retrieve documents error here:", error);
          setLoading(false);
      })


  }

    const searchDocuments = (status, title, month, year, page) => {
      setLoading(true);

      DocumentService.searchDocument(status, title, month, year, page, 10).then((res) => {
          log("search documents res here:", res);

          setLoading(false);

          if (res.data.success) {
              // setDocumentList(res.data.data);
              setDocumentListSearch(res.data.data);

              if(res.data.data.length === 0){
                setSearchPagination(0)
              }else{
                var last_page = Math.ceil(res.data.total / 10); //set it depending on ui return - 18
                console.log("last_page here", last_page)
                setSearchPagination(last_page)
              }
            }

      }).catch((error) => {
          log("retrieve documents error here:", error);
          setLoading(false);
      })


  }

    const updateAutoReminder = (document_id, is_auto_reminder) => {
      setIsUpdating(true)
      DocumentWorkflowService.updateAutoReminder(document_id, is_auto_reminder).then((res)=>{
        log('update auto revoke res here', res)
        setIsUpdating(false)
        if(res.data.data.is_auto_remind === true){
          enqueueSnackbar("Auto reminder has been enabled.", { 
            variant: "success",
            autoHideDuration: 3000
          });
        } else if (res.data.data.is_auto_remind === false){
          enqueueSnackbar("Auto reminder has been disabled.", { 
            variant: "success",
            autoHideDuration: 3000
          });
        }
      }).catch((err)=>{
        setIsUpdating(false)
        log('err', err)
      })
    }


    const getDeniedReason = (id, setOpenInfoDialog) => {

      setGenerating(true);
      DocumentWorkflowService.getDeniedReason(id).then((res) => {
        log("getDeniedReason data xxxx", res);
        setGenerating(false);
        setGenerated(true);
        if (res.data.success) {
          setDeclineReason(res.data.data.denied_reason);
          setOpenInfoDialog(true);
        }

      }).catch((error) => {
        setGenerating(false);
        setGenerated(false);
        console.log("error here:", error)
        enqueueSnackbar(error?.response?.statusText, { 
          variant: "error",
          autoHideDuration: 3000
        });
       
      })
    }



    return {
      getDocumentById, loading, pagination, created, id, countAllRecords, drafts, pending, completed, cancelled, denied, isCreatingDocw,
      viewDocumentByID, retrieveDocuments, createDocumentWorkflow, filterByStatus, deleteDocumentWorkflow, saveDocAsDraft, isDeleting, 
      isDeleted, isSaving, isSaved, downloadFile, isDownloading, resendEmail, isResendingEmail, retryEmail, isUpdating, allAnalytics, draftsAnalytics, pendingAnalytics, 
      completedAnalytics, cancelledAnalytics,isRetryingEmail, viewFile, isViewing, setLoading, all, retryError, downloadView, viewDownloadedPdf, searchDocuments, 
      updateAutoReminder, countAnalyticsRecord, attachFile, attachingFile, fileAttached, isResendingFile, resendFile, sendFile, getDeniedReason, decline_reason, 
      generating, generated, documentHistory, historyData, generatingHistory, listOfDocuments, documentListFilter, documentListSearch, searchPagination, filterPagination,
      searchStatusDocuments, statusRes, statusSearchPagination
    }
      
}

export default useDocuments