import { useEffect, useState, useRef } from "react";
import {Box, Stack} from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import "../styles/viewer.css";

import { getWidthByPercentage } from "../../utils/ProportionDimenUtil";
import useWidth from '../hooks/useWidth';
import PDFPageViewerComponent from "./PDFPageViewerComponent";
import testPDF4 from "../pdf-with-diff-orientations.pdf";
import { log } from "../../../console-config";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFBoxViewerComponent = ({file_url, elements_to_plot}) => {

    const [pdfPages, setPdfPages] = useState([])
    const [elements, setElements] = useState([])
    const [error, setError] = useState(null)

    const pdfEditorRef = useRef()
    const width = useWidth();

    const elementProps = {
        elements,
        elements_to_plot,
        setElements,
    }

    // useEffect(() => {
    //     setElements(elements_to_plot)
    // }, [])

    function handleOnDocumentLoadSuccess(pdf){
        log("hit handleOnDocumentLoadSuccess PDFSigneeScreen")

        // onDocumentLoadSuccess(pdf);
        handleSetPDFPages(pdf)
    }

    const handleSetPDFPages = (pdf) => {
        //TODO make comments

        [...Array(pdf.numPages)].map((item, i) => {
            let pageNum = i + 1
            pdf.getPage(pageNum).then((page) => {
                let pdfEditorWrapperWidth = pdfEditorRef?.current?.clientWidth

                let percentage = 60
                let percentageWidth = getWidthByPercentage(percentage, pdfEditorWrapperWidth)

                let pdfPageOrigWidth = page._pageInfo.view[2]
                let pdfPageOrigHeight = page._pageInfo.view[3]

                let rotate = page.rotate
                let isLandscape = rotate == 90 || rotate == 270 ? true : false
                let widthDiff = isLandscape ? pdfPageOrigHeight - percentageWidth : 0
                let isNegative = widthDiff < 0 ? true : false
                let newNum = isLandscape ? pdfPageOrigHeight + widthDiff : 0
                let origNum = isLandscape ? pdfPageOrigHeight : pdfPageOrigWidth
                let percentageDiff = Math.abs(getPercentageIncreaseDecrease(newNum, origNum))
                percentageDiff = isNegative ? Math.abs(percentageDiff) : -Math.abs(percentageDiff)

                let obj = {
                    numPages: pdf.numPages,
                    page_number: page.pageNumber,
                    isLandscape,
                    page,
                    ...(isLandscape && {adjustedWidth: percentageWidth, percentageDiff, widthDiff})
                }

                setPdfPages((prev) => {
                    let _page = prev.find((item) => item.isLandscape)

                    // For portrait
                    if (_page && !isLandscape) {
                        let portrait_percentage_width = getWidthByPercentage(_page.percentageDiff, pdfPageOrigWidth)
                        obj.adjustedWidth = portrait_percentage_width + pdfPageOrigWidth
                    }
                    return [...prev, obj]
                })
            })
        })
    }

    function getPercentageIncreaseDecrease(newNum, origNum) {
        return ((newNum - origNum) / origNum) * 100;
    }

    function handleOnLoadError(error){
        setError(error.message); // react-pdf validates by default
    }

    return(
        <Box ref={pdfEditorRef} sx={{bgcolor: 'lightgray'}} display="flex" justifyContent="center" alignItems="center">
            <Document file={file_url} onLoadSuccess={handleOnDocumentLoadSuccess} onLoadError={handleOnLoadError}>
                <Stack sx={{mt: 3, mb: 3}} direction="column" justifyContent="space-evenly" alignItems="center" spacing={width != 'xs' ? 10 : 4}>
                    {pdfPages.map((page, i) => {
                        return(
                            <PDFPageViewerComponent 
                                key={i} 
                                numPages={page.numPages} 
                                page_number={page.page_number} 
                                adjustedPDFWidth={page.adjustedWidth} 
                                elementProps={elementProps}
                                />
                        )
                    })}
                </Stack>
            </Document>
        </Box>
    )
}

export default PDFBoxViewerComponent