import { log } from "../../../console-config";
import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, CustomHttpPostMultipart, HttpPublicPost, HttpGet1, HttpRawGet, HttpPatch, HttpPublicGet, HttpDelete} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.ovsign_account_service.subscriptionKey;

/**
 * getSignatureByEmail - GET Method
 * @param {String} email_address email_address
*/ 
const getSignatureByEmail = (email_address) => {
    let query_params = {
        email_address: email_address
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_SIGNATURE_BY_EMAIL_UA_PUBLIC, query_params, subscriptionKey);
    return result;
}

/**
 * updateSignature - POST Method
 * @param {String} email_address email_address
 * @param {String} email_address signature
*/ 
const updateSignature = (email_address, signature) => {
    let payload = {
        email_address: email_address,
        signature: signature
    }
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.UPDATE_SIGNATURE_UA_PUBLIC, payload, subscriptionKey);
    return result;
}

const updateSignaturePublic = (formData, accessToken) => {
    let result = CustomHttpPostMultipart(routes.UPDATE_SIGNATURE_UA_PUBLIC, formData, accessToken);
    return result;
}

/**
 * updateSignatureStatus - POST Method
 * @param {String} email_address email_address
*/ 
const updateSignatureStatus = (email_address) => {
    let payload = null;
    let subscriptionKey = SUBSCRIPTION_KEY;
    var route = routes.UPDATE_SIGNATURE_STATUS_UA_PUBLIC + '?email_address=' + email_address;
    let result = HttpPost(route, payload, subscriptionKey);
    return result;
}

const updateSignatureStatusPublic = (email_address, accessToken) => {
    let query_params = {
        email_address: email_address
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPublicPost(routes.UPDATE_SIGNATURE_STATUS_UA_PUBLIC, query_params, null, accessToken, subscriptionKey);
    return result;
}

const saveSignee = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.SAVE_RECENT_ADDED_SIGNEE, payload, subscriptionKey);
    return result;
}

const getRecentAddedSignees = () => {

    let query_params = null;
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_RECENT_SIGNEES, query_params, subscriptionKey);
    
    return result;
}



const deletRecentAddedSignee = (delete_signees) => {
    let parameters = {
        delete_signees: delete_signees
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.DELETE_RECENT_SIGNEE, parameters, subscriptionKey);

    return result;
}

const searchEmailSignees = (searchEmail) => {

    let parameters = {
        keyword: searchEmail
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.SEARCH_EMAIL_SIGNEE, parameters, subscriptionKey);
    
    return result;
}

const getUserByOID = (oid, accessToken) => {
    log('get token hereeeeeeeeeeeeeee', accessToken)
    let query_params = {
        oid: oid
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPublicGet(routes.SEARCH_USER_BY_OID, query_params, accessToken, subscriptionKey);
    
    return result;
}

const AccountPublicService = {
    getSignatureByEmail: getSignatureByEmail,
    updateSignature: updateSignature,
    updateSignatureStatus: updateSignatureStatus,
    updateSignaturePublic,
    updateSignatureStatusPublic,
    saveSignee,
    getRecentAddedSignees,
    searchEmailSignees,
    getUserByOID,
    deletRecentAddedSignee
}

export default AccountPublicService;