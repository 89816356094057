import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import useDocuments from "../../hooks/useDocuments";
import {ThreeDots} from "react-loader-spinner";
import { useEffect, useState } from "react";
import { log } from "../../console-config";
import DocumentWorkflowService from "../../services/api/documents";
import { useSnackbar } from "notistack";


const DeleteFileDialog = (props) => {

    const {openDeleteFile, handleClose, userDocument, setOpenDeleteFile } = props;

    const { enqueueSnackbar } = useSnackbar();

    const [isDeleting, setIsDeleting] = useState(false);
    const [page, setPage] = useState(1);



    const handleDelete = (id) => {
        if (id) {
            setIsDeleting(true)
            DocumentWorkflowService.deleteDocumentWorkflow(id).then(res => {
                console.log('Deleted response', res);
                enqueueSnackbar("Successfully deleted document", { variant: "success", autoHideDuration: 3000 });
                if (res.status === 200) {
                    setOpenDeleteFile(false)
                    setIsDeleting(false)
                    props.retrieveDocuments(page);
                }
            }).catch((error) => {
                enqueueSnackbar(error.response.data.error, { variant: "success", autoHideDuration: 3000 });
            }).finally(() => {
                setOpenDeleteFile(false);
            });
        }
    }

    return(
        <Dialog open={openDeleteFile} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
              Delete Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, textAlign:'center'}}>
                <Typography sx={{fontSize: 16}}>
                    Are you sure you want to delete this <b>{userDocument?.title}</b>?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{width: 120, borderRadius: 10}} 
                    onClick={handleClose}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{width: isDeleting ? 180 : 120, backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'none'}}
                  onClick={(e) => handleDelete(userDocument?.id)}
                  startIcon={isDeleting &&
                    <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                }
                >
                   {isDeleting ? 'Deleting': 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteFileDialog