import { useState } from "react";
import DocumentWorkflowService from "../services/api/documents";
import { log } from "../console-config";


const useSignedLogs = () => {

    const [pagination, setPagination] = useState(0);
    const [signedList, setSignedList] = useState([])
    const [loading, setLoading] = useState(false)

    var limit = 10;


    const retrieveSignedLogs = (email, page) => {
        setLoading(true)
        DocumentWorkflowService.signedLogs(email, page, limit).then((res)=>{
            setLoading(false)
            if (res.data.success) {
                log('get signed logs here', res)
                setSignedList(res.data.data)

                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    setPagination(last_page)
                  }
            }
        }).catch((err)=>{
            log('get signed logs err here', err)
            setLoading(false)
        })
    }

return {retrieveSignedLogs, signedList, pagination, loading}
}

export default useSignedLogs;