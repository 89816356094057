import React, {useState} from "react";
import {Dialog, DialogContent, DialogTitle, Divider, Grid} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SignatureTabs from "./signature-tabs.component";
import {useDialog} from "../../../ovdocs/utils/common-dialog";
import { log } from "../../../console-config";

const CreateSignatureDialogComponent = (props) => {

    const {breakpoints, openSignatureDialog, handleCloseSignatureDialog, sendDataToParent, open, tag, user, params} = props;
    const url = window.location.pathname;
    let isPublic = params?.isPublic
    let email_address = params?.email_address

    const [isLinkTab, setLinkTab] = useState(false);

    log("isPublic aa", isPublic);

    const {closeDialog} = useDialog()

    const sendTabLabelToParent = (child) => {
        setLinkTab(child);
    }

    const handleCloseDialog = (signature_url) => {
        log("signature_url mmm", signature_url);
        let signatureUrl = typeof signature_url === 'string' || signature_url instanceof String ? signature_url : null

        if (url == "/pdf-template") {
            closeDialog(tag, signatureUrl);
        } else {
            if (handleCloseSignatureDialog) {
                handleCloseSignatureDialog()
                // window.location.reload()
            }
            else closeDialog(tag, signatureUrl);
        } 
    }


    return (
        <Dialog open={openSignatureDialog || open} onClose={handleCloseDialog} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 16, display: "flex"}}>
                Create Electronic Signature

                <Grid sx={{ml: "auto"}}>
                    {isLinkTab && <CloseIcon fontSize="small" onClick={handleCloseDialog} sx={{cursor: "pointer"}} />}
                </Grid>
            </DialogTitle>

            <Divider />
            
            <DialogContent sx={{padding: 3, paddingTop: 2}}>
               <SignatureTabs handleCloseSignatureDialog={handleCloseDialog} sendDataToParent={sendDataToParent} 
                    breakpoints={breakpoints} sendTabLabelToParent={sendTabLabelToParent} user={user} isPublic={isPublic}
                    email_address={email_address}
                />
            </DialogContent>
        </Dialog>
    )
}

export default CreateSignatureDialogComponent