import AutoCompleteSelectComponent from "./common/AutoCompleteSelectComponent"
import {useDialog} from "../../utils/common-dialog";
import AddSigneeDialogComponent from "./dialogs/signees/AddSigneeDialogComponent";
import {v4 as uuidv4} from 'uuid';
import { useState } from "react";
import AccountPublicService from "../../../services/api/account_public";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { errorCodes } from "../common/staticVariables";
import { log } from "../../../console-config";

const SelectSignersComponent = ({options, signeesOptionLabel, defaultValue, value, textColor, onChange, onAddSignee, onAddToSignees, 
    onAssignNewSigneeToElement, element, currentUser, onErrorAddSignee, dataArray, getDocumentById, setIsElementClicked, updated_dataArray, setUpdateDataArray}) => {

  log('get document by id on ovdocs component', getDocumentById)

      const {openDialog} = useDialog()
      log("value ll", value);
      // const signeesArr = options.map((opt) => opt.signee)
  
  
      const [ searchEmail , setSearchEmail ] = useState("");
      const {enqueueSnackbar} = useSnackbar();
      const [searchEmailSignee, setSearchEmailSignee] = useState(null)
      const [selectedSearchUser, setSelectedSearchUser] = useState(null)
      const [recentEmail, setRecentEmail] = useState(null)
      const [clicked, setClicked] = useState(false);

      const handleClickRecentEmail = (signee, handleCloseAutoComplete) => {

        let new_signee = { id: uuidv4(), email_address: signee, isNew: true };
        setRecentEmail(signee)
        onAddToSignees(new_signee)
        onAssignNewSigneeToElement(new_signee, element)
        handleCloseAutoComplete()
      }

      

      const handleSelectUserSignee = (searchSignee, handleCloseAutoComplete) => {
        let payload = {
          recently_added_signees : [searchSignee]
        }
        const domain = searchSignee?.split('@')[1];
        if(!clicked){
          setClicked(true);

          if (getDocumentById?.domain.length === 0 && getDocumentById?.email.length === 0) {
            AccountPublicService.saveSignee(payload).then((res)=>{
              console.log('sssssssssssssssssssssssssssssssssss', res)
              let new_signee = { id: uuidv4(), email_address: searchSignee, isNew: true };
                setSelectedSearchUser(searchSignee)
                onAddToSignees(new_signee)
                onAssignNewSigneeToElement(new_signee, element)
                handleCloseAutoComplete()
                setIsElementClicked(false)
            }).catch((err)=>{
                enqueueSnackbar(err.response.data.error, { 
                    variant: 'error',
                    autoHideDuration: 3000
                });
                handleCloseAutoComplete()
                setIsElementClicked(false)
            })
          } else if(!getDocumentById?.domain.includes(domain) === getDocumentById?.email.includes(searchSignee)){
            AccountPublicService.saveSignee(payload).then((res)=>{
              let new_signee = { id: uuidv4(), email_address: searchSignee, isNew: true };
                setSelectedSearchUser(searchSignee)
                onAddToSignees(new_signee)
                onAssignNewSigneeToElement(new_signee, element)
                handleCloseAutoComplete()
                setIsElementClicked(false)
              enqueueSnackbar("Success! Email address saved.", { 
                  variant: 'success',
                  autoHideDuration: 3000
              });
          }).catch((err)=>{
              enqueueSnackbar(err?.response, { 
                  variant: 'error',
                  autoHideDuration: 3000
              });
              handleCloseAutoComplete()
              setIsElementClicked(false)
          })
          } else {
            enqueueSnackbar("This email/domain is unauthorized. Contact your enterprise admin.", { 
                variant: 'error',
                autoHideDuration: 3000
            });
            handleCloseAutoComplete()
            setIsElementClicked(false)
          }
        }
        document.getElementById('myButton')?.removeEventListener('click', handleSelectUserSignee)
      }

      // const handleSaveSignee = (payload, searchSignee) => {
      //     AccountPublicService.saveSignee(payload).then((res)=>{
      //         let new_signee = { id: uuidv4(), email_address: searchSignee, isNew: true };
      //           setSelectedSearchUser(searchSignee)
      //           onAddToSignees(new_signee)
      //           onAssignNewSigneeToElement(new_signee, element)
      //           handleCloseAutoComplete()
      //         enqueueSnackbar("Success! Email address saved.", { 
      //             variant: 'success',
      //             autoHideDuration: 3000
      //         });
      //     }).catch((err)=>{
      //         enqueueSnackbar(err.response.data.error, { 
      //             variant: 'error',
      //             autoHideDuration: 3000
      //         });
      //     })
      // }

      useEffect(() => {
        if(recentEmail){
          setRecentEmail(recentEmail); // Set the initial value of email using the params prop
        }
      }, [recentEmail]);

      const handleInputChange = (event) => {
          setSearchEmail(event.target.value);

          if(searchEmail){
              AccountPublicService.searchEmailSignees(searchEmail).then((res)=>{
                  log('get search user signee res here', res)
                  setSearchEmailSignee(res.data.data)
              })
          }
      };

      log('get search user email', searchEmailSignee)
  
      log("searchEmail", searchEmail)
  
      const handleOnAddSignee = () => {

        let selectedUser = selectedSearchUser ? selectedSearchUser : recentEmail ? recentEmail : searchEmail
        
          openDialog('add-signee-dialog', AddSigneeDialogComponent, selectedUser, getDocumentById).then((email) => {
            if (email) {
              if (currentUser === email) {
                onErrorAddSignee({ code: errorCodes.invalidSignatory, error: "Email is already the owner of the document. Please use the signature element instead for signing.", email_address: email });
              } else {
                let new_signee = { id: uuidv4(), email_address: email, isNew: true };
                // Add to Signees
                onAddToSignees(new_signee);
                // Assign newly created signee to Element
                onAssignNewSigneeToElement(new_signee, element);
              }
            }
          });
        };
  
      const handleOnChangeSignee = (signee) => {
          log("signee on change ", signee);
          // onChange(signee)
          onChange(recentEmail ? recentEmail : selectedSearchUser);
      }

    return(
            <AutoCompleteSelectComponent options={options} getOptionLabel={signeesOptionLabel} label="Select Signee" addlabel={'Add Signee'} placeholder={'Search for email'} 
                noOptionsText={'No emails found'} textColor={textColor} defaultValue={defaultValue} value={value} onChange={handleOnChangeSignee} onAdd={handleOnAddSignee}
                setSearchEmail={setSearchEmail} searchEmail={searchEmail} handleInputChange={handleInputChange} searchEmailSignee={searchEmailSignee} selectedSearchUser={selectedSearchUser} 
                setSelectedSearchUser={setSelectedSearchUser} updated_dataArray={updated_dataArray} setUpdateDataArray={setUpdateDataArray}  getDocumentById={getDocumentById}
                dataArray={dataArray} handleClickRecentEmail={handleClickRecentEmail} recentEmail={recentEmail} handleSelectUserSignee={handleSelectUserSignee}
            />
        )
}

export default SelectSignersComponent