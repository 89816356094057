import * as React from 'react';
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, FormControl, Grid, Select, Typography, MenuItem, InputLabel, Divider, TextField } from "@mui/material"
import DrawerHeader from "../../layout/drawer-header"
import AddIcon from '@mui/icons-material/Add';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import eSignature from '../../icons/esignature.png'
import ConfirmationDialog from './dialog/confirmation-dialog.component';
import AddSigneeDialogComponent from './dialog/add-signee-dialog.component';
import {useNavigate} from "react-router-dom";

function PDFElementsComponents () {

    const [email, setEmail] = React.useState('');
    const [openAddSigneeDialog, setOpenAddSigneeDialog] = React.useState(false)
    const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false)
    const navigate = useNavigate();

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleOpenAddSigneeDialog = () =>{
        setOpenAddSigneeDialog(true)
    }

    const handleCloseAddSigneeDialog = () => {
        setOpenAddSigneeDialog(false)
    }

    const handleOpenConfirmationDialog = () =>{
        setOpenConfirmationDialog(true)
    }

    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false)
    }
    
    const elementsT = [
        {
            label: "E-Signature",
            icon : <AddIcon sx={{color: '#0178f2', paddingLeft: '3px'}}/>
        },
        {
            label: "Text field",
            icon : <TextFieldsIcon sx={{color: '#0178f2', paddingLeft: '3px'}}/>
        },
        {
            label: "Date",
            icon : <CalendarMonthIcon sx={{color: '#0178f2', paddingLeft: '3px'}}/>
        },
        {
            label: "Signatory",
            icon : <img src={eSignature} style={{height:20, paddingLeft: '3px'}}/>
        },
    ]

    return(
      
        <Box sx={{width: "100%", height: "100vh", overflow: 'hidden'}}>
            <Box sx={{p: 2}}>
                <Typography sx={{fontWeight:'bold'}}>Elements</Typography>
                <br />
                <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '80vh'}}>
                    <Grid container spacing={2} sx={{height: "100%", overflowY: "hidden"}}>
                        {elementsT.map((elements)=>{
                            return(
                                <Grid item xs={6} sm={6}>
                                    <Card sx={{backgroundColor:'#E2EBF5', height: 55}}>
                                        <CardActionArea sx={{padding: 2}}>
                                            <Typography sx={{fontSize: 12, display:'flex', alignItems:'center'}}>
                                                {elements.label} {elements.icon}
                                            </Typography>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>

                    <DrawerHeader/>

                    <Typography sx={{fontWeight:'bold'}}>Fillable Elements</Typography>
                    <br/>
                    <Card sx={{backgroundColor:'#E2EBF5'}}>
                        <CardHeader title="Signee" titleTypographyProps={{fontSize: 14, fontWeight:'bold'}}/>
                        <CardContent>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Signee</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={email}
                                    label="Select Signee"
                                    onChange={handleChange}
                                    sx={{backgroundColor:'white', padding: 0}}
                                    InputProps={{
                                        style: {
                                            fontSize: "10px"
                                        }
                                    }}
                                >
                                    <Button onClick={handleOpenAddSigneeDialog} fullWidth sx={{textTransform:'none', textAlign:'start', marginBottom: 1}}><AddIcon/> Add Signee</Button>
                                    <Divider/>
                                    <TextField sx={{width:'90%', margin: 2}} placeholder="Search ..." variant="outlined" 
                                        InputProps={{
                                            style: {fontSize: 14}
                                        }}
                                    />
                                    <Divider/>
                                    <MenuItem value={10}>juandelacruz@mail.com</MenuItem>
                                    <MenuItem value={20}>johndoe@testmail.com</MenuItem>
                                </Select>
                            </FormControl>
                        </CardContent>
                    </Card>
                </Box>
                <Box sx={{flex: 1}} />
                <Button variant="contained" onClick={handleOpenConfirmationDialog} fullWidth sx={{textTransform: 'capitalize', bgcolor: '#0178f2'}}>Save</Button>
                <Button variant="outlined" onClick={() => navigate(-1)} fullWidth sx={{textTransform:'none', marginTop: 1}}>Cancel</Button>

                {/* Add Signee Dialog */}
                <AddSigneeDialogComponent openAddSigneeDialog={openAddSigneeDialog} handleCloseAddSigneeDialog={handleCloseAddSigneeDialog} />

                {/* Confirmation Dialog */}
                <ConfirmationDialog openConfirmationDialog={openConfirmationDialog} handleCloseDialog={handleCloseConfirmationDialog}/>
            </Box>
        </Box>
    )
}
export default PDFElementsComponents