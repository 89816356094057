import { grey } from '@mui/material/colors';

const Colors = {
  primaryColor: "#1976d2",
  backgroundDefaultColor: "#F7F7F8",
  primaryTextColor: "#2E3D49",
  secondaryTextColor: "#6D7780",
  darkModeBackgroundDefaultColor: "#35363a",
  darkModeSecondaryBackgroundColor: "#353535",
  darkModeTextColor: "#fff"
}

export default (mode) => ({

  palette: {
    mode,
    ...(mode === 'light' ? 

      // ----------------------------- LIGHT/DEFAULT MODE -----------------------------
      {

        primary: {
          main: Colors.primaryColor,
        },

        background: {
          default: Colors.backgroundDefaultColor,
        },

        text: {
          primary: Colors.primaryTextColor,
          secondary: '#6D7780',
        },

        // RIGHT  DRAWER
        right_drawer: {
          background: {
            default: 'white',
          },
          text: {
            primary: '#fff',
            secondary: grey[800],
          },
        },

        // HEADER
        header: {
          background: {
            default: Colors.backgroundDefaultColor,
          },
          text: {
            // primary: '#4d657b',
            primary: Colors.primaryTextColor,
          },
        },

        // TOOLS PANEL
        tools_panel: {
          background: {
            default: 'white',
          },
          text: {
            // primary: '#4d657b',
            primary: Colors.primaryTextColor,
          },
        },

        // DRAGGABLE ELEMENT
        draggable_element: {
          background: {
            default: '#E2EBF5',
          },
          border_color:{
            default: '#E2EBF5',
          },
          text: {
            primary: '#000000',
          },
        }

        }
      : 
      // ----------------------------- DARK MODE -----------------------------
      {
        primary: {
          main: Colors.primaryColor,
        },

        content: {
          background: {
            default: '#121212',
            secondary: Colors.darkModeSecondaryBackgroundColor
          },
        },

        // RIGHT DRAWER
        right_drawer: {
          background: {
            default: '#353535',
          },
        },

        // TOOLS PANEL
        tools_panel: {
          background: {
            default: Colors.darkModeSecondaryBackgroundColor,
          },
          text: {
            // primary: '#4d657b',
            // primary: Colors.primaryTextColor,
          },
        },

         // DRAGGABLE ELEMENT
         draggable_element: {
          background: {
            default: '#E2EBF5',
          },
          border_color:{
            default: '#E2EBF5',
          },
          text: {
            primary: '#000000',
          },
        }

      }
    ),
  },
  
});