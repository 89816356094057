import React, { useEffect, useState, useRef } from "react";
import {Box, CssBaseline, Grid, Toolbar, Button, IconButton, Typography} from '@mui/material';

import { Document, Page, pdfjs } from 'react-pdf';
import useWidth from "../../hooks/useWidth";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {ThreeDots} from "react-loader-spinner";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


// import AppBar from '../pdf-editor/styled-components/AppBar';
import Main from '../pdf-editor/styled-components/Main';

import PDFBoxSigneeComponent from "./components/PDFBoxSigneeComponent";
import ToolsPanelDrawerComponent from './components/ToolsPanelDrawerComponent';
import PDFSignatoryComponent from "../../components/pdf-editor/pdf-signatory.component";
import ElementSigneeContainerComponent from "../pdf-viewer/components/ElementViewerContainerComponent";
import samplePdf from "../pdf-signee/sample.pdf"
import { elementTypes } from "./common/staticVariables";
import logo from "../../icons/nextixLogo.png"

import PDFSigneeToolbarComponent from "./components/PDFSigneeToolbarComponent"
import DrawerHeader from "../../layout/drawer-header";
import FinishNotificationDialog from "../../components/pdf-editor/dialog/finish-notification.dialog";
import MenuIcon from '@mui/icons-material/Menu';
import testPDF4 from "./pdf-with-diff-orientations.pdf";
import { log } from "../../console-config";
import DeclineResonDialog from "./components/dialogs/reason-to-decline.dialog";


const initialPdfResult = {
    filename: "",
    total_page: 0,
    elements: [],
    signatory: []
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

const PDFSignee = ({file_url, elements_to_plot, signatory, onDocumentLoadSuccess, onDocumentPageLoadSuccess, onClickFinish, currentUser = null, 
    currentUserSignature = null, setCurrentUserSignature, isViewing = false, viewDownloadedPdf, getDocumentById, sender, signeeCCEmail, document_id, setOpenCancelledDialog}) => {
    const [pdfResult, setPDFResult] = useState(initialPdfResult);
    const [open] = useState(true);
    const [elRefIndex, setElRefIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [signatoriesCounter, setSignatoriesCounter] = useState(null);
    const [elements, setElements] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(true);
    const [signedCounter, setSignedCounter] = useState(0);
    const [openDeclined, setOpenDeclined] = useState(false);
    const [isDeclineClicked, setIsDeclineClicked] = useState(false);
    const [isFinishClicked, setIsFinishClicked] = useState(false);
    const elRefs = useRef([]);
    

    const breakpoints = useWidth();
    const width = useWidth();

    log('get signatories counter', signatoriesCounter)

    log("elRefs axx", elRefs);
    log("elements axx", elements);
    log('get cc emails from public', signeeCCEmail)

    function handleDrawerToggle(){
        setOpenDrawer(true);
    };

    function handleDrawerToggleClose(){
        setOpenDrawer(false);
    };

    useEffect(() => {
        log("pdfResult aa", pdfResult);
    }, [JSON.stringify(pdfResult)])

    const onUpdateElements = (elements) => {
        setElements(elements)
    }

    const handleOnClickFinish = () => {
        setIsLoading(true)
        setIsFinishClicked(true);
        setIsDeclineClicked(false);
        onClickFinish(pdfResult, setIsLoading).then(() => {
            setIsLoading(false)
        }).catch(() => {
            setIsLoading(false)
        })
    }
    function gotoSigningPages() {
        if (signatoriesCounter) {
            let hasAllSigned = signatoriesCounter.remainingSig == signatoriesCounter.totalSig
            let pagesSigning
            if (hasAllSigned) {
                pagesSigning = elRefs
            } else {
                let remainingSignatories = elements.filter((el) => el.type == elementTypes.signatory && !el.isSigned)
                log("remainingSignatories aa", remainingSignatories);
                log("elRefs aa", elRefs);

                let remaining_refs = elRefs.current.filter((item, i) => i == remainingSignatories.find((el) => el.id == i)?.id)
                pagesSigning = {current: remaining_refs}
            }
            log("pagesSigning bbb", pagesSigning);
            handleNavigateSignatoryPages(pagesSigning)
        }
    }

    const handleNavigateSignatoryPages = (elRefs) => {
        let elRefsKeys = Object.keys(elRefs.current)

        log("elRefsKeys bbb", elRefsKeys);
        log("elRefIndex bbb", elRefIndex);

        const currentElRefsIndex = elRefsKeys[elRefIndex] ? parseInt(elRefsKeys[elRefIndex]) : parseInt(elRefsKeys[0]);

        log("currentElRefsIndex bbb", currentElRefsIndex);

        elRefs.current[currentElRefsIndex].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        let index = elRefIndex >= elRefsKeys.length - 1 ? 0 : elRefIndex + 1

        log("currentElRefsIndex bbb", currentElRefsIndex);

        setElRefIndex(index)
    }

    const onChangeSignature = (signature) => {
        setCurrentUserSignature(signature)
    }

    const onUpdateSignatoryCounter = (signatories_counter) => {
        log("updateSignatoryCounter aa", signatories_counter);
        setSignatoriesCounter(signatories_counter)
    }

    useEffect(() => {
        if (signatory.length > 0) {
            let signed_counter = signatory.filter((item) => item.status == "DONE").length
            setSignedCounter(signed_counter)
        }
    }, [JSON.stringify(signatory)])

    const handleClickOpen = () => {
        setOpenDeclined(true);
        setIsDeclineClicked(true);
        setIsFinishClicked(false);
    };

    const handleClose = () => {
        setOpenDeclined(false);
        setIsDeclineClicked(false);
    };

    const handleDecline = () => {
        setOpenDeclined(false);
        setIsDeclineClicked(false);
    };

    return(
        <Box sx={{ display: 'flex', height: isViewing ? '92vh' : '100vh'}}>
                <CssBaseline />
                {
                    !isViewing && 
                    <AppBar position="fixed" open={open} sx={{backgroundColor: "#fff", height: 64, boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)'}}>
                        <Toolbar sx={{paddingLeft: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {},
                            paddingRight: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {}}}
                        >
                            <Button variant="text" disableElevation sx={{textTransform: "none", 
                                color: "rgb(0 0 0 / 87%)", fontSize: "20px", fontWeight: "bold"}}
                            >
                                <img alt="" src={logo} style={{cursor: "default", height: 25}}/>
                            </Button>
                            <Typography align="center" sx={{color: "black", flexGrow: 1}}><b style={{fontSize: 18}}>
                                {signatoriesCounter?.remainingSig == signatoriesCounter?.totalSig ? <CheckCircleIcon sx={{color: "#357a38", mr: 1, verticalAlign: "middle"}} /> : null}
                                {signatoriesCounter?.remainingSig ? signatoriesCounter?.remainingSig: signedCounter} / {signatoriesCounter?.totalSig}</b> &nbsp;
                                {signatoriesCounter?.remainingSig == signatoriesCounter?.totalSig ? "All required signatory signed" : "required signatory left"}
                            </Typography>

                            { width != 'xs' && 
                            <Grid sx={{ml: "auto"}}>

                                <Button
                                    onClick={handleClickOpen}
                                    disabled={isFinishClicked}
                                    variant="outlined" sx={{ border: '1px solid red', color: 'red', borderRadius: 10, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', "&:hover": { border: "1px solid red" } }} >
                                    Decline
                                </Button>

                                &nbsp; &nbsp;

                                <Button onClick={gotoSigningPages} variant="outlined" sx={{borderRadius: 10, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', color: "#000", border: "1px solid rgb(0 0 0 / 33%)", "&:hover": {border: "1px solid rgb(0 0 0 / 33%)"}}}>
                                    {signatoriesCounter?.remainingSig == signatoriesCounter?.totalSig ? "Go to page signed" : "Go to page signing"}
                                </Button>

                                &nbsp; &nbsp;

                                <Button 
                                    startIcon={isLoading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                                    variant="contained"  disabled={isDeclineClicked} sx={{backgroundColor: '#168401', borderRadius: 10, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}} onClick={handleOnClickFinish}>
                                    Finish & Submit
                                </Button>
                            </Grid>
                           } 
                            {!openDrawer ?
                                <IconButton color="inherit" aria-label="open drawer"
                                    onClick={handleDrawerToggle}
                                >
                                    <MenuIcon sx={{color: "black"}} />
                                </IconButton>
                                :
                                <IconButton color="inherit" aria-label="open drawer"
                                    onClick={handleDrawerToggleClose}
                                >
                                    <MenuIcon sx={{color: "black"}} />
                                </IconButton>
                            }
                        </Toolbar>
                    </AppBar>
                }
                <Main style={{bgcolor: 'lightgrey', overflow:'auto', display:'grid', padding: 0}} open={openDrawer}>
                    <PDFBoxSigneeComponent 
                        file_url={file_url} 
                        signatory={signatory} 
                        elements_to_plot={elements_to_plot}
                        onUpdateElements={onUpdateElements}
                        pdfResult={pdfResult}
                        setPDFResult={setPDFResult}
                        currentUser={currentUser}
                        currentUserSignature={currentUserSignature}
                        elRefs={elRefs}
                        isViewing={isViewing}
                        onChangeSignature={onChangeSignature}
                        onUpdateSignatoryCounter={onUpdateSignatoryCounter}
                        viewDownloadedPdf={viewDownloadedPdf}
                        
                        />
                </Main>
                
                <ToolsPanelDrawerComponent isLoading={isLoading} signatoriesCounter={signatoriesCounter} handleOnClickFinish={handleOnClickFinish} 
                    gotoSigningPages={gotoSigningPages} signatory={signatory} currentUserSignature={currentUserSignature} isViewing={isViewing}
                    handleDrawerToggleClose={handleDrawerToggleClose} handleDrawerToggle={handleDrawerToggle} openDrawer={openDrawer} getDocumentById={getDocumentById}
                    sender={sender} signeeCCEmail={signeeCCEmail} handleClickOpen={handleClickOpen}
                /> 

            {openDeclined &&
                <DeclineResonDialog openDeclined={openDeclined} handleClose={handleClose} handleDecline={handleDecline} currentUser={currentUser} document_id={document_id} setOpenCancelledDialog={setOpenCancelledDialog} />
            }

               
        </Box>
    )
}

export default PDFSignee