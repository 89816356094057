import {useEffect, useState} from 'react';
import {Box, Grid, Stack, Typography, Button} from '@mui/material';
import DraggableFillableElementComponent from './DraggableFillableElementComponent';
import SelectSigneeComponent from "./SelectSigneeComponent";
import ManageSigneesDialogComponent from "./dialogs/signees/ManageSigneesDialogComponent";
import {useDialog} from "../../utils/common-dialog";
import { log } from '../../../console-config';

const DraggableFillableElementsPanelContainer = ({draggable_fillable_elements = [], signees = [], onAddSignee, onUpdateSignees, onEditSignee,
    onDeleteSignee, onUpdateSignatorySequence, getDocumentById}) => {

    const [currentSignee, setCurrentSignee] = useState({id: null, signee: null});

    const {openDialog} = useDialog();

    useEffect(() => {
        log("currentSignee aa", currentSignee);
    }, [currentSignee])

    const handleOnChangeSignee = (signee) => {
        setCurrentSignee(signee)
    }

    const handleOpenManageSigneesDialog = () => {
        openDialog('manage-recipient-dialog', ManageSigneesDialogComponent, signees).then((updatedSignees) => {
            log("signess updated aa", updatedSignees);
            if (updatedSignees) {
                // let updatedSignee = updatedSignees.find((item) => item.id == currentSignee?.id && !item.isRemove)
                // setCurrentSignee(updatedSignee ? updatedSignee : null)
                onUpdateSignees(updatedSignees)
            }
        })
    }

    const handleOnAddSignee = (signee) => {
        log("onAddSignee aa", signee);
        setCurrentSignee(signee)
        onAddSignee(signee)
    }


    return (
        <Box>
            <Typography sx={{pl: 1, fontWeight: "bold", mb: 1}}>
                Fillable Elements
            </Typography>

            <Box sx={{pl: 1}}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    sx={{pl: 1}}    
                >
                    <Typography sx={{pb: 1, pt: 1, fontSize: 14, fontWeight: 700}}>
                        Signee
                    </Typography>

                    {signees.length > 0 && <Button sx={{textTransform: 'capitalize', textDecoration: 'underline'}} onClick={handleOpenManageSigneesDialog}>Manage</Button>}
                </Stack>

                {/* <SelectSigneeComponent options={signees} value={currentSignee} 
                    onChange={handleOnChangeSignee} onAddSignee={handleOnAddSignee}
                /> */}
            </Box>
            
            <Grid sx={{p: 1}} container spacing={1}>
                {
                    draggable_fillable_elements.map((draggable_fillable_element, i)=>{
                        return (
                            <Grid key={i} item xs={6}>
                                <DraggableFillableElementComponent currentSignee={currentSignee} draggable_fillable_element={draggable_fillable_element} getDocumentById={getDocumentById} />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}

export default DraggableFillableElementsPanelContainer