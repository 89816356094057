import React, {useState, useRef, useEffect} from "react";
import {Box, Button, Grid, InputBase, Typography} from "@mui/material";
import SignaturePad from "react-signature-canvas";
import useSignature from "../../../hooks/useSignature";
import {ThreeDots} from "react-loader-spinner";
import AccountService from "../../../services/api/accounts";
import { useNavigate } from "react-router";

const ChangeSignatureByDrawComponent = ({props}) => {

  const {handleCloseSignatureDialog, breakpoints, isPublic, email_address} = props;
  
  const signaturePadRef = useRef(null);
  const [isCanvasEmpty, setCanvasEmpty] = useState(true);

  const {convertUrlToFile, uploadSignature, uploadSignaturePublic, isUploadingSignature} = useSignature();

  let accessToken = (new URLSearchParams(window.location.search)).get("token");

  const handleCanvasChange = () => {
    setCanvasEmpty(signaturePadRef.current.isEmpty());
  }

  const handleClearSignature = () => {
    signaturePadRef.current.clear();
    setCanvasEmpty(true);
  }

  const handleUploadSignature = async () => {
    let signaturePad = signaturePadRef.current; 

    if (signaturePad.isEmpty()) {
      // do nothing
      
    } else {
      const signatureDataUrl = signaturePad.toDataURL();

      convertUrlToFile(signatureDataUrl, 'file.png', 'image/png').then(function(file) { 
        let formData = new FormData();

        if (isPublic) {
          formData.append("email_address", email_address);
          formData.append("signature", file);
          uploadSignaturePublic(formData, accessToken, handleCloseSignatureDialog)
        } else {
          formData.append("signature_url", file);
          uploadSignature(formData, handleCloseSignatureDialog);
        }
      })
    }
  }

  useEffect(() => {
    const getSignatureUpdate = async () => {
      try {
        let isRemoveCache = true;
        
        await AccountService.retrieveSignature(isRemoveCache)
      
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    if (isUploadingSignature) getSignatureUpdate()
}, [isUploadingSignature])


  return (
    <Box sx={{padding: (!isCanvasEmpty && (breakpoints == 'xs' || breakpoints == 'sm')) ? "0px 0px 16px 0px" 
      : (!isCanvasEmpty && (breakpoints == 'md' || breakpoints == 'lg' ||  breakpoints == 'xl')) ? "0px 40px 16px 40px" 
      : (isCanvasEmpty && (breakpoints == 'xs' || breakpoints == 'sm')) ? "23px 0px 16px 0px" : "23px 40px 16px 40px", 
      mt: (!isCanvasEmpty) ? "-14px" : {}}}
    >
      {(!isCanvasEmpty) &&
        <Grid sx={{textAlign: "end"}}>
          <Button sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14}}
            onClick={() => { handleClearSignature()}}
          >
            Clear
          </Button>
        </Grid>
      }

      <Box>
        <SignaturePad ref={signaturePadRef} clearOnResize={false} onEnd={handleCanvasChange} 
          canvasProps={{width: (breakpoints == 'xs' || breakpoints == 'sm') ? 260 : 480, height: 200}}
        />
      </Box>

      <div style={{borderBottom: "1px solid #d3d3d3", marginTop: "-45px"}}>
        <InputBase disabled sx={{color: "#b9b9b9", mb: 1, ml: 1, width: "100%"}} />
      </div>

      <Typography sx={{fontSize: "14px", color: "#b9b9b9", textAlign: "center", mt: "10px", letterSpacing: "0.6px"}}>
        Draw Signature
      </Typography>

      <Box sx={{paddingTop: 3.2, textAlign: "end"}}>
        <Button onClick={handleCloseSignatureDialog} variant="outlined" sx={{width: 120, borderRadius: 10, color: '#3333cf', borderColor: "#3333cf4f !16840100"  }} >
          Close
        </Button>
        &nbsp;
        <Button variant="contained" sx={{width: isUploadingSignature ? 180 : 120, backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
          onClick={(e) => (isUploadingSignature) ? null : handleUploadSignature(e)}
          startIcon={isUploadingSignature && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />} 
        >
          {isUploadingSignature ? "Saving" : "Save"} 
        </Button>
      </Box>
    </Box>
  )
}

export default ChangeSignatureByDrawComponent