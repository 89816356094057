const isOutsideParentBoundingBox = (parentRect, childRect) => {
    const isOutside = (
        childRect.top < parentRect.top ||
        childRect.bottom > parentRect.bottom ||
        childRect.left < parentRect.left ||
        childRect.right > parentRect.right
    );
    return isOutside;
}

const isOutsideParentBoundingBoxV2 = (parentRect, child_x, child_y, child_width, child_height) => {
    const isChildOutsideParent =
    child_x < 0 ||
    child_y < 0 ||
    child_x + child_width > parentRect.width ||
    child_y + child_height > parentRect.height;

    return isChildOutsideParent;
}

export {isOutsideParentBoundingBox, isOutsideParentBoundingBoxV2}