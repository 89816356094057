import React, { useEffect, useState } from "react";
import {Box, Typography, List, ListItem, ListItemText, Grid, CardMedia, Avatar} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { resizeImageWithAspectRatio } from "../../ovdocs/utils/AspectRatioUtil";
import { useRef } from "react";
import { log } from "../../console-config";
import { format } from "date-fns";

const PDFSignatoryComponent = ({signatory, currentUserSignature, sender, isViewing}) => {

    log("currentUserSignature aabb", currentUserSignature);
    log('ssssssssssssssss', sender)
    log('get signatory here', signatory)

    const [signedCounter, setSignedCounter] = useState(0)
    const [imgDimens, setImgDimens] = useState(null);
    const [adjustedImgDimens, setAdjustedImgDimens] = useState(null);

    const signatureRef = useRef()

    log("signatureRef clientWidth", signatureRef.current?.clientWidth);
    log("signatureRef clientHeight", signatureRef.current?.clientHeight);

    useEffect(() => {
        if (imgDimens) {
            let {width, height} = resizeImageWithAspectRatio(imgDimens?.width, imgDimens?.height, signatureRef.current?.clientWidth, 
                signatureRef.current?.clientHeight)
            log("imgDimens nnn", imgDimens);
            log("newWidth", width);
            log("newHeight", height);
            setAdjustedImgDimens({width, height})
        }
    }, [JSON.stringify(imgDimens)])

    useEffect(() => {
        if (signatory.length > 0) {
            let signed_counter = signatory.filter((item) => item.status == "DONE").length
            setSignedCounter(signed_counter)
        }
    }, [JSON.stringify(signatory)])

    const onLoadImg = (e) => {
        setImgDimens({width: e.target.naturalWidth, height: e.target.naturalHeight})
    }

    signatory.sort((a, b) => a.sequence - b.sequence);

    return (
        <Box sx={{margin: 3}}>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                {!isViewing && 
                    <>
                        <Typography sx={{fontWeight: "bold"}}>
                            Sender
                        </Typography>   
                        
                        <Box sx={{padding: "20px", mt: "20px", backgroundColor: "#16840138", borderRadius: "5px"}}>
                         <List sx={{backgroundColor: "#fff", borderRadius: 2, margin: 1}}>
                            <ListItem >
                                <ListItemText  primary={
                                    <Typography sx={{fontSize: 14, width: 150,textAlign: 'center',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                        {sender?.email_address}
                                    </Typography>} />
                            </ListItem>
                        </List>
                        </Box>
                    </>
                }
                {
                    currentUserSignature &&
                    <>
                        <br />
                        <Typography sx={{fontWeight: "bold"}}>
                            Signature
                        </Typography>
                        <br />
                        <Box ref={signatureRef} sx={{
                            display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center',
                            width: "100%", height: signatureRef.current?.clientWidth / 2, border: "2px solid #ffd500", borderRadius: 2}}>
                            <img src={currentUserSignature} style={{width: adjustedImgDimens?.width, height: adjustedImgDimens?.height}} onLoad={(e) => onLoadImg(e)} /> 
                        </Box>
                        <br />
                    </>
                }
                <Typography sx={{fontWeight: "bold"}}>
                    Signed
                </Typography>
                
                <Grid container direction="column" sx={{padding: "30px 0px", mt: "20px", backgroundColor: "#16840138", borderRadius: "5px"}}>
                    <Grid item sx={{margin: "auto"}}>
                        <Typography variant="h6" sx={{fontWeight: "bold"}}>
                            {signedCounter} / {signatory.length}
                        </Typography>
                    </Grid>

                    <Grid item sx={{margin: "auto"}}>
                        <Typography variant="subtitle2">
                            Sign Counter
                        </Typography>
                    </Grid>
                </Grid>

                <br /> <br />

                <Typography sx={{fontWeight: "bold"}}>
                    Signatories
                </Typography>

                <Grid container direction="column" sx={{padding: "20px", mt: "20px", backgroundColor: "#16840138", borderRadius: "5px"}}>
                    {signatory.length == 0 && 
                        <Typography align="center">
                            No available signatories.
                        </Typography>
                    }
                    
                    {signatory.map((signatory) => {
                        return (
                            <List sx={{backgroundColor: "#fff", borderRadius: 2, mb: 1}}>
                                <ListItem >
                                    {signatory.status == "DONE" && <CheckCircleIcon sx={{color: "#357a38"}} />}
                                    <ListItemText  primary={<Typography sx={{fontSize: 14, width: 150, textAlign: signatory.sequence ? 'start' : 'center',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis'}}><b style={{color: "#0178f2", marginRight: 8}}>{signatory.sequence}</b>{signatory.email_address}</Typography>} />
                                </ListItem>
                                {signatory.status == "DONE" && <Typography sx={{fontSize: 10, textAlign:'center', color:'gray'}}><strong>Signed:</strong> {format(new Date(signatory?.updated_time), 'PPpp')}</Typography>}
                            </List>
                        )
                    })}
                </Grid>
            </Box> 
        </Box>
    )
}

export default PDFSignatoryComponent