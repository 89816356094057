import environment from "../../../environment/environment"

const USER_ACCOUNTS_SERVICE_BASE_URL = environment.ovsign_account_service.url ;
const USER_ACCOUNTS_PUBLIC_SERVICE_BASE_URL = environment.ovsign_account_public_service.url;
const DOCUMENT_WORKFLOW_SERVICE_BASE_URL = environment.ovsign_document_workflow_service.url;
const ACCOUNT_PUBLIC_SERVICE_BASE_URL = environment.ovsign_account_public_service.url;
const DOCUMENT_WORKFLOW_PUBLIC_SERVICE_BASE_URL = environment.ovsign_document_workflow_public_service.url;
const FILE_SERVICE_BASE_URL = environment.ovsign_file_service.url;

export default {

   // users
   RETRIEVE_USER :                   USER_ACCOUNTS_SERVICE_BASE_URL + '/retrieve/profile',
   UPDATE_USER_INFORMATION :         USER_ACCOUNTS_SERVICE_BASE_URL + '/update/profile',
   RETRIEVE_SIGNATURE :              USER_ACCOUNTS_SERVICE_BASE_URL + '/retrieve/signature',
   UPLOAD_SIGNATURE:                 USER_ACCOUNTS_SERVICE_BASE_URL + '/upload/signature',
   GET_ESIG_ACCESS_TOKEN:            USER_ACCOUNTS_SERVICE_BASE_URL + '/e-signature/access-token',
   GET_ESIG_TOKEN_VALIDATION:        DOCUMENT_WORKFLOW_PUBLIC_SERVICE_BASE_URL + '/api/v1/validate/token',
   CREATE_SIGNATURE_BY_LINK:         ACCOUNT_PUBLIC_SERVICE_BASE_URL + '/api/v1/update/signature',
   SAVE_RECENT_ADDED_SIGNEE:         ACCOUNT_PUBLIC_SERVICE_BASE_URL + '/api/v1/signee-histories',
   GET_RECENT_SIGNEES:               ACCOUNT_PUBLIC_SERVICE_BASE_URL + '/api/v1/signee-histories',
   DELETE_RECENT_SIGNEE:             ACCOUNT_PUBLIC_SERVICE_BASE_URL + '/api/v1/signee-histories/delete/signee',
   SEARCH_EMAIL_SIGNEE:              ACCOUNT_PUBLIC_SERVICE_BASE_URL + '/api/v1/users/search',
   SEARCH_USER_BY_OID:               ACCOUNT_PUBLIC_SERVICE_BASE_URL + '/api/v1/get/user/oid',

   UPLOAD_PROFILE:                   USER_ACCOUNTS_SERVICE_BASE_URL + '/upload/profile',
   RETRIEVE_WALKTHROUGH_BY_OID:      USER_ACCOUNTS_SERVICE_BASE_URL + '/user/isWalkthrough',
   POST_USER_WALKTHROUGH:            USER_ACCOUNTS_SERVICE_BASE_URL + '/user/set/walkthrough',
   USER_LOGS:                        USER_ACCOUNTS_SERVICE_BASE_URL + '/user/sign-in/logs',
   GET_USER_SIGNED_LOGS:             USER_ACCOUNTS_SERVICE_BASE_URL + '/user/signed/logs',


   //documents
   RETRIEVE_DOCUMENTS :                   DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/list',
   FILTER_DOCUMENTS_BY_STATUS :           DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/filter/status',
   GET_DOCUMENTS_BY_ID:                   DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document',
   CREATE_DOCUMENT_WORKFLOW:              DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/create',
   DELETE_DOCUMENT_WORKFLOW:              DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/delete',
   GET_DOCUMENTS_PROPERTIES:              DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/element/list', 
   CREATE_UPDATE_TEMPLATE_PROPERTIES:     DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/element/update', 
   SAVE_DOC_AS_DRAFT:                     DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/save/draft',
   COUNT_ALL_RECORDS:                     DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/count',
   SIGNATORY_LIST:                        DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/signatory/list',
   ADD_SIGNATORY:                         DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/add/signatory',
   UPDATE_SIGNATORY:                      DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/update/signatory',
   DELETE_SIGNATORY:                      DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/delete/signatory',
   UPDATE_SIGNATORY_SEQUENCE:             DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/update/signatory/sequence',
   SAVE_DOCUMENT_WORKFLOW:                DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/workflow/process',
   SAVE_AS_DRAFT_DOCUMENT_WORKFLOW:       DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/draft/workflow/process',
   DOWNLOAD_FILE:                         DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/download',
   // RESEND_EMAIL:                          DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/signatory/ongoing/resend-email',
   RESEND_FILE:                           DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/resend/workflow/process',
   GENERATE_VERIFIABLE_QRCODE:            DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/reference/generator',
   RETRY_EMAIL:                           DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/completed',
   RETRY_ERROR:                           DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/error/retry',
   RETRIEVE_ANALYTICS:                    DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/analytics/getComponents',
   REPORT_ONE:                            DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/report/esign',
   GET_REPORT:                            DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/report/esign',
   GET_REPORT_MONTHLY:                    DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/report/esign/monthly',
   GET_WALKTHROUGH:                       DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/walkthrough/module',
   WALKTHROUGH_UPDATE:                    DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/walkthrough/update',
   SIGNED_LOGS:                           DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/signLogs',
   ACTIVITY_LOGS_LIST:                    DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/activitylogs/list',
   SEARCH_DOCUMENT:                       DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/search',
   UPDATE_EMAIL:                          DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/signatory/ongoing/update-email',
   CANCEL_DOCUMENT:                       DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/cancel',
   SEND_INQUIRY:                          DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/inquiry',
   UPDATE_AUTO_REMINDER:                  DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/update/auto/reminder',
   COUNT_ANALYTICS:                       DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/analytics/count',
   GET_DENIED_REASON:                     DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/decline/signatory',
   GET_HISTORY:                           DOCUMENT_WORKFLOW_SERVICE_BASE_URL + '/document/history',

   // account public
   GET_SIGNATURE_BY_EMAIL_UA_PUBLIC :          USER_ACCOUNTS_PUBLIC_SERVICE_BASE_URL + '/api/v1/get/signature/email', // ?email_address=tiler22087@wireps.com
   UPDATE_SIGNATURE_UA_PUBLIC :                USER_ACCOUNTS_PUBLIC_SERVICE_BASE_URL + '/api/v1/update/signature',
   UPDATE_SIGNATURE_STATUS_UA_PUBLIC :         USER_ACCOUNTS_PUBLIC_SERVICE_BASE_URL + '/api/v1/update/signature/status', // ?email_address


   // document workflow public
   UPDATE_STATUS_DW_PUBLIC :              DOCUMENT_WORKFLOW_PUBLIC_SERVICE_BASE_URL + '/api/v1/update/status', // ?element_id=99&document_id=3&email_address=xxwgfer@nextix.co
   VALIDATE_TOKEN_DW_PUBLIC :             DOCUMENT_WORKFLOW_PUBLIC_SERVICE_BASE_URL + '/validate/token',
   GET_DOCUMENT_WITH_PROPERTIES_PUBLIC :  DOCUMENT_WORKFLOW_PUBLIC_SERVICE_BASE_URL + '/api/v1/ovsign-dw',
   SIGN_DOCUMENT :                        DOCUMENT_WORKFLOW_PUBLIC_SERVICE_BASE_URL + '/api/v1/ovsign-dw/sign',
   DECLINE_DOCUMENT:                      DOCUMENT_WORKFLOW_PUBLIC_SERVICE_BASE_URL + '/api/v1/ovsign-dw/decline',


   // file service
   FILE_UPLOADER :                        FILE_SERVICE_BASE_URL + "/file/upload"
}