import {useState, useEffect} from "react";
import AccountService from "../services/api/accounts";
import AccountPublicService from "../services/api/account_public";
import { log } from "../console-config";

const useSignature = () => {

    const [signature, setSignature] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [isValidEsignatureToken, setValidEsignatureToken] = useState(false);
    const [isFetchingAccessToken, setFetchingAccessToken] = useState(true);
    const [isValidatingAccessToken, setValidatingAccessToken] = useState(false);
    const [signatureUrl, setSignatureUrl] = useState(null);

    const [isUploadingSignature, setUploadingSignature] = useState(false);
    const [hasSignature, setHasSignature] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [isRetrievingSig, setRetrievingSig] = useState(false);


    useEffect(() => {
      setTimeout(() => {
        setFetchingAccessToken(false);
      }, 3000)
    },[])

    useEffect(() => {
      log("signatureUrl 111", signatureUrl);

      if (signatureUrl) {
        log("signatureUrl avvvvv", signatureUrl);
      }
    }, [signatureUrl])
    
    const retrieveSignature = () => {
      setRetrievingSig(true);
        
      AccountService.retrieveSignature().then((res)=>{
        log('retrieve signature res', res.data)
        setSignature(res.data.data);
        setRetrievingSig(false);
      }).catch(()=>{

      })
    }

    const uploadSignature = (formData, handleCloseSignatureDialog) => {
      log("uuuuaaaaa");
      setUploadingSignature(true);  
      AccountService.uploadSignature(formData).then((res) => {
        log("uploadSignature data xxxx", res.data);
        let signature_url = res.data.data.signature_url;
        log("signature_url data xxxx", signature_url);

        if (res.data.success) {

          setSignatureUrl(signature_url)
          const getSignatureUpdate = async () => {
            try {

              const response = await AccountService.retrieveSignature();
              setSignature(response.data.data)
              
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          }

          getSignatureUpdate();
          setUploadingSignature(false);
          handleCloseSignatureDialog(signature_url)
          
        }

      }).catch((error) => {
        log("uploadSignature error xxxx", error, error.response.data.error);
        setUploadingSignature(false);
      })
    }

    const uploadSignaturePublic = (formData, accessToken, handleCloseSignatureDialog) => {
      log("uuuuaaaaa");
      setUploadingSignature(true);
      AccountPublicService.updateSignaturePublic(formData, accessToken).then((res) => {
        log("uploadSignature data xxxx", res.data);
        let signature_url = res.data.data.signature_url;
        log("signature_url data xxxx", signature_url);

        if (res.data.success) {
          setUploadingSignature(false);
          // setSignatureUrl(signature_url)
          handleCloseSignatureDialog(signature_url)
          // window.location.reload();
      }

      }).catch((error) => {
        log("updateSignaturePublic error xxxx", error, error.response.data.error);
      })
    }

    const convertUrlToFile = (url, filename, mimeType) => {
      return (fetch(url)
        .then(function(res) {
          return res.arrayBuffer();
        })
      
        .then(function(buf){
          return new File([buf], filename, {type:mimeType});
        })
      )
    }

    const getEsignatureAccessToken = () => {
      AccountService.getEsignatureAccessToken().then((res) => {
        log("getEsignatureAccessToken data xxxx", res);
    
        if (res.data.success) {
          setAccessToken(res.data.data.token);
        }
    
      }).catch((error) => { 
        log("getEsignatureAccessToken error xxxx", error);
        setFetchingAccessToken(false);
      })
    }
    
    const getEsignatureTokenValidation = (accessToken) => {
      setValidatingAccessToken(true);
    
      AccountService.getEsignatureTokenValidation(accessToken).then((res) => {
        log("getEsignatureTokenValidation data xxxx", res, res.data.data.is_valid);
    
        if (res.data.success) {
          setValidEsignatureToken(res.data.data.is_valid);
          setValidatingAccessToken(false);
        }
    
      }).catch((error) => { 
        log("getEsignatureTokenValidation error xxxx", error);
        setValidatingAccessToken(false);
      })
    } 

    const createSignatureByLink = (formData, accessToken) => {
      setUploadingSignature(true);
      
      AccountService.createSignatureByLink(formData, accessToken).then((res) => {
        log("createSignatureByLink data xxxx", res);
  
        if (res.data.success) {
          setUploadingSignature(false);
          setHasSignature(true);
          setOpenSuccessDialog(true);
  
        }
  
      }).catch((error) => { 
        log("createSignatureByLink error xxxx", error);
  
        setUploadingSignature(false);
      })
    }

    const handleCloseSuccessDialog = () => {
      setOpenSuccessDialog(false);
      
      window.location.href = "/" ;
    }


    
    

    return {signature, accessToken, isValidEsignatureToken, isFetchingAccessToken, isValidatingAccessToken, 
      isUploadingSignature, hasSignature, openSuccessDialog, isRetrievingSig, signatureUrl,
      retrieveSignature, uploadSignature, uploadSignaturePublic, convertUrlToFile, getEsignatureAccessToken, getEsignatureTokenValidation, createSignatureByLink, handleCloseSuccessDialog, setSignature
    }
}

export default useSignature