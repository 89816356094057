import { TableCell, TableRow } from "@mui/material"
import { format } from "date-fns"



const ActivityLogsComponent = ({list}) => {


    return (
        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell align="left" sx={{fontSize: 12, width: 100}}> 
                {list.id}
            </TableCell>
            <TableCell align="left" sx={{fontSize: 12}}> 
                {list.email}
            </TableCell>

            <TableCell align="left" sx={{fontSize: 12}}> 
                {list.module}
            </TableCell>

            <TableCell align="left" sx={{fontSize: 12}}> 
                {list.actions}
            </TableCell>

            <TableCell align="left" sx={{fontSize: 12}}> 
                {list.activity}
            </TableCell>
            <TableCell  align="left" sx={{fontSize: 12}}>
                {list.user_agent === null ? "none" : list.user_agent}
            </TableCell>
            <TableCell align="left" sx={{fontSize: 12}}> 
                {format(new Date(list?.timestamp), 'PPpp')}
            </TableCell>
        </TableRow>
    )
}
export default ActivityLogsComponent