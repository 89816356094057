import React, {useEffect, useState} from "react";
import {Box, Grid, Typography, IconButton} from "@mui/material";
import useWidth from "../hooks/useWidth";
import ProfileComponent from "../components/account/profile.component";
import useUsers from "../hooks/useUsers";
import ProfileDetailsSkeletonComponent from "../components/skeleton/profile-details-skeleton.component";
import ProfileSignatureComponent from "../components/account/profile-signature.component";
import useSignature from "../hooks/useSignature";
import ProfileSignatureSkeletonComponent from "../components/skeleton/profile-signature-skeleton.component";
import DocumentWorkflowService from "../services/api/documents";
import HelpIcon from '@mui/icons-material/Help';
import ProfileGuide from "../components/walkthrough/profile-guide";
import { log } from "../console-config";

function Header () {
    return (
        <Box sx={{padding: 1, width: "100%", borderRadius: 0}}>
            <Grid container sx={{textAlign: "start", ml: 2}}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{fontWeight: "bold", color: "#21649e"}}>
                        Account Profile
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const ProfileScreen = () => {

    const breakpoints = useWidth();

    const { loading, user, retrieveUser } = useUsers();
    const {signature, retrieveSignature, isRetrievingSig} = useSignature();
    const [isWalkthrough, setIsWalkthrough] = useState(null)
    const [showWalkthrough, setShowWalkthrough] = useState(false);

    var module_id = "PRF"

    const handleWalkthroughButtonClick = () => {
        setShowWalkthrough(true);
    };

    const reopenJoyride = () => {
        setShowWalkthrough(true);
    };

    const retrieveWalkthrough = () =>{
        DocumentWorkflowService.getWalkthrough(module_id).then((res)=>{
            log('is user walkthrough', res)
            setIsWalkthrough(res.data.data.is_walkthrough)
        })
    }

    useEffect(()=>{
        retrieveWalkthrough(module_id)
    },[module_id])

    useEffect(() => {
        retrieveUser();
    },[])

    useEffect(() => {
        retrieveSignature();
    },[])

    
    return (
        <Box sx={{overflow: "hidden"}}>

            <Header />

            <Box style={{flexGrow: 1, margin:'auto'}}>
                <Grid container spacing={2} direction="row" sx={{padding: 2}}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{pt: 0}}>
                        {loading?
                            <ProfileDetailsSkeletonComponent breakpoints={breakpoints} />
                        :
                            <ProfileComponent breakpoints={breakpoints} user={user} />
                        }
                    </Grid>
                
                    {isRetrievingSig ? 
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{pt: 0}}>
                            <ProfileSignatureSkeletonComponent breakpoints={breakpoints} />
                        </Grid>
                    :
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{pt: 0}}>
                            <ProfileSignatureComponent breakpoints={breakpoints} signature={signature} user={user} />
                        </Grid>
                    } 
                </Grid>
            </Box>
            <div style={{position:'fixed', bottom: "5px", right:"3%", cursor:'pointer'}} >
                {showWalkthrough ? (
                    <IconButton variant="contain" onClick={handleWalkthroughButtonClick}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                ) : (
                    <IconButton variant="contain" onClick={reopenJoyride}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                )}
            </div>
            {(isWalkthrough === false) && (
                <ProfileGuide setShowWalkthrough={setShowWalkthrough} />
            )}

            {showWalkthrough && <ProfileGuide showWalkthrough={showWalkthrough} setShowWalkthrough={setShowWalkthrough}/>}

        </Box>
    )
}

export default ProfileScreen