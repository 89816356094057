import { Dialog, DialogContent, DialogTitle, Typography, Button, Divider, DialogActions, TextField } from "@mui/material";
import DocumentWorkflowService from "../../../services/api/documents";
import { log } from "../../../console-config";
import { ThreeDots } from "react-loader-spinner";
import { useState } from "react";
import { useSnackbar } from "notistack";


export default function CancelDocumentDialog ({openCancelDocument, setOpenCancelDocument, document}) {

    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState('');
    const [maxWidth, setMaxWidth] = useState('sm');
    const [fullWidth, setFullWidth] = useState(true);
    const [error, setError] = useState(false);
    const [cancelling, setCancelling] = useState(false);

    const handleReasonChange = (event) => {
        setReason(event.target.value);
        if (event.target.value) {
            setError(false);
        }
    };

    const handleClose = () => {
        setOpenCancelDocument(false)
    }

    const handleSubmit = () => {
        if (reason) {
            setCancelling(true);
            DocumentWorkflowService.cancelDocument(document.id, reason).then((res)=>{
                log("cancelDocument data response xxxx", res);
                setCancelling(false)
                if (res?.data?.success) {
                    enqueueSnackbar("Document cancelled.", { 
                        variant: "success",
                        autoHideDuration: 3000
                    });
                    window.location.reload();
                } else {
                    log("cancelDocument reject error response xxxx", res?.data?.error);
                    enqueueSnackbar("Something went wrong. Please try again.", {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                    setCancelling(false);
                    handleClose();
                }
            }).catch((e) => {
                log("cancelDocument error response xxxx", e);
                enqueueSnackbar(e.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
                setCancelling(false);
                handleClose();
            })
        } else {
            setError(true);
        }
        // setLoading(true)
        // DocumentWorkflowService.cancelDocument(document.id).then((res)=>{
        //     log('cancel document res',res)
        //     setLoading(false)
        //     enqueueSnackbar("Document cancelled.", { 
        //         variant: "success",
        //         autoHideDuration: 3000
        //     });
        //     window.location.reload();
        // }).catch((err)=>{
        //     log('error', err)
        //     setLoading(false)
        //     enqueueSnackbar(err.response.data.error, { 
        //         variant: "error",
        //         autoHideDuration: 3000
        //     });
        // })
    }

    return(
        <Dialog open={openCancelDocument} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>Cancel Document</DialogTitle>
            <Divider/>
            <DialogContent sx={{padding: 6, textAlign:'center'}}>
                <Typography sx={{ fontSize: 16 }}>
                    Please provide a reason to cancel the document <strong>{document.title}</strong> :
                </Typography>
                <br />
                <TextField
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    sx={{ "& .MuiInputBase-root": { borderRadius: 4, backgroundColor: 'transparent' } }}
                    inputProps={{ maxLength: 120, style: { fontSize: 14 } }}
                    multiline
                    rows={3}
                    placeholder="Enter reason here..."
                    value={reason}
                    onChange={handleReasonChange}
                    error={error}
                    helperText={error ? "Reason is required" : `${reason.length}/120`}
                />
            </DialogContent>
            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{width: 120, borderRadius: 10}} 
                    onClick={handleClose}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'none'}}
                  onClick={(e) => handleSubmit(e)}
                  startIcon={loading &&
                    <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                }
                >
                   {loading ? 'Cancelling': 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}