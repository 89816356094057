const cache_strategy = {

    StaleWhileRevalidate: "StaleWhileRevalidate",

    NetworkOnly: "NetworkOnly",

    CacheOnly: "CacheOnly",

    NetworkFirst: "NetworkFirst",

    CacheFirst: "CacheFirst"

}

export {cache_strategy}