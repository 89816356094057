import { PublicClientApplication, InteractionStatus } from "@azure/msal-browser";
import { msalconfig, loginRequest } from '../../../config';

// This should be the same instance you pass to MsalProvider
const msalInstance = new PublicClientApplication(msalconfig);

/**
 * getMsalInstance
 * @returns {PublicClientApplication} returns PublicClientApplication object of msalInstance
 */
const getMsalInstance = () => {
    return msalInstance;
}

/**
 * loginPopup
 * @returns {Object} returns Object object return by loginPopup
 */
 const loginPopup = () => {
    return msalInstance.loginPopup(loginRequest);

}

/**
 * loginPopup
 * @returns {Object} returns Object object return by loginPopup
 */
const loginRedirect = () => {
    return msalInstance.loginRedirect(loginRequest);

}

const loginssoSilent = () => {
    return msalInstance.ssoSilent(loginRequest);

}

/**
 * loginPopup
 * @returns {Object} returns Object object return by logoutPopup
 */
 const logoutPopup = () => {
    return msalInstance.logoutPopup();
}

/**
 * acquireTokenSilentFirstAccount
 * @returns {Object} returns Object object return by acquireTokenSilentFirstAccount
 */
 const acquireTokenSilentFirstAccount = () => {
    let accounts = msalInstance.getAllAccounts();
    return msalInstance.acquireTokenSilent(
    {   ...loginRequest,
        account: accounts[0]
    })
}

/**
 * isUserLoggedIn
 * @returns {Boolean} returns boolean 
 */
 const isUserLoggedIn = () => {

    var accounts = getAllAccounts();
    var interactionInProgress = msalInstance.interactionInProgress;

    var isAuthenticated = accounts.length > 0; // checks the array of accounts if has objects
    if(!interactionInProgress){
        interactionInProgress = 'none';
    }

    if (!isAuthenticated && interactionInProgress === InteractionStatus.None) {
        return false
    }

    return true;
}

/**
 * getFirstAccount
 * @returns {AccountInfo} returns first AccountInfo info object from the accounts array 
 */
const getFirstAccount = () => {
    return msalInstance.getAllAccounts()[0];
}

/**
 * getAllAccounts
 * @returns {AccountInfo[]} returns AccountInfo array
 */
 const getAllAccounts = () => {
    return msalInstance.getAllAccounts();
}


/**
 * UNDER CONSTRUCTION
 * has problems after setActiveAccount. If you go to other page, it will getActiveAccount return null
 * getActiveAccount
 * @returns {AccountInfo} returns AccountInfo or null
 */
 const getActiveAccount = () => { // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    return msalInstance.getActiveAccount();
}

const MsalUtil = {
    getMsalInstance,
    loginPopup,
    logoutPopup,
    acquireTokenSilentFirstAccount,
    isUserLoggedIn,
    getFirstAccount,
    getAllAccounts,
    getActiveAccount,
    loginRedirect,
    loginssoSilent
}

export default MsalUtil;