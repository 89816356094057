import { Box, Button, Grid, IconButton, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import FilesTabComponent from "../components/files/files-tab.component";
import FilesComponent from "../components/files/files.component"
import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';
import DocumentWorkflowService from '../services/api/documents';
import FilesGuide from "../components/walkthrough/files-guide";
import { log } from "../console-config";
import { useNavigate } from "react-router-dom";

function FilesHeader(){

    const navigate = useNavigate()

    return(
        <Box sx={{padding: 1, width:'100%', borderRadius: 0}}>
            <Grid container  sx={{width:'98%', margin:'auto'}}>
                <Grid item xs={6} >
                    <Typography variant='h6' sx={{fontWeight: "bold", color: "#21649e"}}>Files</Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'end', alignSelf:'center'}} >
                    <Button className="Create" onClick={()=> navigate("/documents")} variant="contained" sx={{backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', textTransform:'none', pt: 0.5, pb: 0.5, '&:hover': { backgroundColor: "#b5b5b5", color: "white"}}}>
                       <AddIcon sx={{marginRight: 1}}/> Create
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

function FilesScreen() {

    const [isWalkthrough, setIsWalkthrough] = useState(null)
    const [showWalkthrough, setShowWalkthrough] = useState(false);

    var module_id = "FLS"

    const handleWalkthroughButtonClick = () => {
        setShowWalkthrough(true);
    };

    const reopenJoyride = () => {
        setShowWalkthrough(true);
    };

    const retrieveWalkthrough = () =>{
        DocumentWorkflowService.getWalkthrough(module_id).then((res)=>{
            log('is user walkthrough', res)
            setIsWalkthrough(res.data.data.is_walkthrough)
        })
    }

    useEffect(()=>{
        retrieveWalkthrough(module_id)
    },[module_id])

    return(

        <Box sx={{overflow: 'hidden'}}>
            <FilesHeader />
            
            <Box style={{flexGrow: 1, margin:'auto'}}>
                <FilesTabComponent />
            </Box>
            <div style={{position:'fixed', bottom: "5px", right:"3%", cursor:'pointer'}} >
                {showWalkthrough ? (
                    <IconButton variant="contain" onClick={handleWalkthroughButtonClick}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                ) : (
                    <IconButton variant="contain" onClick={reopenJoyride}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                )}
            </div>
            {(isWalkthrough === false) && (
                <FilesGuide setShowWalkthrough={setShowWalkthrough} />
            )}

            {showWalkthrough && <FilesGuide showWalkthrough={showWalkthrough} setShowWalkthrough={setShowWalkthrough}/>}
        </Box>
    )
    
}

export default FilesScreen