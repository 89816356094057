import { Box, Stack, Tooltip, Typography } from '@mui/material';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import moment from 'moment'

import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import GestureIcon from '@mui/icons-material/Gesture';

import { useDragHook } from "../hooks/dnd/useDragHook";
import { dragSourceType, initialDragElements } from "../common/staticVariables";
import { useState } from 'react';
import { log } from '../../../console-config';

export default function DraggableElementComponent({draggable_element}){

    const [isHovered, setIsHovered] = useState(false);

    const onMouseEnter = () => {
        setIsHovered(true)
    }
    
    const onMouseLeave = () => {
        setIsHovered(false)
    }

    log("draggable_elementxxxxxxxx",draggable_element);

    const getDefaultDraggableElProps = (type) => {
        let defaultProps = {}
        if(type == 'esignature') {
            defaultProps.name = draggable_element.name || 'E-Signature';
            defaultProps.width = initialDragElements.userSignature.width;
            defaultProps.height = initialDragElements.userSignature.height;
            defaultProps.value = draggable_element.value
        }
        if(type == 'textfield') {
            defaultProps.name = draggable_element.name || 'Text Field';
            defaultProps.width = initialDragElements.textField.width;
            defaultProps.height = initialDragElements.textField.height;
            defaultProps.value = draggable_element.value
            defaultProps.font_size = 16
        }
        if(type == 'current_date') {
            defaultProps.name = draggable_element.name || 'Current Date';
            defaultProps.value = moment().format('LL');
            defaultProps.width = initialDragElements.currentDate.width
            defaultProps.height = initialDragElements.currentDate.height
            defaultProps.font_size = 16
        }
        if(type == 'qrcode') {
            defaultProps.name = draggable_element.name || 'QR Code';
            defaultProps.width = initialDragElements.qrcode.width;
            defaultProps.height = initialDragElements.qrcode.height;
            defaultProps.value = draggable_element.value
        }
        return defaultProps;
    }

    let item = {type: draggable_element?.type, ...getDefaultDraggableElProps(draggable_element?.type)}
    const {dragRef} = useDragHook(dragSourceType, item)

    function getIconByType(type){
        if(type == 'esignature') return <GestureIcon sx={{color: '#168401'}}/>;
        if(type == 'textfield') return <TextFieldsIcon sx={{color: '#168401'}}/>;
        if(type == 'current_date') return <CalendarMonthIcon sx={{color: '#168401'}}/>;
        if(type == 'qrcode') return <QrCodeOutlinedIcon sx={{color: '#168401'}}/>;
    }

    const getElementsClassName = (className) => {
        if (className === "esignature") return 'Signature';
        if (className === "textfield") return 'Textfield';
        if (className === "current_date") return 'Date';
        if (className === "qrcode") return 'QRcode';
        return '';
    };

    const placeholderTooltip = draggable_element.type === 'current_date' || draggable_element.type === 'qrcode' ? 'bottom' : 'top' ;

    const TooltipLabel = draggable_element.type === 'textfield' && "Click and drag the textfield to insert it into the PDF document." 
    || draggable_element.type === "esignature" && "Click and drag the signature to insert it into the PDF document." 
    || draggable_element.type === "current_date" && "Click and drag the date to insert it into the PDF document." 
    || draggable_element.type === "qrcode" && "Click and drag the QR Code to insert it into the PDF document.";

    return(
        <Tooltip placement={placeholderTooltip} title={TooltipLabel}>
        <Box ref={dragRef} sx={{
                height: '45px', p: 1, 
                color: 'draggable_element.text.primary',
                bgcolor: '#16840138', 
                borderColor: '#16840100', 
                borderStyle: 'solid',
                cursor: 'all-scroll'
            }}
            className={getElementsClassName(draggable_element?.type)}
            // className={draggable_element?.type == "textfield" ? "Textfield" : null}
            onMouseEnter={(e) => onMouseEnter()} 
            onMouseLeave={(e) => onMouseLeave()}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0.5}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0.5}
                    sx={{flex: 1, pl: 1, alignSelf: 'center', transition: 'padding 0.15s ease 0s'}}
                >
                    {isHovered && <DragIndicatorOutlinedIcon sx={{alignSelf: 'center', color: "#168401", transition: 'opacity 0.15s ease 0s, visibility 0.15s ease 0s'}}/> }
                    <Typography sx={{fontSize: 13, color: 'rgba(24, 24, 24, 0.85)'}}  >{getDefaultDraggableElProps(draggable_element?.type).name}</Typography>
                </Stack>
                {!isHovered && 
                    <>
                        {getIconByType(draggable_element.type)}
                    </>
                }
            </Stack>
        </Box>
        </Tooltip>
    )
}