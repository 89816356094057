import React, { useState, useEffect } from "react";
import { Box, Typography, Tabs, Tab, useTheme, Button, List, ListItem, ListItemText, Skeleton } from '@mui/material';
import { CustomTab, CustomTabs } from "../../common/custom-tabs";
import FilesComponent from "./files.component";
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useDocuments from "../../hooks/useDocuments";
import { log } from "../../console-config";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function FilesTabComponent(props) {

  const location = useLocation();
  const navigate = useNavigate();

  const [filesPath, setFilesPath] = React.useState("");
  const [value, setValue] = React.useState(location.filesPath);

  log('GET FILES PATH HERE', filesPath)
  log('get value here', value)

  useEffect(() => {
    let type;
    if (location.pathname == '/files') {
      type = 0
    } else if (location.pathname == '/files/draft') {
      type = 1
    } else if (location.pathname == '/files/pending') {
      type = 2
    } else if (location.pathname == '/files/completed') {
      type = 3
    } 
    else if (location.pathname == '/files/cancelled') {
      type = 4
    }
    else if (location.pathname == '/files/denied') {
      type = 5
    }
    setFilesPath(type)
  }, [location.pathname])

  useEffect(() => {
    setValue(filesPath)
  }, [filesPath])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const { loading, countAllRecords, all, drafts, pending, completed, cancelled, denied } = useDocuments();

  console.log("denied here", denied)

  useEffect(() => {
    countAllRecords();
  }, [])

  return (
    <Box sx={{ width: '100%',  pt: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <CustomTabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ marginTop: 2, marginLeft: 3, marginRight: 3 }}
          indicatorColor="transparent"
          centered
          className="TabStatus"
        >
          <CustomTab label={
            <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'transparent', pt: '0px !important', pb: '0px !important' }} >
              <ListItem sx={{ pt: '0px !important', pb: '0px !important' }}>
                <ListItemText
                  primary={
                    <Typography sx={{ textAlign: 'left', color: '#000', fontWeight: "bold", fontSize: 14 }} >
                      All
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ textAlign: 'left', fontSize: 12 }}>
                      {loading ? (
                        <Skeleton style={{ margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", width: 72 }} />
                      ) : (
                        `${all} ${all === 1 ? 'Document' : 'Documents'}`
                      )}
                    </Typography>
                  }
                />
              </ListItem>
            </List>

          } to="/files" component={Link} {...a11yProps(0)} />
          <CustomTab label={
            <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'transparent', pt: '0px !important', pb: '0px !important' }} >
              <ListItem sx={{ pt: '0px !important', pb: '0px !important' }}>
                <ListItemText
                  primary={
                    <Typography sx={{ textAlign: 'left', color: '#000', fontWeight: "bold", fontSize: 14 }} >
                      Draft
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ textAlign: 'left', fontSize: 12 }}>
                      {loading ? (
                        <Skeleton style={{ margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", width: 72 }} />
                      ) : (
                        `${drafts} ${drafts === 1 || drafts === 0 ? 'Document' : 'Documents'}`
                      )}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          } to="/files/draft" component={Link} {...a11yProps(1)} />
          <CustomTab label={
            <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'transparent', pt: '0px !important', pb: '0px !important' }} >
              <ListItem sx={{ pt: '0px !important', pb: '0px !important' }}>
                <ListItemText
                  primary={
                    <Typography sx={{ textAlign: 'left', color: '#000', fontWeight: "bold", fontSize: 14 }} >
                      Pending
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ textAlign: 'left', fontSize: 12 }}>
                      {loading ? (
                        <Skeleton style={{ margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", width: 72 }} />
                      ) : (
                        `${pending} ${pending === 1 || pending === 0 ? 'Document' : 'Documents'}`
                      )}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          } to="/files/pending" component={Link} {...a11yProps(2)} />
          <CustomTab label={
            <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'transparent', pt: '0px !important', pb: '0px !important' }} >
              <ListItem sx={{ pt: '0px !important', pb: '0px !important' }}>
                <ListItemText
                  primary={
                    <Typography sx={{ textAlign: 'left', color: '#000', fontWeight: "bold", fontSize: 14 }} >
                      Completed
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ textAlign: 'left', fontSize: 12 }}>
                      {loading ? (
                        <Skeleton style={{ margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", width: 72 }} />
                      ) : (
                        `${completed} ${completed === 1 || completed === 0 ? 'Document' : 'Documents'}`
                      )}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          } to="/files/completed" component={Link} {...a11yProps(3)} />
          <CustomTab label={
            <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'transparent', pt: '0px !important', pb: '0px !important' }} >
              <ListItem sx={{ pt: '0px !important', pb: '0px !important' }}>
                <ListItemText
                  primary={
                    <Typography sx={{ textAlign: 'left', color: '#000', fontWeight: "bold", fontSize: 14 }} >
                      Cancelled
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ textAlign: 'left', fontSize: 12 }}>
                      {loading ? (
                        <Skeleton style={{ margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", width: 72 }} />
                      ) : (
                        `${cancelled} ${cancelled === 1 || cancelled === 0 ? 'Document' : 'Documents'}`
                        // '1 Document'
                      )}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          } to="/files/cancelled" component={Link} {...a11yProps(4)} />
           <CustomTab label={
            <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'transparent', pt: '0px !important', pb: '0px !important' }} >
              <ListItem sx={{ pt: '0px !important', pb: '0px !important' }}>
                <ListItemText
                  primary={
                    <Typography sx={{ textAlign: 'left', color: '#000', fontWeight: "bold", fontSize: 14 }} >
                      Denied
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ textAlign: 'left', fontSize: 12 }}>
                      {loading ? (
                        <Skeleton style={{ margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", width: 72 }} />
                      ) : (
                        `${denied} ${denied === 1 || denied === 0 ? 'Document' : 'Documents'}`
                        // '1 Document'
                      )}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          } to="/files/denied" component={Link} {...a11yProps(5)} />
        </CustomTabs>
        

        {/* <Box sx={{ mr: 6.5 }}>
          <Button
            variant="contained"
            onClick={() => navigate('/documents')}
            sx={{
              fontSize: 12,
              p: '4px 5px',
              width: 80,
              backgroundColor: '#0178f2',
              borderRadius: 10,
              boxShadow: 'none',
              color: '#ffff',
              '&:hover': {
                backgroundColor: '#0178f2',
                color: 'white'
              }
            }}
          >
            Create
          </Button>
        </Box> */}

      </Box>

      <TabPanel  value={value} index={0} >
        <FilesComponent chosenTab={""} />
      </TabPanel>

      <TabPanel value={value} index={1} >
        <FilesComponent chosenTab={"DRAFT"} />
      </TabPanel>

      <TabPanel value={value} index={2} >
        <FilesComponent chosenTab={"PENDING"} />
      </TabPanel>

      <TabPanel value={value} index={3} >
        <FilesComponent chosenTab={"COMPLETED"} />
      </TabPanel>

      <TabPanel value={value} index={4} >
        <FilesComponent chosenTab={"CANCELLED"} />
      </TabPanel>

      <TabPanel value={value} index={5} >
        <FilesComponent chosenTab={"DENIED"} />
      </TabPanel>

    </Box>
  );
};

export default FilesTabComponent;