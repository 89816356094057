import React, {useState, useRef, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import {Popper, InputBase, List, ListItem, ListItemIcon, ListItemSecondaryAction, ClickAwayListener, ListItemButton, Button, Typography, Divider, Autocomplete, Box, ListItemText, Paper, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddIcon from '@mui/icons-material/Add';
import AccountPublicService from '../../../../services/api/account_public';
import { log } from '../../../../console-config';
import CloseIcon from '@mui/icons-material/Close';
import { ThreeDots } from "react-loader-spinner";
import { useSnackbar } from 'notistack';

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${
      theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
    }`,
    borderRadius: 6,
    minWidth: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0
}));



const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: 10,
    width: '100%',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
      padding: 8,
      border: `1px solid rgb(0 0 0 / 20%)}`,
      fontSize: 14,
    },
}));


const AutoCompleteSelectComponent = ({selectedSearchUser, setSelectedSearchUser, searchEmailSignee, options, getOptionLabel, label, addlabel, placeholder, defaultValue, value, textColor, 
    onChange, onAdd, noOptionsText, setSearchEmail, searchEmail, handleInputChange, dataArray, recentEmail, handleClickRecentEmail, handleSelectUserSignee, setUpdateDataArray, updated_dataArray}) => {

    const ref = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [autoCompleteEl, setAutoCompleteEl] = useState(ref.current);
    const [loadingSignee, setLoadingSignee] = useState(null);

    log('get selected search user', selectedSearchUser)

    const handleOpenAutoComplete = (event) => {
        setAutoCompleteEl(autoCompleteEl ? null : event.currentTarget);
    }

    useEffect(() => {
        setAutoCompleteEl(ref.current);
    },[])

    const handleCloseAutoComplete = () => {
        setAutoCompleteEl(null);
    }

    const handleChangeValue = (e, value) => {
        log("value aaa", value);
        if (value) {
            onChange(value)
            handleCloseAutoComplete()
        }
    }

    const open = Boolean(autoCompleteEl);
    const id = open ? 'simple-popper' : undefined;

    const getLabel = () => {
        if (!value) return undefined
        if (typeof value === "object")
            if (Object.keys(value).length > 0)
                return getOptionLabel(value)
        return value
    }

    const hasOptionLabel = () => {
        return !!getLabel() 
    }

    const handleRemoveSignee = (signee) => {
        setLoadingSignee(signee);
        AccountPublicService.deletRecentAddedSignee(signee).then((res) => {
            console.log('delete recent signatory res', res);
            const recentlyAddedSignees = res.data.data.recently_added_signees
                ?.substring(1, res.data.data.recently_added_signees?.length - 1)
                .split(', ');
            setUpdateDataArray(recentlyAddedSignees);
            setLoadingSignee(null);
        }).catch(error => {
            console.error("Failed to delete signee", error);
            setLoadingSignee(null);
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        });
    };

    return(
        <>
            <List disablePadding sx={{border: "1px solid rgb(0 0 0 / 14%)", borderRadius: "4px"}}>
                <Button onClick={handleOpenAutoComplete} sx={{pt: 0, pb: 0, display:'flex', alignItems:'center'}}>
                {/* <ListItem button > */}
                    {/* <ListItemText sx={{padding: 0}} primary={ */}
                        <Typography noWrap sx={{color: textColor, alignSelf:'center', fontSize: 13}}>{hasOptionLabel() ? getLabel() : label}</Typography>
                    {/* } /> */}
                    {/* <ListItemSecondaryAction> */}
                        {/* {open ? <ArrowDropUpIcon sx={{mt: 1, color: textColor}} /> : <ArrowDropDownIcon sx={{mt: 1, color: textColor}} />} */}
                        <ArrowDropDownIcon sx={{color: textColor, alignSelf:'center'}} />
                    {/* </ListItemSecondaryAction> */}
                </Button>
            </List>
            {open &&
                <StyledPopper sx={{width: ref.current ? ref.current.offsetWidth : 0}} id={id} open={true} anchorEl={autoCompleteEl}>
                    <ClickAwayListener onClickAway={handleCloseAutoComplete}>
                        <div>
                            <Box sx={{mt: 1}}>
                                <Autocomplete
                                    fullWidth
                                    options={options}
                                    // getOptionLabel={getOptionLabel}
                                    value={recentEmail ? recentEmail : selectedSearchUser}
                                    // disabled={disabled}
                                    onChange={handleChangeValue}
                                    // loading={isLoadingDirectoryList}
                                    open={true}
                                    // defaultValue={defaultValue}
                                    noOptionsText={
                                        <Typography variant="caption" sx={{fontSize: 14, color: "rgb(0 0 0 / 38%)"}}>
                                            {noOptionsText}
                                        </Typography>
                                        }
                                    ListboxProps={{
                                        onScroll: (event) => {
                                            const listboxNode = event.currentTarget;
                                            if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                                log("load more data xxx");
                                            }
                                        }
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper>
                                            <Divider />
                                            <List>
                                                <ListItemButton selected onClick={onAdd} sx={{'&.Mui-selected': {
                                                    backgroundColor: '#1684013b',
                                                },}}>
                                                    <ListItemIcon>
                                                        <AddIcon style={{color: "#168401"}} />
                                                    </ListItemIcon>

                                                    <ListItemText primary={
                                                        <Typography variant="caption" sx={{ml: "-25px", color: "#168401"}}>
                                                            {addlabel}
                                                        </Typography>}
                                                    />
                                                </ListItemButton>
                                            </List>
                                            {searchEmail ?
                                                <> 
                                                    { searchEmailSignee?.map((searchSignee, index)=>(
                                                        <Button sx={{textTransform:'none', justifyContent:'flex-start'}} onClick={(e) => handleSelectUserSignee(searchSignee?.email_address, handleCloseAutoComplete)} fullWidth variant="text" key={index}>
                                                            {searchSignee?.email_address}
                                                        </Button> 
                                                    ))}
                                                </>
                                            :
                                                <>
                                                    {updated_dataArray && updated_dataArray.length > 0 ? (
                                                            updated_dataArray.map((signee, index) => (
                                                                <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                                                    <>
                                                                        <Button
                                                                            sx={{ textTransform: 'none', justifyContent: 'flex-start', flexGrow: 1,  color:'#168401' }}
                                                                            onClick={(e) => handleSelectUserSignee(signee, handleCloseAutoComplete)}
                                                                            fullWidth 
                                                                            variant="text" 
                                                                            key={signee}
                                                                        >
                                                                            {signee}
                                                                        </Button>
                                                                        <IconButton
                                                                            aria-label="close"
                                                                            onClick={() => handleRemoveSignee(signee)}
                                                                            sx={{ ml: 1, mr: 1 }}
                                                                        >
                                                                            {loadingSignee === signee ?
                                                                                <ThreeDots height="20" width="20" radius="9" color="#168401" ariaLabel="three-dots-loading" visible={true} />
                                                                            :
                                                                                <CloseIcon sx={{ width: '12px' }} />
                                                                            }
                                                                        </IconButton>
                                                                    </>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <Button disabled fullWidth variant="caption" sx={{ color: "#168401", textTransform: 'none' }}>
                                                                No emails found.
                                                            </Button>
                                                        )}
                                                    {/* {dataArray ?
                                                         <>
                                                             {dataArray?.map((signee) => (
                                                                <Button sx={{textTransform:'none', justifyContent:'flex-start', color:'#168401'}} onClick={(e)=> handleSelectUserSignee(signee, handleCloseAutoComplete)} fullWidth variant="text" key={signee}>{signee}</Button>
                                                            ))}
                                                         </>
                                                        :
                                                        <Button disabled fullWidth variant="caption" sx={{color: "#168401", textTransform:'none'}}>
                                                           No emails found.
                                                        </Button>
                                                    } */}
                                                </>
                                            }
                                        </Paper>
                                    )}
                                    renderOption={(props) => {
                                        return(
                                            <li key={props['data-option-index']} {...props}>{props.key}</li>
                                        )
                                    }}
                                    renderInput={(params) => 
                                        <>
                                            <StyledInput
                                                ref={params.InputProps.ref}
                                                inputProps={params.inputProps}
                                                autoFocus
                                                placeholder={placeholder}
                                                onChange={handleInputChange}
                                            />
                                        </>
                                    }
                                />
                            </Box>
                        </div>
                    </ClickAwayListener>
                </StyledPopper>
            }
        </>
    )
}

export default AutoCompleteSelectComponent