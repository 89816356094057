import React, {useEffect} from "react";
import {styled} from '@mui/material/styles';
import {Button, ListItem, Grid, ListItemText, IconButton, CssBaseline, Toolbar, Avatar, Box, ListItemAvatar, Typography, Skeleton} from "@mui/material";
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerHeader from "./drawer-header";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Sidebar from "./sidebar";
import AccountPopper from "./account-popper";
import useWidth from "../hooks/useWidth";
import useUsers from "../hooks/useUsers";
import TopNavigationComponent from "./top-navigation.component";
import logo from "../icons/nextixLogo.png";
import { stringAvatar } from "../utils/stringAvatarUtil";

const drawerWidth = 250;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),

        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Navbar = ({children}) => {

    const breakpoints = useWidth();

    const {loading, user, retrieveUser} = useUsers();

    const [open, setOpen] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleDrawerOpen = () => {setOpen(true);}
    const handleDrawerClose = () => {setOpen(false);}
    const handleOpenMenu = (event) => {setAnchorEl(event.currentTarget);}
    const handleCloseMenu = () => {setAnchorEl(null);}

    // const stringAvatar = (name) => {
    //     return {
    //         children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    //     }
    // }

    useEffect(() => {
        retrieveUser();
    },[])


    return (
        <Box sx={{display: "flex"}}>
            <CssBaseline />

            <AppBar position="fixed" open={open} sx={{width: (breakpoints == 'xs' || breakpoints == 'sm') ? "100%" : {}, backgroundColor: "#fff", height: 64, boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)'}}>
                <Toolbar sx={{paddingLeft: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {}, paddingRight: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {}}}>

                    {(breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') &&
                        <IconButton edge="start" color="inherit" aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            sx={{mr: 5, ...(open && {display: "none"})}}
                        >
                            <MenuIcon sx={{color: "#21649e"}} />
                        </IconButton>
                    } 

                    <Button variant="text" disableElevation sx={{textTransform: "none", cursor: "default", ...(open && {display: 'none'}), 
                        ...((breakpoints == 'xs' || breakpoints == 'sm') && {display: "visible"}), 
                        color: "rgb(0 0 0 / 87%)", fontSize: "20px", fontWeight: "bold"}}
                    >
                        <img alt="" src={logo} style={{cursor: "default", height: 25}}/>
                    </Button>

                    <ListItem sx={{ml: "auto", width: "auto"}}>
                        <ListItemAvatar sx={{minWidth: 0}}>
                            {(breakpoints == 'xs' || breakpoints == 'sm') ?
                                <Grid>
                                    <IconButton
                                        aria-label="dropdown-menu"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenMenu}
                                    >
                                        {user &&
                                            <Avatar alt="" {...stringAvatar(user?.firstName ? user?.firstName : user?.email_address )} src={user?.profile_picture} style={{color: "#fff", background: "#0178f2", width: 40, height: 40, border:'1px solid #0178f2'}} />
                                        }
                                    </IconButton>

                                    {/* account popper menu dropdown if mobile view */}
                                    <AccountPopper anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} />
                                </Grid>

                            : 
                                <>
                                    {user &&
                                        <Avatar alt="" {...stringAvatar(user?.firstName ? user?.firstName : user?.email_address )} src={user?.profile_picture} style={{color: "#fff", background: "#168401", width: 40, height: 40, border:'1px solid #168401'}} />
                                    }
                                </>
                            }
                        </ListItemAvatar>

                        
                            
                        {loading ?
                            <Skeleton variant="rectangular" style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                        :
                            <>
                                {(breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') &&
                                    <ListItemText 
                                        primary={
                                            <Typography variant="caption" component="p" sx={{color: "#000", ml: 1, textAlign: "justify", fontSize: 16, fontWeight:'bold'}}>
                                                {user?.firstName ? user?.firstName : null} {user?.lastName ? user?.lastName : null}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant="caption" component="p" sx={{color: "#000", ml: 1, textAlign: "justify", fontSize: 12}}>
                                                {user?.email_address}
                                            </Typography>
                                        }
                                    />
                                }
                            </>
                        }
                            
                        
                    
                        {(breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') && 
                            <Grid>
                                <IconButton
                                    aria-label="dropdown-menu"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenMenu}
                                >
                                    <ExpandMoreIcon fontSize="medium" style={{color: "#168401"}} className="Dropdown" />
                                </IconButton>

                                {/* account popper menu dropdown if laptop view */}
                                <AccountPopper anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} /> 
                            </Grid>
                        }
                    </ListItem>
                </Toolbar>
            </AppBar>

            {/* for sidebar */}
            {(breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') && 
                <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
            }

            {/* top navigation bar if mobile view */}
            {(breakpoints == 'xs' || breakpoints == 'sm') && 
                <TopNavigationComponent breakpoints={breakpoints} />
            } 

            <Box component="main" sx={{flexGrow: 1, pt: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 1, width: "100%"}}>
                <DrawerHeader />   

                {(breakpoints == 'xs' || breakpoints == 'sm') && <DrawerHeader/>}

                {children}   
            </Box>
        </Box>
    )

}

export default Navbar