import { Button, Card, CardActionArea, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@mui/material"


function AddSigneeDialogComponent({openAddSigneeDialog, handleCloseAddSigneeDialog}) {
    return(
        <Dialog open={openAddSigneeDialog} onClose={handleCloseAddSigneeDialog}maxWidth={"sm"} fullWidth={"sm"} 
            aria-labelledby="responsive-dialog-title"
            PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}}}
        >
            <DialogTitle>
                Add Signee
            </DialogTitle>

            <DialogContent>
                <TextField fullWidth sx={{margin: 'auto', backgroundColor:'white'}} placeholder="Email" variant="outlined" 
                    InputProps={{
                        style: {fontSize: 14, padding: 0}
                    }}
                />
            </DialogContent>
            <DialogActions>
                    <Button sx={{textTransform:'none'}} onClick={handleCloseAddSigneeDialog}>
                        Cancel
                    </Button>
                    <Button variant="contained" sx={{textTransform:'none'}} onClick={handleCloseAddSigneeDialog}>
                        Add
                    </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddSigneeDialogComponent