import React, {useEffect, useState} from 'react';
import {Box,  Grid, Typography, IconButton} from '@mui/material';
import useWidth from "../hooks/useWidth";
import DigitalSignatureComponent from '../components/digital-siganture/digital-signature.component';
import useSignature from '../hooks/useSignature';
import AccountService from '../services/api/accounts';
import DocumentGuide from '../components/walkthrough/documents-guide';
import HelpIcon from '@mui/icons-material/Help';
import DocumentWorkflowService from '../services/api/documents';
import { log } from '../console-config';


function DigitalSignatureHeader(){

    return(
        <Box sx={{padding: 1, width:'100%', borderRadius: 0}}>
            <Grid container  sx={{textAlign: 'start', marginLeft: 2}}>
                <Grid item xs={12} >
                    <Typography variant='h6' sx={{fontWeight: "bold", color: "#21649e"}}>Documents</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}


function DigitalSignatureScreen () {

    const breakpoints = useWidth();
    const {signature, retrieveSignature, isUploadingSignature} = useSignature();
    const [isWalkthrough, setIsWalkthrough] = useState(null)
    const [showWalkthrough, setShowWalkthrough] = useState(false);
    const [data, setData] = useState([]);

    var module_id = "DOC"

    const handleWalkthroughButtonClick = () => {
        setShowWalkthrough(true);
    };

    const reopenJoyride = () => {
        setShowWalkthrough(true);
    };

    useEffect(() => {
      retrieveSignature();
    }, [])

    // useEffect(()=>{
    //     const fetchData = async () => {
    //         try {
    //           const response = await AccountService.retrieveSignature();
    //           setData(response.data.data);
    //         } catch (error) {
    //           console.error('Error fetching data:', error);
    //         }
    //       };
      
    //         fetchData();
    //         if (isUploadingSignature){

    //             const intervalId = setInterval(fetchData, 5000); // Fetch data every 5 seconds (for example)

    //             // Clean up the interval to prevent memory leaks when the component unmounts
    //             return () => clearInterval(intervalId);
    //         }
    // },[])

    

    const retrieveWalkthrough = () =>{
        DocumentWorkflowService.getWalkthrough(module_id).then((res)=>{
            log('is user walkthrough', res)
            setIsWalkthrough(res.data.data.is_walkthrough)
        })
    }

    useEffect(()=>{
        retrieveWalkthrough(module_id)
    },[module_id])

    return (
        <Box sx={{overflow: 'hidden'}}>

            <DigitalSignatureHeader signature={data} />
            
            <Box style={{flexGrow: 1, margin:'auto'}}>
                <DigitalSignatureComponent breakpoints={breakpoints} signature={signature} />
            </Box>
            <div style={{position:'fixed', bottom: "5px", right:"3%", cursor:'pointer'}} >
                {showWalkthrough ? (
                    <IconButton variant="contain" onClick={handleWalkthroughButtonClick}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                ) : (
                    <IconButton variant="contain" onClick={reopenJoyride}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                )}
            </div>
            {(isWalkthrough === false) && (
                <DocumentGuide setShowWalkthrough={setShowWalkthrough} />
            )}

            {showWalkthrough && <DocumentGuide showWalkthrough={showWalkthrough} setShowWalkthrough={setShowWalkthrough}/>}
        </Box>
    )
}

export default DigitalSignatureScreen