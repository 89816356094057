import { v4 as uuidv4 } from 'uuid';

import { getReverseProportionDimens, getReverseProportionCoords, getProportionalCoords, getProportionalDimens, getProportionalFontSize, getReverseProportionFontSize } from "../../utils/ProportionDimenUtil";
import { elementTypes, pdfPageSizes } from "../common/staticVariables";
import { log } from '../../../console-config';

export const useElementHook = (dndRef, currentPage) => {

    let dropRefCurrentWidth = dndRef?.current?.clientWidth
    let dropRefCurrentHeight = dndRef?.current?.clientHeight
    let rotation = currentPage?.rotate
    let isLandscape = rotation == 90 || rotation == 270 ? true : false

    //TODO create rotation condition
    let pageOrigWidth = isLandscape ? currentPage?.originalHeight : currentPage?.originalWidth
    let pageOrigHeight = isLandscape ? currentPage?.originalWidth : currentPage?.originalHeight

    const createElement = (initialPosition, afterDropPosition, page_no, type, width, height, x, y, value, signeeId, font_size, page_rect) => {
        let {w, h} = getReverseProportionDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, width, height)
        log("width aaa", width);
        log("height aaa", height);
        log("w aaa", w);
        log("h aaa", h);
        w = isNaN(w) ? 0 : w 
        h = isNaN(h) ? 0 : h 

        // return {id: uuidv4(), initialPosition, afterDropPosition, page_no, type, width: w, height: h, origWidth: width, origHeight: height, x, y, value, ...(signeeId && {signeeId}), font_size}
        return {id: uuidv4(), initialPosition, afterDropPosition, page_no, type, width: width, height: height, origWidth: width,
            origHeight: height, x, y, value, ...(signeeId && {signeeId}), font_size, page_rect}
    }

    const getProportionalElementCoords = (propX, propY, dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOriginalWidth, pageOriginalHeight) => {
        log("dropRefCurrentWidth 11", dropRefCurrentWidth);
        log("dropRefCurrentHeight 11", dropRefCurrentHeight);
        log("pageOriginalWidth 11", pageOriginalWidth);
        log("pageOriginalHeight 11", pageOriginalHeight);
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
        log("isLandscape 11", isLandscape);

        let pageWidth = isLandscape ? pageOriginalHeight : pageOriginalWidth
        let pageHeight = isLandscape ? pageOriginalWidth : pageOriginalHeight
        
        log("pageWidth 11", pageWidth);
        log("pageHeight 11", pageHeight);
        log("x 11", propX);
        log("y 11", propY);
        
        let {x,y} = getProportionalCoords(dropRefCurrentWidth, dropRefCurrentHeight, pageWidth, pageHeight, propX, propY)
        return {x,y}
    }

    const getProportionalElementDimens = (width, height, dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOriginalWidth, pageOriginalHeight) => {
        log("element width aa", width);
        log("element height aa", height);
        log("pageOriginalWidth aa", pageOriginalWidth);
        log("pageOriginalHeight aa", pageOriginalHeight);
        log("dropRefCurrentWidth aa", dropRefCurrentWidth);
        log("dropRefCurrentHeight aa", dropRefCurrentHeight);
        let isLandscape = rotation == 90 || rotation == 270 ? true : false

        let pageWidth = isLandscape ? pageOriginalHeight : pageOriginalWidth
        let pageHeight = isLandscape ? pageOriginalWidth : pageOriginalHeight

        log("pageWidth aa", pageOrigWidth);
        log("pageHeight aa", pageOrigHeight);

        let test = getProportionalDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageWidth, pageHeight, 290, 56)
        log("test 1sss", test);

        return getProportionalDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageWidth, pageHeight, width, height)
    }

    const getReverseProportionElementCoords = (propX, propY, dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOriginalWidth, pageOriginalHeight, isOriginalPageDimensRotated = true) => {
        log("dropRefCurrentWidth getReverseProportionElementCoords", dropRefCurrentWidth);
        log("dropRefCurrentHeight getReverseProportionElementCoords", dropRefCurrentHeight);
        log("pageOriginalWidth getReverseProportionElementCoords", pageOriginalWidth);
        log("pageOriginalHeight getReverseProportionElementCoords", pageOriginalHeight);
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
        log("isLandscape getReverseProportionElementCoords", isLandscape);

        let pageWidth = isOriginalPageDimensRotated && isLandscape ? pageOriginalHeight : pageOriginalWidth
        let pageHeight = isOriginalPageDimensRotated && isLandscape ? pageOriginalWidth : pageOriginalHeight
        
        log("pageWidth getReverseProportionElementCoords", pageWidth);
        log("pageHeight getReverseProportionElementCoords", pageHeight);
        log("aaax getReverseProportionElementCoords", propX);
        log("aaay getReverseProportionElementCoords", propY);

        let {x,y} = getReverseProportionCoords(dropRefCurrentWidth, dropRefCurrentHeight, pageWidth, pageHeight, propX, propY)
        log("aaax 111 getReverseProportionElementCoords", x);
        log("aaay 111 getReverseProportionElementCoords", y);
        
        return {x,y}
    }

    const getReverseProportionElementDimens = (rotation, dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, width, height, isOriginalPageDimensRotated = true) => {
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
        console.log("isLandscape 11xxx", isLandscape, rotation);

        let pageWidth = isOriginalPageDimensRotated && isLandscape ? pageOrigHeight : pageOrigWidth
        let pageHeight = isOriginalPageDimensRotated && isLandscape ? pageOrigWidth : pageOrigHeight

        let {w, h} = getReverseProportionDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageWidth, pageHeight, width, height)
        return {w,h}
    }

    const getProportionalElementFontSize = (rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, font_size) => {
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
        let pageWidth = isLandscape ? pageOrigHeight : pageOrigWidth
        console.log("rotation from util 11", rotation);
        console.log("pageOrigWidth from util 11", pageOrigWidth);
        console.log("pageWidth from util 11", pageWidth);

        return getProportionalFontSize(dropRefCurrentWidth, pageWidth, font_size)
    }

    const getReverseProportionalElementFontSize = (rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, font_size, isOriginalPageDimensRotated = true) => {
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
        let pageWidth = isOriginalPageDimensRotated && isLandscape ? pageOrigHeight : pageOrigWidth

        return getReverseProportionFontSize(dropRefCurrentWidth, pageWidth, font_size)
    }

    return {createElement, getProportionalElementCoords, getProportionalElementDimens, getReverseProportionElementCoords, getReverseProportionElementDimens, 
        getProportionalElementFontSize, getReverseProportionalElementFontSize}
}