import React, {useState} from "react";
import {Box, Stack, Typography } from "@mui/material";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import {useDragHook} from "../hooks/dnd/useDragHook";
import {dragSourceType, initialDragElements} from "../common/staticVariables";

export default function DraggableFillableElementComponent({currentSignee, draggable_fillable_element}){

    const [isHovering, setHovering] = useState(false);

    const onMouseEnter = () => {setHovering(true);}
    
    const onMouseLeave = () => {setHovering(false);}

    const getDefaultDraggableElProps = (type) => {
        let defaultProps = {}
        
        if (type == "signatory") {
            defaultProps.name = draggable_fillable_element.name || "Signatory";
            defaultProps.width = initialDragElements.signatory.width;
            defaultProps.height = initialDragElements.signatory.height;
        }

        return defaultProps;
    }

    let item = {type: draggable_fillable_element?.type, ...getDefaultDraggableElProps(draggable_fillable_element?.type), value: currentSignee?.signee, signeeId: currentSignee?.id}
    const {dragRef} = useDragHook(dragSourceType, item)

    function getIconByType(type){
        if (type == "signatory") return <BorderColorOutlinedIcon fontSize="small" style={{color: "#168401"}} />;
    }

    return (
        <Box ref={dragRef} 
            sx={{
                height: '45px', p: 1, 
                color: 'draggable_element.text.primary',
                bgcolor: '#16840138', 
                borderColor: '#16840100', 
                borderStyle: 'solid',
                cursor: 'all-scroll'
            }}
            className={draggable_fillable_element?.type === "signatory" ? "Signatory" : ""}
            onMouseEnter={(e) => onMouseEnter()} 
            onMouseLeave={(e) => onMouseLeave()}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0.5}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0.5}
                >
                    {isHovering && <DragIndicatorOutlinedIcon style={{color: "#168401"}} />}
                    
                    <Typography sx={{fontSize: 13, color: "rgba(24, 24, 24, 0.85)"}}>
                        {getDefaultDraggableElProps(draggable_fillable_element?.type).name}
                    </Typography>
                </Stack>

                {!isHovering && getIconByType(draggable_fillable_element.type)}
            </Stack>
        </Box>
    )
}