import React, {useState, useEffect, useRef} from 'react';
import { Box, IconButton, Stack, Tooltip, Fade, Badge } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { elementTypes } from "../common/staticVariables";
import SelectSigneeComponent from './SelectSigneeComponent';
import AccountPublicService from '../../../services/api/account_public';
import { log } from '../../../console-config';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenWithIcon from '@mui/icons-material/OpenWith';

function FontSizeSelect({fontSize, setIsEditing, onChangeFontSize}) {

  // const [size, setSize] = useState(fontSize);
  const handleChange = (event) => {
    // setSize(event.target.value);
    onChangeFontSize(event.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <Select
          style={{color: '#e5e5e5', height: '30px'}}
          labelId="font-size-label"
          id="font-size-select"
          value={fontSize}
          label="Size"
          onChange={handleChange}
          onOpen={()=>setIsEditing(true)}
          onClose={()=>setIsEditing(false)}
        >
          {/* TODO */}
          <MenuItem value={16}>16</MenuItem>
          <MenuItem value={18}>18</MenuItem>
          <MenuItem value={20}>20</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

const Signee = ({element, onChangeSignee, signees, signeesOptionLabel, onAddSignee, onAddToSignees, onAssignNewSigneeToElement, currentUser, onErrorAddSignee, getDocumentById, handleOnMouseEnter, elementToolbarRef, setIsElementClicked}) => {

  const [recentSignatory, setRecentSignatory ]= useState(null)
  const [updated_dataArray, setUpdateDataArray] = useState(null);

  const handleOnChangeSignee = (signee) => {
    onChangeSignee(signee)
  }

  const getRecentSignatory = () => {
    AccountPublicService.getRecentAddedSignees().then((res)=>{
      setRecentSignatory(res.data.data.recently_added_signees)
      let recent_signee = res.data.data.recently_added_signees?.substring(1, res.data.data.recently_added_signees?.length - 1).split(", ");
      setUpdateDataArray(recent_signee)
      log('get recent signatory res', res)
    })
  }

  useEffect(()=>{ getRecentSignatory() },[])

  const dataArray = recentSignatory?.substring(1, recentSignatory?.length - 1).split(", ");

  log('GET DATA ARRAY FROM SIGNATORY', dataArray)

  let signeeValue = signees.find((signee) => signee.email_address == element?.value) 
  log("element vaule aa", element);
  log("signee vaule aa", signeeValue);

  return(
    <SelectSigneeComponent options={signees} signeesOptionLabel={signeesOptionLabel} value={signeeValue} textColor={'white'} onChange={handleOnChangeSignee} updated_dataArray={updated_dataArray}
      onAddSignee={onAddSignee} onAddToSignees={onAddToSignees} onAssignNewSigneeToElement={onAssignNewSigneeToElement} element={element} setIsElementClicked={setIsElementClicked} setUpdateDataArray={setUpdateDataArray}
      currentUser={currentUser} onErrorAddSignee={onErrorAddSignee} dataArray={dataArray} getDocumentById={getDocumentById} elementToolbarRef={elementToolbarRef} handleOnMouseEnter={handleOnMouseEnter}/>
  )
}

const ElementToolbarComponent = ({element, isElementClicked, onEditElement, onRemoveElement, setIsEditing, onChangeFontSize, onChangeSignee, setIsDraggingUsingToolbarDragItem,
  signees, signeesOptionLabel, onAddSignee, onAddToSignees, onAssignNewSigneeToElement, currentUser, onErrorAddSignee, getDocumentById, setDuplicateElementData, elDragRef, elementToolbarRef, setTextElementFontSize, setIsElementClicked}) => {

    const [disableUpFontSize, setDisableUpFontSize] = useState(element.font_size >= 64 ? true : false)
    const [disableDownFontSize, setDisableDownFontSize] = useState(element.font_size <= 8 ? true : false)

    const handleDuplicateElements = () => {
      setDuplicateElementData({isDuplicate: true, data: element})
    }

    const handleOnMouseEnter = (isDraggable) => {
      console.log("handleOnMouseEnter bbbb", isDraggable);
      setIsDraggingUsingToolbarDragItem(true)
    }

    const handleUpFontSize = () => {
      if (element.font_size >= 64) {
          setDisableUpFontSize(true)
      } else {
          setDisableDownFontSize(false)
          setTextElementFontSize({id: element.id, font_size: element.font_size + 2})
      }
    }

    const handleDownFontSize = () => {
        if (element.font_size <= 8) {
            setDisableDownFontSize(true)
        } else {
            setDisableUpFontSize(false)
            setTextElementFontSize({id: element.id, font_size: element.font_size - 2})
        }
    }

    const actions = [
        {title: "Drag", type: 'dragElement', icon_component: <OpenWithIcon onMouseEnter={() => handleOnMouseEnter(true)} sx={{color: '#e5e5e5', cursor: 'all-scroll', height: 15, width: 15}}/>, 
        },
        {title: "Edit", icon_component: <EditIcon onMouseEnter={() => handleOnMouseEnter(false)} sx={{color: '#e5e5e5', height: 13, width: 13}}/>, onClick: onEditElement},
        {title: "Increase Font Size", type: 'upFontSize', icon_component: <Badge onClick={handleUpFontSize} onMouseEnter={() => handleOnMouseEnter(false)} badgeContent={<b style={{height: 5, marginLeft: 8}}> ▲</b>} 
            style={{backgroundColor: 'transparent', color: disableUpFontSize ? "gray" : '#e5e5e5'}}>
            <span style={{color: disableUpFontSize ? "gray" : '#e5e5e5', fontSize: 14}}>A</span>
        </Badge>, 
        },

        {title: "Decrease Font Size", type: 'downFontSize', icon_component: <Badge onClick={handleDownFontSize} badgeContent={<b style={{height: 5, marginLeft: 5}}> ▼</b>} 
            style={{backgroundColor: 'transparent', color: disableDownFontSize ? "gray" : '#e5e5e5'}}>
            <span style={{color: disableDownFontSize ? "gray" : '#e5e5e5', fontSize: 14}}>A</span>
        </Badge>, 
        },
        {title: "Duplicate", type: 'duplicate', icon_component: <ContentCopyIcon onMouseEnter={() => handleOnMouseEnter(false)} sx={{color: '#e5e5e5', height: 13, width: 13}}
            onClick={() => handleDuplicateElements()} 
        />, 
        },
        {title: "Delete", icon_component: <DeleteIcon onMouseEnter={() => handleOnMouseEnter(false)} sx={{color: '#e5e5e5',  "&:hover": { color: "#ff7961" }, height: 13, width: 13}}/>, onClick: onRemoveElement},
    ]

    function handleOnChangeFontSize(font_size){
      // todo
      log("TODO: handleOnChangeFontSize - pass size to parent ", font_size)
      onChangeFontSize(font_size);
    }

    return(
        <Fade direction="up" in={isElementClicked} mountOnEnter unmountOnExit>
            <Box onMouseEnter={() => handleOnMouseEnter(false)} ref={elementToolbarRef} sx={{zIndex: 9999, bgcolor: "#474747",  position: 'absolute', mt: -5, width: 'auto', borderRadius: '4px', justifyContent:'end'}}>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={0.5}
                >
                  {element.type == elementTypes.signatory && <Signee elementToolbarRef={elementToolbarRef} handleOnMouseEnter={handleOnMouseEnter} element={element} onChangeSignee={onChangeSignee} getDocumentById={getDocumentById}
                    signees={signees} signeesOptionLabel={signeesOptionLabel} onAddSignee={onAddSignee} onAddToSignees={onAddToSignees} setIsElementClicked={setIsElementClicked}
                    onAssignNewSigneeToElement={onAssignNewSigneeToElement} currentUser={currentUser} onErrorAddSignee={onErrorAddSignee} />}
                  {/* {(element.type == elementTypes.textField || element.type == elementTypes.currentDate) && <FontSizeSelect fontSize={element.font_size} setIsEditing={setIsEditing} onChangeFontSize={handleOnChangeFontSize}/>} */}
                  {actions.map((action)=>{
                      if(action.title == 'Edit' && element.type != elementTypes.userSignature) return null;
                      if(action.title == 'Increase Font Size' && element.type != elementTypes.textField ) return null;
                      if(action.title == 'Decrease Font Size' && element.type != elementTypes.textField) return null;

                      // userSignature: 'esignature',
                      // companySeal: 'eseal',
                      // qrcode: 'qrcode',
                      // textField: 'textfield',
                      // currentDate: 'current_date',
                      // signatory: 'signatory',
                      return(
                          <Tooltip key={action.title} title={action.title} arrow>
                              <IconButton sx={{"&:hover": {backgroundColor: "#474747"}}} onClick={action.onClick}>
                                  {action.icon_component}
                              </IconButton>
                          </Tooltip>
                      )
                  })}
                </Stack>
            </Box>
        </Fade>
    )
}

export default ElementToolbarComponent