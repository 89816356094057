import React, { useState } from 'react';
import ReactJoyride from 'react-joyride';
import ovsignLogo from "../../icons/nextixLogo.png"
import AccountService from '../../services/api/accounts';
import DocumentWorkflowService from '../../services/api/documents';
import { log } from '../../console-config';

const DocumentGuide = ({showWalkthrough, setShowWalkthrough}) => {
  const [joyride, setJoyride] = useState({
    run: true,
    steps: [
      {
        content: <div>
            <img src={ovsignLogo} style={{height: 30}}/>
            <p style={{fontSize: 19, color:'#0178f2', fontWeight:'bold'}}>
                Set up your first document.
            </p>
        </div>,
        target: "body",
        placement: "center",
        // content: <div><p>You will be presented with an all-encompassing view, showcasing not only the total number of impeccably crafted documents but also offering a comprehensive display of analytics and real-time updates on the most recent transactions.</p></div>,
        disableBeacon: true,
      },
      {
        target: ".CreateSignature",
        content: "Click to begin creating your electronic signature",
        placement: "bottom",
      },
      {
        content: "Upload your files using either drag and drop or click to upload.",
        target: ".DragandDrop",
      },
      {
        content: "Display the document name after uploading. You can also change the document name.",
        target: ".DocumentName",
      },
      
      {
        content: "After uploading your documents, click 'Submit' to access our PDF editor. ",
        target: ".Submit",
      },
      
    ]
  });

//   const handleStepCallback = (data) => {
//     const { status, type, action } = data;
//     let is_walkthrough = 0
//     if (status === 'finished' && type === 'tour:end' || action === 'close'|| action === 'skip' ) {
//         AccountService.postUserWalkthrough(is_walkthrough).then((res)=>{
//           log('update walkthrough res', res)
//           window.location.reload()
//         })
//     }
//     log('ACTIONSSSSSS', action)
// };

  const handleStepCallback = (data) => {
    const { status, type, action } = data;
    let module_id = "DOC"
    let is_walkthrough = 1
    if (action === 'close'|| action === 'skip' ) {
      DocumentWorkflowService.walkthroughUpdate(module_id, is_walkthrough).then((res)=>{
        log('update walkthrough res', res)
      })
      setShowWalkthrough(false)
    } else if (status === 'finished' &&  type === 'tour:end'){
      DocumentWorkflowService.walkthroughUpdate(module_id, is_walkthrough).then((res)=>{
        log('update walkthrough res', res)
      })
      setShowWalkthrough(false)
    }
    log('ACTIONSSSSSS', action)
  };


  return (<React.Fragment>
    <ReactJoyride
      steps={joyride.steps}
      run={showWalkthrough}
      continuous
      showProgress
      showSkipButton
      disableScrolling={true}
      styles={{
        options: {
          arrowColor: '#fff',
          primaryColor: '#0178f2',
          textColor: '#000',
          width: 380,
          zIndex: 1250,
        },
        buttonNext: {
          backgroundColor: '#168401',
          borderRadius: 4,
          color: '#fff',
          borderColor: '#168401',
          outline: 'none',
        },
        buttonBack: {
          color: '#0178f2',
          marginLeft: 'auto',
          marginRight: 5,
        },
      }}
      callback={handleStepCallback} // Set the callback function
    />
  </React.Fragment>);
};

export default DocumentGuide


