import React, {useState, useRef, useEffect} from "react";
import {Stack, Slider, IconButton, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {ThreeDots} from "react-loader-spinner";
import { convertUrlToFile } from "../../../ovdocs/utils/convertUrlToFileUtil";
import AccountService from "../../../services/api/accounts";
import { useSnackbar } from "notistack";
import imageCompressor from "image-compressor";
import { log } from "../../../console-config";

const ImageCropperDialog = (props) => {

    const {breakpoints, openImageEditor, setOpenImageEditor, userImg, setImageStateFromChild, setPhoto, setPhotoFile, setUserPhotoPreview, setPhotoPreview, tempUserPhoto} = props;

    const url = window.location.pathname;
    const {enqueueSnackbar} = useSnackbar();
    const editorRef = useRef();
    const [image, setImage] = useState(null);
    const [zoomValue, setZoomValue] = useState(1.2);
    const [isZoomInDisabled, setZoomInDisabled] = useState(false);
    const [isZoomOutDisabled, setZoomOutDisabled] = useState(false);
    const [isSavingPhoto, setSavingPhoto] = useState(false);

    useEffect(() => {
        if (userImg) {
            setImage(userImg);
        }

    }, [userImg])

    const handleCloseDialog = (e) => {
        setOpenImageEditor(false);
        setImage(null);
        setPhotoFile(null);
    }

    // const handleSave = (e) => {
    //     setSavingPhoto(true);

    //     const canvas = editorRef.current.getImageScaledToCanvas();
    //     const base64Image = canvas.toDataURL();

    //     convertUrlToFile(base64Image, "photo_file.png", "image/png").then(function(file) {
    //         console.log('cropped image logs', file)
    //         let formData = new FormData();
    //         formData.append("file", file);
    //         setLoading(true)
    //         AccountService.uploadProfile(formData).then((res)=>{
    //             console.log('upload profile response', res);
    //             if(res.data.success){
    //                 // setLoading(false)
    //                 window.location.reload()
    //                 setSavingPhoto(false);
    //                 setOpenImageEditor(false);
    //                 setPhotoFile(file);
    //                 setUserPhotoPreview(base64Image);
    //                 enqueueSnackbar("Uploaded", { 
    //                     variant: "success",
    //                     autoHideDuration: 3000
    //                 });
    //             }
                
    //         }).catch((error)=>{
    //             // setLoading(false)
    //             setSavingPhoto(false);
    //             enqueueSnackbar(error.response.data.error, { 
    //                 variant: "error",
    //                 autoHideDuration: 3000
    //             });
    //         })
                
    //     })
    // }

    const handleSave = async (e) => {
        setSavingPhoto(true);
      
        const canvas = editorRef.current.getImageScaledToCanvas();
        const base64Image = canvas.toDataURL();
      
        try {
          const compressedBase64Image = await compressImage(base64Image, 0.6); // Adjust the quality between 0 and 1 as needed
          const compressedFile = convertBase64ToFile(compressedBase64Image, "photo_file.png", "image/png");
      
          let formData = new FormData();
          formData.append("file", compressedFile);
      
          AccountService.uploadProfile(formData)
            .then((res) => {
              log('upload profile response', res);
              if (res.data.success) {
                window.location.reload();
                setSavingPhoto(false);
                setOpenImageEditor(false);
                setPhotoFile(compressedFile);
                setUserPhotoPreview(compressedBase64Image);
                enqueueSnackbar("Uploaded", {
                  variant: "success",
                  autoHideDuration: 3000
                });
              }
            })
            .catch((error) => {
              setSavingPhoto(false);
              enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
              });
            });
        } 
        catch (error) {
          console.error("Image compression error:", error);
        }
      };
      
      // Helper function to compress the image using canvas
      const compressImage = (base64Image, quality) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = base64Image;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
      
            // Set the canvas dimensions to the image dimensions
            canvas.width = img.width;
            canvas.height = img.height;
      
            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0, img.width, img.height);
      
            // Convert the canvas back to base64 with the specified quality
            const compressedBase64 = canvas.toDataURL("image/jpeg", quality);
      
            resolve(compressedBase64);
          };
          img.onerror = (error) => {
            reject(error);
          };
        });
      };
      
      // Helper function to convert base64 to a File object
      const convertBase64ToFile = (base64Image, fileName, mimeType) => {
        const byteString = atob(base64Image.split(",")[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new File([ab], fileName, { type: mimeType });
      };

    const handleSliderChange = (event, newValue) => {
        let minValue = 1;
        let maxValue = 10;

        if ((zoomValue == minValue) || (zoomValue < minValue)) {
            setZoomInDisabled(true);
            setZoomOutDisabled(false);

        } else if ((zoomValue == maxValue) || (zoomValue > maxValue)) {
            setZoomOutDisabled(true);
            setZoomInDisabled(false);

        } else {
            setZoomInDisabled(false);
            setZoomOutDisabled(false);
        }

        setZoomValue(newValue);
    }

    const handleZoomIn = () => {
        let minValue = 1;

        setZoomOutDisabled(false);

        if ((zoomValue == minValue) || (zoomValue < minValue)) {
            setZoomInDisabled(true);

        } else {
            setZoomInDisabled(false);
            setZoomValue(zoomValue - 0.1);
        }
    }

    const handleZoomOut = () => {
        let maxValue = 10;

        setZoomInDisabled(false);

        if ((zoomValue == maxValue) || (zoomValue > maxValue)) {
            setZoomOutDisabled(true);

        } else {
            setZoomOutDisabled(false);
            setZoomValue(zoomValue + 0.1);
        }
    }


 
    return (
        <Dialog open={openImageEditor} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 10}}}
            onClose={(e) => handleCloseDialog(e)}
        >
            <DialogTitle sx={{fontWeight: "bold"}}>
                Crop Photo
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: "24px 24px 10px"}}>
                <Box sx={{objectFit: "contain", mt: -1, textAlign: "center",  zoom: (breakpoints == "xs" || breakpoints == "sm") ? "30%" : "120%" }}>
                    <AvatarEditor
                        ref={editorRef}
                        image={image}
                        color={[179, 215, 243, 0.4]}
                        rotate={0}
                        width={120}
                        height={120}
                        border={50}
                        scale={zoomValue}
                        disableBoundaryChecks={false}
                    />
                </Box>

                <Stack spacing={2} direction="row" alignItems="center" sx={{margin: (breakpoints == "xs" || breakpoints == "sm") ? {} : "16px auto 0px"}}>
                    <IconButton onClick={(e) => (isZoomInDisabled) ? null : handleZoomIn()} 
                        sx={{cursor: (isZoomInDisabled) ? "no-drop" : "pointer"}}
                    >
                        <RemoveIcon fontSize="small" sx={{color: (isZoomInDisabled) ? "#8080808f" : "#000"}} />
                    </IconButton>

                    <Slider 
                        size="large"
                        value={zoomValue} 
                        onChange={handleSliderChange}
                        aria-label="Default"
                        min={1}
                        max={10}
                        step={0.1}
                    />

                    <IconButton onClick={(e) => (isZoomOutDisabled) ? null : handleZoomOut()} 
                        sx={{cursor: (isZoomOutDisabled) ? "no-drop" : "pointer"}}
                    >
                        <AddIcon fontSize="small" sx={{color: (isZoomOutDisabled) ? "#8080808f" : "#000"}} />
                    </IconButton>
                </Stack>
            </DialogContent>
            
            <DialogActions sx={{padding: 6, paddingTop: 3, paddingBottom: 3, paddingRight: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}}}>
                <Button sx={{width: "15%", borderRadius: 10, color: "#0178f2", textTransform: "none", fontSize: 14}} variant="outlined"
                    onClick={(e) => handleCloseDialog(e)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{width: (isSavingPhoto) ? "auto" : "15%", backgroundColor: "#0178f2", borderRadius: 10, textTransform: "none", 
                    fontSize: 14, '&:hover': {backgroundColor: "#0178f2", color: "white"}}}
                    onClick={(e) => (image) ? handleSave(e) : null}
                    startIcon={isSavingPhoto && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {(isSavingPhoto) ? "Saving" : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ImageCropperDialog