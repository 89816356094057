import React, {useState, useEffect, useRef} from 'react';
import { Box } from '@mui/material';
import { Page } from 'react-pdf';
import { v4 as uuidv4 } from 'uuid';


import ElementSigneeContainerComponent from './ElementSigneeContainerComponent';
import { getReverseProportionCoords, getReverseProportionDimens, getReverseProportionFontSize } from "../../utils/ProportionDimenUtil";
import { getAdjFontSize } from "../../utils/FontUtil";
import { useElementHook } from "../hooks/useElementHook";
import { useDropHook } from "../hooks/dnd/useDropHook";
import { elementTypes } from "../common/staticVariables";
import { log } from '../../../console-config';

let pagesArr = []
let elements_from_api = []

const PDFPageSigneeComponent = ({numPages, page_number, adjustedPDFWidth, elementProps, isViewing, onChangeSignature, onUpdateSignatoryCounter}) => {

    const [currentPage, setCurrentPage] = useState({})
    const [currentElement, setCurrentElement] = useState(null)
    // const [pdfPages, setPdfPages] = useState([])
    const [hasUpdatedUserSigOnLoad, setHasUpdatedUserSigOnLoad] = useState(false)

    const pdfPageRef = useRef()

    const {dndRef, dropResult} = useDropHook(pdfPageRef, "pdfSignee")

    const {getProportionalElementCoords, getReverseProportionElementCoords, getReverseProportionElementDimens, getProportionalElementDimens, getReverseProportionalElementFontSize} = useElementHook(pdfPageRef, currentPage)

    const elements = elementProps.elements
    const elements_to_plot = elementProps.elements_to_plot
    const setElements = elementProps.setElements
    const pdfResult = elementProps.pdfResult
    const setPDFResult = elementProps.setPDFResult
    const signatory = elementProps.signatory
    const currentUser = elementProps.currentUser
    const currentUserSignature = elementProps.currentUserSignature
    const elRefs = elementProps.elRefs
    
    // handle PDF Pages
    useEffect(() => {
        if (elements_to_plot?.length > 0 && pagesArr?.length > 0) {
            elements_to_plot.forEach((element) => {
                log("elements_to_plot in PDFSigneeComponent: ", elements_to_plot)
                let page = pagesArr.find((item) => item.pageNo == element.page_no)
                if (element.page_no == page.pageNo) {
                    console.log("elements_to_plot page: ", page, element.page_no)

                    let rotation = page.rotate

                    let {x, y} = getReverseProportionElementCoords(element.x, element.y, page.dropRefCurrentWidth, page.dropRefCurrentHeight, rotation, page.pageOrigWidth, page.pageOrigHeight)
                    let {w: width, h: height} = getReverseProportionElementDimens(rotation, page.dropRefCurrentWidth, page.dropRefCurrentHeight, page.pageOrigWidth, page.pageOrigHeight, element.width, element.height)
                    let {font_size} = getReverseProportionalElementFontSize(rotation, page.dropRefCurrentWidth, page.pageOrigWidth, page.pageOrigHeight, element.font_size)
                    console.log("proportional_font_size bbb 111", font_size, element.type);
                    console.log("x bbb 111", x, element.type);
                    console.log("y bbb 111", y, element.type);
                    console.log("width bbb 111", width, element.type);
                    console.log("height bbb 111", height, element.type);
                    
                    element = {...element, id: element.id != undefined ? element.id : uuidv4(), x, y, width, height};
                    if(element.type == elementTypes.currentDate || element.type == elementTypes.textField){
                        element.font_size = font_size
                    }
                    elements_from_api.push(element)
                }
            })
            let uniqueJsonArray = Array.from(new Set(elements_from_api.map(obj => JSON.stringify(obj))), JSON.parse);
            console.log("elements_from_api aaaa", uniqueJsonArray);
            setElements(uniqueJsonArray)
        }
    }, [JSON.stringify(pagesArr), JSON.stringify(elements_to_plot)])

    // handle create/update elements
    useEffect(() => {
        log("current element aa", currentElement);
        if (currentElement) handleElement(currentElement)
    }, [JSON.stringify(currentElement)])

    // handle update PDF Result after updating elements
    useEffect(() => {
        log("elements lll", elements);
        if (elements.length > 0){
            handlePDFResult(elements)
            handleUpdateSignatoryCounter(elements)
        }
        else
            setPDFResult({...pdfResult, elements: [], total_page: numPages})
    }, [JSON.stringify(elements)])

    function handleOnLoadSuccessPDFPage(page) {
        setCurrentPage(page)
        handleLoadInitialElements(page)
        const elements = document.getElementsByClassName("react-pdf__Page__textContent");

        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    function getFontSizes(element, currentPage){
        var orig_font_size = element.font_size ? element.font_size : 16; // 16px as default
        var adjusted_font_size = getAdjFontSize(orig_font_size, currentPage.originalWidth, currentPage.width);
        element.font_size = orig_font_size;
        element.adjusted_font_size = adjusted_font_size;
        return element;
    }

    const handleLoadInitialElements = (page) => {
        let rotate = page?.rotate

        let isLandscape = rotate == 90 || rotate == 270 ? true : false
        let pageWidth = isLandscape ? page?.height : page?.width
        let pageHeight = isLandscape ? page?.width : page?.height

        let dropRefCurrentWidth = pageWidth
        let dropRefCurrentHeight = pageHeight

        let pageOrigWidth = page?.originalWidth
        let pageOrigHeight = page?.originalHeight - page?.view[1]
        let pageNo = page?.pageNumber
        let originalWidth = pageOrigWidth
        let originalHeight = pageOrigHeight
        let width = page?.width
        let height = page?.height

        let pdf_page = {dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, pageNo, rotate, originalWidth, originalHeight, width, height}
        pagesArr.push(pdf_page)

        // if (pagesArr.length == numPages) setPdfPages(pagesArr)
    }

    const handleCurrentElement = (element) => {
        setCurrentElement(element)
    }

    const handlePDFResult = (elements) => {
        let pdf_result_elements = elements.map((el) => handleGetPDFResultElement(el))
        setPDFResult({...pdfResult, elements: pdf_result_elements, signatory, total_page: numPages})
    }

    const handleUpdateSignatoryCounter = (elements) => {
        let signatories = elements.filter((el) => el.type == elementTypes.signatory);
        let signatories_counter = {remainingSig: 0, totalSig: signatories.length};
        
        if (signatories.length > 0) {
            let hasSignedCounter = signatories.filter((el) => el.isSigned)
            signatories_counter.remainingSig = hasSignedCounter.length
        }
        onUpdateSignatoryCounter(signatories_counter)
    }

    const handleGetPDFResultElement = (element) => {
        let {x, y} = getProportionalElementCoords(element.x, element.y)
        let {w: width, h: height} = getProportionalElementDimens(element.width, element.height)

        let updated_element_props = {x, y, width, height}
        return {...element, ...updated_element_props}
    }

    const handleElement = (currentElement) => {
        log("currentElement oooo", currentElement);
        if (currentElement.type == elementTypes.signatory) {
            setElements((prev) => prev.map(el => {
                let updatedEl
                if (el.value === currentElement.value && el.isSigned) {
                    updatedEl = {...el, width: currentElement.width, height: currentElement.height, isSigned: currentElement.isSigned, signature: currentElement.signature}
                } else if (el.id == currentElement.id) {
                    updatedEl = currentElement
                } else {
                    updatedEl = el
                }
                return updatedEl
            }))
        }
    }

    const handleOnEditElement = () => {

    }

    return(
        <>
            <p style={{color: '#5e5d5d'}}>Page {currentPage?.pageNumber} / {numPages}</p>
            <Page inputRef={dndRef} className={'pdf_page_custom'} pageNumber={page_number} 
            width={adjustedPDFWidth}
            scale={1}
            onLoadSuccess={handleOnLoadSuccessPDFPage}>
                {
                    elements.map((el, i) => {
                        return(
                            <>
                                {(el.page_no == page_number) &&
                                    <>
                                        <ElementSigneeContainerComponent 
                                            element={el}
                                            currentPage={currentPage}
                                            setCurrentElement={handleCurrentElement} 
                                            onEditElement={handleOnEditElement} 
                                            currentUser={currentUser}
                                            currentUserSignature={currentUserSignature}
                                            elRefs={elRefs}
                                            isViewing={isViewing}
                                            signatory={signatory}
                                            onChangeSignature={onChangeSignature}
                                        />
                                    </>
                                }
                            </>
                        )
                    })
                }
            </Page>
        </>
    )
}

export default PDFPageSigneeComponent