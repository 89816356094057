import { ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Button, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import eyeIcon from '../../icons/eye.png';
import resendIcon from '../../icons/resend.png';
import deleteIcon from '../../icons/delete.png';
import historyIcon from '../../icons/history.png';
import downloadIcon from '../../icons/downloadIcon.png'
import DocumentSignatoriesDialogComponent from "./dialog/document-signatories-dialog.component";
import SignatureIcon from '../../icons/signature.png';
import CancelDocumentDialog from "./dialog/cancel-document-dialog.component";
import CancelIcon from '../../icons/multiply.png'
import ResendIcon from '../../icons/resend.png'
import { useNavigate } from "react-router-dom";
import DeleteFileDialog from "../dialog/delete-file-dialog.component";
import { log } from "../../console-config";

function ActionsPopperComponent(props) {

    const navigate = useNavigate();

    const { isViewing, document, setOpenDownloadFile, setOpenResendEmail, setOpenRetryEmail, setOpenRetryError,
            isRetryAllowed, documentDetails, setOpenResendFile, 
        setOpenHistory } = props;



    const [anchorEl, setAnchorEl] = useState(null);
    const [openSignatories, setOpenSignatories] = useState(false);
    const [openCancelDocument, setOpenCancelDocument] = useState(false);
    const [openDeleteFile , setOpenDeleteFile] = useState(false);

    log("documentDetailsxxx", documentDetails)
    log("document action popper", document)

    const handleOpenSignatories = () => {
        setOpenSignatories(true)
    }

    const handleOpenCancelDocument = () => {
        setOpenCancelDocument(true)
    }

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleOpenDownloadFile = () => {
        setOpenDownloadFile(true);
    };

    const handleOpenResendEmail = () => {
        setOpenResendEmail(true);
    }

    const handleOpenRetryEmail = () => {
        setOpenRetryEmail(true);
    }

    const handleOpenRetryError = () => {
        setOpenRetryError(true);
    }

    const handleOpenResendFile = () => {
        setOpenResendFile(true);
    }

    const handleOpenHistory = () => {
        setOpenHistory(true);
    }

    const handleCloseDeleteFile = () => {
        setOpenDeleteFile(false);
    };

    const handleOpenDeleteFile = (id, title) => {
        setOpenDeleteFile(true);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


    return (
        <div>
            <IconButton sx={{ color: 'black' }} onClick={handleClick} >
                <MoreVertIcon aria-describedby={id} style={{ cursor: "pointer" }} />
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{ borderRadius: 2 }}>
                    <ClickAwayListener onClickAway={handleClick}>
                        <MenuList sx={{ width: 150 }}>
                            {document?.status === "COMPLETED" ?
                                <MenuItem style={{ color: '#168401', fontSize: 13, fonWeight: 500 }} onClick={(e) => navigate("/view-template?id=" + document.id)}>
                                    <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                        <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#168401' }}>
                                            <img src={eyeIcon} style={{ width: 16, height: 16 }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        {isViewing ? <ThreeDots height="20" width="20" radius="9" color="#168401" ariaLabel="three-dots-loading" visible={true} /> : 'View'}
                                    </Typography>
                                </MenuItem>
                                :
                                document?.status === "ERROR" || document?.status === "error" ?
                                    null
                                    :
                                    <MenuItem style={{ color: '#168401', fontSize: 13, fonWeight: 500 }} onClick={(e) => navigate("/view-template?id=" + document.id)}>
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#168401' }}>
                                                <img src={eyeIcon} style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">
                                            View
                                        </Typography>
                                    </MenuItem>
                            }
                            {document.status === 'PENDING' && document?.total_signed !== document?.total_signatory ?
                                <MenuItem style={{ color: '#168401', fontSize: 13, fonWeight: 500 }} onClick={handleOpenResendEmail}>
                                    <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                        <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#168401' }}>
                                            <img src={resendIcon} style={{ width: 16, height: 16 }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        Resend Email
                                    </Typography>
                                </MenuItem>
                                :
                                null
                            }
                            {document.status === 'PENDING' && document?.total_signed === document?.total_signatory && isRetryAllowed(document?.created_at) ?
                                <MenuItem style={{ color: '#168401', fontSize: 13, fonWeight: 500 }} onClick={handleOpenRetryEmail}>
                                    <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                        <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#168401' }}>
                                            <img src={resendIcon} style={{ width: 16, height: 16 }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        Retry
                                    </Typography>
                                </MenuItem>
                                :
                                null
                            }
                            {document.status === 'ERROR' || document.status === 'error' ?
                                <MenuItem style={{ color: '#168401', fontSize: 13, fonWeight: 500 }} onClick={handleOpenRetryError}>
                                    <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                        <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#168401' }}>
                                            <img src={resendIcon} style={{ width: 16, height: 16 }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        Retry
                                    </Typography>
                                </MenuItem>
                                :
                                null
                            }
                            {/* {document.status == "DRAFT" || document.status == "CANCELLED" ? */}
                            {document.status == "DRAFT" ?
                                <MenuItem style={{ color: '#168401', fontSize: 13, fonWeight: 500 }} onClick={(e) => navigate('/pdf-template?id=' + document.id)}>
                                    <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                        <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#168401' }}>
                                            <EditIcon style={{ width: 16, height: 16 }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        Edit
                                    </Typography>
                                </MenuItem>
                                :
                                null
                            }
                            {document.status === "CANCELLED" || document.status === "DENIED" || document.status === "PENDING" ? (
                                <MenuItem style={{ color: '#168401', fontSize: 13, fontWeight: 500 }} onClick={handleOpenResendFile}>
                                    <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                        <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#168401' }}>
                                            <img src={ResendIcon} style={{ width: 16, height: 16 }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        Resend
                                    </Typography>
                                </MenuItem>
                            ) : null}

                            {document.status == 'COMPLETED' &&
                                <MenuItem style={{ color: '#168401', fontSize: 13, fonWeight: 500 }} onClick={(e) => handleOpenDownloadFile(e)}>
                                    <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                        <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#168401' }}>
                                            <img src={downloadIcon} style={{ width: 16, height: 16 }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit" >
                                        Download
                                    </Typography>
                                </MenuItem>
                            }


                            {document.status === 'PENDING' || document.status === 'COMPLETED' || document.status == "CANCELLED" ?
                                <MenuItem style={{ color: '#168401', fontSize: 13, fonWeight: 500 }} onClick={handleOpenSignatories}>
                                    <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                        <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#168401' }}>
                                            <img src={SignatureIcon} style={{ width: 16, height: 16 }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        Signatories
                                    </Typography>
                                </MenuItem>
                                : null
                            }

                            <MenuItem style={{ color: '#168401', fontSize: 13, fonWeight: 500 }} onClick={handleOpenHistory} >
                                <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                    <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#168401' }}>
                                        <img src={historyIcon} style={{ width: 16, height: 16 }} />
                                    </IconButton>
                                </ListItemIcon>
                                <Typography variant="inherit" >
                                    History
                                </Typography>
                            </MenuItem>

                            {document.status == 'DRAFT' || document.status === 'ERROR' || document.status === 'error'  ?
                                <MenuItem style={{ color: '#168401', fontSize: 13, fonWeight: 500 }} onClick={() => handleOpenDeleteFile(document.id, document.title)}>
                                    <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                        <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#168401' }}>
                                            <img src={deleteIcon} style={{ width: 16, height: 16 }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit" sx={{ color: 'red' }}>
                                        Delete
                                    </Typography>
                                </MenuItem>
                                :
                                null
                            }


                            {document.status === 'PENDING' ?
                                <MenuItem style={{ color: 'red', fontSize: 13, fonWeight: 500 }} onClick={handleOpenCancelDocument}>
                                    <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                        <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: 'red' }}>
                                            <img src={CancelIcon} style={{ width: 16, height: 16 }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        Cancel
                                    </Typography>
                                </MenuItem>
                                : null
                            }

                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            {openSignatories && <DocumentSignatoriesDialogComponent documentDetails={documentDetails} openSignatories={openSignatories} setOpenSignatories={setOpenSignatories} />}

            {openCancelDocument && <CancelDocumentDialog document={document} openCancelDocument={openCancelDocument} setOpenCancelDocument={setOpenCancelDocument} />}

            {openDeleteFile &&
                <DeleteFileDialog
                    openDeleteFile={openDeleteFile} handleClose={handleCloseDeleteFile}  userDocument={document} setOpenDeleteFile={setOpenDeleteFile}
                    retrieveDocuments={props.retrieveDocuments}
                />
            }

        </div>
    )
}

export default ActionsPopperComponent
