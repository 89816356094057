import React, { useState } from 'react';
import ReactJoyride from 'react-joyride';
import ovsignLogo from "../../icons/nextixLogo.png"
import AccountService from '../../services/api/accounts';
import DocumentWorkflowService from '../../services/api/documents';
import { log } from '../../console-config';

const FilesGuide = ({showWalkthrough, setShowWalkthrough}) => {
  const [joyride, setJoyride] = useState({
    run: true,
    steps: [
      {
        content: 
        <div>
            <img src={ovsignLogo} style={{height: 30}}/>
            <p style={{fontSize: 19, color:'#0178f2', fontWeight:'bold'}}>
                Let's get started.
            </p>
        </div>,
        target: "body",
        placement: "center",
        disableBeacon: true,
      },
      {
        content: "Display the total number of documents created.",
        target: ".AllDocument",
      },
      {
        content: "Display the total number of documents in drafts.",
        target: ".TotalDrafts",
      },
      {
        content: "Display the total number of pending documents.",
        target: ".TotalPending",
      },
      {
        content: "Display the total number of documents with completed statuses.",
        target: ".TotalCompleted",
      },
      {
        content: "Filter graph display by year",
        target: ".FilterYear",
      },
      {
        content: "Display the total count of draft, pending, and completed documents during the month.",
        target: ".GraphAll",
      },
      {
        content: "Display the total count of documents in the draft stage",
        target: ".GraphDraft",
      },
      {
        content: "Display the total count of documents awaiting review, approval, or pending action.",
        target: ".GraphPending",
      },
      {
        content: "Documents that have successfully passed all stages of signed.",
        target: ".GraphCompleted",
      },
    ]
  });

  const handleStepCallback = (data) => {
    const { status, type, action } = data;
    let module_id = "ANL";
    let is_walkthrough = 1
    if (action === 'close'|| action === 'skip' ) {
      DocumentWorkflowService.walkthroughUpdate(module_id, is_walkthrough).then((res)=>{
        log('update walkthrough res', res)
        DocumentWorkflowService.getWalkthrough(module_id, true).then(()=>{ // update cache
          // window.location.reload();
        })
      })
      setShowWalkthrough(false)
    } else if (status === 'finished' &&  type === 'tour:end'){
      DocumentWorkflowService.walkthroughUpdate(module_id, is_walkthrough).then((res)=>{
        log('update walkthrough res', res)
        DocumentWorkflowService.getWalkthrough(module_id, true).then(()=>{ // update cache
          // window.location.reload();
        })
      })
      setShowWalkthrough(false)
    }
    log('ACTIONSSSSSS', action)
  };


  return (<React.Fragment>
    <ReactJoyride
      steps={joyride.steps}
      run={showWalkthrough ? showWalkthrough : joyride.run}
      continuous
      scrollOffset={100}
      showProgress
      showSkipButton
      styles={{
        options: {
          arrowColor: '#fff',
          primaryColor: '#0178f2',
          textColor: '#000',
          width: 380,
          zIndex: 1250,
        },
        buttonNext: {
          backgroundColor: '#168401',
          borderRadius: 4,
          color: '#fff',
          borderColor: '#168401',
          outline: 'none',
        },
        buttonBack: {
          color: '#0178f2',
          marginLeft: 'auto',
          marginRight: 5,
        },
      }}
      locale={{
        skip: "Skip",
        last:"Done",
        
      }}
      callback={handleStepCallback} // Set the callback function
    />
  </React.Fragment>);
};

export default FilesGuide


