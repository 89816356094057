import { Grid, Button, Typography, Box } from "@mui/material"
import AuthService from "../../services/auth";
import DrawerHeader from "../../layout/drawer-header";
import OVSignLogo from "../../icons/nextixLogo.png"
import { log } from "../../console-config";
import { useEffect } from "react";

import { PublicClientApplication, InteractionStatus } from "@azure/msal-browser";
import { msalconfig, loginRequest } from '../../config';


const msalInstance = new PublicClientApplication(msalconfig);

function BannerContentComponent(props) {

  const { breakpoints, isUserLoggedIn } = props;

  console.log('get isUserLoggedIn', isUserLoggedIn)

  const loginPrompt = new URLSearchParams(window.location.search).get("login");

  const login = () => {
    AuthService.login().then((res) => {

      window.location.href = "/dashboard";

    }).catch((error) => {

      log("login error iii", error);

    });
  }

  const loginssoSilent = () => {
    AuthService.loginssoSilent().then((res) => {

      window.location.href = "/dashboard";

    }).catch((error) => {

      log("login error iii", error);

    });
  }


  useEffect(() => {

    if(isUserLoggedIn) {
      const userAccount = msalInstance.getAllAccounts()[0]

      if (userAccount) {
        window.location.href = "/dashboard"
      } else {
        if (loginPrompt) {
          loginssoSilent();
        }
      }
    }
  }, [isUserLoggedIn])


  return (
    <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{ alignSelf: 'start', textAlign: 'left', margin: 0, position: 'absolute', top: '50% ', transform: 'translate(0, -50%)' }} >
      <img src={OVSignLogo} style={{height: breakpoints == 'xs' || breakpoints == 'sm' ? 90 : 120, paddingLeft: 0, pb: 0}}/>
      {breakpoints == 'xs' || breakpoints == 'sm' ? null : <Box sx={{ mt: 2 }} />}
      <Typography sx={{ textAlign: 'justify', maxWidth: breakpoints == 'xs' || breakpoints == 'sm' ? '100%' : 430, color: '#000', textJustify: 'inter-word', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', ml: breakpoints == 'xs' || breakpoints == 'sm' ? '0px' : 1, margin: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : '0px' }}>
        Ovcode offers seemless solutions for your agreement and approval needs by allowing all stakeholders to participate; and complexities of digital signing workflows are eliminated.
      </Typography>
      <Button variant="contained" sx={{mt: 3, width: 120, backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', mb: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 2}} onClick={login}>
        Sign In
      </Button>
      {/* <Typography sx={{ fontSize: 12, display: 'flex', alignItems: 'center' }} >
        Not registered yet? <p style={{ color: '#0178f2', marginLeft: '5px', cursor: 'pointer' }} onClick={() => window.open('https://app.eid.ovcode.com', '_blank')}>Create an Account</p>
      </Typography> */}
    </Grid>

  )
}

export default BannerContentComponent