import { useState } from "react";
import DocumentWorkflowService from "../services/api/documents";
import { log } from "../console-config";



const useUserLogs = () => {

    const [isFetchingLogs, setFetchingLogs] = useState(false);
    const [sessionLogs, setSessionLogs] = useState([]);
    const [pagination, setPagination] = useState(0);

    var limit = 5;


    const userLogs = (page) => {
        setFetchingLogs(true);
        DocumentWorkflowService.userLogs(page, limit).then((res)=>{
            log('get user logs', res)
            if (res.data.success) {
                setFetchingLogs(false);

                setSessionLogs(res.data.data.values);

                if (res.data.data.values.length === 0) {
                    setPagination(0);

                } else {
                    const last_page = Math.ceil(res.data.data.values.length / limit);
                    
                    setPagination(last_page);
                }
            }
        }).catch(()=>{
            setFetchingLogs(false);
        })
    }

return {userLogs, pagination, setSessionLogs, setFetchingLogs, isFetchingLogs, sessionLogs}
}

export default useUserLogs;