import React, {useEffect} from "react";
import { log } from "../../../console-config";

const SignatureTextToImage = (props) => {
    
    const {signatureText, sendDataToParent, parentFontSize, parentFontStyle, parentFontFamily} = props;

    useEffect(() => {
        
        let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
        var textMetrics = canvasTxt.measureText(signatureText);
        
        canvasTxt.canvas.width = textMetrics.width;

        canvasTxt.canvas.height = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;

        let fontSize;

        if (signatureText.length <= 7) {
            fontSize = "50px";

        } else {
            fontSize = parentFontSize;
        }

        let fontStyle = parentFontStyle;
        let fontFamily = parentFontFamily;

        canvasTxt.font = `${fontStyle} ${fontSize} ${fontFamily}`;

        canvasTxt.fillText(signatureText, 0, textMetrics.actualBoundingBoxAscent);
        log("signature textMetrics xxxx", textMetrics);

        sendDataToParent(canvasTxt.canvas.toDataURL());

    }, [sendDataToParent])

    
    return (
        <div>
            <canvas id="canvasComponent" style={{display: "none"}} />
        </div>
    )
}
    
export default SignatureTextToImage