import React from "react";
import {Skeleton, Card, CardContent, Grid} from '@mui/material';

const ProfileSignatureSkeletonComponent = (breakpoints) => {

    return (
        <Card sx={{borderRadius: 2, padding: 3, paddingTop: 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints == 'xs' || breakpoints == 's' ? 'auto' : 458}}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Card sx={{border: "1px solid #0178f252", height: 90, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center',flexWrap: 'wrap', boxShadow: 'none'}}>
                            <Skeleton variant="rectangular" style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", width: "100%", height: "100%"}} />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{textAlign: "center"}}>
                        <Skeleton variant="rectangular" style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>  
    )
}

export default ProfileSignatureSkeletonComponent