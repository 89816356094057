import { Box, Typography } from "@mui/material"
import { log } from "../../../console-config";

const SignatoryElementComponent = ({element, onLoadImg, isViewing}) => {
    log("element 123", element);
    return(
        <Box sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', cursor: 'pointer', width: 'inherit', height: 'inherit'}}>
            {
                element.isSigned ?
                <img src={element.signature} style={{width: element.width, height: element.height, objectFit: "contain",}} onLoad={(e) => onLoadImg(e)} />
                :
                <Typography noWrap sx={{textAlign: 'center', whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', margin:'auto'}}>{isViewing ? !element.value ? "Signatory" : element.value : "Click to sign"}</Typography>
            }
        </Box>
    )
}

export default SignatoryElementComponent