// const website_URL = "http://localhost:4500"
const website_URL = "https://esign.nextix.co"
const accountPublicServiceBaseURL = "https://api.cybergems.com/esign-account-public-v1"
const accountServiceBaseURL = "https://api.cybergems.com/esign-account-v1"
const documentServiceBaseURL = "https://api.cybergems.com/esign-dw-v1"
const documentPublicServiceBaseURL = "https://api.cybergems.com/esign-dw-public-v1"
const fileServiceBaseURL = "https://api.cybergems.com/file-converter-v1"

const subscriptionKey = "cf57f0a8dba3418289c5578f3138807e"

export default {

    ovsign_account_service : {
        url: accountServiceBaseURL,
        subscriptionKey : subscriptionKey,
    },

    ovsign_account_public_service : {
        url: accountPublicServiceBaseURL,
        subscriptionKey : subscriptionKey,
    },

    ovsign_document_workflow_service : {
        url: documentServiceBaseURL,
        subscriptionKey : subscriptionKey,
    },

    ovsign_document_workflow_public_service : {
        url: documentPublicServiceBaseURL,
        subscriptionKey : subscriptionKey,
    },

    ovsign_account_public_service : {
        url: accountPublicServiceBaseURL,
        subscriptionKey : subscriptionKey 
    },

    ovsign_document_workflow_public_service : {
        url : documentPublicServiceBaseURL,
        subscriptionKey : subscriptionKey 
    },

    ovsign_file_service : {
        url : fileServiceBaseURL,
        subscriptionKey : subscriptionKey 
    },

    msal_url : {
        url: website_URL,
    },

    msal_authConfig:{
        clientId:'d2b06c12-a15a-453d-ae29-9823105d648e', 
        knownAuthorities: ["nextixeid.b2clogin.com"], 
        authority:'https://nextixeid.b2clogin.com/tfp/nextixeid.onmicrosoft.com/B2C_1_esign', 
        postLogoutRedirectUri: website_URL,
    },
    
    b2c_scope:{ 
        scopes:[
            "https://nextixeid.onmicrosoft.com/d2b06c12-a15a-453d-ae29-9823105d648e/eSign" 
        ]
    } 
};