import React, {useEffect, useState} from "react";
import {styled, useTheme} from '@mui/material/styles';
import {useNavigate} from "react-router-dom";
import MuiDrawer from '@mui/material/Drawer';
import {Tooltip, Typography, Button, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, List, Box} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {tooltipClasses} from '@mui/material/Tooltip';
import DocumentsIcon from "../icons/file.png";
import FilesIcon from "../icons/files.png";
import logo from "../icons/nextix-logo.png";
import DashboardIcon from "../icons/dashboard.png";
import ovcodeLogo from "../icons/new-ovcode-logo.png";
import ovcLogo from "../icons/ovclogo.png";
import AnalyticsIcon from "../icons/analytics.png";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ReportIcon from "../icons/document.png";
import eIDIcon from "../icons/link.png"
import createDocumentIcon from "../icons/add-document.png";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}} placement="right-start" />

    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#0178f2",
            color: "#fff",
            boxShadow: theme.shadows[1],
            fontSize: 11,
            fontWeight: "bold",
            padding: 7,
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: "#0178f2",
        },
    }
));
  

const Sidebar = (props) => {

    const {open, handleDrawerClose} = props;
    const url = window.location.pathname;

    const theme = useTheme();
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        if (url == "/dashboard" ) 
        {
            setSelectedIndex(0);

        } else if (url == "/documents") {
            setSelectedIndex(1);

        } else if (url == "/files" || url == "/files/draft" || url == "/files/pending" || url == "/files/completed" || url == "/files/cancelled") {
            setSelectedIndex(2);
        } else if (url == "/analytics") {
            setSelectedIndex(3);
        } else if (url == "/reports") {
            setSelectedIndex(4);
        } else if (url == "/signed-logs" || url == "/profile" || url == "/activity-logs" || url == "/logs" || url == "/send-inquiry" || url == "/pdf-template" || url == "/view-template") {
            setSelectedIndex(5);
        }
    }, [url])

    const handleListMenuClick = (e, index) => {
        setSelectedIndex(index);

        let route;
        
        switch (index) {
            case 0:
                route = '/dashboard';
                break;

            case 1:
                route = '/documents';
                break;
            
            case 2:
                route = '/files';
                break;

            case 3:
                route = '/analytics';
                break;
            
            case 4:
                route = '/reports';
                break;

            default:
                route = '/dashboard';
        }
        
        navigate(route);
    }


    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <Box sx={{width:'100%', textAlign:'left'}}>
                <Button onClick={() => window.location.href="/dashboard"} variant="text" disableElevation sx={{cursor:'pointer' ,textTransform: "none", ...(!open && {display: "none"})}}
                >
                    <img alt="" src={logo} style={{cursor: "default", height: 25, cursor:'pointer'}}/>
                </Button>
                </Box>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ?  null : <MenuIcon sx={{color: "black"}} />}
                </IconButton>
            </DrawerHeader>

            <List>
                {["Dashboard","Create Document", "Files", "Analytics", "Reports"].map((text, index) => (
                    <LightTooltip arrow sx={{display: (open) ? "none" : "visible"}} 
                        title={
                            (index == 0) ? "Dashboard" 
                        :
                            (index == 1) ? "Create Document" 
                        :   
                            (index == 2) ? "Files" 
                        :
                            (index == 3) ? "Analytics" 
                        :
                            (index == 4) ? "Reports" 
                        :
                            null
                        }
                    > 
                        <ListItem key={index} disablePadding sx={{display: "block"}}>
                            <ListItemButton sx={{minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5}}
                                selected={
                                    (index == 0) ? selectedIndex === 0 
                                : 
                                    (index == 1) ? selectedIndex === 1 
                                :
                                    (index == 2) ? selectedIndex === 2
                                :
                                    (index == 3) ? selectedIndex === 3
                                :
                                    (index == 4) ? selectedIndex === 4
                                :
                                    null
                                }

                                onClick={(e) => handleListMenuClick(e, index)}
                            >
                                <ListItemIcon sx={{minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center"}}>
                                    {
                                        (index == 0) ? 
                                            <img alt="account-profile-icon" src={DashboardIcon} style={{height: 25}} />
                                    :   (index == 1) ? 
                                            <img alt="document-icon" src={createDocumentIcon} style={{height: 25}} />
                                    :
                                        (index == 2) ? 
                                            <img alt="files-icon" src={FilesIcon} style={{height: 25}} />
                                    :
                                        (index == 3) ? 
                                            <img alt="logs-icon" src={AnalyticsIcon} style={{height: 25}} />
                                    :
                                        (index == 4) ?
                                            <img alt="report-icon" src={ReportIcon} style={{height: 25}}/>
                                    :
                                        null
                                    }
                                </ListItemIcon>

                                <ListItemText sx={{opacity: open ? 1 : 0}} 
                                    primary={
                                        <Typography sx={{fontSize: 14, color: 
                                            (selectedIndex === 0 && index === 0) ? "#0178f2" 
                                        :
                                            (selectedIndex === 1 && index === 1) ? "#0178f2" 
                                        :
                                            (selectedIndex === 2 && index === 2) ? "#0178f2" 
                                        :
                                            (selectedIndex === 3 && index === 3) ? "#0178f2" 
                                        :
                                            (selectedIndex === 4 && index === 4) ? "#0178f2" 
                                        :
                                            null
                                        }}>
                                            {text}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    </LightTooltip>
                ))}
            </List>
            <Box sx={{flexGrow: 1}}/>
            {open ?
                <Typography sx={{textAlign:'center', fontSize: 12, display:'flex', alignItems:'center', margin:'auto', pb: 2}}>
                    Powered by <img src={ovcodeLogo} style={{height: 25}}/>
                </Typography>
            :
                <img src={ovcLogo} style={{height: 30, width:30, margin:'auto', marginBottom: 10}}/>
            }
        </Drawer> 
    )
}

export default Sidebar