import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {ThreeDots} from "react-loader-spinner";
import { useEffect } from "react";

const SaveAsDraftDialog = (props) => {

    const {openSaveAsDraft, handleOnClickNo, handleOnClickYes, saving, saved, getDocumentById} = props;

    useEffect(() => {
        if(saved){
            window.location.href="/files";
        }
    },[saved])

    return(
        <Dialog open={openSaveAsDraft} onClose={handleOnClickNo} handleCloseSaveAsDraft={handleOnClickYes} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
              Save As Draft Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography sx={{fontSize: 16}}>
                  Are you sure you want to save <b>{getDocumentById?.title}</b> as Draft?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{width: 120, borderRadius: 10 }} 
                    onClick={handleOnClickNo}
                >
                    Cancel
                </Button>


                <Button variant="contained" sx={{width: saving ? 180 : 120, backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                    onClick={(e) => handleOnClickYes(e)}
                    startIcon={saving &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                  {saving ? 'Saving' : 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SaveAsDraftDialog