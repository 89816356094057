import React, { useContext } from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useMediaQuery } from '@mui/material';
import { DialogProvider } from "./utils/common-dialog";
import { AlertDialogProvider } from "./utils/common-alert-dialog";
import { TouchBackend } from 'react-dnd-touch-backend'
import useWidth from '../hooks/useWidth';
import { isMobile } from 'react-device-detect';

const OVCPDFEditorContext = React.createContext()

export const OVCPDFEditorProvider = ({children}) => {

    // const breakpoints = {
    //     mobile: useMediaQuery(`(max-width: 600px)`),
    //     tablet: useMediaQuery(`(min-width: 640px) and (max-width: 1023px)`),
    //     laptop: useMediaQuery(`(min-width: 1024px) and (max-width: 1199px)`),
    //     desktop: useMediaQuery(`(min-width: 1200px)`),
    //   };

    //   const staticTablet = useMediaQuery(`(min-width: 1600px) and (max-width: 2560px)`);
      
    //   let backend = HTML5Backend; // Default to HTML5Backend
      
    //   if (breakpoints.mobile) {
    //     backend = TouchBackend; // Enable mouse events for touch devices
    //   }

    const DNDBackend = isMobile ? TouchBackend : HTML5Backend;

    return(
        <OVCPDFEditorContext.Provider value={null}>
            <DndProvider backend={DNDBackend}>
                <AlertDialogProvider>
                    <DialogProvider>
                        {children}
                    </DialogProvider>
                </AlertDialogProvider>
            </DndProvider>
        </OVCPDFEditorContext.Provider>
    )
}