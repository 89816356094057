import { Box, IconButton, Typography, Grid } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import UserLoggedListComponent from "../components/user-logged/user-logged-list.component";
import useWidth from "../hooks/useWidth";
import DocumentWorkflowService from "../services/api/documents";
import { useEffect, useState } from "react";
import useUserLogs from "../hooks/userUserLogs";
import LogsGuide from "../components/walkthrough/logs-guide";
import HelpIcon from '@mui/icons-material/Help';
import { log } from "../console-config";

function SignInLogsHeader(){

    return(
        <Box sx={{padding: 1, width:'100%', borderRadius: 0}}>
            <Grid container  sx={{width:'98%', margin:'auto'}}>
                <Grid item xs={12} >
                    <Typography variant='h6' sx={{fontWeight: "bold", color: "#21649e"}}>Sign in Logs</Typography>
                </Grid>
                
            </Grid>
        </Box>
    )
}

function UserLoggedScreen () {

    const breakpoints = useWidth()
    const {userLogs, sessionLogs, isFetchingLogs, pagination, setFetchingLogs} = useUserLogs()
    const [isWalkthrough, setIsWalkthrough] = useState(null)
    const [showWalkthrough, setShowWalkthrough] = useState(false);

    var module_id = "LGS"

    const handleWalkthroughButtonClick = () => {
        setShowWalkthrough(true);
    };

    const reopenJoyride = () => {
        setShowWalkthrough(true);
    };

    const retrieveWalkthrough = () =>{
        DocumentWorkflowService.getWalkthrough(module_id).then((res)=>{
            log('is user walkthrough' + res)
            setIsWalkthrough(res.data.data.is_walkthrough)
        })
    }

    useEffect(()=>{
        retrieveWalkthrough(module_id)
    },[module_id])

    useEffect(()=>{
        userLogs()
    },[])

    return(
        <Box sx={{overflow: 'hidden'}}>
            <SignInLogsHeader />
            
            <Box style={{flexGrow: 1, margin:'auto'}}>
                <UserLoggedListComponent breakpoints={breakpoints} sessionLogs={sessionLogs} isFetchingLogs={isFetchingLogs}
                pagination={pagination} setFetchingLogs={setFetchingLogs} handleUserLogs={userLogs}
                />
            </Box>
            <div style={{position:'fixed', bottom: "5px", right:"3%", cursor:'pointer'}} >
                {showWalkthrough ? (
                    <IconButton variant="contain" onClick={handleWalkthroughButtonClick}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                ) : (
                    <IconButton variant="contain" onClick={reopenJoyride}><HelpIcon sx={{height: 40, width: 40, color: '#0178f2'}} /></IconButton>

                )}
            </div>
            {(isWalkthrough === false) && (
                <LogsGuide setShowWalkthrough={setShowWalkthrough} />
            )}

            {showWalkthrough && <LogsGuide showWalkthrough={showWalkthrough} setShowWalkthrough={setShowWalkthrough}/>}
        </Box>
    )
}

export default UserLoggedScreen;