import { Toolbar, IconButton, Box, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DraggableElementsToolbarContainer from './DraggableElementsToolbarContainer';
import DraggableElementComponent from './DraggableElementComponent';
import { generateQRCodeBase64 } from '../../utils/QRCodeUtil';
import { useEffect, useState } from 'react';
import DraggableFillableElementsPanelContainer from './DraggableFillableElementsPanelContainer';
import DraggableSignatoryElementForMobile from './DraggableSignatoryElementForMobile';

export default function ToolsPanelToolbarComponent({draggable_elements, draggable_fillable_elements, signees, onAddSignee, onUpdateSignees, getDocumentById}){
    const [draggableElements, setDraggableElements] = useState([]);
    
    useEffect(() => {
        setDraggableElements(draggable_elements);

        let qrcodeEl = draggable_elements.find((el) => el.type == 'qrcode');

        const generateElementsQRCodeBase64 = async () => {
            let qrcodeBase64 = await generateQRCodeBase64(qrcodeEl.value);

            setDraggableElements((prev) => prev.map(el => el.type == "qrcode" ? ({...qrcodeEl, value: qrcodeBase64}) : el))
        }

        if (qrcodeEl) generateElementsQRCodeBase64();

    }, [JSON.stringify(draggable_elements)])
    return(
        <Toolbar sx={{color: 'tools_panel.text.primary', bgcolor: 'tools_panel.background.default', height: '75px', justifyContent:'center'}}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0.5}
            >
                    {
                        draggableElements.map((draggable_element, i)=>{
                            return(
                                <Box key={i} sx={{height: '100%'}}>
                                    <DraggableElementComponent draggable_element={draggable_element}/>
                                </Box>
                            )
                        })
                    }
                    <DraggableSignatoryElementForMobile draggable_fillable_elements={draggable_fillable_elements} 
                        signees={signees} onAddSignee={onAddSignee} onUpdateSignees={onUpdateSignees} getDocumentById={getDocumentById}
                    />
            </Stack>
        </Toolbar>
    )
}