import { Box, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography, Button, TableCell, TableRow, TableContainer, Paper, Table, TableBody, TableHead, Pagination, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import CachedIcon from '@mui/icons-material/Cached';
import DocumentWorkflowService from "../services/api/documents";
import { log } from "../console-config";
import ActivityLogsComponent from "../components/activity-logs/activitiy-logs.component";
import useActivityLogs from "../hooks/useActivityLogs";
import ActivityLogsSkeletonComponent from "../components/skeleton/activity-logs-list.skeleton.component";
import { format } from "date-fns";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import ExtractConfirmationModal from "../components/activity-logs/dialog/extract-confirmation-modal.component";
import { Download } from "@mui/icons-material";
import useWidth from "../hooks/useWidth";
import SearchIcon from '@mui/icons-material/Search';

const TableHeader = () => {
    const tablecell = [
        // {label : 'User ID'},
        {label : 'ID'},
        {label : 'Email'},
        {label : 'Module'},
        {label : 'Actions'},
        {label : 'Activity'},
        {label : 'User Agent'},
        {label : 'Timestamp'},
    ];

    return(
        <TableHead sx={{borderRadius: 2}}>
            <TableRow sx={{position: "sticky", bgcolor:'none', borderRadius: 2}}>
                {tablecell.map((value)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, backgroundColor: '#0178f2', color: '#0178f2', bgcolor:'none'}}>{value.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )    
}

const SignedLogsHeader = ({searchInputField, keyPressInput, setActivity, breakpoints, handleSearchActivity}) => {

    return(
        <Box sx={{p: 1, pl: 2, width:'100%', borderRadius: 0}}>
            <Grid container  sx={{width:'98%', margin:'auto'}}>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' sx={{fontWeight: "bold", color: "#21649e"}}>Activity Logs</Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{textAlign: breakpoints == 'xs' || breakpoints == 's' ? 'center' : 'end'}}>
                    <TextField
                        className="card-search"
                        variant="outlined"
                        autoComplete="off"
                        defaultValue={setActivity}
                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor:'white', width: breakpoints == 'xs' || breakpoints == 's' ? 'auto' : 375 } }}
                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle:'italic' } }}
                        placeholder="Search Activity..."
                        InputProps={{
                            startAdornment: <InputAdornment position="start" ><p style={{fontWeight:'bold', fontSize: 12}}>Search:</p></InputAdornment>,
                            endAdornment: <InputAdornment position="end"> <IconButton aria-label="toggle password visibility" edge="end" onClick={handleSearchActivity}><SearchIcon /></IconButton></InputAdornment>,
                        }}
                        onChange={searchInputField}
                        onKeyDown={keyPressInput}
                    />
                </Grid>
            </Grid>
            
        </Box>
    )
}

export function ActivityLogsScreen () {

    const breakpoints = useWidth();
    const [year, setYear] = useState("");
    const [month, setMonth] = useState(""); 
    const [monthValue, setMonthValue] = useState(""); 
    const [isMonthSelected, setIsMonthSelected] = useState(false);
    const [module, setModule] = useState('')
    const [actions, setActions] = useState('')
    const [page, setPage] = useState(1)
    const [activity, setActivity] = useState('')

    const {activityLogsList, logsList, activityListFilter, loading, pagination} = useActivityLogs();
    const [openExtractConfirmation, setOpenExtractConfirmation] = useState(false);
    const [download, setDownload] = useState(false);
    
    const handleOpenModal = () => {
        setOpenExtractConfirmation(true)
    }

    const handleCloseModal = () => {
        setOpenExtractConfirmation(false)
    }

    const handleSearchModule = () => {
      if(module){
        activityListFilter(activity,module, actions, monthName, year, page)
      }
    }

    const handleSearchActivity = () => {
      if(activity){
        activityListFilter(activity, module, actions, monthName, year, page)
      }
    }

    const handleSearchActions = () => {
      if(actions){
        activityListFilter(activity,module, actions, monthName, year, page)
      }
    }

    function generateArrayOfYears() {
        var current_year = new Date().getFullYear();
        var years = [];

        for (var i = 2022; i <= current_year; i++) {
            let year = { value: i };
            years.push(year);
        }

        return years;
    }

    var years = generateArrayOfYears();

    years.sort(function (a, b) {
        return b.value - a.value;
    });

    function getMonthNames() {
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        return monthNames.map((monthName, index) => {
            const monthValue = (index + 1).toString().padStart(2, '0');
            return {
                name: monthName,
                value: monthValue,
            };
        });
    }

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const monthName = monthNames[monthValue - 1];

    const searchInputField = (e) => {
        setActivity(e.target.value)
    }

    const keyPressInput = (e) => {
        if (e.keyCode === 13) {
            activityListFilter(activity, module, actions, monthName, year, page)
        }
    }

    const searchModule = (e) => {
        setModule(e.target.value)
    }

    const keyPressModule = (e) => {
        if (e.keyCode === 13) {
            activityListFilter(activity,module, actions, monthName, year, page)
        }
    }

    const handleChangePagination = (event, page) => {
        setPage(page);
        if(module || actions){
            activityListFilter(activity,module, actions, monthName, year, page)
        }else{
            activityLogsList(page, download)
        }
    };

    const searchActions = (e) => {
        setActions(e.target.value)
    }

    const keyPressActions = (e) => {
        if (e.keyCode === 13) {
            activityListFilter(activity, module, actions, monthName, year, page)
        }
    }

    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        const mName = monthNames[event.target.value - 1];
        const paddedMonth = selectedMonth.toString().padStart(2, '0');
        setMonth(paddedMonth);
        setMonthValue(selectedMonth);
        setIsMonthSelected(true);
        if(month || year){
            activityListFilter(activity,module, actions, mName, year, page)
        }
        
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
        const yearChange = event.target.value;
        setIsMonthSelected(false);
        if(month || year){
            activityListFilter(activity, module, actions, monthName, yearChange, page)
        }
    };

    const reload = () => {
        setActivity("")
        setModule("")
        setActions("")
        setMonthValue("")
        setYear("")
        setIsMonthSelected(false);
        activityLogsList()
        setPage(1)
    }

    const generatePDF = (mappedActivityList) => {
        
        const doc = new jsPDF({
            orientation: "landscape" // Set the orientation to landscape
        });

        doc.text(`Activity Report`, 10, 10);
      
        const columns = ["ID", "Email", "Module", "Actions", "Activity", "User Agent", "Timestamp"];
        const data = mappedActivityList?.map(item => [item.project_id, item.email, item.module, item.actions, item.activity, item.user_agent, item.timestamp]);

        const overallTotal = mappedActivityList?.reduce((total, item) => total + item.count, 0);
      
        doc.autoTable({
          head: [columns],
          body: data,
          startY: 20
        });
        doc.setFontSize(12); // Set the font size to 12

        
        // Calculate the position to display the "Overall Total" text below the right side of the table
        const pageWidth = doc.internal.pageSize.getWidth();
        const textWidth = doc.getStringUnitWidth(`Overall Total: ${overallTotal}`) * 12; // 12 is the font size
        const textX = pageWidth - textWidth - -35; // Adjust the position as needed
        const textY = doc.autoTable.previous.finalY + 10; // Adjust the vertical position as needed

        // doc.text(`Total Authentication: ${overallTotal}`, textX, textY);

      
        doc.save('Reports.pdf');
        setDownload(true)
        handleCloseModal()
    };

    const generateExcel = (mappedActivityList) => {
        try {
            const wb = XLSX.utils.book_new();
    
            // Creating worksheet with merged cells for Month and Year in the first row
            const ws = XLSX.utils.aoa_to_sheet([[`Activity Report`, "", "", ""]]);
            ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }];
    
            // Adding headers in the second row
            XLSX.utils.sheet_add_aoa(ws, [["ID", "Email", "Module", "Actions", "Activity", "User Agent", "Timestamp"]], { origin: 'A2' });
    
            // Adding data from mappedDataX starting from the third row
            const dataRows = mappedActivityList.map(item => [item.project_id, item.email, item.module, item.actions, item.activity, item.user_agent, item.timestamp]);
            XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: 'A3' });
    
            XLSX.utils.book_append_sheet(wb, ws, `Logs`);
            const excelFileName = `Activity Report.xlsx`;
            XLSX.writeFile(wb, excelFileName);
            handleCloseModal()
        } catch (error) {
            console.error("Error generating Excel:", error);
            // Handle the error (e.g., show a message to the user)
        }

    };

    const handleDownloadPDF = (file) => {
        let limit = 1000;
        if(activity || module || actions || monthName || year) {
            DocumentWorkflowService.activityListFilter(activity, module, actions, monthName, year, page, limit).then((res)=>{
                const mappedActivityList = res.data.data?.map(item => {
                    return {
                        project_id: item?.id,
                        email: item?.email,
                        module: item.module,
                        actions: item.actions,
                        activity: item.activity,
                        user_agent: item.user_agent,
                        timestamp: format(new Date(item?.timestamp), 'PPpp')
                    };
                });
                if(file ==="PDF"){
                    generatePDF(mappedActivityList)
                    handleCloseModal(false)
                }else{
                    generateExcel(mappedActivityList)
                    handleCloseModal(false)
                }
            })
        } else {
            DocumentWorkflowService.activityLogsList(page, limit).then((res)=>{
                const mappedActivityList = res.data.data?.map(item => {
                    return {
                        project_id: item?.id,
                        email: item?.email,
                        module: item.module,
                        actions: item.actions,
                        activity: item.activity,
                        user_agent: item.user_agent,
                        timestamp: format(new Date(item?.timestamp), 'PPpp')
                    };
                });
                if(file ==="PDF"){
                    generatePDF(mappedActivityList)
                    handleCloseModal(false)
                }else{
                    generateExcel(mappedActivityList)
                    handleCloseModal(false)
                }
            })
        }
    }

    log('get month value', monthName)

    useEffect(()=>{
        activityLogsList(page)
    },[])

    return(
        <Box sx={{overflow:'hidden'}}>
            <SignedLogsHeader breakpoints={breakpoints} handleSearchActivity={handleSearchActivity} searchInputField={searchInputField} keyPressInput={keyPressInput} setActivity={setActivity} />
            <Grid container>
                <Grid item xs={12} sm={12} sx={{ display: 'flex', alignItems: 'center', alignSelf: 'center', mt: 3, mb: 0, }}>
                    <Stack spacing={3} sx={{width:'97%', margin:'auto', alignItems:'center'}}
                    direction={{ xs: 'column', sm: 'row' }}
                    >
                                <TextField
                                    className="card-search"
                                    variant="outlined"
                                    autoComplete="off"
                                    value={module}
                                    sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor:'white', width: 300 } }}
                                    inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle:'italic' } }}
                                    placeholder="Search Module..."
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start" ><p style={{fontWeight:'bold', fontSize: 12}}>Module:</p></InputAdornment>,
                                        endAdornment: <InputAdornment position="end"> <IconButton aria-label="toggle password visibility" edge="end" onClick={handleSearchModule}><SearchIcon /></IconButton></InputAdornment>,
                                    }}  
                                    onChange={searchModule}
                                    onKeyDown={keyPressModule}
                                />
                                

                                <TextField
                                    className="card-search"
                                    variant="outlined"
                                    autoComplete="off"
                                    value={actions}
                                    sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor:'white', width: 300 } }}
                                    inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle:'italic' } }}
                                    placeholder="Search Actions..."
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><p style={{fontWeight:'bold', fontSize: 12}}>Actions:</p></InputAdornment>,
                                        endAdornment: <InputAdornment position="end"> <IconButton aria-label="toggle password visibility" edge="end" onClick={handleSearchActions}><SearchIcon /></IconButton></InputAdornment>,
                                    }}
                                    onChange={searchActions}
                                    onKeyDown={keyPressActions}
                                />
                          
                        

                        <Box sx={{flexGrow: 1}}/>
                        <FormControl >
                        <InputLabel id="demo-simple-select-label" sx={{mt: -.8, fontSize: 12, fontWeight:'bold'}}>Month</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Age"
                            value={monthValue}
                            sx={{ width: 175, height: 38, borderRadius: 10, backgroundColor: 'white' }}
                            onChange={handleMonthChange}
                            inputProps={{ sx: { mr: "28px", fontSize: 12, textAlign: "center" } }}
                            
                        >
                            {getMonthNames().map((monthInfo, index) => (
                                <MenuItem key={monthInfo.value} value={monthInfo.value}>
                                    {monthInfo.name}
                                </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                        
                        <FormControl>
                            <InputLabel id="demo-simple-select-label" sx={{mt: -.8, fontSize: 12, fontWeight:'bold'}}>Year</InputLabel>
                            <Select
                                // className="card-year"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Year"
                                value={year}
                                sx={{ width: 175, height: 38, borderRadius: 10, backgroundColor: 'white',
                                '&.Mui-focused': {
                                    // Remove the border when the Select is focused (selected)
                                    border: 'none',
                                },
                                    border: isMonthSelected ? '1px solid red' : '',
                                }}
                                onChange={handleYearChange}
                                inputProps={{ sx: { mr: "28px",fontSize: 12, textAlign: "center" } }}
                            >
                                {years?.map((year) => {
                                    return (
                                        <MenuItem key={year.value} value={year.value}>
                                            {year.value}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container sx={{p: 3, pt: 0}}>
                <Box sx={{width:'100%', display:'flex', alignItems:'center'}}>
                    <Button onClick={reload} sx={{textTransform:'none', justifyContent:'end', fontSize: 12, fontWeight:'bold'}} > <CachedIcon sx={{height: 20, width: 20}}/> 
                        Reload
                    </Button>
                    <Box sx={{flexGrow: 1}}/>
                    <Button onClick={handleOpenModal} sx={{textTransform:'none', fontSize: 12, fontWeight:'bold'}}><Download sx={{height: 20, width: 20}}/> Export </Button>
                </Box>

                <TableContainer component={Paper} sx={{border: 'none', borderRadius: 2,  width: '100%'}}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeader/>
                        {loading ?
                        <TableBody><ActivityLogsSkeletonComponent/></TableBody>
                        :
                        <TableBody>
                            {logsList.length > 0 ?
                                <>
                                    {logsList.map((list)=>{
                                        return(
                                            <ActivityLogsComponent list={list}/>
                                        )
                                    })}
                                </>
                            :
                                <>
                                    { !loading && 
                                        (<TableRow key={0} >
                                            <TableCell colSpan={7} align="center">
                                                    <Typography sx={{ textAlign: "center"}}>
                                                        No activity logs.
                                                    </Typography>
                                            </TableCell>
                                        </TableRow>)
                                    }
                                </>
                            }
                            
                                
                        </TableBody>
                        }
                            
                    </Table>
                    {logsList.length === 0 ?
                    null
                    :
                    <Pagination
                        count={pagination}
                        page={page}
                        onChange={handleChangePagination}
                        style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                    />
                    }
                    
                </TableContainer>
            </Grid>
            <ExtractConfirmationModal handleDownloadPDF={handleDownloadPDF} openExtractConfirmation={openExtractConfirmation} handleCloseModal={handleCloseModal}/>
        </Box>
    )
}