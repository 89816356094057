import React, {useState, useEffect, useRef} from 'react';
import { Page } from 'react-pdf';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';

import { useDropHook } from "../hooks/dnd/useDropHook";
import { useElementHook } from "../hooks/useElementHook";

import { dragSourceType, elementModes, elementTypes, errorCodes, pdfPageSizes } from "../common/staticVariables";
import { getReverseProportionCoords, getReverseProportionDimens, getReverseProportionFontSize } from "../../utils/ProportionDimenUtil";

import ElementContainerComponent from "./ElementContainerComponent";
import { getAdjFontSize } from "../../utils/FontUtil";
import { useElementDropHook } from '../hooks/useElementDropHook';
import { isOutsideParentBoundingBox, isOutsideParentBoundingBoxV2 } from '../../utils/BoundingBoxUtil';
import { log } from '../../../console-config';
import { ptToPx, pxToPt } from "../../utils/UnitConverterUtil";
import {useDialog} from "../../utils/common-dialog";



const PDFPageComponent = ({documentId, pdfPages, setPdfPages, numPages, page_number, adjustedPDFWidth, elementProps, onDocumentPageLoadSuccess, onEditElement, onDeleteElement,
     currentUser, onErrorAddSignee, getDocumentById}) => {

    const [elementRef, setElementRef] = useState(null)

    const [currentPage, setCurrentPage] = useState({})
    const [elementResizedData, setElementResizedData] = useState(null)
    const [elementDeletedData, setElementDeletedData] = useState(null)
    const [eSignatureUpdateValueData, setESignatureUpdateValueData] = useState(null)
    const [elementUpdateValueData, setElementUpdateValueData] = useState(null)
    const [newSigneeData, setNewSigneeData] = useState(null)
    const [textElementFontSize, setTextElementFontSize] = useState({id: null, font_size: null})
    const [elementsFromAPI, setElementsFromAPI] = useState([]);

    const pdfPageRef = useRef()

    const {dndRef, dropResult, getCoords, getWithinParentBoundingBoxCoords} = useDropHook(pdfPageRef, dragSourceType)
    // const {elementDropRes, getCoords} = useElementDropHook(dndRef, dropResult)
    const {createElement, getProportionalElementCoords, getReverseProportionElementCoords, getProportionalElementDimens, getReverseProportionElementDimens, getProportionalElementFontSize, getReverseProportionalElementFontSize} = useElementHook(dndRef, currentPage)
    const [duplicateElementData, setDuplicateElementData] = useState({isDuplicate: false, data: null})
    const [parentRef, setParentRef] = useState(null)
    const [hasDoneSetupElementSource, setHasDoneSetupElementSource] = useState(false)
    const [hasDoneLoadElements, setHasDoneLoadElements] = useState(false)
    
    const elements = elementProps.elements
    const setElements = elementProps.setElements
    const deletedElements = elementProps.deletedElements
    const setDeletedElements = elementProps.setDeletedElements
    const elements_to_plot = elementProps.elements_to_plot
    const pdfResult = elementProps.pdfResult
    const setPDFResult = elementProps.setPDFResult
    const signees = elementProps.signees
    const setSignees = elementProps.setSignees
    const signeesOptionLabel = elementProps.signeesOptionLabel
    const onAddToSignees = elementProps.onAddToSignees


    useEffect(() => {
        return () => { 
            localStorage.removeItem("elements")
            localStorage.removeItem("deleted_elements")
        };
    }, []);

    useEffect(() => {
        if (dndRef != null) setParentRef(dndRef)
    }, [dndRef])

    // 1. Setup elements from source
    useEffect(() => {
        // Source could be from API or from Cache
        if (!hasDoneSetupElementSource) {
            setupElementsFromSource(pdfPages, signees);
        }
    }, [hasDoneSetupElementSource, JSON.stringify(pdfPages), JSON.stringify(signees)])

    // 2. Setup elements from API (to be seperated for appending new elements from API)
    useEffect(() => {
        setupElementsFromAPI(elements_to_plot, pdfPages)
    }, [JSON.stringify(elements_to_plot), JSON.stringify(pdfPages)])

    // 3. Load elements
    useEffect(() => {
        if (hasDoneSetupElementSource && !hasDoneLoadElements) {
            console.log("hasDoneSetupElementSource 111 ", hasDoneSetupElementSource);
            console.log("hasDoneLoadElements 111 ", hasDoneLoadElements);
            loadElements(elements);
        }
    }, [hasDoneSetupElementSource, JSON.stringify(elements)])

    // save elements to cache
    useEffect(() => {
        saveElementsToCache(elements, signees)
    }, [JSON.stringify(elements), JSON.stringify(signees)])


    // -------- REGULAR ELEMENTS ------- //
    // Handle drop element
    useEffect(() => {
        if (dropResult) handleDropElement(dropResult)
    }, [JSON.stringify(dropResult)])

    // Handle text element font size
    useEffect(()=> {
        handleTextElementFontSize(textElementFontSize)
    }, [JSON.stringify(textElementFontSize)])

    // Handle resize element
    useEffect(() => {
        if (elementResizedData) handleResizeElement(elementResizedData)
    }, [JSON.stringify(elementResizedData)])

    // Handle remove element
    useEffect(() => {
        if (elementDeletedData) handleRemoveElement(elementDeletedData)
    }, [JSON.stringify(elementDeletedData)])

    // Handle update element
    useEffect(() => {
        if (elementUpdateValueData) handleUpdateValueElement(elementUpdateValueData)
    }, [JSON.stringify(elementUpdateValueData)])

    // Handle update esignature element
    useEffect(() => {
        if (eSignatureUpdateValueData) handleUpdateESignatureValue(eSignatureUpdateValueData)
    }, [JSON.stringify(eSignatureUpdateValueData)])

    // Handle Duplicate elements
    useEffect(()=> {
        if (duplicateElementData.isDuplicate) {
            handleDuplicateElements(duplicateElementData.data)

            // reset
            setDuplicateElementData({isDuplicate: false, data: null})
        }
    }, [JSON.stringify(duplicateElementData)])
    // -------- END OF REGULAR ELEMENTS ------- //



    // -------- FILLABLE ELEMENTS -------//
    // Handle assign new signee to element
    useEffect(() => {
        if (newSigneeData) setElements((prev) => prev.map((el) => el.id == newSigneeData.element.id ? {...el, value: newSigneeData.signee.email_address} : el))
    }, [JSON.stringify(newSigneeData)])

    // Handle deleted signees
    useEffect(() => {
        console.log("signesss aaaa1", signees);
        handleDeletedSignees(signees)
    }, [JSON.stringify(signees)])
    // -------- END OF FILLABLE ELEMENTS ------- //

    

    // Handle PDF result
    useEffect(() => {
        handlePDFResult(pdfPages, elements, signees)
    }, [JSON.stringify(pdfPages), JSON.stringify(elements), JSON.stringify(signees)])

    // save deleted elements to cache
    useEffect(() => {
        console.log("deleted Elemetns aaa", deletedElements);
        localStorage.setItem("deleted_elements", JSON.stringify(deletedElements))
    }, [JSON.stringify(deletedElements)])

    const setupElementsFromSource = (pdfPages, signees) => {
        setElements((prev) => {
            let updated_elements = prev?.map(element => {
                console.log("element aaaa11", element);
                console.log("pdfPages aaaa11", pdfPages);
                console.log("page_number aaaa11", page_number);
                let page = pdfPages.find((item) => item.pageNo == element.page_no)

                let rotation = page.rotate
                let dropRefCurrentWidth = page.dropRefCurrentWidth
                let dropRefCurrentHeight = page.dropRefCurrentHeight
                let pageOrigWidth = page.pageOrigWidth
                let pageOrigHeight = page.pageOrigHeight

                let x = element.x
                let y = element.y
                let width = element.width
                let height = element.height
                let font_size = element.font_size;
                let hasPageDimensChanged = false;
                let isElementFromAPI = false;

                if (element.page_no == page.pageNo && (dropRefCurrentWidth && dropRefCurrentHeight)) {
                    console.log("elements_to_plot page", page);

                    if (element.page_rect) {
                        // From Cache
                        // For responsive plotting of elements with differenct pdf page sizes from Cache
                        hasPageDimensChanged = Math.round(dropRefCurrentWidth) != element.page_rect.width
                        if (hasPageDimensChanged) {
                            pageOrigWidth = element.page_rect.width
                            pageOrigHeight = element.page_rect.height
                        }
                    } else {
                        // From API
                        isElementFromAPI = typeof element.id === 'number' && Number.isInteger(element.id)
                    }

                    let {x: reversedX, y: reversedY} = getReverseProportionElementCoords(element.x, element.y, dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOrigWidth, pageOrigHeight)
                    let {w: reversedWidth, h: reversedHeight} = getReverseProportionElementDimens(rotation, dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, element.width, element.height)
                    
                    let reversedFontSize;
                    if(element.type == elementTypes.currentDate || element.type == elementTypes.textField){
                        let {font_size} = getReverseProportionalElementFontSize(rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, element.font_size)
                        reversedFontSize = font_size;
                    }

                    x = hasPageDimensChanged || isElementFromAPI ? reversedX : x;
                    y = hasPageDimensChanged || isElementFromAPI ? reversedY : y;
                    width = hasPageDimensChanged || isElementFromAPI ? reversedWidth : width;
                    height = hasPageDimensChanged || isElementFromAPI ? reversedHeight : height;
                    font_size = hasPageDimensChanged || isElementFromAPI ? reversedFontSize : font_size;

                    element = {...element, id: element.id != undefined ? element.id : uuidv4(), x, y, width, height, font_size, page_rect: {width: dropRefCurrentWidth, height: dropRefCurrentHeight}};

                    return element
                } else {
                    return element
                }
            })

            console.log("updated_elements 111", updated_elements);

            //Handle remove elements that was assigned from deleted signee
            updated_elements.filter((el) => el.type == elementTypes.signatory && el.value != signees.find((signee) => signee.email_address == el.value)?.email_address)

            setHasDoneSetupElementSource(true)
            
            return updated_elements
        })
    }

    const setupElementsFromAPI = (elements, pdfPages) => {
        let updated_elements_from_api = elements?.map(element => {
            console.log("element setupElementsFromAPI", element);
            console.log("pdfPages setupElementsFromAPI", pdfPages);
            console.log("page_number setupElementsFromAPI", page_number);
            let page = pdfPages.find((item) => item.pageNo == element.page_no)
            if (element.page_no == page.pageNo) {
                console.log("elements_to_plot page setupElementsFromAPI", page);

                let rotation = page.rotate
                let dropRefCurrentWidth = page.dropRefCurrentWidth
                let dropRefCurrentHeight = page.dropRefCurrentHeight
                let pageOrigWidth = page.pageOrigWidth
                let pageOrigHeight = page.pageOrigHeight
                let font_size;

                let {x, y} = getReverseProportionElementCoords(element.x, element.y, dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOrigWidth, pageOrigHeight)
                let {w: width, h: height} = getReverseProportionElementDimens(rotation, dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, element.width, element.height)
                
                if(element.type == elementTypes.currentDate || element.type == elementTypes.textField){
                    let {font_size: reversedFontSize} = getReverseProportionalElementFontSize(rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, element.font_size)
                    font_size = reversedFontSize;
                }
                element = {...element, id: element.id != undefined ? element.id : uuidv4(), x, y, width, height, font_size, page_rect: {width: dropRefCurrentWidth, height: dropRefCurrentHeight}};
                return element
            }
        })
        setElementsFromAPI(updated_elements_from_api)
    }

    const loadElements = (elements) => {
        console.log("edit here bbbb", elements);
        let deleted_elements_from_cache = JSON.parse(localStorage.getItem("deleted_elements"));
        let deletedElements = !deleted_elements_from_cache ? [] : deleted_elements_from_cache
        
            // 1. Append new elements from API to already filtered elements
        let new_elements_to_append = elementsFromAPI.filter((item) => item.id != elements.find((el) => item.id == el.id)?.id)
        console.log("new_elements_to_append here bbbb", new_elements_to_append);
        elements = [...elements, ...new_elements_to_append]
        console.log("elements here bbbb", elements);

            // 2. Filter elements with deleted cache elements
        let filtered_elements = elements.filter((item) => item.id != deletedElements.find((el) => item.id == el.id)?.id)
        console.log("filtered_elements here bbbb", filtered_elements);

            // 3. handle signees and load elements
        let signees = filtered_elements.filter((item) => item.type == elementTypes.signatory && item.value).map((item) => ({id: item.id, email_address: item.value, isNew: false}))
        console.log("signees bbb11", signees);
        let remove_duplicate_signees = signees.filter((item, index, self) =>
            index === self.findIndex((t) => (
              t.email_address === item.email_address
            ))
        );

        setSignees(remove_duplicate_signees)
        setElements(filtered_elements)
        setHasDoneLoadElements(true)
    }

    const handlePDFResult = (pdfPages, elements, signees) => {
        executePDFResult(pdfPages, elements, signees)
    }

    const executePDFResult = (pdfPages, elements, signees) => {
        console.log("elements jjjj", elements);
        let pdf_result_elements = elements.map((el) => handleGetPDFResultElement(pdfPages, el))
        // let filtered_elements = pdf_result_elements.filter((el) => el.value != null || el.value != undefined)
        let pdf_result_signatory = handleGetPDFResultSignatories(elements, signees)
        setPDFResult({...pdfResult, elements: pdf_result_elements, signatory: pdf_result_signatory})
    }

    const handleGetPDFResultElement = (pdfPages, element) => {
        console.log("element nnnnn1", element);
        console.log("pdfPages nnnnn1", pdfPages);
        let current_page = pdfPages.find((page) => page.pageNo == element.page_no)
        
        if (element.page_no == current_page?.pageNo) {
            
            console.log("currentPage aa11", current_page);
            let rotation = current_page?.rotate
            console.log("rotation aa11", rotation);

            let {x, y} = getProportionalElementCoords(element.x, element.y, current_page?.dropRefCurrentWidth, current_page?.dropRefCurrentHeight, rotation, current_page?.pageOrigWidth, current_page?.pageOrigHeight)
            let {w: width, h: height} = getProportionalElementDimens(element.width, element.height, current_page?.dropRefCurrentWidth, current_page?.dropRefCurrentHeight, rotation, current_page?.pageOrigWidth, current_page?.pageOrigHeight)
            console.log("element width 22", element.width);
            console.log("element height 22", element.height);
            console.log("element x 22", x);
            console.log("element y 22", y);
    
            let elDimens = ({width, height})
            let updated_element_props = {...element, x, y, ...elDimens, value: element.value}
            
            if(element.type == elementTypes.textField || element.type == elementTypes.currentDate){
                console.log("element bbb22222", element);
                let el_font_size = element.font_size
                console.log("plottedElementRef 11", el_font_size);
                console.log("el_font_size bb1 ", element.font_size);

                let {font_size: proportional_font_size} = getProportionalElementFontSize(rotation, current_page?.dropRefCurrentWidth, current_page?.pageOrigWidth, current_page?.pageOrigHeight, el_font_size)
                console.log("proportional_font_size bbb", proportional_font_size);

                updated_element_props.font_size = proportional_font_size;
            }
    
            console.log("updated_element_props 22", updated_element_props);
            return updated_element_props
        }

        return element
    }

    const handleGetPDFResultSignatories = (elements, signees) => {
        let signatory = elements.map((el) => el.type == elementTypes.signatory && el.value)

        let pdf_signees = [];

        if (signees.length == 0) {
            let filter_signees = elements.filter((item) => item.value === signatory?.find((pdfSignee) => pdfSignee == item.value))
            let final_filter_signees = filter_signees.filter((item) => item.value)
            
            pdf_signees = final_filter_signees.map((item) => item.value)
            return pdf_signees
        }

        let filter_signees = signees.filter((item) => item.email_address == signatory?.find((pdfSignee) => pdfSignee == item.email_address))
        pdf_signees = filter_signees.map((item) => item.email_address)

        return pdf_signees
    }

    const saveElementsToCache = (elements, signatory) => {
        let cachedElements = JSON.parse(localStorage.getItem("elements"))
        console.log("cachedElements 888", cachedElements);

        let docElements = {
            [documentId]: {
                elements,
                signatory
            }
        }
        cachedElements = {
            ...cachedElements,
            ...docElements
        }
        localStorage.setItem("elements", JSON.stringify(cachedElements))
    }

    const handleDeletedSignees = (signees) => {
        // remove elements that was assigned from deleted signee
        setElements((prev) => {
            let to_be_remove_assigned_signatory_elements = prev.filter((el) => el.type == elementTypes.signatory && el.value != signees.find((signee) => signee.email_address == el.value)?.email_address)
            console.log("to_be_remove_assigned_signatory_elements 2221 ", to_be_remove_assigned_signatory_elements);
            let remaining_elements = prev.filter((el) => el.id != to_be_remove_assigned_signatory_elements.find((item) => item.id == el.id)?.id)
            console.log("remaining_elements 2221 ", remaining_elements);
            return remaining_elements
        })
    }

    const handleUpdateValueElement = (elementUpdateValueData) => {
        setElements((prev) => {
            let updated_elements = prev.map((el) => {
                if (el.id === elementUpdateValueData.id) return {...el, page_no: page_number, value: elementUpdateValueData.value}
                else return el;
            })
            return updated_elements
        })
    }

    const handleUpdateESignatureValue = (eSignatureUpdateValueData) => {
        setElements((prev) => {
            let updated_elements = prev.map((el) => {
                if (el.type === elementTypes.userSignature) return {...el, page_no: page_number, value: eSignatureUpdateValueData}
                else return el;
            })
            return updated_elements
        })
    }

    const handleRemoveElement = (elementDeletedData) => {
        setElements((prevElements) => {
            let element_to_remove = prevElements.find(el => el.id == elementDeletedData.id)
            let updated_elements = prevElements.filter((element) => element.id !== element_to_remove.id)
            let isSignatory = element_to_remove.type == elementTypes.signatory ? true : false

            // Remove signee if there is no element assigned
            if (isSignatory) {
                setSignees((prevSignees) => {
                    let updated_signees = prevSignees.filter((signee) => signee.email_address == updated_elements.find((element) => element.value == signee.email_address)?.value)
                    return updated_signees
                })
            }

            //add to deleted elements array
            console.log("element_to_remove bbb", element_to_remove);
            if (element_to_remove) {
                setDeletedElements((prev) => [...prev, element_to_remove])
            }

            return updated_elements;
        })

    }

    const handleResizeElement = (elementResizedData) => {
        const parentRect = parentRef.current.getBoundingClientRect();
        log("parentRect ttt", parentRect);
        log("elementRef ttt", elementRef.current);

        if(elementRef.current){
            const childRect = elementRef.current.getBoundingClientRect();
            const isOutsideBox = isOutsideParentBoundingBox(parentRect, childRect)
            log("parentRect ttt", parentRect);
            log("childRect ttt", childRect);
            log("isOutsideBox ttt", isOutsideBox);

            // Update element dimens and coords if element is outside of parent
            if (isOutsideBox) {
                setElements((prev) => {
                    let updated_elements = prev.map((el) => {
                        if (el.id === elementResizedData.id) {
                            const xDiff = childRect.right > parentRect.right ? childRect.right - parentRect.right : 0;
                            const yDiff = childRect.bottom > parentRect.bottom ? childRect.bottom - parentRect.bottom : 0;
                            const adjustedX = el.x - xDiff
                            const adjustedY = el.y - yDiff

                            console.log("Adjusted X:", adjustedX);
                            console.log("Adjusted Y:", adjustedY);

                            // let updated_coords = getCoords(dndRef, el.x, el.y, elementResizedData.width, elementResizedData.height)
                            return {...el, page_no: page_number, x: adjustedX, y: adjustedY, 
                                width: elementResizedData.width, height: elementResizedData.height}
                        }
                        else return el;
                    })
                    return updated_elements
                })
                return;
            }
            
            console.log("elementResizedData abbbbb", elementResizedData);
    
            // Update element dimens
            setElements((prev) => {
                let updated_elements = prev.map((el) => {
                    if (el.id === elementResizedData.id) return {...el, page_no: page_number, width: elementResizedData.width, height: elementResizedData.height}
                    else return el;
                })
                return updated_elements
            })
        }
    }

    const handleDropElement = (elementDropRes) => {
        console.log("elementDropRes bbbb", elementDropRes);
        let isUpdate = elementDropRes.id ? true : false;

        // Add New Element
        if (!isUpdate) {
            let currentPage = pdfPages.find((item) => item.pageNo == page_number)
            console.log("pdfPages lll", pdfPages);
            console.log("currentPage lll", currentPage);
            let page_rect = {width: currentPage?.dropRefCurrentWidth, height: currentPage?.dropRefCurrentHeight}

            let created_element = createElement(elementDropRes.initialPosition, elementDropRes.afterDropPosition, page_number, elementDropRes.type, 
                elementDropRes.width, elementDropRes.height, elementDropRes.x, elementDropRes.y, elementDropRes.value, elementDropRes.signeeId, elementDropRes.font_size, page_rect)

            console.log("created_element bbbb", created_element);

            setElements((prev) => [...prev, created_element])
            return;
        }

        // Update Element
        setElements((prev) => {
            let updated_elements = prev.map((el) => {
                if (el.id === elementDropRes.id) return {...el, page_no: page_number, initialPosition: elementDropRes.initialPosition, 
                    afterDropPosition: elementDropRes.afterDropPosition, x: elementDropRes.x, y: elementDropRes.y}
                else return el;
            })
            return updated_elements
        })
    }

    const handleTextElementFontSize = (textElementFontSizeData) => {
        setElements((prev) => {
            let el = prev.find((item) => item.id == textElementFontSizeData.id)
            console.log("el aaa2222", el);

            if (el) {
                el.font_size = textElementFontSizeData.font_size
            }
            return [...prev]
        })
    }

    const handleOnAssignNewSigneeToElement = (signee, element) => {
        log("handleOnAssignNewSigneeToElement bbb", signee);
        setNewSigneeData({signee, element})
    }

    const handleDuplicateElements = (currentElement) => {
        console.log('gettttttttttttttttttttttttttttttttttttttttttt', currentElement)
        // let id = uuidv4()
        let x = currentElement.x + 20
        let y = currentElement.y + 20

        let currentPage = pdfPages.find((item) => item.pageNo == page_number)
        console.log("currentPage lll", currentPage);
        let page_rect = {width: currentPage?.dropRefCurrentWidth, height: currentPage?.dropRefCurrentHeight}

        let created_element = createElement(currentElement.initialPosition, currentElement.afterDropPosition, page_number, currentElement.type, 
            currentElement.width, currentElement.height, x, y, currentElement.value, currentElement.signeeId, currentElement.font_size, page_rect)
            
        const parentRect = parentRef.current.getBoundingClientRect();
        const isOutsideBox = isOutsideParentBoundingBoxV2(parentRect, currentElement.x, currentElement.y, currentElement.width, currentElement.height)

        if(isOutsideBox){
            created_element.x = created_element.x - 20
            created_element.y = created_element.y - 20
        }

        setElements((prev) => [...prev, created_element])

        // setElements((prev) => {
        //     let updated_elements = prev.map((el) => {
        //         if (el.id === elementDropRes.id) return {...el, page_no: page_number, initialPosition: elementDropRes.initialPosition, 
        //             afterDropPosition: elementDropRes.afterDropPosition, x: elementDropRes.x, y: elementDropRes.y}
        //         else return el;
        //     })
        //     return updated_elements
        // })

    }

    const addElement = (id, type, width, height, x, y, value, signature_id, userId, initialPosition, afterDropPosition, font_size) => {
        
        let dropRefCurrentWidth = dndRef.current.clientWidth
        let dropRefCurrentHeight = dndRef.current.clientHeight
        let pageOrigWidth = currentPage?.originalWidth
        let pageOrigHeight = currentPage?.originalHeight

        let adjustedElDimens = getReverseProportionDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, width, height)
        return {id, page_no: page_number, type, x, y, userId, initialPosition, afterDropPosition, ...adjustedElDimens, value, ...(signature_id ? { signature_id: signature_id } : {}),
            ...((type == elementTypes.textfield || type == elementTypes.date) ? { font_size } : {})}
    }

    function getFontSizes(element, currentPage){
        var orig_font_size = element.font_size ? element.font_size : 16; // 16px as default
        var adjusted_font_size = getAdjFontSize(orig_font_size, currentPage.originalWidth, currentPage.width);
        element.font_size = orig_font_size;
        element.adjusted_font_size = adjusted_font_size;
        return element;
    }

    const handleLoadInitialElements = (page) => {
        let rotate = page?.rotate

        let isLandscape = rotate == 90 || rotate == 270 ? true : false
        let pageWidth = isLandscape ? page?.height : page?.width
        let pageHeight = isLandscape ? page?.width : page?.height

        let dropRefCurrentWidth = pageWidth
        let dropRefCurrentHeight = pageHeight

        let pageOrigWidth = page?.originalWidth
        let pageOrigHeight = page?.originalHeight - page?.view[1]
        let pageNo = page?.pageNumber

        setPdfPages((prev) => {
            let current_page = prev.find((item) => item.pageNo == pageNo)
            if (current_page) {
                current_page.dropRefCurrentWidth = dropRefCurrentWidth
                current_page.dropRefCurrentHeight = dropRefCurrentHeight
                current_page.pageOrigWidth = pageOrigWidth
                current_page.pageOrigHeight = pageOrigHeight
            }
            return prev
        })
    }

    function handleOnLoadSuccessPDFPage(page) {
        log("page mmm", page);
        
        onDocumentPageLoadSuccess(page);
        setCurrentPage(page)
        handleLoadInitialElements(page)
        const elements = document.getElementsByClassName("react-pdf__Page__textContent");
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    return(
        <>
            <p style={{color: "#5e5d5d", fontSize: 12}}>
                Page {currentPage?.pageNumber} / {numPages}
            </p>
            <Page inputRef={dndRef} className={'pdf_page_custom'} pageNumber={page_number} 
            width={adjustedPDFWidth} 
            scale={1}
            onLoadSuccess={handleOnLoadSuccessPDFPage}>
                {
                    elements.map((el) => (
                        <>
                            {(el.page_no == page_number) &&
                                <ElementContainerComponent 
                                    element={el}
                                    currentPage={currentPage}
                                    dndRef={dndRef}
                                    parentRef={parentRef}
                                    setElementRef={setElementRef} elementRef={elementRef}
                                    setElementResizedData={setElementResizedData}
                                    setElementDeletedData={setElementDeletedData}
                                    setElementUpdateValueData={setElementUpdateValueData}
                                    setESignatureUpdateValueData={setESignatureUpdateValueData}
                                    onDeleteElement={onDeleteElement} 
                                    onEditElement={onEditElement} 
                                    signees={signees}
                                    signeesOptionLabel={signeesOptionLabel}
                                    onAddToSignees={onAddToSignees}
                                    onAssignNewSigneeToElement={handleOnAssignNewSigneeToElement}
                                    currentUser={currentUser}
                                    onErrorAddSignee={onErrorAddSignee}
                                    getDocumentById={getDocumentById}
                                    setDuplicateElementData={setDuplicateElementData} setTextElementFontSize={setTextElementFontSize}
                                />
                            }
                        </>
                    ))
                }
            </Page>
        </>
    )
}

export default PDFPageComponent