import React, {useEffect, useState} from 'react';
import {Button, Grid, TextField} from '@mui/material';
import {FileUploader} from "react-drag-drop-files";
import {useSnackbar} from "notistack";
import {convertFileSizeToBytes} from "../../utils/fileSizeToBytesUtils";
import PDFIcon from '../../icons/pdf-icon.png';
import useDocuments from '../../hooks/useDocuments';
import {ThreeDots} from "react-loader-spinner";
import { Document, Page } from 'react-pdf';
import html2canvas from 'html2canvas'; // Make sure to import the html2canvas library
import jsPDF from 'jspdf'; // Make sure to import the jsPDF library
import DocumentCreditLimitErrorDialog from './dialog/document-credit-limit-error-dialog.component';
import FileService from '../../services/api/file';
import useFile from '../../hooks/useFile';
import { log } from '../../console-config';


function FileUploaderComponent(props) {

    const {breakpoints, title, setTitle} = props;

    const {enqueueSnackbar} = useSnackbar();
    const {createDocumentWorkflow, created, id, isCreatingDocw} = useDocuments();
    const {fileUploader} = useFile()

    const [file, setFile] = useState(null);
    // const [title, setTitle] = useState("");
    const [pdfPreview, setPDFPreview] = useState(null);
    const [exactFileSize, setExactFileSize] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [openCreditLimit, setOpenCreditLimit] = useState(false)

    const allowedImageTypes = [
        "image/jpeg",
        "image/png",
        "image/webp",
        "image/svg+xml",
        "image/pjpeg", // .jfif
        "image/jp2",   // .jp2
        "image/tiff",  // .tiff
        "image/tiff",  // .tif (tiff can have the same MIME type)
        "image/svg+xml" // .svg (assuming it's SVG)];
    ];

    const handleChange = (file) => {
        var fileName = file.name;
        var fileExt = fileName.lastIndexOf(".") + 1;
        var mimetype = fileName.substr(fileExt, fileName.length).toLowerCase();
        log('get mime type', mimetype)
        
        setTitle(fileName.split('.').slice(0, -1).join('.'));

        if (mimetype == "pdf"){
            setFile(file);
            setPDFPreview(URL.createObjectURL(file));
        } else if (file.type.startsWith('image/')) {
            if (file.type === 'image/gif') {
                // Reject GIF images
                log('GIF images are not allowed');
                setTitle("");
                enqueueSnackbar("File type not supported", { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else if (allowedImageTypes.includes(file.type)) {
                // Accept other allowed image MIME types
                log('Accepting image file...', file);
                let formData = new FormData();
                formData.append("file", file);

                fileUploader(formData, title, setFile)
            }
        }
        else {
            setTitle("");
            enqueueSnackbar("File type not supported", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    };

    const handleResetFile = (e) => {
        setFile(null);
        setPDFPreview(null);
        setTitle("");
        document.querySelector('input[type="file"]').value = '';
    }

    const handleTemplateNameChange = (e) => {
        const { value } = e.target;
        setTitle(value);
    }


    function formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

     let sizeFile = file?.size;

     let fileSizes = formatBytes(sizeFile);


     useEffect(() => {
        if (fileSizes) {
            convertFileSizeToBytes(fileSizes);
            setExactFileSize(convertFileSizeToBytes(fileSizes));
        }
    }, [fileSizes])

    const handleCreateDocumentWorkflow = () => {

        const fileName = title;

        if (file) {
            if (exactFileSize > 10000000 ) {
                enqueueSnackbar("Uploaded file size exceeds 10MB, please choose another file", { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                let formData = new FormData();
        
                formData.append("title", fileName)
                formData.append("file", file);
                createDocumentWorkflow(formData, setOpenCreditLimit);
            }
        }
    }

    useEffect(()=>{
        if(created){
            window.location.href = '/pdf-template?id=' + id;
        }
    },[created])

    log('get uploaded file', file)

    return(

        <Grid container spacing={2} sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 16, pt: 0, pb: 0}}>

            {pdfPreview &&
                <Grid item xs={12} sx={{textAlign: "end"}}>
                    <Button onClick={(e) => handleResetFile(e)} sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14}}>
                        Clear
                    </Button>
                </Grid>
            }

            <Grid item xs={12} className="DragandDrop">
                <FileUploader
                    multiple={false}
                    handleChange={handleChange}
                    name="file"
                    label="Drag and Drop file here or click here to upload"
                    children={
                        <>
                            {(file) ? 
                                <div 
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "100% !important",
                                        maxWidth: "100% !important",
                                        border: "dashed 2px #b2d6f9",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        flexGrow: 0,
                                        height: "400px",
                                        justifyContent: "center",
                                        margin: "auto",
                                        transition: "border-color .15s linear",
                                        backgroundColor:'#8080802e'
                                    }} 
                                >
                                    <Document file={file} renderMode="canvas">
                                            <Page pageNumber={pageNumber} height={300} />
                                    </Document>
                                </div>
                            :
                                <div
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "100% !important",
                                        maxWidth: "100% !important",
                                        border: "dashed 2px #b2d6f9",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        flexGrow: 0,
                                        height: "400px",
                                        justifyContent: "center",
                                        margin: "auto",
                                        transition: "border-color .15s linear",
                                    }}
                                >
                                <p style={{color: "#666", fontWeight: 600, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : 16}}>
                                    Drag and Drop file here or click here to upload
                                </p>
                            </div>
                            }
                        </>
                    }
                />
                {file && <p style={{fontSize: 12, textAlign:'start', color:'#0178f2', marginBottom: 0}}>Image or PDF file.</p>}
                
            </Grid>

            <Grid item xs={12} sm={12} sx={{textAlign: 'end', alignSelf:'center'}}>
                {file ?
                    <Button variant="contained" className="Submit"
                        sx={{width: isCreatingDocw ? 180 : 120, backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                        onClick = {(e) => handleCreateDocumentWorkflow(e)}
                        startIcon={isCreatingDocw && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />} 
                    >
                        {isCreatingDocw ? "Submitting" : "Submit"}
                    </Button>
                :
                    <Button variant="contained" className="Submit" disabled
                        sx={{width: isCreatingDocw ? 180 : 120, backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'none'}}
                    >
                        Submit
                    </Button>
                }
                
            </Grid>
            <DocumentCreditLimitErrorDialog openCreditLimit={openCreditLimit} setOpenCreditLimit={setOpenCreditLimit}/>
        </Grid>

    )
    
}

export default FileUploaderComponent