import "./App.css";
import {history} from "./router/history.js";
import {BrowserRouter} from "react-router-dom"; 
import Routes from "./router";
import LandingScreen from "./screens/landing-screen";
import {MsalProvider} from "@azure/msal-react";
import AuthService from "./services/auth";
import MainScreen from "./layout/navbar";
import SignaturePadScreen from "./screens/SignaturePadScreen";
import SignPDFScreen from "./screens/SignPDFScreen";
import OvdocsScreen from "./screens/OvdocsScreen";
import SampleComponent from "./components/digital-siganture/sample.component";
import { useEffect, useState } from "react";
import LandingConfirmationComponent from "./components/landing-component/landing-confirmation.component.jsx";


const App = ({publicClientApp}) => {

const url = window.location.pathname;
var isUserLoggedIn = AuthService.isUserLoggedIn();
var timeout;

const TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

useEffect(() => {
  if (isUserLoggedIn) {
    // Set a timeout to automatically logout the user after 5 minutes of inactivity
    timeout = setTimeout(() => {
      isUserLoggedIn = false;
      caches.keys().then(function(cacheNames) {
        // Iterate through all cache storage keys and delete them
        cacheNames.forEach(function(cacheName) {
          caches.delete(cacheName);
        });
      }).then(function() {
        console.log('All cache storage has been cleared.');
        // Clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();
        // Redirect to the landing page
        window.location.href = '/';
      }).catch(function(error) {
        console.error('Error clearing cache storage:', error);
      });
    }, TIMEOUT);
  }

  const resetTimeout = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      isUserLoggedIn = false;
      caches.keys().then(function(cacheNames) {
        // Iterate through all cache storage keys and delete them
        cacheNames.forEach(function(cacheName) {
          caches.delete(cacheName);
        });
      }).then(function() {
        console.log('All cache storage has been cleared.');
        // Clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();
        // Redirect to the landing page
        window.location.href = '/';
      }).catch(function(error) {
        console.error('Error clearing cache storage:', error);
      });
    }, TIMEOUT);
  };

  // Add event listeners for user activity
  window.addEventListener('mousemove', resetTimeout);
  window.addEventListener('keydown', resetTimeout);

  // Clean up the event listeners on component unmount
  return () => {
    clearTimeout(timeout);
    window.removeEventListener('mousemove', resetTimeout);
    window.removeEventListener('keydown', resetTimeout);
  };
}, [isUserLoggedIn]);


    return (
        <div>
            <MsalProvider instance={publicClientApp}>
              <BrowserRouter history={history}>
                  {(isUserLoggedIn) ? 
                    <>
                      {(url !== "/" && url !== "/ovsign/signaturePad" && url !== "/sign" && url !== "/landing-confirmation") ?
                        <MainScreen>
                          <Routes /> 
                        </MainScreen>
                      :
                        (url == '/view-template') ?
                          <Routes /> 
                      :
                        (url == "/ovsign/signaturePad") ? 
                          <SignaturePadScreen />
                      :
                        (url == "/sign") ? 
                          <SignPDFScreen />
                      :
                        (url == "/landing-confirmation") ?
                          <LandingConfirmationComponent/>
                      :         
                        <LandingScreen isUserLoggedIn={isUserLoggedIn}/>                   
                      }
                    </>

                  :   
                      (url == "/ovsign/signaturePad") ?
                        <SignaturePadScreen />
                      : 
                      (url == "/sign") ?
                        <SignPDFScreen />
                      :
                      (url == "/landing-confirmation") ?
                        <LandingConfirmationComponent/>
                      :
                      <LandingScreen isUserLoggedIn={isUserLoggedIn}/>
                  }
              </BrowserRouter>
            </MsalProvider>
        </div>
    )
}

export default App