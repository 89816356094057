import QRCode from 'qrcode';

const generateQRCodeBase64 = async (value) => {
    var opts = {
        errorCorrectionLevel: 'H',
        margin: 0,
    }
    var promise = new Promise((res, rej) => {
        QRCode.toDataURL(value, opts, (err, url) => {
            console.log("url bbbbb", url);
            res(url)
        });
    })
    return promise
}

export {generateQRCodeBase64}